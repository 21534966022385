import styled from "styled-components";
import {Image as ImageComponent} from "../../../components/Image/Image"
import { Typography } from "../../../components/Typography"

const Wrapper = styled.div`
    position: relative;
    margin-top: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`

const Image = styled(ImageComponent)``

const Title = styled(Typography.Headline)`
    & {
        margin-top: 40px;
    }
`

const Description = styled(Typography.SectionTitle)`
    & {
        margin: 8px 0 24px;
    }
`

export const Styled = {
    Wrapper,
    Image,
    Title,
    Description,
}
