import React, { PureComponent } from "react"
import styled, { css, keyframes } from "styled-components";
import { colors } from "../../styles";

const SizeTiny = 20;
const SizeSmall = 32;
const SizeHuge = 72;

export type SpinnerSize = "tiny" | "small" | "huge";

export interface SpinnerProps {
    /** Spinner Size */
    size?: SpinnerSize;
    /** Spinner Class Name */
    className?: string;
}

/** Styled Keyframes */
const rotate = keyframes`
    100%{
        transform: rotate(360deg);
    }
`;

const dash = keyframes`
    0% {
        stroke-dasharray: 0, 150;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 100, 150;
        stroke-dashoffset: -24;
    }
    100% {
        stroke-dasharray: 0, 150;
        stroke-dashoffset: -124;
    }
`

export const StyledCircle = styled.circle<SpinnerProps>`
    animation: ${dash} 1.5s ease-in-out infinite;
    stroke-linecap: round;
    stroke-width: 2;
    stroke: ${colors.neutral.dark};
`;

export const StyledSpinner = styled.svg<SpinnerProps>`
    animation: ${rotate} 2s linear infinite;
    position: relative;
    transform-origin: center;

    ${({ size }) => size === "tiny" && css`
        width: ${SizeTiny}px;
        height: ${SizeTiny}px;
    `}

    ${({ size }) => size === "small" && css`
        width: ${SizeSmall}px;
        height: ${SizeSmall}px;
    `}

    ${({ size }) => size === "huge" && css`
        width: ${SizeHuge}px;
        height: ${SizeHuge}px;

        ${StyledCircle} {
            stroke-width: 1;
        }
    `}
`;

/**
 * # Spinner Component
 */
class SpinnerComponent extends PureComponent<SpinnerProps> {

    public static defaultProps: SpinnerProps = {
        size: "tiny",
    };

    public render() {
        const { size, className } = this.props;

        return (
            <React.Fragment>
                <StyledSpinner viewBox="-10 -10 20 20" size={size} className={className}>
                    {size && this.renderSpinner(size)}
                </StyledSpinner>
            </React.Fragment>
        );
    }

    private renderSpinner = (size: SpinnerSize): React.ReactNode => {
        switch (size) {
            case "tiny":
                return (
                    <StyledCircle
                        cx={0}
                        cy={0}
                        r={9}
                        fill="none"
                    />
                )
            case "small":
                /**
                 * 
                 * We need to build variants in the future.
                 * 
                 * For now, we just have the design of the tiny version
                 */
                return null;
            case "huge":
                return (
                    <StyledCircle
                        cx={0}
                        cy={0}
                        r={9}
                        fill="none"
                    />
                )
            default:
                return (
                    <StyledCircle
                        cx={0}
                        cy={0}
                        r={9}
                        fill="none"
                    />
                )
        }
    }
}

export const Spinner = SpinnerComponent;
