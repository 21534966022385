import React from "react";
import loadable from "@loadable/component";
import { Block } from "@7egend/web.core.cms/lib/dlos/block";
import { Text as TextDLO } from "@7egend/web.core.cms/lib/dlos/text";
import { BlockComponentProps } from "../../interfaces";
import { withI18n, WithI18nProps } from "@7egend/web.core/lib/components/withI18n";
import { blockTypes } from "../config";
import { I18N_KEY_CMS_COMPONENTS_BLOCK } from "../../../../base/i18n";

const { smallText } = blockTypes;

const Structure = {
    type: smallText.type,
    content: {
        style: smallText.style,
    },
} as Block<TextDLO>;

const TextArea = loadable(async () => (await import("../../../TextArea")).TextArea);

const I18N_NAMESPACE = `${I18N_KEY_CMS_COMPONENTS_BLOCK}.types.smallText`

const SmallTextComponent: React.FC<WithI18nProps & BlockComponentProps<TextDLO>> = ({ className, data, updateData, t }) => {
    return (
        <TextArea
            className={className}
            name=""
            placeholder={t(`${I18N_NAMESPACE}.inputTextPlaceholder`)}
            value={data.content && data.content.value}
            onChange={(value: string) => updateData({
                ...data,
                content: {
                    ...data.content,
                    value,
                }
            })}
        />
    )
}

const Component = withI18n()(SmallTextComponent);

const Button = {
    icon: "reorder",
    name: {
        key: `${I18N_NAMESPACE}.name`,
        en: "Small Text",
        es: "Texto pequeño",
        pt: "Texto Pequeno",
    },
};

export const SmallText = {
    key: `${smallText.type}_${smallText.style}`,
    Structure,
    Component,
    Button,
};
