import { Module } from "../../../bootstrap"
import loadable from "@loadable/component"

export const multimediaSetup: Module = {
    routes: [
        // Multimedia List
        {
            component: loadable(async () => (await import('./Multimedia')).Multimedia),
            path: "/cms/multimedia",
            exact: true,
        },
        // Multimedia Create
        {
            component: loadable(async () => (await import('./MultimediaEditor/MultimediaEditor')).MultimediaEditor),
            path: "/cms/multimedia/add",
        },
        // Multimedia Update
        {
            component: loadable(async () => (await import('./MultimediaEditor/MultimediaEditor')).MultimediaEditor),
            path: "/cms/multimedia/edit/:id",
        },
    ],
    menus: [
        {
            title: {
                key: "cms.multimedia.menu.title",
                en: "Multimedia",
                es: "Multimedia",
                pt: "Multimídia",
            },
            path: "cms/multimedia",
            permission: "cms.multimedia.list",
            children: [
                {
                    title: {
                        key: "cms.multimedia.add.menu.title",
                        en: "Create Multimedia",
                        es: "Crear Multimedia",
                        pt: "Criar Multimídia",
                    },
                    path: "cms/multimedia/add",
                    visible: false,
                    permission: "cms.multimedia.add",
                },
                {
                    title: {
                        key: "cms.multimedia.edit.menu.title",
                        en: "Edit Multimedia",
                        es: "Editar Multimedia",
                        pt: "Editar Multimídia",
                    },
                    path: "cms/multimedia/edit",
                    visible: false,
                    permission: "cms.multimedia.edit",
                },
            ],
        }
    ]
}
