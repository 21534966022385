import React, { PureComponent } from "react"
import { StyledUploader } from "../styles";
import { Column } from "../../Structure";
import { MediaListData } from "../../Multimedia";
import { Button } from "../../Button";
import { Multimedia } from "../../Multimedia";
import { Modal } from "../../Modal";
import { Image } from "../../Image";
import styled from "styled-components";
import { UploaderProps } from "../domain"

export interface MediaListProps extends React.PropsWithChildren<UploaderProps> {
    /** limit the number of items (media) that can be selectable */
    limitSelectable?: number;

    context?: any;
    /** return checked media items */
    checkedMedia: (checkedMedia: MediaListData[]) => void;

    /** to override the default number of columns */
    renderColumns?: {
        xs?: number;
        sm?: number;
        md?: number;
        lg?: number;
        xl?: number;
    };

    /** height limited */
    heightLimited?: boolean;

    /** preview selected image */
    previewImage?: string;

    media?: MediaListData[];
}

const StyledPreviewImage = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: inherit;
    overflow: hidden;

    img {
        max-height: 100%;
    }
`

interface MediaListUploaderState {
    modalVisible?: boolean;
    checkedMedia: MediaListData[];
}

const defaultColumns = {
    xl: 2,
};

/**
 * # Uploader Media List
 *
 * ## How to use:
 *
 * ```jsx
 * <Uploader.MediaList
 *      checkedMedia={(checkedMedia: MediaListData[]) => void}
 *      label="Upload Thumbnail"
 *      heightLimited={true|false}
 *      previewImage={string}
 *      limitSelectable={number}
 *      renderColumns={{ xs: 12, sm: 6 ... }}
 * />
 * ```
 */
export class MediaList extends PureComponent<
    MediaListProps,
    MediaListUploaderState
> {
    public state: MediaListUploaderState = {
        modalVisible: false,
        checkedMedia: [],
    };

    public render() {
        const { info, label, heightLimited } = this.props;
        const hasPreviewImage: boolean = !!this.props.previewImage;
        const actionIcon: string = hasPreviewImage ? "close" : "add";

        return (
            <React.Fragment>
                <Column {...(this.props.renderColumns || defaultColumns)}>
                    <StyledUploader.Wrapper
                        heightLimited={heightLimited}
                        className={this.props.className}
                    >
                        <StyledUploader.Actions
                            onClick={() => {
                                if (hasPreviewImage) {
                                    this.clear()
                                } else {
                                    this.toggleModal()
                                }
                            }}
                            hasPreviewImage={hasPreviewImage}
                        >
                            {hasPreviewImage &&
                                <StyledPreviewImage>
                                    <Image src={this.props.previewImage || " "} />
                                </StyledPreviewImage>
                            }
                            <StyledUploader.Action layout="alt" icon={actionIcon} />
                            <StyledUploader.FlatInfo>
                                {info}
                            </StyledUploader.FlatInfo>
                        </StyledUploader.Actions>
                        <StyledUploader.Header>{label}</StyledUploader.Header>
                    </StyledUploader.Wrapper>
                </Column>
                <Modal
                    handleModal={this.toggleModal}
                    isVisible={this.state.modalVisible}
                    overlay
                    fullWidth
                >
                    <Multimedia
                        limitSelectable={this.props.limitSelectable}
                        mediumType={this.props.mediumType}
                        context={this.props.context}
                        data={this.props.media}
                        footer={
                            <Column contentAlign="right">
                                <Button
                                    layout="main"
                                    layoutOutline
                                    icon="clear"
                                    layoutIconPosition="right"
                                    onClick={this.toggleModal}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    layout="main"
                                    layoutFill
                                    icon="check"
                                    layoutIconPosition="right"
                                    onClick={this.addItems}
                                    disabled={
                                        this.state.checkedMedia.length < 1
                                    }
                                >
                                    Add{" "}
                                    {this.state.checkedMedia.length > 0 &&
                                        this.state.checkedMedia.length}{" "}
                                    items
                                </Button>
                            </Column>
                        }
                        checkedMedia={(checkedMedia: MediaListData[]) =>
                            this.setState({
                                checkedMedia,
                            })
                        }
                    />
                </Modal>
            </React.Fragment>
        );
    }

    private toggleModal = () => {
        const { modalVisible } = this.state;

        this.setState({
            modalVisible: !modalVisible,
        });
    };

    private addItems = () => {
        if (this.props.checkedMedia) {
            /** sent to parent component, checked items */
            this.props.checkedMedia(this.state.checkedMedia);

            /** hide modal */
            this.toggleModal();

            /** clean state */
            this.setState({
                checkedMedia: [],
            });
        }
    };

    private clear = () => {
        this.setState({
            checkedMedia: []
        }, () => this.props.checkedMedia([]))
    }
}
