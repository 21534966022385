import { Medium } from "@7egend/web.core.media/lib/dlos";
import { DloInput, buildQLQuery, QueryQLParams, DloOutput } from "@7egend/web.core/lib/extensions/dlo";
import { Visibility } from "../core/channel"

const MEDIUM_FIELDS: QueryQLParams = [
    "uuid",
    "title",
    "url",
    "description",
    "date",
    "medium_type",
    "mime_type",
    "image",
    "normal",
    "small",
    "thumb",
    "file",
    "panoramic",
    "public",
    "tags",
    "tag_relations",
    ["categories", ["id", "name"]],
    ["visibility",
        ["app", "website"]
    ]
];

export interface Media extends Medium, Visibility { }

interface MediaCreateUpdateBody extends Visibility {
    locale?: string
    /**
     * Content
     */
    content: {
        title: string,
        description?: string,
        /**
         * Base64 for raw content
         */
        base64?: string,
        /**
         * Url for external media
         */
        url?: string,
        /**
         * Mandatory when have url
         */
        mime_type?: string,
        /**
         * Thumbnail for external media
         */
        thumbnail?: {
            base64: string
        }
    }
    /** 
     * Panoramic
     * - 0 => false
     * - 1 => true
     */
    panoramic?: 0 | 1
    origin: number
    categories?: string[]
    tags?: string[]
}

/** 
 * 
 * # Media Get All <Input>
 * 
 * It returns all the media
 */
export class MediaGetAllInput extends DloInput<{
    length: number;
    start: number;
}> {
    public timeStamp = Date.now();
    public authentication = true;
    public uri =
        "media/datatables?draw=${timeStamp}&order[0][column]=0&order[0][dir]=desc";
}

/**
 * # Media Create <Input>
 */
export class MediaCreateInput extends DloInput<MediaCreateUpdateBody> {
    public method = "POST";
    public uri = "media";
    public fields = buildQLQuery(MEDIUM_FIELDS);
    public transform = (input: any) => {
        input.body.fields = input.fields;
        return input;
    }
}

export class MediaCreateOutput extends DloOutput<Medium> { }

/**
 * # Delete Media <input>
 */
export class MediaDeleteInput extends DloInput<{
    /** uuid */
    uuid: string
}> {
    public method = "DELETE"
    public uri = "media/${uuid}"
}

/**
 * # Update Media <input>
 */
export class MediaUpdateInput extends DloInput<MediaCreateUpdateBody & {
    uuid: string
}> {
    public method = "PUT";
    public uri = "media/${uuid}";
    public fields = buildQLQuery(MEDIUM_FIELDS);
    public transform = (input: any) => {
        input.body.fields = input.fields;
        return input;
    };
}

export class MediaUpdateOutput extends DloOutput<Medium> { }

/**
 * # Get a Media <input>
 */
export class MediaGetByIdInput extends DloInput<{
    uuid: number | string;
    locale: string
}> {
    public uri = "media/${uuid}?fields=${fields}&locale=${locale}";
    public fields = buildQLQuery(MEDIUM_FIELDS);
}
