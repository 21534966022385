import React, { PureComponent } from "react"
import styled, { css } from "styled-components";
import { Icon } from "../Icon";
import { colors, font } from "../../styles";

export type StepType = Array<string | number>;

export interface StepperProps {
    /** className */
    className?: string
    /** Steps */
    steps: StepType;
    /** Step Active */
    active: number;
    /** Click on Step */
    onClick: (index: number) => void;
}

interface StyledStepProps {
    isActive?: boolean
    isComplete?: boolean
}

const StyledTrack = styled.span`
    width: 72px;
    height: 1px;
    border-radius: 2px;
    background-color: ${colors.neutral.medium};
    margin: 0 10px;
    position: relative;
    overflow: hidden;

    &::after {
        content: '';
        width: 100%;
        height: 1px;
        border-radius: 2px;
        display: inline-block;
        background-color: ${colors.primary.lighter};
        position: absolute;
        transform: translate3d(-100%, 0, 0);
        transition: transform .4s ease-in-out;
    }
`

const StyledIcon = styled(Icon)`
    font-size: ${font.size.xs};
    color: ${colors.light};
`

const StyledContent = styled.span`
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background-color: ${colors.neutral.medium};
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: ${font.size.xs};
    color: ${colors.light};
    transition: background-color 200ms ease-in,
                box-shadow 200ms ease;
`

const StyledStep = styled.div<StyledStepProps>`
    position: relative;
    display: flex;
    align-items: center;

    &:last-child ${StyledTrack} {
        display: none;
    }

    ${(props: StyledStepProps) => props.isActive && css`
        ${StyledContent} {
            background-color: ${colors.primary.lighter};
            box-shadow: 0 2px 4px 0 rgba(20, 192, 196, 0.4);
            transition: background-color 800ms ease-in,
                    box-shadow 800ms ease;

            &:hover {
                cursor: pointer;
            }
        }
    `}

    ${(props: StyledStepProps) => props.isComplete && css`
        ${StyledTrack} {
            &::after {
               transform: translate3d(0, 0, 0);
            }
        }
    `}
`;

const StyledStepper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    padding: 3px 0;
`;

/**
 *
 * # Stepper Component
 *
 * ## How to use:
 * ```tsx
 * <Stepper steps={} active={} onClick={} />
 * ```
 */
export class Stepper extends PureComponent<StepperProps> {
    public render() {
        const { steps } = this.props;

        return (
            <StyledStepper className={this.props.className} >
                {
                    steps.map((element, index) => {
                        return (
                            <StyledStep
                                key={`step_${element}`}
                                isActive={index <= this.props.active}
                                isComplete={index < this.props.active}
                            >
                                <StyledContent
                                    onClick={() => { this.clickStep(index) }}
                                >
                                    {this.stepContent(element, index)}
                                </StyledContent>
                                <StyledTrack />
                            </StyledStep>
                        )
                    })
                }
            </StyledStepper>
        )
    }

    private clickStep = (elementIndex: number) => {
        const { active } = this.props;

        if (elementIndex <= active) {
            this.props.onClick(elementIndex);
        }
    };

    private stepContent = (element: string | number, elementIndex: number) => {
        const { active } = this.props;

        return elementIndex < active ? <StyledIcon icon="check" /> : element
    }
}
