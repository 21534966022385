import React from "react";
import { Block } from "@7egend/web.core.cms/lib/dlos/block";
import { Medium } from "@7egend/web.core.media/lib/dlos";
import { BlockComponentProps } from "../../interfaces";
import { MediaListData, MediaList } from "../../../MediaList";
import styled from "styled-components";
import { AlbumPicker } from "../../../../modules/cms/Albums";
import { blockTypes } from "../config";
import { Config } from "../../../../base/config";
import { useFramework } from "@7egend/web.core/lib/components/useFramework";
import { I18N_KEY_CMS_COMPONENTS_BLOCK } from "../../../../base/i18n";

const { album } = blockTypes;

const Wrapper = styled.div`
    display: grid;
`;

const Structure = {
    type: album.type,
    content: {}
} as Block<Medium>;

const Component: React.FC<BlockComponentProps<Medium>> = ({ className, data, updateData }) => {
    const hasMedia = !!data.content && !!data.content.uuid;
    const fw = useFramework();
    const config = (fw.config as Config);

    Structure.type = config?.blockTypes?.album?.type ? config.blockTypes.album.type : Structure.type;

    return (
        <Wrapper className={className}>
            {!hasMedia &&
                <AlbumPicker
                    limitSelectable={1}
                    renderColumns={{
                        xs: hasMedia ? 6 : 3,
                    }}
                    checkedMedia={(checkedMedia: MediaListData[]) =>
                        updateData({
                            ...data,
                            content: checkedMedia[0],
                        })
                    }
                />
            }
            {hasMedia && (
                <MediaList
                    isAlbum={true}
                    renderColumns={{
                        xs: 3,
                    }}
                    list={[data.content]}
                    isCheckable={false}
                    isEditable={true}
                    deleteMedia={(item: MediaListData) => {
                        updateData({
                            type: Structure.type,
                            id: item.uuid,
                        } as any)
                    }
                    }
                />
            )}
        </Wrapper>
    );
}

const Button = {
    icon: "photo_library",
    name: {
        key: `${I18N_KEY_CMS_COMPONENTS_BLOCK}.types.album.name`,
        en: "Album",
        es: "Álbum",
        pt: "Álbum",
    },
};

export const Album = {
    key: album.type,
    Structure,
    Component,
    Button,
};
