//#region Media & Mime Types
export const MimeTypesById: Record<string, string> = {
    "1": "image/gif",
    "2": "image/jpeg",
    "3": "image/png",
    "200": "video/mp4",
    "201": "application/x-mpegURL",
    "400": "audio/mp3",
    "401": "audio/wav",
    "500": "application/pdf",
    "600": "x-application/zpl"
}

export const MimeTypesByExtension: Record<string, string> = {
    "gif": "image/gif",
    "jpg": "image/jpeg",
    "png": "image/png",
    "mp4": "video/mp4",
    "m3u8": "application/x-mpegURL",
    "mp3": "audio/mp3",
    "wav": "audio/wav",
    "pdf": "application/pdf",
    "zpl": "x-application/zpl"
}
//#endregion