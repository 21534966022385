import styled from "styled-components";
import { colors, font } from "../../../styles";

const SlugBox = styled.div`
    max-width: 70%;
    position: relative;
    flex: 1;

    .slugBox {
        &__tooltip {
            position: absolute;
            right: 0;
            top: 0;
        }

        &__input {
            border: 0;
            width: 100%;
            height: 40px;
            background-color: ${colors.neutral.lighter};
            color: ${colors.neutral.darker};
            display: flex;
            border-radius: 6px;
            padding: 10px 40px 10px 10px;
            outline: none;
            font-family: ${font.primary};
            font-weight: ${font.weight.light};
            font-size: ${font.size.sm};
        }
    }
`
const Details = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
`
const Actions = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;

    button:first-child {
        padding: 0;
    }
`
const ResetContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    cursor: pointer;

    &:hover {
        i { color: ${colors.neutral.darker}}
    }
`

export default {
    SlugBox,
    Details,
    Actions,
    ResetContent,
}