import { Module } from "../../../bootstrap";
import loadable from "@loadable/component";

export { Boosters } from "./Boosters";
export { BoostersPicker } from "./Picker"

export const boostersSetup: Module = {
    routes: [
        // Boosters List
        {
            component: loadable(async () => (await import('./Boosters')).Boosters),
            path: "cms/boosters",
            exact: true,
        },
        // Boosters Create
        {
            component: loadable(async () => (await import('./BoosterEditor')).BoosterEditor),
            path: "/cms/boosters/add",
        },
        // Boosters Update
        {
            component: loadable(async () => (await import('./BoosterEditor')).BoosterEditor),
            path: "/cms/boosters/edit/:id",
        },
    ],
    menus: [
        {
            title: {
                key: "cms.boosters.menu.title",
                en: "Boosters",
                es: "Boosters",
                pt: "Boosters",
            },
            path: "cms/boosters",
            permission: "cms.boosters.list",
            children: [
                {
                    title: {
                        key: "cms.boosters.create.menu.title",
                        en: "Create Boosters",
                        es: "Crear Boosters",
                        pt: "Criar Boosters",
                    },
                    path: "cms/boosters/add",
                    visible: false,
                    permission: "cms.boosters.add",
                },
                {
                    title: {
                        key: "cms.boosters.edit.menu.title",
                        en: "Edit Boosters",
                        es: "Editar Boosters",
                        pt: "Editar Boosters",
                    },
                    path: "cms/boosters/edit",
                    visible: false,
                    permission: "cms.boosters.edit",
                },
            ],
        },
    ]
}
