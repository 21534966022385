import React from "react";
import { Block } from "@7egend/web.core.cms/lib/dlos/block";
import { Booster as BoosterDLO } from "@7egend/web.core.cms/lib/dlos/booster";
import { BlockComponentProps } from "../../interfaces";
import { BoostersPicker } from "../../../../modules/cms/Boosters";
import { Button as ButtonComponent } from "../../../Button";
import { colors } from "../../../../styles";
import styled from "styled-components";
import { Divider, Space } from "../../../Divider";
import { withI18n, WithI18nProps } from "@7egend/web.core/lib/components/withI18n";
import { APP_TRANSLATIONS } from "../../../../locale";
import { blockTypes } from "../config";
import { I18N_KEY_CMS_COMPONENTS_BLOCK } from "../../../../base/i18n";

const { container, boosterList, booster } = blockTypes;

const Structure = {
    type: container.type,
    content: {
        style: boosterList.style,
        blocks: [{
            type: booster.type,
            content: {}
        }] as Array<Block<BoosterDLO>>
    },
} as any;

const StyleListWrapper = styled.div`
    border: 1px solid ${colors.neutral.light};
    border-radius: 6px;
    padding: 16px;

    &::after {
        content: "";
        width: 100%;
        display: block;
        white-space: nowrap;
        clear: both;
    }
`;

const StyledBoosterList = styled.div`
    display: block;
    width: 100%;
    padding-top: 5px;

    &::after {
        content: "";
        width: 100%;
        display: block;
        white-space: nowrap;
        clear: both;
    }
`

const StyledAddButton = styled(ButtonComponent)`
    display: block;
    width: 100%;
`;

const StyledRemoveButton = styled(ButtonComponent)`
    float: right;
`;

const updateBooster = (valuesList: any, currentBooster: BoosterDLO, i: number) => {
    const newValuesList = [...valuesList];
    newValuesList[i].content = {
        ...currentBooster,
        type: booster.type
    };
    return newValuesList;
};

const removeItem = (valuesList: any, i: number) => {
    const updatedList = [
        ...valuesList.slice(0, i),
        ...valuesList.slice(i + 1),
    ];

    return updatedList;
};

const addItem = (valuesList: any) => {
    const newvaluesList = [...valuesList];
    newvaluesList.push(
        {
            type: booster.type,
            content: {}
        }
    );

    return newvaluesList;
};

const I18N_NAMESPACE = `${I18N_KEY_CMS_COMPONENTS_BLOCK}.types.booster.list`

const BoosterListComponent: React.FC<WithI18nProps & BlockComponentProps<any>> = ({ className, data, updateData, t }) => {

    if (!data.content.blocks || data.content.blocks.length === 0) {
        return null
    }

    return (
        <StyleListWrapper className={className}>
            {data.content.blocks.map((eachBlock: any, index: number) => (
                <React.Fragment key={index}>
                    <StyledBoosterList>
                        {data.content.blocks.length > 1 &&
                            <StyledRemoveButton
                                icon={"delete"}
                                onClick={() => updateData({
                                    ...data,
                                    content: {
                                        ...data.content,
                                        blocks: removeItem(data.content.blocks, index)
                                    }
                                })}
                            />
                        }
                        <BoostersPicker
                            renderColumns={{
                                xs: 3,
                            }}
                            checkedItems={[data.content.blocks[index].content]}
                            selectItem={(checkedItems: BoosterDLO[]) =>
                                updateData({
                                    ...data,
                                    content: {
                                        ...data.content,
                                        blocks: updateBooster(data.content.blocks, checkedItems[0], index)
                                    }
                                })
                            }
                        ></BoostersPicker>
                    </StyledBoosterList>
                    {data.content.blocks.length > 1 && <Divider space={Space.Small} layout={"lighter"} />}
                </React.Fragment>
            ))}
            <StyledAddButton
                text={t(`${I18N_NAMESPACE}.addNewItem`)}
                icon={"add"}
                layoutIconPosition={"left"}
                onClick={() => updateData({
                    ...data,
                    content: {
                        ...data.content,
                        blocks: addItem(data.content.blocks)
                    }
                })}
            />
        </StyleListWrapper>
    )
}

const Component = withI18n(APP_TRANSLATIONS)(BoosterListComponent);

const Button = {
    icon: "whatshot",
    name: {
        key: `${I18N_NAMESPACE}.name`,
        en: "Boosters List",
        es: "Lista Boosters",
        pt: "Lista Boosters",
    },
};

export const BoosterList = {
    key: `${boosterList.type}_${boosterList.style}`,
    Structure,
    Component,
    Button,
};
