
import React from "react";
import { Block } from "@7egend/web.core.cms/lib/dlos/block";
import { News as NewsDLO } from "@7egend/web.core.cms/lib/dlos/news";
import { BlockComponentProps } from "../../interfaces";
import styled from "styled-components";
import { colors } from "../../../../styles";
import { NewsPicker } from "../../../../modules/cms/News/Picker";
import { blockTypes } from "../config";
import { I18N_KEY_CMS_COMPONENTS_BLOCK } from "../../../../base/i18n";

const { news } = blockTypes;

const Wrapper = styled.div`
    display: grid;
    border: 1px solid ${colors.neutral.light};
    border-radius: 6px;
    padding: 16px;
`;

const Structure = {
    type: news.type,
    properties: {},
    content: {}
} as unknown as Block<NewsDLO>;

const I18N_NAMESPACE = `${I18N_KEY_CMS_COMPONENTS_BLOCK}.types.news`

/**
 * # News Block type
 */
const Component: React.FC<BlockComponentProps<any>> = ({ className, data, updateData }) => {

    return (
        <Wrapper className={className}>
            <NewsPicker
                picked={[data.content]}
                pickItem={picked =>
                    updateData({
                        ...data,
                        content: picked[0]
                    })
                }
            />
        </Wrapper>
    );
};

const Button = {
    icon: "book",
    name: {
        key: `${I18N_NAMESPACE}.name`,
        en: "News",
        es: "Noticias",
        pt: "Notícias",
    },
};

export const News = {
    key: news.type,
    Structure,
    Component,
    Button,
};
