import { Store, Generic } from "@7egend/web.core";
import { State } from "../state";
import {
    CreateSessionKeySecretStartAction,
    CreateSessionKeySecretSuccessAction,
    CreateSessionKeySecretErrorAction,
    GetSessionErrorAction,
    GetSessionSuccessAction,
    GetSessionStartAction,
    UpdateUserStartAction,
    UpdateUserSuccessAction,
    UpdateUserErrorAction,
    UpdateUserImageStartAction,
    UpdateUserImageSuccessAction,
    DeleteUserDataErrorAction,
    DeleteUserDataSuccessAction,
    LogoutUserSuccessAction,
    ForgotPasswordSuccessAction,
    ForgotPasswordErrorAction,
} from "./types";
import { User } from "@7egend/web.core.people/lib/dlos/user";
import { ForgotPasswordInput, UpdateUserPasswordInput, UpdateUserInput, DeleteUserDataInput, UpdateUserImageInput } from "../../dlos/session"
import { Framework } from "../../base/framework";
import { Session, SessionCreateBySocialInput, SessionGetInput, SessionGetOutput, SessionCreateByKeySecretInput, SessionCreateByKeySecretOutput, SessionCreateMagicLinkInput, SessionConfirmMagicLinkInput } from "../../dlos/session";

//#region Get session
const getSessionStart = (): GetSessionStartAction => ({ type: "@@core.security/GET_SESSION_KEYSECRET_START" });
const getSessionSuccess = (payload: Session): GetSessionSuccessAction => ({ type: "@@core.security/GET_SESSION_KEYSECRET_SUCCESS", payload });
const getSessionError = (payload: any): GetSessionErrorAction => ({ type: "@@core.security/GET_SESSION_KEYSECRET_ERROR", payload });

/**
 * Gets a session from an external API
 */
export function getSession() {
    return async (dispatch: Store.Dispatch) => {
        dispatch(getSessionStart());
        const generic = new Generic();
        const request = new SessionGetInput();
        try {
            const response = await generic.framework.dlo.call(request) as SessionGetOutput;
            dispatch(getSessionSuccess(response.body));
            return response.body
        } catch (error) {
            dispatch(getSessionError(error));
        }
    }
}

//#endregion

//#region Create session

const createSessionKeySecretStart = (): CreateSessionKeySecretStartAction => ({ type: "@@core.security/CREATE_SESSION_KEYSECRET_START" });
const createSessionKeySecretSuccess = (payload: Session): CreateSessionKeySecretSuccessAction => ({ type: "@@core.security/CREATE_SESSION_KEYSECRET_SUCCESS", payload });
const createSessionKeySecretError = (payload: any): CreateSessionKeySecretErrorAction => ({ type: "@@core.security/CREATE_SESSION_KEYSECRET_ERROR", payload });

/**
 * Saves the token for future use
 * @param token Authentication token
 */
const saveLocalSession = (token: string, uuid?: string) => {
    const generic = new Generic();
    (generic.framework as Framework).auth.setAuthToken(token);
    if (uuid) { (generic.framework as Framework).auth.setUserUUID(uuid); }
}

const clearLocalSession = () => {
    const generic = new Generic();
    (generic.framework as Framework).auth.clear();
}

/**
 * Fetch items from an external API
 */
export function createSessionKeySecret(key: string, secret: string, terms: number = -1) {
    return async (dispatch: Store.Dispatch) => {
        dispatch(createSessionKeySecretStart());

        const generic = new Generic();
        const request = new SessionCreateByKeySecretInput();

        request.body = {
            username: key,
            password: secret,
            bundle: generic.framework.config.api.bundle,
            platform: generic.framework.config.api.platform,
        }

        if (terms !== -1) { request.body.tnc_acceptance = terms }

        try {
            const response = await generic.framework.dlo.call(request) as SessionCreateByKeySecretOutput;
            saveLocalSession(response.body.access_token);
            dispatch(createSessionKeySecretSuccess(response.body));
            return response.body || response
        } catch (error) {
            dispatch(createSessionKeySecretError(error));
        }
    }
}

/**
 * Social Names for session
 */
export enum SocialProvider {
    Google = "google",
    Facebook = "facebook",
    Apple = "apple",
}

/**
 * Create a facebook and google session login
 */
export function createSessionSocial(social: SocialProvider | string, token: string, terms: number = -1) {
    return async (dispatch: Store.Dispatch) => {
        dispatch(createSessionKeySecretStart());

        const generic = new Generic();

        const request = new SessionCreateBySocialInput();
        request.network = social;

        request.body = {
            token,
            bundle: generic.framework.config.api.bundle,
            platform: generic.framework.config.api.platform,
        }

        if (terms !== -1) { request.body.tnc_acceptance = terms }

        try {
            const response = await generic.framework.dlo.call(request) as SessionCreateByKeySecretOutput;
            saveLocalSession(response.body.access_token);
            dispatch(createSessionKeySecretSuccess(response.body));
            return response.body || response
        } catch (error) {
            dispatch(createSessionKeySecretError(error));
        }
    }
}

/**
 * Create a magic link session login
 */
export function createSessionMagicLink(email: string) {
    return async (dispatch: Store.Dispatch) => {
        dispatch(createSessionKeySecretStart());

        const generic = new Generic();

        const request = new SessionCreateMagicLinkInput();
        request.body = {
            email,
            source_bundle: generic.framework.config.api.bundle,
            target_bundle: generic.framework.config.api.bundle,
        }

        try {
            const response = await generic.framework.dlo.call(request) as SessionCreateByKeySecretOutput;
            dispatch(createSessionKeySecretSuccess(response.body));
            return response.body || response
        } catch (error) {
            dispatch(createSessionKeySecretError(error));
        }
    }
}

/**
 *
 */
export function confirmSessionMagicLink(token: string = "", code: string = "", terms: number = -1) {
    return async (dispatch: Store.Dispatch) => {
        dispatch(createSessionKeySecretStart());

        const generic = new Generic();

        const request = new SessionConfirmMagicLinkInput();
        request.body = {
            // @ts-ignore
            bundle: generic.framework.config.api.bundle, // for compatibility mode only
            platform: generic.framework.config.api.platform,
            source_bundle: generic.framework.config.api.bundle,
            target_bundle: generic.framework.config.api.bundle,
        }
        // -1 to allow to submit value 0 for accept terms and conditions
        if (terms !== -1) { request.body!.tnc_acceptance = terms }
        if (code !== "") { request.body!.code = code; }
        if (token !== "") { request.body!.token = token; }

        try {
            const response = await generic.framework.dlo.call(request) as SessionCreateByKeySecretOutput;
            saveLocalSession(response.body.access_token, response.body.user.uuid);
            dispatch(createSessionKeySecretSuccess(response.body));
            return response.body || response
        } catch (error) {
            dispatch(createSessionKeySecretError(error));
        }
    }
}

const forgotPasswordSuccess = (email: string): ForgotPasswordSuccessAction => ({ type: "@@core.security/FORGOT_PASSWORD_SUCCESS", email });
const recoverPasswordError = (payload: any): ForgotPasswordErrorAction => ({ type: "@@core.security/FORGOT_PASSWORD_ERROR", payload });

/**
 *
 */
export function submitForgotPassword(email: string) {
    return async (dispatch: Store.Dispatch) => {
        const generic = new Generic();
        const request = new ForgotPasswordInput();

        request.body = {
            username: email,
            source_bundle: generic.framework.config.api.bundle,
            target_bundle: generic.framework.config.api.bundle,
        }

        try {
            const response = await generic.framework.dlo.call(request);
            dispatch(forgotPasswordSuccess(email));
            return response;
        } catch (error) {
            dispatch(recoverPasswordError(error));
        }
    }
}

/**
 *
 */
export function updateUserPassword(token: string, secret: string) {
    return async () => {
        const generic = new Generic();

        const request = new UpdateUserPasswordInput();
        request.body = {
            token,
            password: secret,
        }

        return await generic.framework.dlo.call(request);
    }
}

const updateUserStart = (): UpdateUserStartAction => ({ type: "@@core.security/UPDATE_USER_START" });
const updateUserSuccess = (payload: User): UpdateUserSuccessAction => ({ type: "@@core.security/UPDATE_USER_SUCCESS", payload });
const updateUserError = (payload: any): UpdateUserErrorAction => ({ type: "@@core.security/UPDATE_USER_ERROR", payload });

/**
 *
 */
export function updateUser(data: User) {
    return async (dispatch: Store.Dispatch) => {
        dispatch(updateUserStart());

        const generic = new Generic();
        const request = new UpdateUserInput();
        request.body = data;

        try {
            const response = await generic.framework.dlo.call(request);
            dispatch(updateUserSuccess(response.body));
            return response.body || response
        } catch (error) {
            generic.fw.log.info("error", error);
            dispatch(updateUserError(error));
        }
    }
}

const updateUserImageStart = (): UpdateUserImageStartAction => ({ type: "@@core.security/UPDATE_USER_IMAGE_START" });
const updateUserImageSuccess = (payload: any): UpdateUserImageSuccessAction => ({ type: "@@core.security/UPDATE_USER_IMAGE_SUCCESS", payload });

export function updateUserImage(image: string) {
    return async (dispatch: Store.Dispatch, getState: (() => State)) => {
        dispatch(updateUserImageStart())

        const generic = new Generic();
        const request = new UpdateUserImageInput();

        request.uuid = getState().security.session.user!.uuid;

        request.body = {
            fields: "profile(uuid,image,thumb)",
            profile: {
                base64: image,
            },
        };

        try {
            const response = await generic.framework.dlo.call(request);
            dispatch(updateUserImageSuccess(response.body))
            return response.body || response
        } catch (error) {
            generic.fw.log.info("error", error);
        }
    }
}

const deleteUserDataSuccess = (payload: any): DeleteUserDataSuccessAction => ({ type: "@@core.security/DELETE_USER_DATA_SUCCESS", payload });
const deleteUserDataError = (payload: any): DeleteUserDataErrorAction => ({ type: "@@core.security/DELETE_USER_DATA_ERROR", payload });

/**
 * Deletes user data
 */
export function deleteUserData(uuid: string) {
    return async (dispatch: Store.Dispatch) => {
        const generic = new Generic();
        const request = new DeleteUserDataInput();

        request.body = { uuid };

        try {
            const response = await generic.framework.dlo.call(request);
            dispatch(deleteUserDataSuccess(response.body));
            return response.body || response
        } catch (error) {
            dispatch(deleteUserDataError(error));
        }
    }
}

const userLogoutSuccess = (): LogoutUserSuccessAction => ({ type: "@@core.security/LOGOUT_USER_SUCCESS" });

/**
 * Logout user
 */
export function logoutUser() {
    return (dispatch: Store.Dispatch) => {
        clearLocalSession();
        dispatch(userLogoutSuccess());
    }
}

//#endregion
