import React, { PureComponent } from "react"
import styled, { css } from "styled-components";
import { colors, font } from "../../styles";

export interface SwitchProps {
    /** className */
    className?: string;
    /** switch name */
    name: string;
    /** switch value */
    value: boolean;
    /** if switch if required @default false */
    required?: boolean;
    /** switch onchange method */
    onChange: (value: boolean, name: string, valid: boolean) => void;
    /** switch disabled @default false */
    disabled?: boolean;
    /** reverse layout position label + input */
    reverseLayout?: boolean
    /** full width */
    fullWidth?: boolean
}

interface StyledSwitchProps {
    disabled?: boolean;
    reverseLayout?: boolean;
    fullWidth?: boolean;
}

const StyledLabel = styled.span`
    position: relative;
    display: inline-block;
    vertical-align: top;
    z-index: 1;
    align-self: center;
    font-family: ${font.primary};
    font-size: ${font.size.sm};
    color: ${colors.neutral.darker};
    font-weight: ${font.weight.light};

    a{
        border-bottom: 1px solid ${colors.primary.lighter};
    }

    div {
        display: inline;
    }
    
`

const StyledBullet = styled.div<StyledSwitchProps>`
    width: 40px;
    border-radius: 1em;
    height: 14px;
    background-color: ${colors.neutral.light};
    z-index: 1;
    transition: background-color 300ms;
    transition-delay: 100ms;

    &::before {
        content: '';
        display: block;
        width: 20px;
        height: 20px;
        background-color: ${colors.neutral.dark};
        border-radius: 1em;
        top: -3px;
        position: relative;
        box-shadow: 0 0.1em 0.3em rgba(22, 22, 22, 0.3);
        -webkit-transition: all 300ms;
        -moz-transition: all 300ms;
        transition: all 300ms;
    }
`

const StyledSwitch = styled.label<StyledSwitchProps>`
    display: flex;
    align-items: center;
    height: auto;
    width: auto;
    position: relative;

    ${({ disabled }) => disabled && css`
        cursor: not-allowed;

        ${StyledBullet} {
            background-color: ${colors.neutral.light} !important;

            &::before {
                background-color: ${colors.neutral.medium} !important;
                box-shadow: none !important;
            }
        }
    `}
    cursor: pointer;

    ${({ fullWidth }) => fullWidth && css`
        justify-content: space-between;
        width: 100%;
    `}

    ${({ reverseLayout }) => reverseLayout ? css`
        ${StyledLabel} {
            padding-right: 16px;
        }
    `: css`
        ${StyledBullet} + ${StyledLabel} {
            padding-left: 10px;
            margin: 0 5px;
        }
    `}
`

const StyledInput = styled.input`
    position: absolute;
    opacity: 0;

    &:checked + ${StyledBullet},
    &:checked + ${StyledLabel} + ${StyledBullet} {
        background-color: ${colors.semantic.light};

        &::before {
            background-color: ${colors.primary.lighter};
            box-shadow: 0 0.1em 0.3em rgba(20, 192, 196, 0.5);
            transform: translate3d(100%, 0, 0);
        }
    }
`

/**
 * # Switch Component
 * This class represents the input type checkbox component
 *
 * #How to use
 *
 * ```js
 * <Switch
 *      name="nameOfComponent"
 *      value={true | false}
 *      onChange={event}
 *      required
 *      disabled />
 * ```
 */
export class Switch extends PureComponent<SwitchProps> {
    public render() {
        const { name, value, required, disabled, children, className, reverseLayout, fullWidth } = this.props;

        return (
            <StyledSwitch
                reverseLayout={reverseLayout}
                fullWidth={fullWidth}
                disabled={disabled}
                className={className}
                htmlFor={`field-${name}`}
            >
                <StyledInput type="checkbox"
                    id={`field-${name}`}
                    name={name}
                    checked={!!value}
                    required={required}
                    onChange={this.onChange}
                    disabled={disabled}
                />
                {!this.props.reverseLayout ?
                    <React.Fragment>
                        <StyledBullet />
                        {children &&
                            <StyledLabel>{children}</StyledLabel>
                        }
                    </React.Fragment>
                    : <React.Fragment>
                        {children &&
                            <StyledLabel>{children}</StyledLabel>
                        }
                        <StyledBullet />
                    </React.Fragment>
                }

            </StyledSwitch>
        );
    }

    private onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value: boolean = (e.target as any).checked;
        const valid: boolean = (e.target as any).validity.valid;
        this.props.onChange(value, this.props.name, valid);
    };
}
