import { DloInput, DloOutput } from "@7egend/web.core/lib/extensions/dlo";
import { BaseModel } from "@7egend/web.core.dlos/lib/dlos/base";

export interface Country extends BaseModel {
    id: string,
    name: string,
    nationality: string,
    phone_code: string,
    iso2_code: string
}

export class CountriesGetInput extends DloInput {
    public uri = "countries?fields=${fields}";
    public fields = "id,name,phone_code,iso2_code";
}

export class CountriesGetOutput extends DloOutput<Country[]> {

}
