import { Store } from "@7egend/web.core";
import { Generic } from "@7egend/web.core/lib/base/generic";
import { State } from "../state";
import {
    GetCategoriesStartAction,
    GetCategoriesSuccessAction,
    GetCategoriesErrorAction,
} from "./types";
import { Category, CategoryGetAllInput, CategoryGetAllOutput } from "../../dlos/category";

//#region GetCategories

const getCategoriesStart = (): GetCategoriesStartAction => ({ type: "@@core.cms/GET_CATEGORIES_START" });
const getCategoriesSuccess = (payload: Category[]): GetCategoriesSuccessAction => ({ type: "@@core.cms/GET_CATEGORIES_SUCCESS", payload });
const getCategoriesError = (payload: any): GetCategoriesErrorAction => ({ type: "@@core.cms/GET_CATEGORIES_ERROR", payload });

/**
 * getCategories
 */
export function getCategories() {
    return async (dispatch: Store.Dispatch, getState: (() => State)) => {
        dispatch(getCategoriesStart());
        const generic = new Generic();

        const request = new CategoryGetAllInput()

        try {
            const response = await generic.framework.dlo.call(request) as CategoryGetAllOutput
            dispatch(getCategoriesSuccess(response.body))
        } catch (error) {
            dispatch(getCategoriesError(error));
        }
    }
}

//#endregion
