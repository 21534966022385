export interface BlockTypeConfig {
    type: string;
    style?: string;
}

const news: BlockTypeConfig = {
    type: "2",
};
const text: BlockTypeConfig = {
    type: "3",
    style: "1",
};
const highlight: BlockTypeConfig = {
    type: text.type,
    style: "2",
};
const title: BlockTypeConfig = {
    type: text.type,
    style: "3",
};
const lead: BlockTypeConfig = {
    type: text.type,
    style: "4",
};
const smallText: BlockTypeConfig = {
    type: text.type,
    style: "5",
};
const videoYoutube: BlockTypeConfig = {
    type: text.type,
    style: "7"
}
const iFrame: BlockTypeConfig = {
    type: text.type,
    style: "100",
};
const list: BlockTypeConfig = {
    type: "4",
};
const container: BlockTypeConfig = {
    type: "5",
};
const columnHighlight: BlockTypeConfig = {
    type: container.type,
    style: "1",
};
const boosterList: BlockTypeConfig = {
    type: container.type,
    style: "2",
};
const ctaList: BlockTypeConfig = {
    type: container.type,
    style: "3",
};
const expansionPanel: BlockTypeConfig = {
    type: container.type,
    style: "4",
};
const imageList: BlockTypeConfig = {
    type: container.type,
    style: "5",
};
const videoList: BlockTypeConfig = {
    type: container.type,
    style: "6",
};
const booster: BlockTypeConfig = {
    type: "6",
};
const separator: BlockTypeConfig = {
    type: "7",
};
const cta: BlockTypeConfig = {
    type: "8",
    style: "1"
};
const attachment: BlockTypeConfig = {
    type: cta.type,
    style: "2",
};
const html: BlockTypeConfig = {
    type: "9",
};
const imageAndText: BlockTypeConfig = {
    type: "11",
};
const form: BlockTypeConfig = {
    type: "12",
};
const widget: BlockTypeConfig = {
    type: "14",
};
const album: BlockTypeConfig = {
    type: "200"
};
const medium: BlockTypeConfig = {
    type: "201",
};

export const blockTypes = {
    album,
    attachment,
    booster,
    boosterList,
    columnHighlight,
    container,
    cta,
    ctaList,
    expansionPanel,
    form,
    highlight,
    html,
    iFrame,
    imageAndText,
    lead,
    list,
    medium,
    news,
    separator,
    smallText,
    text,
    title,
    widget,
    imageList,
    videoList,
    videoYoutube,
};
