import { Module } from "../../../bootstrap";
import loadable from "@loadable/component";

export const staticPagesSetup: Module = {
    routes: [
        // Pages List
        {
            component: loadable(async () => (await import('./StaticPages')).StaticPages),
            path: "/cms/static-pages",
            exact: true,
        },
        // Pages Update
        {
            component: loadable(async () => (await import('./StaticPageEditor')).StaticPageEditor),
            path: "/cms/static-pages/edit/:id",
        },
    ],
    menus: [
        {
            title: {
                key: "cms.staticPages.menu.title",
                en: "Static Pages",
                es: "Páginas estáticas",
                pt: "Páginas estáticas",
            },
            path: "cms/static-pages",
            permission: "cms.static-pages.list",
            children: [
                {
                    title: {
                        key: "cms.staticPages.edit.menu.title",
                        en: "Edit Pages",
                        es: "Editar estáticas",
                        pt: "Editar estáticas",
                    },
                    path: "cms/static-pages/edit",
                    visible: false,
                    permission: "cms.static-pages.edit",
                },
            ],
        },
    ]
}
