import { DloInput } from "@7egend/web.core/lib/extensions/dlo";
import { Visibility } from "../core/channel";

export interface Social extends Visibility {
    uuid: string,
    title: string,
    medium?: {
        thumb?: string
    }
}


/**
 * # Social Get all
 */
export class SocialGetAllInput extends DloInput<{}> {
    public timeStamp = Date.now();
    public authentication = true;
    public uri = "social/datatables?draw=${timeStamp}";
}

/**
 * # Social update
 */
export class SocialUpdateInput extends DloInput<{
    uuid: string | number,
} & Visibility> {
    public method = "PUT";
    public uri = "social-posts/${uuid}";
}

/**
 * # Social facebook register token
 */
export class SocialRegisterFacebookTokenInput extends DloInput<{
    access_token: string,
    page: string,
    instagram?: boolean,
    instagram_object?: string
}> {
    public method = "POST";
    public uri = "social/facebook";
}

/**
 * # Social register token
 */
export class SocialGetRefetchedDataInput extends DloInput<{
    socialID: number | string;
}> {
    public uri = "social/${socialID}";
}
