import { PureComponent } from "@7egend/web.core/lib/base/component"

export interface IconProps { }

/**
 * # Core Icon Component
 */
export class Icon extends PureComponent<IconProps> {

    public render() {
        return this.props.children
    }
}
