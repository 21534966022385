import {Store} from "@7egend/web.core";
import { LOAD_COUNTRIES_ACTION_KEY, CountriesState } from "./types";

const initialState: CountriesState = {}

const root: Store.Reducer<CountriesState> = (state: CountriesState = initialState, action: any) => {
    return Store.utils.reducerDefaultProcessor(LOAD_COUNTRIES_ACTION_KEY, state, action)
}

export { root }
