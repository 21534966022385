import React, { ComponentClass } from "react";
import { compose, CorePureComponent, DloInput } from "@7egend/web.core";
import { RouteComponentProps, withRouter } from "react-router";
import { memoize } from "@7egend/web.core/lib/utils"
import { WithCategories, WithCategoriesProps } from "@7egend/web.core.cms/lib/components/withCategories";
import {
    WrapperWith,
    PageEditor,
    MultiSelectItemsData,
    Input,
    MultiSelect,
    Uploader,
    MediaListData,
    MediaList,
    Column,
    Typography,
    Divider,
    Space,
    Row,
    BlocksEditor,
    Select,
    Button,
} from "../../../components";
import { CategoryGetAllInput, Category } from "@7egend/web.core.cms/lib/dlos/category";
import { Block } from "@7egend/web.core.cms/lib/dlos/block";
import { MediumType } from "@7egend/web.core.media/lib/dlos";
import { News, NewsCreateInput, NewsGetByIdInput, NewsUpdateInput, NewsCreateOutput, NewsTypeGetAllInput, NewsTypeGetAllOutput, NewsType } from "../../../dlos/cms/news";
import { PageEditorRenderOptions, PageEditorSavingOptions, FetchCallback } from "../../../components/Structure/PageEditor";
import { errorMessage } from "../../../utils";
import { Config } from "../../../base/config";
import { withI18n, WithI18nProps } from "@7egend/web.core/lib/components/withI18n";
import { Trans } from "@7egend/web.core/lib/components/i18n";
import { APP_TRANSLATIONS } from "../../../locale";
import { ConfigFlags } from "../base/flags";
import { SnackbarLayout } from "../../../components/Snackbar";
import { withFlex, WithFlexProps } from "../../../utils/flex";
import { withCanView, WithCanViewProps } from "../../../components/CanView";
import { PageEditorDefaultWidgets } from "../../../components/PageEditorDefaultWidgets";
import { I18N_KEY_CMS_NEWS_EDITOR, I18N_KEY_GLOBAL } from "../../../base/i18n";

type NewsEditorState = News;

interface MetadataState {
    categories: MultiSelectItemsData[];
    types: NewsType[];
}

/**
 * # Flex prop
 *
 * ## Nodes Available
 * 
 *  `fields`: this node returns an array of react elements, most likely input fields
 *  `beforeSubmit`: array of functions that overrides request var value used to send in api methods, POST and PUT
 *
 * ### How to use
 *
 * ```js
 *  fields: [
 *      {
 *          position: 0,
 *          component: ({ data, setData }) =>
 *             <Input
 *               label="label"}
 *               name="name"}
 *               placeholder="placeholder"}
 *               type="text"
 *               value={data?.FIELD || ""}
 *               onChange={(value: string) =>
 *                   setData({
 *                       ...data,
 *                       FIELD: value
 *                   })
 *               }
 *           />
 *       },
 *  ],
 * ```
 *
 * ```js
 *  beforeSubmit: [
 *      (request: DloInput<NewsCreateInput | NewsUpdateInput>, options: any) => {
 *          (request.body as OverridedNewsInput).localization = options.data.localization;
 *          return request;
 *      }
 *  ]
 * ```
 */
export interface NewsEditorFlex {
    /**
     * `fields`: this node returns an array of react elements, most likely input fields
     */
    fields?: Array<{
        component: (options: Pick<PageEditorRenderOptions<NewsEditorState, MetadataState>, "data" | "setData">) => React.ReactElement;
    }>
    /**
     * `beforeSubmit`: array of functions that overrides request var value used to send in api methods, POST and PUT
     */
    beforeSubmit?: Array<(request: NewsCreateInput | NewsUpdateInput, options: { data: News, language: string, addMessage: (message: string, layout?: SnackbarLayout, timeout?: number) => void }) => DloInput
    >;
}

export const NewsEditorFlexKey = "cms.news.editor"


interface NewsEditorProps {
    flex?: NewsEditorFlex;
}

interface NewsEditorOverrideState {
    active: any
}

const I18N_NAMESPACE = I18N_KEY_CMS_NEWS_EDITOR

/**
 * # News Editor Module
 */
class NewsEditorComponent extends CorePureComponent<WithCategoriesProps & WithI18nProps & WithFlexProps<NewsEditorFlex> & RouteComponentProps<{ id: string }> & WithCanViewProps, NewsEditorOverrideState> {
    private inputRef: React.RefObject<any> = React.createRef();

    public componentDidMount() {
        this.props.getCategories();
    }

    public render() {
        const flags = (this.fw.config as Config).flags as ConfigFlags;
        const BlocksList = this.getBlocksList();

        return (
            <React.Fragment>
                <PageEditor
                    metadataInitialState={{
                        categories: [],
                        types: [],
                    }}
                    fetch={this.fetchAction}
                    submit={this.props.canView("cms.news.edit") ? this.submitAction : undefined}
                    aside={(options: PageEditorRenderOptions<NewsEditorState>) => (
                        <React.Fragment>
                            {flags.cms_news_channels !== false && (
                                <PageEditorDefaultWidgets.Channels
                                    {...options}
                                />
                            )}
                            <Divider space={Space.Tiny} />
                            <PageEditorDefaultWidgets.Tags {...options} />
                        </React.Fragment>
                    )}
                >
                    {({ data, metadata, setData }: PageEditorRenderOptions<NewsEditorState, MetadataState>) => {
                        const { t } = this.props;
                        const news = data;
                        const categories = metadata?.categories || [];
                        const hasMedia = !news || (news && !news.cover);
                        const newsBlocks = (news && news.blocks) ? news.blocks : [];
                        const hasNewsTypes = !!(metadata?.types && metadata.types.length > 0);

                        return (
                            <React.Fragment>
                                <WrapperWith.Title
                                    title={t(`${I18N_NAMESPACE}.title`)}
                                >
                                    <Column>
                                        {hasNewsTypes && (
                                            <Select
                                                name="type"
                                                onChange={type => setData({
                                                    ...news,
                                                    type: {
                                                        id: type,
                                                        name: "",
                                                    }
                                                })}
                                                options={
                                                    metadata?.types.map(type =>
                                                    ({
                                                        value: type.id,
                                                        label: type.name,
                                                        option: { value: type.id }
                                                    })) || []
                                                }
                                                value={news?.type?.id}
                                                label={t(`${I18N_NAMESPACE}.fieldTypeLabel`)}
                                            />
                                        )}
                                        <MultiSelect
                                            placeholder={t(`${I18N_NAMESPACE}.fieldCategoriesPlaceholder`)}
                                            label={t(`${I18N_NAMESPACE}.fieldCategoriesLabel`)}
                                            items={categories}
                                            handleItem={selectedCategories => {
                                                setData({
                                                    ...data,
                                                    categories: (selectedCategories) as Category[],
                                                });
                                            }}
                                            checkedItems={(news && news.categories) || []}
                                        />
                                        <Input
                                            placeholder={t(`${I18N_NAMESPACE}.fieldTitlePlaceholder`)}
                                            label={t(`${I18N_NAMESPACE}.fieldTitleLabel`)}
                                            name={t(`${I18N_NAMESPACE}.fieldTitleName`)}
                                            type="text"
                                            value={news && news.title || ""}
                                            onChange={(value: string) =>
                                                setData({
                                                    ...news,
                                                    title: value,
                                                })
                                            }
                                        />
                                        <Input
                                            placeholder={t(`${I18N_NAMESPACE}.fieldIntroductionPlaceholder`)}
                                            label={t(`${I18N_NAMESPACE}.fieldIntroductionLabel`)}
                                            name={t(`${I18N_NAMESPACE}.fieldIntroductionName`)}
                                            type="text"
                                            value={news && news.introduction || ""}
                                            onChange={(value: string) =>
                                                setData({
                                                    ...news,
                                                    introduction: value,
                                                })
                                            }
                                        />
                                        <Input
                                            label={t(`${I18N_NAMESPACE}.fieldDateLabel`)}
                                            name="date"
                                            type="date"
                                            value={news && news.date || ""}
                                            ref={this.inputRef}
                                            action={
                                                <React.Fragment>
                                                    <Button
                                                        icon={"calendar_today"}
                                                        onClick={() => {
                                                            if (this.inputRef && this.inputRef.current) {
                                                                this.inputRef.current!.focus()
                                                            }
                                                        }}
                                                    />
                                                </React.Fragment>
                                            }
                                            onChange={(date: string) =>
                                                setData({
                                                    ...news,
                                                    date
                                                })
                                            }
                                        />
                                        <Row>
                                            <Column>
                                                <Typography.SmallCapsTitle>
                                                    <Trans id={`${I18N_NAMESPACE}.fieldThumbnail`} />
                                                </Typography.SmallCapsTitle>
                                                <Divider space={Space.Tiny} />
                                            </Column>
                                            {(!news || !news.cover) &&
                                                <Uploader.MediaList
                                                    limitSelectable={1}
                                                    heightLimited={hasMedia ? true : false}
                                                    mediumType={MediumType.Image}
                                                    context={{ "panoramic": false }}
                                                    info={
                                                        hasMedia ? (
                                                            <React.Fragment>
                                                                <Typography.RegularText variant="neutral">
                                                                    <strong>{t(`${I18N_KEY_GLOBAL}.selectFromLibrary`)}</strong> {t(`${I18N_KEY_GLOBAL}.or`)} <strong>{t(`${I18N_KEY_GLOBAL}.uploadFiles`)}</strong>
                                                                </Typography.RegularText>
                                                            </React.Fragment>
                                                        ) : (
                                                            ""
                                                        )
                                                    }
                                                    renderColumns={{
                                                        xl: 12,
                                                        xs: 12
                                                    }}
                                                    checkedMedia={(checkedMedia: MediaListData[]) =>
                                                        setData({
                                                            ...news,
                                                            cover: checkedMedia[0],
                                                        })
                                                    }
                                                />
                                            }
                                            {news && news.cover && (
                                                <MediaList
                                                    renderColumns={{
                                                        xs: 6,
                                                        sm: 4,
                                                        md: 3,
                                                    }}
                                                    list={[news && news.cover]}
                                                    isCheckable={false}
                                                    deleteMedia={(item: MediaListData) =>
                                                        setData({
                                                            ...news,
                                                            cover: undefined,
                                                        })
                                                    }
                                                />
                                            )}
                                        </Row>
                                        {this.props.flex?.fields?.map((field) => {
                                            return field.component({ data, setData })
                                        })}
                                        <Divider space={Space.Small} />
                                        <Column>
                                            <BlocksEditor
                                                allowRemove
                                                types={BlocksList}
                                                blocks={newsBlocks}
                                                onChange={(blocks: Block[]) =>
                                                    setData({
                                                        ...news,
                                                        blocks,
                                                    })
                                                }
                                            />
                                        </Column>
                                    </Column>
                                </WrapperWith.Title>
                                <Divider space={Space.Tiny} />
                            </React.Fragment >
                        );
                    }
                    }
                </PageEditor >
            </React.Fragment >
        );
    }

    private getBlocksList = memoize(() => {
        const blocksToInject: any[] = [];
        const modules = (this.framework.config as Config).modules;
        modules.forEach(eachModule => {
            if (eachModule.blocks && eachModule.blocks["cms.news"]) {
                eachModule.blocks["cms.news"].forEach(newBlock => blocksToInject.push(newBlock))
            }
        });

        return blocksToInject;
    });

    /** Fetch News and Categories */
    private fetchAction: FetchCallback = async ({ language }) => {
        const newsDLO = new NewsGetByIdInput();
        const categoriesDLO = new CategoryGetAllInput();
        const typesDLO = new NewsTypeGetAllInput();

        categoriesDLO.body = {
            locale: language,
        };

        /** Request Categories */
        const requestCategories = this.fw.dlo.call(categoriesDLO) as Promise<NewsCreateOutput>;
        /** Request News types */
        const requestTypes = this.fw.dlo.call(typesDLO) as Promise<NewsTypeGetAllOutput>;

        if (this.props.match.params.id) {
            /** on edit... */

            newsDLO.body = {
                id: this.props.match.params.id,
                locale: language,
            };

            /** Request News */
            const requestNews = this.fw.dlo.call(newsDLO) as Promise<NewsCreateOutput>;

            const resultCategories = await Promise.all([requestCategories]).then(res => {
                return res[0].body;
            })
                .catch((e) => {
                    return [];
                });

            const resultNews = await Promise.all([requestNews]).then(res => {
                return res[0].body;
            })
                .catch((e) => {
                    return undefined;
                });

            const resultTypes = await Promise.all([requestTypes]).then(res => {
                return res[0].body;
            })
                .catch((e) => {
                    return undefined;
                });

            return {
                metadata: {
                    types: resultTypes,
                    categories: resultCategories,
                },
                data: resultNews,
            };

        } else {
            return Promise.all([requestCategories, requestTypes]).then(res => ({
                metadata: {
                    categories: res[0].body,
                    types: res[1].body,
                },
                data: {},
            }))
                .catch(res => ({
                    metadata: {
                        categories: [],
                        types: [],
                    },
                    data: {},
                }));
        }
    };

    /** Save data */
    private submitAction = async ({ data, language, addMessage, targetLanguage, metadata }: PageEditorSavingOptions<NewsEditorState, MetadataState>) => {
        const news = data;
        let request: any;
        const { t } = this.props;

        if (this.props.match.params.id) {
            request = new NewsUpdateInput();
        } else {
            request = new NewsCreateInput();
        }

        /** categories */
        // tslint:disable-next-line: no-shadowed-variable
        const categories: string[] = (news.categories && news.categories.length > 0 && news.categories.map(({ id }: any) => id)) || [];

        /** if there is no cover selected */
        if (!news.cover && addMessage) {
            addMessage(t(`${I18N_NAMESPACE}.errorMessage1`), "error", 4000);
            throw t(`${I18N_NAMESPACE}.errorMessage1`);
        }

        /** if title is empty */
        if (!news.title && addMessage) {
            addMessage(t(`${I18N_NAMESPACE}.errorMessage2`), "error", 4000);
            throw t(`${I18N_NAMESPACE}.errorMessage2`);
        }


        if (!news.blocks && addMessage) {
            addMessage(t(`${I18N_NAMESPACE}.errorMessage3`), "error", 4000);
            throw t(`${I18N_NAMESPACE}.errorMessage3`);
        }

        /**
         * UUID
         * - Used if is on edit page
         */
        const id = this.props.match.params.id || undefined;

        /** Request Body */
        request.body = {
            id,
            origin: 1,
            categories,
            cover: news.cover || null,
            tags: news.tags,
            title: news.title,
            introduction: news.introduction,
            visibility: news.visibility,
            locale: language,
            blocks: news.blocks,
        };

        if (news?.type?.id) {
            request.body.type = news.type.id
        } else if (metadata?.types?.[0]?.id) {
            request.body.type = metadata?.types?.[0]?.id
        }

        if (news?.date) {
            request.body.date = news.date
        }

        if (this.props.flex?.beforeSubmit) {
            this.props.flex?.beforeSubmit?.forEach((eachBeforeSubmit) => {
                request = eachBeforeSubmit(request, { data, language, addMessage });
            });
        }

        return this.fw.dlo
            .call(request)
            .then(res => {
                if (res.body && addMessage) {
                    addMessage(t(`${I18N_NAMESPACE}.successMessage`), "success", 4000);
                    if (res.body.id) {
                        this.fw.dom.setTimeout(() => {
                            this.props.history.push(`/${this.props.language}/cms/news/edit/${res.body.id}?language=${targetLanguage}`);
                        }, 1000);
                    }
                }
                return res;
            })
            .catch((err) => {
                if (err) {
                    addMessage(errorMessage(err.body), "error", 4000);
                }

                throw err;
            })
    };

}

export const NewsEditor = compose<ComponentClass<NewsEditorProps>>(
    withCanView,
    withRouter,
    withI18n(APP_TRANSLATIONS),
    WithCategories,
    withFlex<NewsEditorFlex>(NewsEditorFlexKey, {})
)(NewsEditorComponent);
