import React, { PureComponent } from "react"
import { memoize } from "@7egend/web.core/lib/utils"
import styled, { css } from "styled-components";
import { CarouselComponent } from "@7egend/web.core.controls/lib/components/carousel"
import { VideoComponent } from "@7egend/web.core.controls/lib/components/video/video"
import { MediaListData } from "../../Multimedia"
import { Image } from "../../Image";
import { Button } from "../../Button";
import { colors } from "../../../styles";
import { PLACEHOLDER_DOCUMENT } from "../Media/MediaPlaceholder";
import { Column, Row } from "../../Structure";
import { Details } from "./Details";
import { MediumType } from "@7egend/web.core.media/lib/dlos";
import loadable from "@loadable/component";

const VideoStream = loadable(() => import("@7egend/web.core.controls/lib/components/videoStream/videoStream"))

/** Styled Compoenent */
const Styled = {
    /** Preview Wrapper */
    Wrapper: styled.div`
        border-radius: 10px;
        background-color: white;
        height: 700px;

        &::after {
            content: "";
            width: 100%;
            white-space: nowrap;
            clear: both;
            display: block;
        }
    `,
    /** Image Preview */
    Image: styled(Image)`
        & {
            margin: 0 auto;
            height: auto;
            max-height: 700px;
            position: relative;
            z-index: 2;
        }
    `,
    /** Video */
    Video: styled(VideoComponent)`
        position: relative;
        z-index: 2;
    `,
    /**
     * Stream
     */
    Stream: styled(VideoStream)`
        position: relative;
        z-index: 2;
        display: flex;
        align-items: center;
    `,
    /** Image Overlay */
    ImageOverlay: styled.div<{ src?: string }>`
        position: absolute;
        top: 50%;
        left: 50%;
        width: calc(100% + 30px);
        height: calc(100% + 30px) !important;
        z-index: 1;
        filter: blur(10px) brightness(50%);
        transform: translate(-50%,-50%);

        ${({ src }) => src && css`
            background-image: url(${src});
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
        `}
    `,
    /** Container of Carousel */
    CarouselContent: styled.div`
        float: left;
        background-color: ${colors.neutral.lighter};
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        width: 100%;
        height: 100%;

        .slick-prev,
        .slick-next {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            z-index: 1;
        }

        .slick-prev {
            left: 12px;
        }

        .slick-next {
            right: 12px;
        }

        .slick-slide {
            > div {
                height: inherit;
            }
        }

        .slick-media {
            display: inline-flex !important;
            height: inherit;
            align-items: center;
            position: relative;
            overflow: hidden;
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
        }

        .slick-list,
        .slick-slider,
        .slick-track {
            height: 700px;
        }
    `
}

interface MediaPreviewProps {
    /** list of media to preview */
    media: MediaListData[];
    /** active media */
    active?: MediaListData;
}

interface MediaPreviewState {
    activeMedia?: number;
}

export class MediaPreview extends PureComponent<MediaPreviewProps, MediaPreviewState> {

    private sliderRef: React.RefObject<CarouselComponent> = React.createRef();

    public state: MediaPreviewState = {
        activeMedia: this.props.active && this.props.media.indexOf(this.props.active) || 0,
    };

    public render() {
        const { media } = this.props;

        return (
            <Styled.Wrapper>
                <Row>
                    <Column sm={8}>
                        <Styled.CarouselContent>
                            <CarouselComponent
                                ref={this.sliderRef}
                                infinite={false}
                                centerMode={true}
                                slidesToShow={1}
                                adaptiveHeight={false}
                                lazyLoad="ondemand" // this will prevent loading strams and other images
                                afterChange={
                                    (activeMedia: number) =>
                                        this.setState({ activeMedia })
                                }
                                nextArrow={
                                    <Button
                                        disabled={this.state.activeMedia === (this.props.media.length - 1)}
                                        icon="keyboard_arrow_right"
                                        layoutRound
                                        layoutFill
                                        layout="alt"
                                    />
                                }
                                prevArrow={
                                    <Button
                                        disabled={this.state.activeMedia === 0}
                                        icon="keyboard_arrow_left"
                                        layoutRound
                                        layoutFill
                                        layout="alt"
                                    />
                                }
                                initialSlide={this.props.active && this.props.media.indexOf(this.props.active)}
                            >
                                {media.map(item => {
                                    return (
                                        <div
                                            key={`item-${item.id}`}
                                            className="slick-media"
                                        >
                                            {this.renderSlides(item)}
                                        </div>
                                    )
                                })}
                            </CarouselComponent>
                        </Styled.CarouselContent>
                    </Column>
                    <Column sm={4}>
                        <Details
                            medium_type={media[this.state.activeMedia || 0].medium_type || media[this.state.activeMedia || 0].medium_type_id}
                            url={this.getMediaUrl()}
                            title={media[this.state.activeMedia || 0].title}
                        />
                    </Column>
                </Row>
            </Styled.Wrapper>
        )
    }

    /** It returns the url of the media */
    private getMediaUrl = () => {
        const { media } = this.props;
        const targetMedia = media[this.state.activeMedia || 0]

        switch (targetMedia.medium_type || targetMedia.medium_type_id) {
            case MediumType.File:
                return targetMedia.file
            case MediumType.Image:
                return targetMedia.thumb;
            case MediumType.Video:
            case MediumType.Stream:
                return targetMedia.url;
            default:
                break;
        }
    }

    private renderSlides = memoize((item: MediaListData) => {
        switch (item.medium_type || item.medium_type_id) {
            case MediumType.Image:
                return (
                    <React.Fragment>
                        <Styled.ImageOverlay src={item.thumb} />
                        <Styled.Image src={item.thumb || ""} />
                    </React.Fragment>
                )
            case MediumType.File:
                return <Styled.Image src={PLACEHOLDER_DOCUMENT} />
            case MediumType.Video:
                return (
                    <React.Fragment>
                        <Styled.ImageOverlay src={item.thumb} />
                        <Styled.Video controls source={item.url} />
                    </React.Fragment>
                )
            case MediumType.Stream:
                return (
                    <React.Fragment>
                        <Styled.ImageOverlay src={item.thumb} />
                        <Styled.Stream 
                            elementOptions={{
                                controls: true
                            }}
                            pluginOptions={{
                                src: item.url,
                                fluid: true,
                                sources: [{
                                    src: item.url || "",
                                }]
                            } as any} />
                    </React.Fragment>
                )
            default:
                return null;
        }
    })
}
