import React from "react";
import { CTA as CTADLO } from "@7egend/web.core.cms/lib/dlos/cta";
import { Block } from "@7egend/web.core.cms/lib/dlos/block";
import { BlockComponentProps } from "../../interfaces";
import styled from "styled-components";
import { Input } from "../../../Input";
import { colors } from "../../../../styles";
import { Column, Row } from "../../../Structure";
import { Select } from "../../../Select";
import { SelectOption } from "@7egend/web.core.controls/lib/components/select";
import { withI18n, WithI18nProps } from "@7egend/web.core/lib/components/withI18n";
import { APP_TRANSLATIONS } from "../../../../locale";
import { blockTypes } from "../config";
import { I18N_KEY_CMS_COMPONENTS_BLOCK } from "../../../../base/i18n";

const { cta } = blockTypes;

const Wrapper = styled.div`
    display: grid;
    border: 1px solid ${colors.neutral.light};
    border-radius: 6px;
    padding: 16px;
`;

const Structure = {
    type: cta.type,
    content: {
        style: cta.style,
        anchor_target: "1"
    },
    properties: {
        anchor_target: "1"
    }
} as unknown as Block<CTADLO>;

const I18N_NAMESPACE = `${I18N_KEY_CMS_COMPONENTS_BLOCK}.types.cta`

const CTAComponent: React.FC<WithI18nProps & BlockComponentProps<CTADLO>> = ({ className, data, updateData, t }) => {

    const CTAOptions: SelectOption[] = [
        {
            value: "1",
            label: t(`${I18N_NAMESPACE}.inputCTALinkSamePage`),
            option: {
                value: "1"
            }
        },
        {
            value: "2",
            label: t(`${I18N_NAMESPACE}.inputCTALinkNewPage`),
            option: {
                value: "2"
            }
        }
    ];

    return (
        <Wrapper className={className}>
            <Row>
                <Column sm={6}>
                    <Input
                        name="label"
                        type="text"
                        label={t(`${I18N_NAMESPACE}.inputCTALabel`)}
                        placeholder={t(`${I18N_NAMESPACE}.inputCTAPlaceholder`)}
                        value={data.content && data.content.label}
                        onChange={(label: string) => updateData({
                            ...data,
                            content: {
                                ...data.content,
                                label,
                            }
                        })}
                    />
                </Column>
                <Column sm={6}>
                    <Select
                        name="target"
                        label={t(`${I18N_NAMESPACE}.inputCTALink`)}
                        options={CTAOptions}
                        value={data.properties && data.properties.anchor_target || data.content && data.content.anchor_target && data.content.anchor_target.id}
                        onChange={(target: string) => updateData({
                            ...data,
                            properties: {
                                ...data.properties,
                                anchor_target: target,
                            }
                        })}
                    />
                </Column>
            </Row>
            <Input
                name="url"
                type="text"
                label={t(`${I18N_NAMESPACE}.inputLinkLabel`)}
                placeholder={t(`${I18N_NAMESPACE}.inputLinkPlaceholder`)}
                value={data.content && data.content.value}
                onChange={(value: string) => updateData({
                    ...data,
                    content: {
                        ...data.content,
                        value,
                    }
                })}
            />
        </Wrapper>
    );
}

const Component = withI18n(APP_TRANSLATIONS)(CTAComponent);

const Button = {
    icon: "accessibility",
    name: {
        key: `${I18N_NAMESPACE}.name`,
        en: "CTA",
        es: "CTA",
        pt: "CTA",
    },
};

export const CTA = {
    key: `${cta.type}_${cta.style}`,
    Structure,
    Component,
    Button,
};
