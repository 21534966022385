import React from "react";
import classNames from "classnames";
import { CoreComponent } from "@7egend/web.core/lib/base"
import { MediaEvents } from "@7egend/web.core/lib/events";

import styles from "./video.module.css"

/**
 * props for source and all parameters
 */
export interface Props {
    active?: boolean,
    /* source format: mp4 */
    source: string,
    autoPlay?: boolean,
    controls?: boolean,
    controlsList?: string,
    height?: string,
    width?: string,
    loop?: boolean,
    muted?: boolean,
    /* preload: none, metadata or auto (default) */
    preload?: string,
    poster?: string,
    playsInline?: boolean,
    className?: string,
}

/**
 * define all defaultprops
 */
const defaultProps = {
    active: true,
    source: "",
    autoPlay: false,
    controls: false,
    height: "",
    width: "",
    loop: false,
    muted: false,
    preload: "auto",
    poster: "",
    playsInline: true,
    controlsList: "nodownload",
}

/**
 * # Component Video
 * This component renders a basic html5 video implementation.
 * It supports all html5 attributes
 *
 * ## How to use
 *
 * Example:
 * ```HTML
 * <VideoComponent source="path/to/video" autoPlay={true} controls={true} poster="path/to/video-poster" />
 * ```
 *
 *
 * ### Parameters:
 *  source: '', = video path in mp4
 *  autoPlay: false,
 *  controls: false,
 *  height: '',
 *  width: '',
 *  loop: false,
 *  muted: false,
 *  preload: 'auto',
 *  poster: '',
 *  playsInline: true
 *
 */
export class VideoComponent extends CoreComponent<Props> {

    /* set default props */
    public static defaultProps = defaultProps;

    public mediaStartCb?: () => void

    public videoRef = React.createRef<HTMLVideoElement>()

    public componentDidMount() {
        // Subscribe for other media starts
        this.mediaStartCb = this.fw.events.subscribe(MediaEvents.START, this.onMediaStart);
    }

    public componentDidUpdate(prevProps: Props) {
        if (prevProps.active !== this.props.active && this.props.active === false) {
            this.onMediaDeactivate()
        }
    }

    public componentWillUnmount() {
        if (this.mediaStartCb) {
            this.mediaStartCb();
        }
    }

    public render() {
        let poster: string = this.props.poster || this.props.source;
        poster = poster.startsWith("http") ? poster : "data:image/png;base64, " + poster;
        const className = classNames(styles.video, this.props.className);

        return (
            <video
                ref={this.videoRef}
                key={this.props.source}
                className={className}
                autoPlay={this.props.autoPlay}
                controls={this.props.controls}
                height={this.props.height}
                width={this.props.width}
                loop={this.props.loop}
                muted={this.props.muted}
                preload={this.props.preload}
                poster={poster}
                playsInline={this.props.playsInline}
                controlsList={this.props.controlsList}
            >
                <source src={this.props.source} type="video/mp4"></source>
                {/* POSSIBLE FEATURE: <p>Your browser doesn't support HTML5 video. Here is a <a href="myVideo.mp4">link to the video</a> instead.</p> */}
            </video>
        )
    }

    private onMediaStart = () => {
        if (this.videoRef.current) {
            (this.videoRef.current as any).muted = true;
        }
    }

    private onMediaDeactivate = () => {
        if (this.videoRef.current) {
            (this.videoRef.current as any).pause()
        }
    }
}
