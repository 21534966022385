import React from "react";
import { PureComponent } from "@7egend/web.core/lib/base/component"

export interface SelectProps {
    /** classname */
    className?: string

    /** Form name */
    name: string;

    /** Text to show on label */
    label?: string;

    /** Text to show on empty selection */
    defaultLabel?: string;

    /** Options to list inside select */
    options: SelectOption[];

    /** Current selected value */
    value?: string;

    /** Default value */
    defaultValue?: string;

    /** If the select is required */
    required?: boolean;

    /** Disabled */
    disabled?: boolean;

    /** Change event */
    onChange: (value: string, name: string, valid: boolean) => void
}

export interface SelectOption {
    /** Option label */
    label?: string

    /** Option value (this is used as value for onChange */
    value: string;

    /** Option object with value string (this is the option display value) */
    option?: {
        value?: string;
        disabled?: boolean;
    }
}

const EmptyValue = Symbol("empty-value")

/**
 * # Select Component
 * This component represents a select option input
 *
 * ## How to use
 * ````<SelectComponent options={data} label="lorem ipsum" />````
 *
 */
export class Select extends PureComponent<SelectProps> {

    public render() {
        const { name, value, label, defaultValue, defaultLabel, disabled, options, required, className } = this.props;

        return (
            <select
                name={name}
                onChange={this.onChange}
                value={value || defaultValue || EmptyValue.toString()}
                disabled={disabled || false}
                required={required}
                className={className}
            >
                {(defaultValue !== undefined || defaultLabel) &&
                    <option key="default" value={defaultValue || EmptyValue.toString()} disabled={required}>{defaultLabel || label}</option>
                }
                {options.map(this.renderOption)}
            </select>
        );
    }

    private renderOption(item: SelectOption) {
        const value = item.value;
        const label = item.label || item.option && item.option.value

        return (
            <option key={value} value={value}>
                {label}
            </option>
        );
    }

    private onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        let value = (e.target as any).value;
        if (value === EmptyValue.toString()) {
            value = undefined
        }

        const valid: boolean = (e.target as any).validity.valid
        this.props.onChange(value, this.props.name, valid);
    }
}
