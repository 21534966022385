import styled, { css } from "styled-components";
import { colors, font } from "../../../styles";
import { TooltipPosition } from "../Tooltip";

export const StyledTooltipCard = styled.div<{ position: TooltipPosition}>`
    background-color: ${colors.neutral.darker};
    padding: 10px;
    color: ${colors.light};
    font-size: ${font.size.xs};
    font-weight: ${font.weight.regular};
    border-radius: 8px;
    box-sizing: border-box;
    white-space: nowrap;
    -webkit-font-smoothing: antialiased;
    position: absolute;
    z-index: 2;

    &::after  {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        border-style: solid;
    }

    ${({ position }) => position && css`
    
        ${position === "bottom" && css`
            left: 50%;
            top: calc(100% + 8px);
            transform: translateX(-50%);

            &::after  {
                bottom: 100%;
                left: 50%;
                transform: translateX(-50%);
                border-width: 0 5px 5px 5px;
                border-color: transparent transparent ${colors.neutral.darker}
                    transparent;
            }
        `}

        ${position === "top" && css`
            left: 50%;
            bottom: calc(100% + 8px);
            transform: translateX(-50%);

            &::after  {
                left: 50%;
                transform: translateX(-50%);
                top: 100%;
                border-width: 8px 8px 0 8px;
                border-color: ${colors.neutral.darker} transparent
                    transparent transparent;
            }
        `}

        ${position === "left" && css`
            top: 50%;
            right: calc(100% + 8px);
            transform: translateY(-50%);

            &::after  {
                top: 50%;
                left: 100%;
                transform: translateY(-50%);
                border-width: 5px 0 5px 5px;
                border-color: transparent transparent transparent
                    ${colors.neutral.darker};
            }
        `}

        ${position === "right" && css`
            top: 50%;
            left: calc(100% + 8px);
            transform: translateY(-50%);

            &::after  {
                right: 100%;
                top: 50%;
                transform: translateY(-50%);
                border-width: 5px 5px 5px 0;
                border-color: transparent ${colors.neutral.darker} transparent
                    transparent;
            }
        `}
    `}
    
`;
