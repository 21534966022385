import { MenuItems } from "../../bootstrap";

/** Icons */
import apps from "../../assets/menuIcons/apps.svg";
import ticketing from "../../assets/menuIcons/ticketing.svg";
import sportsCenter from "../../assets/menuIcons/sportscenter.svg";
import hospitality from "../../assets/menuIcons/hospitality.svg";
import seatDelivery from "../../assets/menuIcons/seatdelivery.svg";
import marketing from "../../assets/menuIcons/marketing.svg";
import portal from "../../assets/menuIcons/portal.svg";
import ecommerce from "../../assets/menuIcons/ecommerce.svg";
import target from "../../assets/menuIcons/target.svg";
import clubTour from "../../assets/menuIcons/clubtour.svg";
import digitalMagazines from "../../assets/menuIcons/digitalmagazines.svg";
import connectedStores from "../../assets/menuIcons/connectedstores.svg";
import hospitalityMgmt from "../../assets/menuIcons/hospitalitymgmt.svg";
import ott from "../../assets/menuIcons/ott.svg";


export const SYSTEM_MODULES_MENUS_PLACEHOLDER: MenuItems[] = [
    {
        path: "sports-center",
        title: "Sports Center",
        icon: sportsCenter,
        enabled: false
    },
    {
        path: "hospitality-tv",
        title: "Hospitality TV",
        icon: hospitality,
        enabled: false
    },
    {
        path: "seat-delivery",
        title: "Seat Delivery",
        icon: seatDelivery,
        enabled: false
    },
    {
        path: "marketing",
        title: "Marketing",
        icon: marketing,
        enabled: false
    },
    {
        path: "portal",
        title: "Portal",
        icon: portal,
        enabled: false
    },
    {
        path: "mobile-apps",
        title: "Mobile Apps",
        icon: apps,
        enabled: false
    },
    {
        path: "shop",
        title: "Ecommerce",
        icon: ecommerce,
        enabled: false
    },
    {
        path: "ticketing",
        title: "Ticketing",
        icon: ticketing,
        enabled: false,
    },
    {
        path: "target",
        title: "Target",
        icon: target,
        enabled: false,
    },
    {
        path: "club-tour",
        title: "Club Tour",
        icon: clubTour,
        enabled: false,
    },
    {
        path: "digital-magazines",
        title: "Digital Magazines",
        icon: digitalMagazines,
        enabled: false,
    },
    {
        path: "connected-stores",
        title: "Connected Stores",
        icon: connectedStores,
        enabled: false,
    },
    {
        path: "hospitality-mgmt",
        title: "Hospitality Mgmt",
        icon: hospitalityMgmt,
        enabled: false,
    },
    {
        path: "ott",
        title: "OTT",
        icon: ott,
        enabled: false,
    },
]
