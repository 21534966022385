import React, { FC } from 'react'
import { WithI18nProps, withI18n } from "@7egend/web.core/lib/components/withI18n"
import { Medium, MediumType } from "@7egend/web.core.media/lib/dlos/medium"
import { Button as ButtonComponent, Divider, MediaList, MediaListData, Space, Typography, Uploader } from "../../../../components"
import { Block, Container } from '@7egend/web.core.cms/lib/dlos'
import styled from 'styled-components'
import { blockTypes } from "../config"
import { colors } from "../../../../styles"
import { I18N_KEY_CMS_COMPONENTS_BLOCK, I18N_KEY_GLOBAL } from "../../../../base/i18n"
import { BlockComponentProps } from "../../interfaces"

const { container, imageList, medium } = blockTypes;

const Structure = {
    type: container.type,
    content: {
        style: imageList.style,
        blocks: [{
            type: medium.type,
            content: {}
        }] as Array<Block<Medium>>
    },
} as any;

const StyleListWrapper = styled.div`
    border: 1px solid ${colors.neutral.light};
    border-radius: 6px;
    padding: 16px;

    &::after {
        content: "";
        width: 100%;
        display: block;
        white-space: nowrap;
        clear: both;
    }
`;

const StyledImageList = styled.div`
    display: block;
    width: 100%;
    padding-top: 5px;

    &::after {
        content: "";
        width: 100%;
        display: block;
        white-space: nowrap;
        clear: both;
    }
`

const StyledAddButton = styled(ButtonComponent)`
    display: block;
    width: 100%;
`;

const StyledRemoveButton = styled(ButtonComponent)`
    float: right;
`;


const updateItem = (valuesList: any, currentItem: Medium, i: number) => {
    const newValuesList = [...valuesList];
    newValuesList[i].content = {
        ...currentItem,
        type: medium.type
    };
    return newValuesList;
};

const removeSelectedImage = (list: any, i: number) => {
    const newValuesList = [...list];
    newValuesList[i].content = {
        medium_type: MediumType.Image,
    };
    return newValuesList;
}

const removeItem = (valuesList: any, i: number) => {
    const updatedList = [
        ...valuesList.slice(0, i),
        ...valuesList.slice(i + 1),
    ];

    return updatedList;
};

const addItem = (valuesList: any) => {
    const newvaluesList = [...valuesList];
    newvaluesList.push(
        {
            type: medium.type,
            content: {}
        }
    );

    return newvaluesList;
};

const I18N_NAMESPACE = `${I18N_KEY_CMS_COMPONENTS_BLOCK}.types.imageList`

const ImageListComponent: FC<BlockComponentProps<Container<Medium>> & WithI18nProps> = ({ className, data, updateData, t }) => {
    if (!data.content.blocks || data.content.blocks.length === 0) {
        return null
    }

    return (
        <StyleListWrapper className={className}>
            {data.content.blocks.map((eachBlock: any, index: number) => {
                const hasMedia = !!eachBlock.content?.uuid;

                return (
                    <React.Fragment key={index}>
                        <StyledImageList>
                            {data.content.blocks.length > 1 &&
                                <StyledRemoveButton
                                    icon={"delete"}
                                    onClick={() => updateData({
                                        ...data,
                                        content: {
                                            ...data.content,
                                            blocks: removeItem(data.content.blocks, index)
                                        }
                                    })}
                                />
                            }
                            {!hasMedia &&
                                <Uploader.MediaList
                                    limitSelectable={1}
                                    mediumType={MediumType.Image}
                                    context={{ 'panoramic': false }}
                                    heightLimited={hasMedia ? true : false}
                                    info={
                                        hasMedia ? (
                                            <React.Fragment>
                                                <Typography.RegularText variant="neutral">
                                                    <strong>{t(`${I18N_KEY_GLOBAL}.selectFromLibrary`)}</strong> {t(`${I18N_KEY_GLOBAL}.or`)} <strong>{t(`${I18N_KEY_GLOBAL}.uploadFiles`)}</strong>
                                                </Typography.RegularText>
                                            </React.Fragment>
                                        ) : (
                                            ""
                                        )
                                    }
                                    renderColumns={{
                                        xs: hasMedia ? 6 : 3,
                                    }}
                                    checkedMedia={(checkedMedia: MediaListData[]) =>
                                        updateData({
                                            ...data,
                                            content: {
                                                ...data.content,
                                                blocks: updateItem(data.content.blocks, checkedMedia[0], index)
                                            }
                                        })
                                    }
                                />
                            }
                            {hasMedia && (
                                <MediaList
                                    renderColumns={{
                                        xs: 3,
                                    }}
                                    list={[eachBlock.content]}
                                    isCheckable={false}
                                    deleteMedia={(item: MediaListData) => {
                                        updateData({
                                            type: Structure.type,
                                            id: item.uuid,
                                            content: {
                                                style: imageList.style,
                                                blocks: removeSelectedImage(data.content.blocks, index)
                                            },
                                        } as any)
                                    }}
                                />
                            )}
                        </StyledImageList>
                        {data.content.blocks.length > 1 && <Divider space={Space.Small} layout={"lighter"} />}
                    </React.Fragment>
                )
            })}
            <StyledAddButton
                text={t(`${I18N_NAMESPACE}.addNewItem`)}
                icon={"add"}
                layoutIconPosition={"left"}
                onClick={() => updateData({
                    ...data,
                    content: {
                        ...data.content,
                        blocks: addItem(data.content.blocks)
                    }
                })}
            />
        </StyleListWrapper>
    )
}

const Component = withI18n()(ImageListComponent);

const Button = {
    icon: "photo_library",
    name: {
        key: `${I18N_NAMESPACE}.name`,
        en: "Image list",
        es: "Lista de Imagens",
        pt: "Lista de Imagens",
    },
};

export const ImageList = {
    key: `${container.type}_${imageList.style}`,
    Structure,
    Component,
    Button,
};
