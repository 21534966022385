import React, { useCallback, useMemo } from "react"
import moment from "moment"
import { InputProps as InputCoreProps } from "@7egend/web.core.controls/lib/components/input"
import { useFramework } from "@7egend/web.core/lib/components/useFramework"
import { Config } from "../.."
import * as Styles from "./styles"

export const InputDateTime = ({value, onChange, forwardedRef, ...otherProps}: InputCoreProps & { forwardedRef: React.Ref<any> }) => {
    const { config: { locale: { timezone }} }: { config: Config } = useFramework()
    
    const valueFormated = useMemo(() => {
        if (!value) {
            return undefined
        }

        if (timezone) {
            return moment.utc(value).tz(timezone).format("YYYY-MM-DDTHH:mm")
        }

        return moment(value).format("YYYY-MM-DDTHH:mm")
    }, [value, timezone])

    const onValueChange = useCallback((newValue: string, name: string, valid: boolean) => {
        let newValueFormated = newValue

        if (timezone && newValue) {
            newValueFormated = moment.tz(newValue, timezone).utc().format("YYYY-MM-DD HH:mm")
        } else if (newValue) {
            newValueFormated = moment(newValue).utc().format("YYYY-MM-DD HH:mm")
        }

        onChange(newValueFormated, name, valid)

    }, [onChange, timezone])

    return (
        <Styles.StyledField
            {...otherProps}
            ref={forwardedRef}
            value={valueFormated}
            onChange={onValueChange}
        />
    )
}