import styled from "styled-components";
import { Button } from "../Button";
import { Wrapper as WrapperComponent } from "../Wrapper"
import { Dropdown as DropdownComponent } from "../Dropdown";

const Wrapper = styled(WrapperComponent)`
    & {
        height: 100%;
    }
`
const Header = styled.div`
    display: flex;
    justify-content: space-between;
`;

const Dropdown = styled(DropdownComponent)`
    & {
        position: absolute;
        right: 0;
        bottom: -4px;
    }
`;

const Footer = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    position: relative;
    min-height: 20px;
`;

const MetricOptions = styled(Button)`
    padding: 0;
    min-height: 0;
`;

export const Styles = {
    Header,
    Footer,
    MetricOptions,
    Dropdown,
    Wrapper
}
