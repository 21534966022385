
/** Import User DLOs */
import {
    UserGetAllDatatableInput,
    UserGetBySlugInput,
    UserCreateInput,
    UserCreateOutput,
    UserUpdateInput,
    UserGetRolesBySlugInput,
    UserGetSessionsBySlugInput,
    UserDeleteSessionInput,
    UserUpdateRolesInput
} from "./user";

/** Import Group DLOs */
import {
    GroupGetAllInput
} from "./group"

/** Core DLOs */
export const core = {
    /** Users */
    UserGetAllDatatableInput,
    UserGetBySlugInput,
    UserCreateInput,
    UserCreateOutput,
    UserUpdateInput,
    UserGetRolesBySlugInput,
    UserGetSessionsBySlugInput,
    UserDeleteSessionInput,
    /** Groups */
    GroupGetAllInput,

    /** User Roles */
    UserUpdateRolesInput,
}
