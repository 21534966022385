import { ground, ExtensionConstructor } from "@7egend/web.ground/lib/ground";
import fetch from "isomorphic-fetch";
import React from "react";
import ReactDOM from "react-dom";
import { App, AppOptions } from "./components/app/app";
import { Provider as StoreProvider, store } from "./base/store";

import { DloExtension } from "./extensions/dlo/dlo"

declare var document: any;
declare var window: any;

/**
 * Bootstrap Options interface
 */
export interface BootstrapOptions {
    /**
     * List of extensions to load.
     */
    extensions?: { [key: string]: ExtensionConstructor };

    options: AppOptions
}

export default async (configLoader: (config: any) => any, options: BootstrapOptions) => {
    // Load the configuration from the external file
    // This is done here in order to abstract all configuration files
    const baseConfig = await (await fetch("/config.json")).json()
    const localStorageConfig = window && window.localStorage && window.localStorage.getItem("__config") || "{}"
    const config = await configLoader({ ...baseConfig, ...JSON.parse(localStorageConfig) });

    // By default, include these extensions
    const extensions = options.extensions || {};
    extensions.dlo = extensions.dlo || DloExtension;

    // Start the application
    ground.start(config, extensions);

    // Get document root for drawing
    const root = document.getElementById("root");

    ReactDOM.render(
        <StoreProvider store={store}>
            <App options={options.options} />
        </StoreProvider>,
        root,
    );
}
