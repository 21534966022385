import React from "react";
import { CorePureComponent } from "@7egend/web.core";
import { StyledTooltip, StyledTooltipContent, StyledWrapper } from "./styles"
import { TooltipCard, TooltipCardProps } from "./TooltipCard/TooltipCard";

export type TooltipPosition = "top" | "right" | "bottom" | "left";

interface TooltipProps extends TooltipCardProps {
    /** Tooltip component - this property is used to wrap your component */
    component?:
    | React.StatelessComponent<any>
    | React.ComponentType<any>
    | JSX.Element;

    /** attach to the body */
    attachOnBody?: boolean;

    className?: string;
}

const DefaultProps: TooltipProps = {
    position: "bottom",
};

/**
 *
 * # Tooltip Component
 */
export class Tooltip extends CorePureComponent<TooltipProps> {
    public static defaultProps = DefaultProps;
    private readonly rootRef = React.createRef<React.ReactElement>() as any;

    public render() {
        return (
            <StyledTooltip className={this.props.className}>
                <StyledWrapper ref={this.rootRef}>
                    {this.props.component}
                </StyledWrapper>
                <StyledTooltipContent>
                    <TooltipCard position={this.props.position}>{this.props.children}</TooltipCard>
                </StyledTooltipContent>
            </StyledTooltip>
        );
    }
}
