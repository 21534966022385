import { Config } from "./domain/config";
import { Extension, ExtensionConstructor } from "./domain/extension";
import { Ground as GroundInterface } from "./domain/ground";
import { Framework } from "./domain/components/framework";

declare var process: any;
declare var globalThis: any;
declare var console: any;
let ground: Ground = globalThis.ground || null;

//#region Exports

// Extensions and base domain
export { Extension, ExtensionConstructor };
// Export inject and injectable for legacy compatibility
/**
 * @deprecated
 * @param options
 */
export function inject(options?: any) {
    return (constructor: any) => {
        return constructor;
    }
}
/**
 * @deprecated
 * @param options
 */
export function injectable(options?: any) {
    return (constructor: any) => {
        return constructor;
    }
}
//#endregion

//#region Default Imports
import diExtension, { $di } from "./extensions/di/keyValue"
import loggerExtension from "./extensions/logger/console"
import storageMemoryExtension from "./extensions/storage/memory"
import storageLocalExtension from "./extensions/storage/local"
import storageSessionExtension from "./extensions/storage/session"
import httpExtension from "./extensions/http/axios"
import i18nExtension from "./extensions/i18n/memory"
import domExtension from "./extensions/dom/webWindow"
import eventsExtension from "./extensions/events/events"
import { Di } from "./domain/extensions/di";
//#endregion

export class Ground implements GroundInterface {

    //#region Public properties

    /** App config */
    public config: Config = null as any;

    /** Di container */
    public container: Di = $di

    /** Extensions container */
    public extensions: {[key: string]: Extension} = {};

    /** Framework container */
    public get framework(): Framework {
        return this.extensions as any
    };

    //#endregion

    public constructor() {
        // For now, do nothing
        this.debug("Ground: initializing");
    }

    //#region Public methods

    /**
     * Starts the ground.
     * This is going to bootstrap all the extensions and modules.
     */
    public start(config: Config, extensions: {[key: string]: ExtensionConstructor} = {}): Ground {
        // Save the config
        this.config = config;

        // Merge all extensions to load
        const extensionsToLoad: {[key: string]: new (...args: any[]) => any} = Object.assign({}, {
            di:              diExtension,
            log:             loggerExtension,
            memoryStorage:   storageMemoryExtension,
            localStorage:    storageLocalExtension,
            sessionStorage:  storageSessionExtension,
            http:            httpExtension,
            i18n:            i18nExtension,
            dom:             domExtension,
            events:          eventsExtension,
        }, extensions);

        // Install base extensions
        for (const key in extensionsToLoad) {
            if (extensionsToLoad.hasOwnProperty(key)) {
                const element = extensionsToLoad[key];
                this.use(key, element);
            }
        }

        // Instantiate all extensions to instantiate and save to container
        for (const key in extensionsToLoad) {
            if (extensionsToLoad.hasOwnProperty(key)) {
                const extension = this.container.get(key) as Extension;
                this.extensions[key] = extension;
                if (extension.initialize) {
                    extension.initialize(this.config, this);
                }
                this.debug(`Extension initialized: ${key}`);
            }
        }

        // Start all extensions
        for (const extension in this.extensions) {
            if (this.extensions.hasOwnProperty(extension)) {
                const element: Extension = this.extensions[extension];
                if (element.afterAppStarts !== undefined && element.afterAppStarts instanceof Function) {
                    element.afterAppStarts();
                }
            }
        }

        // All done
        this.debug("Ground: started");

        return this;
    }

    /**
     *
     * @param name The extension name
     * @param extension Extension class or type
     */
    public use(name: string, extension: new (...args: any[]) => any): void {
        try {
            this.container.set(name, new extension()); // hack: make them singleton by instantiating
            this.debug(`Extension loaded: ${name}`);
        } catch (error) {
            console.error(`Failed to load extension: ${name}`, error);
        }
    }

    /**
     * Gets an extension
     * @param name Extension name
     */
    public getExtension<T extends Extension>(name: string): T | undefined  {
        return this.container.get<T>(name);
    }

    private debug(...args: any[]) {
        if (process.env.NODE_ENV !== "production") {
            console.debug(...args)
        }
    }

    //#endregion
}

// Creates the Ground
if (!ground) {
    ground = new Ground();
    globalThis.ground = ground;
}

export { ground };
