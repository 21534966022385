import styled from "styled-components";
import { Button as ButtonComponent } from "../Button";
import { Modal as ModalComponent } from "../Modal";
import { Checkbox as CheckboxComponent } from "../Checkbox";
import { colors, queries, font } from "../../styles";
import { StyledModalContent } from "../Modal/Modal";
import { Icon } from "../Icon";

const Wrapper = styled.div`
    border-bottom: 1px solid ${colors.neutral.light};
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    margin-bottom: 32px;
`;

const Labels = styled.div`
    > * {
        margin: 0 8px 3px 0;
        &:last-child {
            margin-right: 0;
        }
    }
`;

const Button = styled(ButtonComponent)`
    & {
        padding: 0;
        min-height: 0;
    }
`;

const Modal = styled(ModalComponent)`
    & {
        ${StyledModalContent} {
            ${queries.minMD} {
                min-width: 600px;
            }
        }
    }
`;

const Placeholder = styled.span`
    color: ${colors.neutral.dark};
    font-size: ${font.size.sm};
    font-weight: ${font.weight.light};
`;

const List = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
    position: relative;

    & li:last-child {
        border-bottom: 1px solid ${colors.neutral.light};
    }
`;

const ListItem = styled.li`
    position: relative;
    display: block;
    border-top: 1px solid ${colors.neutral.light};

    ${List} {
        padding: 0 0 0 12px;

        li {
            padding: 0 0 0 12px;
            border-left: 1px solid ${colors.neutral.light};

            &:last-child,
            &:first-child {
                border-top: 1px solid ${colors.neutral.light};
                border-bottom: none;
            }
        }
    }
`

const ListItemCheckbox = styled(CheckboxComponent)`
    & {
        margin-left: 10px;
    }
`;

const ListItemContent = styled.div`
    position: relative;
    padding: 19px 0;
    display: flex;
    align-items: center;
    cursor: pointer;

    h6 {
        margin-left: 10px;
    }
`

const ListItemIcon = styled(Icon)`
    color: ${colors.primary.lighter};
    cursor: pointer;
    margin-left: 10px;
`;

export const Styled = {
    Wrapper,
    Button,
    Labels,
    Modal,
    Placeholder,
    List,
    ListItem,
    ListItemContent,
    ListItemIcon,
    ListItemCheckbox
};
