import React, { PureComponent } from "react";
import { StyledPicker } from "./styles";
import { Column } from "../../../../components/Structure";
import { Modal } from "../../../../components/Modal";
import { Boosters } from "../Boosters";
import { Button } from "../../../../components";
import { Booster } from "@7egend/web.core.cms/lib/dlos/booster";
import { withI18n, WithI18nProps } from "@7egend/web.core/lib/components/withI18n";
import { Trans } from "@7egend/web.core/lib/components/i18n";
import { APP_TRANSLATIONS } from "../../../../locale";
import { I18N_KEY_CMS_PAGES_BOOSTER, I18N_KEY_GLOBAL } from "../../../../base/i18n";

interface BoosterPickerDLO extends Booster {
    "header:thumb"?: string;
    "preview:thumb"?: string;
    "medium_1x1:thumb"?: string;
}

interface BoostersPickerProps {
    /** return select media */
    selectItem?: (selectedItems: BoosterPickerDLO[]) => void;

    checkedItems?: BoosterPickerDLO[];

    /** to override the default number of columns */
    renderColumns?: {
        xs?: number;
        sm?: number;
        md?: number;
        lg?: number;
        xl?: number;
    };

    /** class name */
    className?: string
}

interface BoostersPickerUploaderState {
    modalVisible?: boolean;
    checkedItems: BoosterPickerDLO[];
}

const defaultColumns = {
    xl: 2,
};

const I18N_NAMESPACE = I18N_KEY_CMS_PAGES_BOOSTER

/**
 * # Uploader Media List
 *
 * ## How to use:
 *
 * ```jsx
 * <BoostersPicker
 *      checkedItems={[BoosterPickerDLO]}
 *      renderColumns={{ xs: 12, sm: 6 ... }}
 *      selectItem={(selectedItems: BoosterPickerDLO[]) => void};
 * />
 * ```
 */
class BoostersPickerComponent extends PureComponent<
    BoostersPickerProps & WithI18nProps,
    BoostersPickerUploaderState
> {

    public static defaultProps = {
        renderColumns: defaultColumns,
    }

    public state: BoostersPickerUploaderState = {
        modalVisible: false,
        checkedItems: [],
    };

    public render() {
        const hasItems = this.props.checkedItems && this.props.checkedItems[0].uuid ? "close" : "add"

        return (
            <React.Fragment>
                <Column {...(this.props.renderColumns)}>
                    <StyledPicker.Wrapper
                        className={this.props.className}
                    >
                        <StyledPicker.Actions
                            onClick={this.toggleModal}
                            style={{
                                backgroundImage: `url(${this.renderThumb()})`,
                                backgroundPosition: "center",
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "cover",
                            }}
                        >
                            <StyledPicker.Action layout="alt" icon={hasItems} />
                        </StyledPicker.Actions>
                        <StyledPicker.Header>{this.props.checkedItems && this.props.checkedItems[0].name}</StyledPicker.Header>
                    </StyledPicker.Wrapper>
                </Column>
                <Modal
                    handleModal={this.toggleModal}
                    isVisible={this.state.modalVisible}
                    overlay
                    fullWidth
                >
                    <Boosters
                        picker={true}
                        pickedItems={this.state.checkedItems}
                        pickItem={this.pickItem}
                        footer={
                            <Column contentAlign="right">
                                <Button
                                    layout="main"
                                    layoutOutline
                                    icon="clear"
                                    layoutIconPosition="right"
                                    onClick={this.toggleModal}
                                >
                                    <Trans id={`${I18N_KEY_GLOBAL}.cancel`} />
                                </Button>
                                <Button
                                    layout="main"
                                    layoutFill
                                    icon="check"
                                    layoutIconPosition="right"
                                    onClick={this.addItems}
                                    disabled={
                                        this.state.checkedItems.length < 1
                                    }
                                >
                                    <Trans id={`${I18N_NAMESPACE}.picker.addForm`} />
                                </Button>
                            </Column>
                        }
                    />
                </Modal>
            </React.Fragment >
        );
    }

    /** render thumb */
    private renderThumb = () => {
        if (!this.props.checkedItems) {
            return
        }

        const item = this.props.checkedItems[0]

        if (!item || !item.uuid) {
            return
        }

        if (item["preview:thumb"]) {
            return item["preview:thumb"]
        }

        if (item["header:thumb"]) {
            return item["header:thumb"]
        }

        if (item["medium_1x1:thumb"]) {
            return item["medium_1x1:thumb"]
        }

        // By default we will use the header
        let result: string | undefined;

        // it will find which medium was created first when it was uploaded.
        const firstMediumCreated = item.booster_media.sort((a: any, b: any) => b.medium.date - a.medium.date)?.[0]

        // Try to locate the square image
        result = firstMediumCreated?.medium.thumb

        // No media, try the header
        if (!result) {
            result = item.header?.thumb
        }

        // At this point there is no header and no square, try any image
        if (!result) {
            result = item.booster_media?.[0]?.medium.thumb
        }

        return result
    }

    private toggleModal = () => {
        const { modalVisible } = this.state;

        this.setState({
            modalVisible: !modalVisible,
            checkedItems: [],
        });
    };

    private addItems = () => {
        if (this.state.checkedItems && this.props.selectItem) {
            /** sent to parent component, checked items */
            this.props.selectItem(this.state.checkedItems);

            /** hide modal */
            this.toggleModal();

            /** clean state */
            this.setState({
                checkedItems: [],
            });
        }
    };

    private pickItem = (checkedItems?: BoosterPickerDLO[]) => {
        if (checkedItems) {
            this.setState({
                checkedItems,
            });
        }
    }
}

export const BoostersPicker = withI18n(APP_TRANSLATIONS)(BoostersPickerComponent);
