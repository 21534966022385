import React, { PureComponent } from "react"
import styled from "styled-components";
import { GridGutter, GridMaxWidth } from "../../../constants";
import { queries } from "../../../styles/variables";

export interface ContainerProps {
    /** className */
    className?: string
    /** gutter */
    gutter?: number
    /** fluid wrapper */
    fluid?: boolean
}

const DefaultProps: ContainerProps = {
    gutter: GridGutter,
    fluid: false,
}

/** Styled Component */
const StyledContainer = styled.div`
    position: relative;
    margin-left: auto;
    margin-right: auto;
    padding-left: ${(props: ContainerProps) => props.gutter && (props.gutter / 2)}px;
    padding-right: ${(props: ContainerProps) => props.gutter && (props.gutter / 2)}px;
    width: 100%;

    ${(props: ContainerProps) => !props.fluid && `
        ${queries.minMD} {
            width: calc(100% - 40px);
        }

        ${queries.minXL} {
            width: calc(100% - 72px);
        }

        @media (min-width: ${(GridMaxWidth - 1)}px) {
            max-width: ${GridMaxWidth}px;
        }
    `}
`

/**
 * # Container Component
 */
export class Container extends PureComponent<ContainerProps> {

    public static defaultProps = DefaultProps;

    public render() {

        return (
            <StyledContainer
                {...this.props}
                gutter={GridGutter}
            >
                {this.props.children}
            </StyledContainer>
        )
    }
}
