import React, { PureComponent } from "react";
import styled, { css } from "styled-components";
import { colors } from "../../styles";

export enum Space {
    Normal = "normal",
    Small = "small",
    Tiny = "tiny",
}

export interface DividerProps {
    className?: string;
    layout?: "lighter" | "light" | "dark";
    space?: Space | `${Space}`;
}

const SizeMapping: Record<Space, string> = {
    normal: "18px",
    small: "10px",
    tiny: "5px",
}

const StyledDivider = styled.div<DividerProps>`
    position: relative;
    width: 100%;
    height: 1px;
    display: block;

    ${(props: DividerProps) =>
        props.layout === "lighter" &&
        css`
            background-color: ${colors.neutral.light};
        `}

    ${(props: DividerProps) =>
        props.layout === "light" &&
        css`
            background-color: ${colors.primary.lighter};
        `}

    ${(props: DividerProps) =>
        props.layout === "dark" &&
        css`
            background-color: ${colors.neutral.darker};
        `}

    ${(props: DividerProps) =>
        props.space === Space.Normal &&
        css`
            margin-top: ${SizeMapping[Space.Normal]};
            margin-bottom: ${SizeMapping[Space.Normal]};
        `}

    ${(props: DividerProps) =>
        props.space === Space.Small &&
        css`
            margin-top: ${SizeMapping[Space.Small]};
            margin-bottom: ${SizeMapping[Space.Small]};
        `}

    ${(props: DividerProps) =>
        props.space === Space.Tiny &&
        css`
            margin-top: ${SizeMapping[Space.Tiny]};
            margin-bottom: ${SizeMapping[Space.Tiny]};
        `}

`;

/**
 *
 * # Divider Component
 *
 * ## How to use:
 *
 * ```js
 * <Divider layout="lighter" />
 * ```
 *
 */
export class Divider extends PureComponent<DividerProps> {
    public render() {
        const { className, layout, space } = this.props;
        return <StyledDivider className={className} layout={layout} space={space} />;
    }
}
