import React from "react";
import { CoreComponent } from "@7egend/web.core";
import { RouteComponentProps, withRouter } from "react-router";
import { withDloService, WithDloServiceProps } from "@7egend/web.core/lib/components/withDloService";
import {
    Button,
    Row,
    Column,
    Datatable,
    DatatableProps,
    DatatableActions,
    Dialog,
    SnackbarContainer,
} from "../../../components";
import { dlos } from "../../../dlos";
import {
    DefaultActions,
    FilterProps,
    DefaultFilters,
    DefaultFormatter,
} from "../../../components/Datatable";
import { DynamicPage } from "../../../dlos/cms/dynamic-page";
import { withI18n, WithI18nProps } from "@7egend/web.core/lib/components/withI18n";
import { Trans } from "@7egend/web.core/lib/components/i18n";
import { APP_TRANSLATIONS } from "../../../locale";
import { CanView, withCanView, WithCanViewProps } from "../../../components/CanView";
import { I18N_KEY_CMS_PAGES_DYNAMIC_PAGE, I18N_KEY_GLOBAL } from "../../../base/i18n";

interface State {
    isModalVisible: boolean;
    deleteID?: string | number;
    isDeleting: boolean;
    deletedSuccess?: string | number;
}

interface DynamicPagesProps extends RouteComponentProps<{ id: string }> {
    /** picker action */
    picker?: boolean

    /** pick page */
    pickItem?: (picked?: DynamicPage[]) => void;

    /** list of picked pages */
    pickedItems?: DynamicPage[];

    footer?: React.ReactComponentElement<any> | React.ComponentType<any> | Element;
}

const I18N_NAMESPACE = I18N_KEY_CMS_PAGES_DYNAMIC_PAGE

class DynamicPagesComponent extends CoreComponent<
    DynamicPagesProps & WithDloServiceProps<{}> & WithI18nProps & WithCanViewProps,
    State
> {
    private snackBarContainerRef = React.createRef<SnackbarContainer>();

    public state: State = {
        isModalVisible: false,
        isDeleting: false,
    };

    public render() {
        const { t } = this.props;
        const actions = [];
        if (this.props.canView("cms.dynamic-pages.details")) {
            actions.push({
                function: (id: string) => this.editAction(id),
                param: "id",
                type: DatatableActions.Link,
                icon: "edit",
            });
        }

        if (this.props.canView("cms.dynamic-pages.delete")) {
            actions.push({
                function: (id: string) => this.deleteAction(id),
                param: "id",
                type: DatatableActions.Common,
                icon: "delete",
            });
        }

        const dTActions = this.props.picker
            ? [
                {
                    function: (id: string, page: DynamicPage[]) => this.pickerAction(page),
                    param: "id",
                    type: DatatableActions.Picker,
                }
            ]
            : actions;

        const dtConfig: DatatableProps = {
            fetch: dlos.cms.DynamicPageGetAllInput,
            rows: {
                lengths: [10, 25, 50],
                actions: {
                    size: 2,
                    list: dTActions
                },
            },
            columns: [
                {
                    param: ["name"],
                    size: 7,
                    title: t(`${I18N_NAMESPACE}.list.columnTitle`),
                    order: true,
                    render: DefaultFormatter.Text,
                },
                {
                    param: ["created"],
                    size: 3,
                    title: t(`${I18N_NAMESPACE}.list.columnPublicationDate`),
                    order: true,
                    render: DefaultFormatter.DateTime,
                },
            ],
            title: t(`${I18N_NAMESPACE}.list.title`),
            actions: [
                <CanView action="cms.dynamic-pages.create">
                    <DefaultActions.Add
                        key="add-action"
                        href={"/cms/dynamic-pages/add"}
                    />
                </CanView>,
            ],
            filters: [
                (props: FilterProps) => (
                    <CanView action="cms.dynamic-pages.search">
                        <DefaultFilters.Search
                            key="search-filter"
                            {...props}
                        />
                    </CanView>
                ),
            ],
        };

        return (
            <CanView action="cms.dynamic-pages.list">
                {/** Render SnackBar */}
                <SnackbarContainer ref={this.snackBarContainerRef} />
                <Row>
                    <Column xs={12}>
                        <Datatable
                            {...dtConfig}
                            actionSuccess={this.state.deletedSuccess}
                            isLoading={this.state.isDeleting}
                            footer={this.props.footer}
                            pickedItems={this.props.pickedItems}
                        />
                        {/** Render dialog */}
                        {this.renderDialog()}
                    </Column>
                </Row>
            </CanView>
        );
    }

    private renderDialog = () => {
        const { t } = this.props;

        return (
            <Dialog
                overlay={true}
                title={t(`${I18N_NAMESPACE}.list.deleteTitle`)}
                description={t(`${I18N_NAMESPACE}.list.deleteDescription`)}
                type="error"
                isVisible={this.state.isModalVisible}
                handleDialog={() => this.toggleDialog()}
            >
                <Button
                    layoutOutline
                    layout={"error"}
                    onClick={() => this.toggleDialog()}
                >
                    <Trans id={`${I18N_KEY_GLOBAL}.cancel`} />
                </Button>
                <Button
                    layoutFill
                    layout={"error"}
                    onClick={() => this.deleteDynamicPages()}
                    isLoading={this.state.isDeleting}
                    layoutIconPosition="right"
                >
                    <Trans id={`${I18N_KEY_GLOBAL}.delete`} />
                </Button>
            </Dialog>
        );
    };

    private toggleDialog = () => {
        this.setState({
            isModalVisible: !this.state.isModalVisible,
        });
    };

    private deleteDynamicPages = () => {
        if (this.state.deleteID) {
            this.setState({ isDeleting: true });
            const input = new dlos.cms.DynamicPageDeleteInput();

            input.body = {
                id: this.state.deleteID,
            };

            this.props
                .executeDloRequest(input)
                .then(res => {
                    this.toggleDialog();
                    this.setState({
                        isDeleting: false,
                        deletedSuccess: this.state.deleteID,
                    });
                })
                .catch(err => {
                    this.addSnackbar(err.body.code, "error");
                    this.setState({
                        isDeleting: false,
                    });
                });
        }
    };

    private addSnackbar = (
        message: string,
        layout?: string,
        timeout?: number
    ) => {
        if (this.snackBarContainerRef.current) {
            this.snackBarContainerRef.current.pushMessage({
                layout: layout as any,
                message,
                timeout,
            });
        }
    };

    private deleteAction = (id?: string) => {
        this.toggleDialog();
        this.setState({ deleteID: id });
    };

    private editAction = (id?: string) => {
        return `/cms/dynamic-pages/edit/${id}`;
    };

    private pickerAction = (page?: any) => {
        if (this.props.pickItem && page) {
            this.props.pickItem([page]);
        }
    }
}

export const DynamicPages = withCanView(withRouter(withI18n(APP_TRANSLATIONS)(withDloService(DynamicPagesComponent))));
