import { Module } from "../../../bootstrap"
import loadable from "@loadable/component"

export { Albums, AlbumsProps } from "./Albums"
export { AlbumPicker } from "./AlbumPicker"

export const albumsSetup: Module = {
    routes: [
        // Albums List
        {
            component: loadable(async () => (await import('./Albums')).Albums),
            path: "/cms/albums",
            exact: true,
        },
        // Albums Create
        {
            component: loadable(async () => (await import('./AlbumsEditor')).AlbumsEditor),
            path: "/cms/albums/add",
        },
        // Albums Update
        {
            component: loadable(async () => (await import('./AlbumsEditor/AlbumsEditor')).AlbumsEditor),
            path: "/cms/albums/edit/:id",
        },
    ],
    menus: [
        {
            title: {
                key: "cms.albums.menu.title",
                en: "Albums",
                es: "Álbumes",
                pt: "Álbuns",
            },
            path: "cms/albums",
            permission: "cms.albums.list",
            children: [
                {
                    title: {
                        key: "cms.albums.create.menu.title",
                        en: "Create Albums",
                        es: "Crear Álbumes",
                        pt: "Criar Álbuns",
                    },
                    path: "cms/albums/add",
                    visible: false,
                    permission: "cms.albums.add",
                },
                {
                    title: {
                        key: "cms.albums.edit.menu.title",
                        en: "Edit Albums",
                        es: "Editar Álbumes",
                        pt: "Editar Álbuns",
                    },
                    path: "cms/albums/edit",
                    visible: false,
                    permission: "cms.albums.edit",
                },
            ],
        },
    ]
}
