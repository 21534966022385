import React from "react"
import loadable from "@loadable/component";
import { Block } from "@7egend/web.core.cms/lib/dlos/block";
import { Text as TextDLO } from "@7egend/web.core.cms/lib/dlos/text";
import { BlockComponentProps } from "../../interfaces";
import { blockTypes } from "../config";
import { I18N_KEY_CMS_COMPONENTS_BLOCK } from "../../../../base/i18n";

const { text } = blockTypes;

const Structure = {
    type: text.type,
    content: {
        style: text.style,
    },
} as Block<TextDLO>;

const TOOLBAR = {
    options: ["inline", "link"]
};

const I18N_NAMESPACE = `${I18N_KEY_CMS_COMPONENTS_BLOCK}.types.text`

const TextEditor = loadable(async () => (await import("../../../TextEditor")).TextEditor);

export class Component extends React.Component<BlockComponentProps<TextDLO>> {
    public render() {
        const { className, data } = this.props;
        const value = data.content?.value || ""

        return (
            <TextEditor
                className={className}
                toolbar={TOOLBAR}
                value={value}
                onChange={this.onChange}
            />
        );
    }

    private onChange = (value: string) => {
        const { data, updateData } = this.props;

        updateData({
            ...data,
            content: {
                ...data.content,
                value: value!,
            }
        })
    }

}

const Button = {
    icon: "format_align_left",
    name: {
        key: `${I18N_NAMESPACE}.name`,
        en: "Text",
        es: "Texto",
        pt: "Texto",
    },
};

export const Text = {
    key: `${text.type}_${text.style}`,
    Structure,
    Component,
    Button,
};
