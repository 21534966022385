import React from "react";
import { Component } from "@7egend/web.core/lib/base/component";
import Moment, { MomentProps } from "react-moment";

export interface DateTimeProps extends MomentProps {
    icon?: string
}

/**
 * # DateTime Component
 * This component formats the Date or Time.
 * It uses the MomentJS library.
 * For more information see: https://www.npmjs.com/package/react-moment
 */
export class DateTime extends Component<DateTimeProps> {
    public render() {
        return (
            <React.Fragment>
                {this.props.icon && <span className={this.props.icon}></span>}
                <Moment utc {...this.props}>{this.props.children}</Moment>
            </React.Fragment>
        )
    }
}
