import React, { PureComponent } from "react"
import styled, { css } from "styled-components";
import { queries } from "../../../styles";

export interface WrapperContentProps {
    /** class name */
    className?: string;
    /** height limited, it adds an scroll bar and a max height to the component */
    heightLimited?: boolean;
}

export const StyledWrapperContent = styled.div<WrapperContentProps>`
    padding: 32px 20px;

    ${queries.maxMD} {
        padding: 16px 4px;  
    }

    &::after {
        content: "";
        display: block;
        width: 100%;
        white-space: nowrap;
        clear: both;
    }

    ${({ heightLimited }) => heightLimited && css`
        max-height: 475px;
        overflow-y: auto;
    `}
`;

export class WrapperContent extends PureComponent<WrapperContentProps> {
    public render() {
        if (this.props.children) {
            return (
                <StyledWrapperContent
                    heightLimited={this.props.heightLimited}
                    className={this.props.className}
                >
                    {this.props.children}
                </StyledWrapperContent>
            );
        }
        return null;
    }
}
