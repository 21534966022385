import bootstrapCore, { BootstrapOptions as BootstrapOptionsCore } from "@7egend/web.core/lib/bootstrap";
import { MenuItems } from "./components/Structure/Menu";
import { BlockInfo } from "./components/BlocksEditor/BlocksEditor";
import { Config } from "./base/config";
import { merge } from "@7egend/web.core/lib/utils";
import { Generic } from "@7egend/web.core";
import "moment-timezone"
import Moment from "react-moment"
import moment from "moment-timezone";
export { MenuItems };

export type ModuleSetup = (config: Config) => Module

/**
 * Bootstrap Options interface
 */
export interface BootstrapOptions extends BootstrapOptionsCore {
    /**
     * List of extensions to load.
     */
    modules?: ModuleSetup[];
    /**
     * Base config
     */
    config?: Partial<Config>;
}

/**
 * Route interface
 */
export interface ModuleRoute {
    component: React.ReactNode;
    exact?: boolean;
    path: string;
    id?: string;
    routes?: ModuleRoute[]
}

/**
 * Module interface
 */
export interface Module {
    key?: string;
    routes: ModuleRoute[];
    menus: MenuItems[];
    blocks?: {
        /** 
         * "key" is the name of the module! 
         */
        [key: string]: BlockInfo[];
    };
    afterInit?: () => void;
}

export async function bootstrap(
    configLoader: (config: Config) => any,
    options: BootstrapOptions,
) {
    const newConfigLoader = (config: Config): any => {
        if (!config.flags) {
            config.flags = {}
        }

        config.modules = [];

        // Merge base config if exists
        if (options.config) {
            merge(config, options.config, config)
        }

        if (options.modules) {
            for (const module of options.modules) {
                config.modules.push(module(config));
            }
        }

        config = configLoader(config);

        // Apply locale and timezone
        const { timezone } = config.locale
        if (timezone) {
            Moment.globalTimezone = timezone
        }

        if (process.env.NODE_ENV === "development") {
            // @ts-ignore
            window.moment = moment
        }

        return config;
    };

    await bootstrapCore(newConfigLoader, options);

    const generic = new Generic();

    (generic.fw.config as Config).modules.forEach(module => module.afterInit && module.afterInit());
}
