import { DloInput, DloOutput } from "@7egend/web.core/lib/extensions/dlo";
import { Role } from "@7egend/web.core.security/lib/dlos";
import { Country } from "@7egend/web.core.geo/lib/dlos/country";
import { buildQLQuery, QueryQLParams } from "@7egend/web.core/lib/extensions/dlo";

export interface UserInterface {
    uuid?: string
    username?: string;
    first_name?: string;
    last_name?: string;
    gender?: string;
    identification?: string;
    birthday?: string;
    phone?: string;
    taxpayer_number?: string;
    password?: string;
    country?: Country | string;
    roles?: Role[];
    phone_country?: {
        id: string;
        name?: string;
        nationality?: string;
        phone_code?: string;
        iso2_code?: string;
    };
    profile?: {
        base64?: string;
        thumb?: string;
    };
}

const USER_FIELDS: QueryQLParams = [
    "uuid",
    "email",
    "username",
    "first_name",
    "last_name",
    "phone",
    "taxpayer_number",
    "birthday",
    "gender",
    ["country", ["id", "name"]],
    ["profile", ["uuid", "image", "thumb"]],
    ["phone_country", ["id", "iso2_code", "phone_code"]],
];

/** Users Get All Input */
export class UserGetAllDatatableInput extends DloInput<{
    length: number;
    start: number;
}> {
    public timeStamp = Date.now();
    public authentication = true;
    public uri = "users/datatables?draw=${timeStamp}&order[0][column]=0&order[0][dir]=desc";
}

/** Users Get by slug */
export class UserGetBySlugInput extends DloInput<{
    slug: string;
    locale: string;
}>{
    public uri = "users/${slug}?fields=${fields}";
    public fields = buildQLQuery(USER_FIELDS);
};

/** Users get roles by slug */
export class UserGetRolesBySlugInput extends DloInput<{
    slug: string;
}>{
    public uri = "users/${slug}/roles?fields=id,name";
    public fields = buildQLQuery(USER_FIELDS);
};

/** Users get active sessions by slug */
export class UserGetSessionsBySlugInput extends DloInput<{
    slug: string;
}>{
    public uri = "users/${slug}/sessions?fields=created_at,id&active=1";
};

/** User Create New */
export class UserCreateInput extends DloInput<UserInterface> {
    public method = "POST";
    public uri = "users";
    public fields = buildQLQuery(USER_FIELDS);
    public transform = (input: any) => {
        input.body.fields = input.fields;
        return input;
    };
}

/** User Update */
export class UserUpdateInput extends DloInput<
    UserInterface & {
        uuid?: string;
    }
> {
    public method = "PUT";
    public uri = "users/${uuid}";
    public fields = buildQLQuery(USER_FIELDS);
    public transform = (input: any) => {
        input.body.fields = input.fields;
        return input;
    };
}

/** User Delete Session */
export class UserDeleteSessionInput extends DloInput<{ id: string; }> {
    public method = "DELETE";
    public uri = "sessions/${id}";
};

export class UserUpdateRolesInput extends DloInput<{
    /** user uuid */
    uuid: string;
    roles: string[];
}>{
    public uri = "users/${uuid}/roles?fields=id,name";
    public method = "PUT"
};

export class UserCreateOutput extends DloOutput<UserInterface> { }
