import { Module } from "../../../bootstrap";
import loadable from "@loadable/component";

export { News } from "./News";
export { NewsPicker } from "./Picker";

export const newsSetup: Module = {
    routes: [
        // News List
        {
            component: loadable(async () => (await import('./News')).News),
            path: "cms/news",
            exact: true,
        },
        // News Create
        {
            component: loadable(async () => (await import('./NewsEditor')).NewsEditor),
            path: "/cms/news/add",
        },
        // News Update
        {
            component: loadable(async () => (await import('./NewsEditor')).NewsEditor),
            path: "/cms/news/edit/:id",
        },
    ],
    menus: [
        {
            title: {
                key: "cms.news.menu.title",
                en: "News",
                es: "Noticias",
                pt: "Notícias",
            },
            path: "cms/news",
            permission: "cms.news.list",
            children: [
                {
                    title: {
                        key: "cms.news.create.menu.title",
                        en: "Create News",
                        es: "Crear noticias",
                        pt: "Criar Notícias",
                    },
                    path: "cms/news/add",
                    visible: false,
                    permission: "cms.news.add",
                },
                {
                    title: {
                        key: "cms.news.edit.menu.title",
                        en: "Edit News",
                        es: "Editar noticias",
                        pt: "Editar Notícias",
                    },
                    path: "cms/news/edit",
                    visible: false,
                    permission: "cms.news.edit",
                },
            ],
        },
    ]
}
