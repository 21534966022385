import { Highlight } from "./Highlight";
import { Headline } from "./Headline";
import { MainTitle } from "./MainTitle";
import { SectionTitle } from "./SectionTitle";
import { SmallCapsTitle } from "./SmallCapsTitle";
import { BoldText } from "./BoldText";
import { RegularText } from "./RegularText";
import { SmallText } from "./SmallText";
import { SmallTextBold } from "./SmallTextBold";
import { TypographyProps } from "./interface"

export { TypographyProps }

export const Typography = {
    Highlight,
    Headline,
    MainTitle,
    SectionTitle,
    SmallCapsTitle,
    BoldText,
    RegularText,
    SmallText,
    SmallTextBold,
}
