import React from "react";
import loadable from "@loadable/component";
import { memoize } from "@7egend/web.core/lib/utils";
import styled from "styled-components";
import { LineProps } from "./Line/Line";
import { Serie as ChartData, LineProps as NivoLineProps } from "@nivo/line";
import { font, colors } from "../../styles";
export { ChartData }

const ChartWrapper = styled.div<{ height?: number; }>`
    position: relative;
    height: ${({ height }) => height ? `${height}px` : "300px"};

    svg {
        overflow: visible;
    }

    text {
        font-family: ${font.primary} !important;
        fill: ${colors.neutral.dark} !important;
        font-size: ${font.size.sm} !important;
    }
`;

/** types of charts */
export enum ChartType {
    Line = "Line"
}

export interface ChartOptions {
    /** @default 300 */
    height?: number;
}

interface ChartProps extends NivoLineProps {
    /** type of chart */
    type: ChartType;

    options?: ChartOptions;
}

const ChartLine = loadable(async () => (await import(`./Line`)).Line) as React.ComponentClass<LineProps>;

/** Returns the Chart according the given type */
const getChartType = memoize((props: ChartProps) => {

    switch (props.type) {
        case ChartType.Line:
            return (
                <ChartLine
                    options={props.options}
                    {...props}
                />
            )
        default:
            return null;
    }
});

/**
 * # Chart Component
 *
 * ## How to use:
 *
 * ```jsx
 *  <Chart
 *      type={ChartType.Line}
 *      data={[]}
 *  />
 * ```
 * 
 * ## Component Properties:
 * 
 * - `options`  -> height: number, etc...;
 * - `type`     -> ChartType.Line, etc...;
 * - `data`     -> ChartData[];
 */
export const Chart: React.FC<ChartProps> = ({ ...props }) => {


    if (!props.type && (!props.data || !props.data.length)) {
        return null;
    }

    return (
        <ChartWrapper height={props.options && props.options.height}>
            {getChartType(props)}
        </ChartWrapper>
    )
}
