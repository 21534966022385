import React from "react";
import styled, { css } from "styled-components";
import { Icon } from "../../Icon";
import { colors } from "../../../styles";
import { convertHexToRgba } from "../../../utils";

export interface FlagProps {
    icon: React.ComponentClass<any> | React.ComponentType | Element | string;
    color?: string;
}

const Wrapper = styled.div<{ color?: string }>`
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;

    /* if dont receive color property */
    ${({ color }) => !color && css`
        background-color: ${colors.neutral.lighter};
        color: ${colors.neutral.dark};

        svg {
            fill: ${colors.neutral.dark};
        }
    `}

    ${({ color }) => color && css`
        background-color: ${convertHexToRgba(color, '0.2')};
        color: ${color};

        svg {
            fill: ${color};
        }
    `}
`

export const Flag: React.FC<FlagProps> = ({ ...props }) => {
    return (
        <Wrapper color={props.color}>
            {typeof props.icon === "object" ?
                props.icon
                :
                <Icon icon={typeof props.icon === "string" ? props.icon : ""} />
            }
        </Wrapper>
    )
}
