import { DloInput, DloOutput } from "@7egend/web.core/lib/extensions/dlo";
import { BaseModel } from "@7egend/web.core.dlos/lib/dlos/base";

export interface Tag extends BaseModel {
    text: string
}

export class TagsGetByTextInput extends DloInput<{
    q: string,
    locale: string,
}> {
    public uri = "cms-tags?fields=${fields}&q=${q}&locale=${locale}"
    public fields = "text"
}

export class TagsGetByTextOutput extends DloOutput<Tag[]>{

}
