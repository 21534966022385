import React from "react";
import { CoreComponent } from "@7egend/web.core";
import { RouteComponentProps, withRouter } from "react-router";
import { withDloService, WithDloServiceProps } from "@7egend/web.core/lib/components/withDloService";
import {
    Button,
    Row,
    Column,
    Datatable,
    DatatableProps,
    DatatableActions,
    Dialog,
    SnackbarContainer,
} from "../../../components";
import { dlos } from "../../../dlos";
import {
    DefaultActions,
    FilterProps,
    DefaultFilters,
    DefaultFormatter,
} from "../../../components/Datatable";
import { Booster } from "@7egend/web.core.cms/lib/dlos/booster";
import { withI18n, WithI18nProps } from "@7egend/web.core/lib/components/withI18n";
import { APP_TRANSLATIONS } from "../../../locale";
import { CanView, withCanView, WithCanViewProps } from "../../../components/CanView";
import { I18N_KEY_CMS_BOOSTER_LIST } from "../../../base/i18n";
import { DefaultFormatterProps } from "../../../components/Datatable/constants";
import { StyledFormatterImage } from "../../../components/Datatable/styles";
import { PLACEHOLDER_IMAGE } from "../../../components/MediaList/Media/MediaPlaceholder";

interface State {
    isModalVisible: boolean;
    deleteID?: string | number;
    isDeleting: boolean;
    deletedSuccess?: string | number;
}

interface BoostersProps extends RouteComponentProps<{ uuid: string }> {
    /** picker action */
    picker?: boolean

    /** pick booster */
    pickItem?: (picked?: Booster[]) => void;

    /** list of picked boosters */
    pickedItems?: Booster[];

    footer?: React.ReactComponentElement<any> | React.ComponentType<any> | Element;
}

const I18N_NAMESPACE = I18N_KEY_CMS_BOOSTER_LIST

class BoostersComponent extends CoreComponent<
    BoostersProps & WithDloServiceProps<{}> & WithI18nProps & WithCanViewProps,
    State
> {
    private snackBarContainerRef = React.createRef<SnackbarContainer>();

    public state: State = {
        isModalVisible: false,
        isDeleting: false,
    };

    public render() {
        const { t } = this.props;
        const actions = [];
        if (this.props.canView("cms.boosters.details")) {
            actions.push({
                function: (uuid: string) => this.editAction(uuid),
                param: "uuid",
                type: DatatableActions.Link,
                icon: "edit",
            });
        }

        if (this.props.canView("cms.boosters.delete")) {
            actions.push({
                function: (uuid: string) => this.deleteAction(uuid),
                param: "uuid",
                type: DatatableActions.Common,
                icon: "delete",
            });
        }

        const dTActions = this.props.picker
            ? [
                {
                    function: (uuid: string, booster: Booster) => this.pickerAction(booster),
                    param: "uuid",
                    type: DatatableActions.Picker,
                }
            ]
            : actions;

        const dtConfig: DatatableProps = {
            fetch: dlos.cms.BoostersGetAllInput,
            rows: {
                lengths: [10, 25, 50],
                actions: {
                    size: 2,
                    list: dTActions,
                },
            },
            columns: [
                {
                    param: ["preview:thumb", "header:thumb"],
                    size: 1,
                    title: t(`${I18N_NAMESPACE}.columnPreview`),
                    render: ({ column, data }: DefaultFormatterProps): Element => {
                        const headerThumb = data[column.param[1]];
                        const previewThumb = data[column.param[0]];
                        const hasPreviewImage = !!previewThumb || !!headerThumb;

                        return (
                            <StyledFormatterImage
                                style={{
                                    backgroundImage: `url(${hasPreviewImage ? previewThumb || headerThumb : PLACEHOLDER_IMAGE})`,
                                    backgroundSize: hasPreviewImage ? 'cover' : '30px',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'center'
                                }}
                            />
                        )
                    }
                },
                {
                    param: ["name"],
                    size: 5,
                    title: t(`${I18N_NAMESPACE}.columnTitle`),
                    order: true,
                    render: DefaultFormatter.Text,
                },
                {
                    param: ["type:name"],
                    size: 2,
                    title: t(`${I18N_NAMESPACE}.columnType`),
                    order: true,
                    render: DefaultFormatter.Text,
                },
                {
                    param: ["created"],
                    size: 2,
                    title: t(`${I18N_NAMESPACE}.columnPublicationDate`),
                    order: true,
                    render: DefaultFormatter.DateTime,
                },
            ],
            order: {
                by: 1,
                direction: "desc"
            },
            title: t(`${I18N_NAMESPACE}.title`),
            actions: [
                <CanView action="cms.boosters.create">
                    <DefaultActions.Add
                        key="add-action"
                        href={"/cms/boosters/add"}
                    />
                </CanView>,
            ],
            filters: [
                (props: FilterProps) => (
                    <CanView action="cms.boosters.search">
                        <DefaultFilters.Search
                            key="search-filter"
                            {...props}
                        />
                    </CanView>
                ),
            ],
        };

        return (
            <CanView action="cms.boosters.list">
                {/** Render SnackBar */}
                <SnackbarContainer ref={this.snackBarContainerRef} />
                <Row>
                    <Column xs={12}>
                        <Datatable
                            {...dtConfig}
                            footer={this.props.footer}
                            actionSuccess={this.state.deletedSuccess}
                            isLoading={this.state.isDeleting}
                            pickedItems={this.props.pickedItems}
                        />
                        {/** Render dialog */}
                        {this.renderDialog()}
                    </Column>
                </Row>
            </CanView>
        );
    }

    private renderDialog = () => {
        const { t } = this.props;

        return (
            <Dialog
                overlay={true}
                title={t(`${I18N_NAMESPACE}.deleteTitle`)}
                description={t(`${I18N_NAMESPACE}.deleteDescription`)}
                type="error"
                isVisible={this.state.isModalVisible}
                handleDialog={() => this.toggleDialog()}
            >
                <Button
                    layoutOutline
                    layout={"error"}
                    onClick={() => this.toggleDialog()}
                >
                    Cancel
                </Button>
                <Button
                    layoutFill
                    layout={"error"}
                    onClick={() => this.deleteBoosters()}
                    isLoading={this.state.isDeleting}
                    layoutIconPosition="right"
                >
                    Delete
                </Button>
            </Dialog>
        );
    };

    private toggleDialog = () => {
        this.setState({
            isModalVisible: !this.state.isModalVisible,
        });
    };

    private deleteBoosters = () => {
        if (this.state.deleteID) {
            this.setState({ isDeleting: true });
            const input = new dlos.cms.BoostersDeleteInput();

            input.body = {
                uuid: this.state.deleteID,
            };

            this.props
                .executeDloRequest(input)
                .then(res => {
                    this.toggleDialog();
                    this.setState({
                        isDeleting: false,
                        deletedSuccess: this.state.deleteID,
                    });
                })
                .catch(err => {
                    this.addSnackbar(err.body.code, "error");
                    this.setState({
                        isDeleting: false,
                    });
                });
        }
    };

    private addSnackbar = (
        message: string,
        layout?: string,
        timeout?: number
    ) => {
        if (this.snackBarContainerRef.current) {
            this.snackBarContainerRef.current.pushMessage({
                layout: layout as any,
                message,
                timeout,
            });
        }
    };

    private deleteAction = (uuid?: string) => {
        this.toggleDialog();
        this.setState({ deleteID: uuid });
    };

    private editAction = (uuid?: string) => {
        return `/cms/boosters/edit/${uuid}`;
    };

    private pickerAction = (booster?: Booster) => {
        if (this.props.pickItem && booster) {
            this.props.pickItem([booster]);
        }
    }
}

export const Boosters = withCanView(withRouter(withI18n(APP_TRANSLATIONS)(withDloService(BoostersComponent))));
