import React from "react";
import { Block } from "@7egend/web.core.cms/lib/dlos/block";
import { Text as TextDLO } from "@7egend/web.core.cms/lib/dlos/text";
import { BlockComponentProps } from "../../interfaces";
import { Select } from "../../../Select";
import { withI18n, WithI18nProps } from "@7egend/web.core/lib/components/withI18n";
import { APP_TRANSLATIONS } from "../../../../locale";
import { blockTypes } from "../config";
import { I18N_KEY_CMS_COMPONENTS_BLOCK } from "../../../../base/i18n";

const { separator } = blockTypes;

const Structure = {
    type: separator.type,
    content: {},
} as Block<TextDLO>;

const I18N_NAMESPACE = `${I18N_KEY_CMS_COMPONENTS_BLOCK}.types.separator`

const SeparatorComponent: React.FC<WithI18nProps & BlockComponentProps<TextDLO>> = ({ className, data, updateData, t }) => {
    const SeparatorOptions = [
        {
            value: "1",
            label: t(`${I18N_NAMESPACE}.options.invisible`),
            option: {
                value: "1"
            }
        },
        {
            value: "2",
            label: t(`${I18N_NAMESPACE}.options.visible`),
            option: {
                value: "2"
            }
        }
    ]

    return (
        <Select
            className={className}
            options={SeparatorOptions}
            name=""
            defaultLabel={t(`${I18N_NAMESPACE}.inputOptionsLabel`)}
            defaultValue="-1"
            value={data.content && data.content.style}
            onChange={(style) => updateData({
                ...data,
                content: {
                    ...data.content,
                    style: style !== "-1" ? style : data.content.style,
                }
            })}
        />
    )
}

const Component = withI18n(APP_TRANSLATIONS)(SeparatorComponent);

const Button = {
    icon: "format_line_spacing",
    name: {
        key: `${I18N_NAMESPACE}.name`,
        en: "Separator",
        es: "Separador",
        pt: "Separador",
    },
};

export const Separator = {
    key: separator.type,
    Structure,
    Component,
    Button,
};
