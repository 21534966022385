import React, { PureComponent } from "react"
import { Column, Typography, Icon } from "../../components";
import { Media } from "./Media";
import { StyledMediaList } from "./styles";
import { MediaListPlaceholder } from "./MediaListPlaceholder";
import { Medium, MediumType } from "@7egend/web.core.media/lib/dlos";
import { MediaPreview } from "./MediaPreview";
import { Modal } from "../Modal";
import { APP_TRANSLATIONS } from "../../locale";
import { withI18n, WithI18nProps } from "@7egend/web.core/lib/components/withI18n";
import { CanView } from "../CanView";
import { I18N_KEY_CMS_MEDIA_LIST } from "../../base/i18n";

export interface MediaListData extends Medium {
    base64?: string;
    file?: string;
    "cover:thumb"?: string;
    cover?: {
        thumb: string;
        medium_type: string | MediumType;
    }
    medium_type_id?: string | MediumType;
    "cover:medium_type_id"?: string | MediumType;
}

interface MediaListProps {
    deleteMedia?: (item: MediaListData) => void;
    selectMedia?: (item: MediaListData) => void;
    list?: MediaListData[];
    checkedMedia?: MediaListData[];
    isLoading?: boolean;
    isAlbum?: boolean;
    isCheckable?: boolean;
    isEditable?: boolean;
    renderColumns?: {
        xs?: number;
        sm?: number;
        md?: number;
        lg?: number;
        xl?: number;
    };
}

interface MediaListState {
    showImagePreview?: boolean;
    selectedMedia?: MediaListData;
}

const defaultColumns = {
    xs: 6,
    sm: 3,
    xl: 2,
};

/**
 *
 * # Media List Component
 *
 * ## How to use:
 *
 * ```jsx
 * <MediaList list={data} />
 * ```
 *
 * It contains all the media components
 *
 */
class MediaListComponent extends PureComponent<MediaListProps & WithI18nProps, MediaListState> {

    public state: MediaListState = {
        showImagePreview: false,
    }

    public componentWillUnmount() {
        this.setState({
            showImagePreview: false,
            selectedMedia: undefined
        });
    }

    public render() {
        const {
            list,
            renderColumns,
            isAlbum,
            isCheckable,
            isLoading,
        } = this.props;

        return (
            <React.Fragment>
                {!isLoading && list && list.length > 0 ? (
                    list.map(item => (
                        <Column
                            key={item.uuid}
                            {...(renderColumns || defaultColumns)}
                        >
                            <Media
                                options={this.getMediaOptions(item)}
                                panoramic={item.panoramic}
                                isChecked={this.verifyCheckedMedia(item)}
                                selectMedia={() => this.selectMedia(item)}
                                thumb={item.thumb || item["cover:thumb"] || item.cover?.thumb}
                                medium_type={
                                    item.medium_type_id ||
                                    item["cover:medium_type_id"] ||
                                    item.medium_type ||
                                    item.cover && item.cover.medium_type || "1"
                                }
                                id={item.uuid || item.id}
                                title={item.title || item.url || ""}
                                date={item.date || item.created_at || ""}
                                isAlbum={isAlbum}
                                isCheckable={isCheckable}
                                previewMedia={() => this.previewMedia(item)}
                            />
                        </Column>
                    ))
                ) : (
                    <MediaListPlaceholder />
                )}
                {this.renderImagePreview()}
            </React.Fragment>
        );
    }

    private renderImagePreview = () => {
        if (this.state.showImagePreview) {
            return (
                <Modal
                    isVisible={this.state.showImagePreview}
                    overlay={true}
                    handleModal={() => this.setState({
                        showImagePreview: !this.state.showImagePreview
                    })}
                    fullWidth
                >
                    <MediaPreview
                        media={this.props.list || []}
                        active={this.state.selectedMedia}
                    />
                </Modal>
            )
        }

        return null;
    }

    private previewMedia = (mediaSelected: MediaListData) => {
        this.setState({
            showImagePreview: true,
            selectedMedia: mediaSelected
        });
    }

    /** It renders edit action */
    private renderEditAction = (action: string, item: MediaListData, message: string, href: string) => {
        const { t } = this.props;

        if (this.props.isEditable || this.props.isEditable === undefined) {
            return (
                <CanView action={action}>
                    <StyledMediaList.Link href={href}>
                        <Typography.RegularText variant="regular">
                            {t(message)}
                        </Typography.RegularText>
                        <Icon icon="edit" />
                    </StyledMediaList.Link>
                </CanView>
            );
        }
    }

    /** It renders delete action */
    private renderDeleteAction = (action: string, item: MediaListData, message: string) => {
        const { t } = this.props;

        return (
            <CanView action={action}>
                <StyledMediaList.Action
                    onClick={() => {
                        if (this.props.deleteMedia && item) {
                            this.props.deleteMedia(item);
                        }
                    }}
                >
                    <Typography.RegularText variant="regular">
                        {t(message)}
                    </Typography.RegularText>
                    <Icon icon="delete" />
                </StyledMediaList.Action>
            </CanView>
        )
    };

    /** It renders the options according to the media type */
    private getMediaOptions = (item: MediaListData) => {
        let options: any;

        if (this.props.isAlbum) {
            return (options = (
                <React.Fragment>
                    {this.renderEditAction(
                        "cms.albums.details",
                        item,
                        `${I18N_KEY_CMS_MEDIA_LIST}.editAlbum`,
                        `/cms/albums/edit/${item.uuid}`
                    )}
                    {this.renderDeleteAction(
                        "cms.albums.delete",
                        item,
                        `${I18N_KEY_CMS_MEDIA_LIST}.deleteAlbum`,
                    )}
                </React.Fragment>
            ));
        }

        switch (item.medium_type || item.medium_type_id) {
            case MediumType.Image:
                options = (
                    <React.Fragment>
                        {this.renderEditAction(
                            "cms.multimedia.details",
                            item,
                            `${I18N_KEY_CMS_MEDIA_LIST}.editImageInfo`,
                            `/cms/multimedia/edit/${item.uuid}?type=${item.panoramic === "1" ? "image-panoramic" : "image"}`,
                        )}
                        {this.renderDeleteAction(
                            "cms.multimedia.delete",
                            item,
                            `${I18N_KEY_CMS_MEDIA_LIST}.deleteImage`,
                        )}
                    </React.Fragment>
                );
                break;
            case MediumType.Stream:
                options = (
                    <React.Fragment>
                        {this.renderEditAction(
                            "cms.multimedia.details",
                            item,
                            `${I18N_KEY_CMS_MEDIA_LIST}.editStream`,
                            `/cms/multimedia/edit/${item.uuid}?type=stream`
                        )}
                        {this.renderDeleteAction(
                            "cms.multimedia.delete",
                            item,
                            `${I18N_KEY_CMS_MEDIA_LIST}.deleteStream`,
                        )}
                    </React.Fragment>
                );
                break;
            case MediumType.Video:
                options = (
                    <React.Fragment>
                        {this.renderEditAction(
                            "cms.multimedia.details",
                            item,
                            `${I18N_KEY_CMS_MEDIA_LIST}.editVideo`,
                            `/cms/multimedia/edit/${item.uuid}?type=video`
                        )}
                        {this.renderDeleteAction(
                            "cms.multimedia.delete",
                            item,
                            `${I18N_KEY_CMS_MEDIA_LIST}.deleteVideo`,
                        )}
                    </React.Fragment>
                );
                break;
            case MediumType.File:
                options = (
                    <React.Fragment>
                        {this.renderEditAction(
                            "cms.multimedia.details",
                            item,
                            `${I18N_KEY_CMS_MEDIA_LIST}.editFileInfo`,
                            `/cms/multimedia/edit/${item.uuid}?type=file`,
                        )}
                        {this.renderDeleteAction(
                            "cms.multimedia.delete",
                            item,
                            `${I18N_KEY_CMS_MEDIA_LIST}.deleteFile`,
                        )}
                    </React.Fragment>
                );
                break;
            default:
                break;
        }

        return <React.Fragment>{options}</React.Fragment>;
    };

    private selectMedia = (item: MediaListData) => {
        if (this.props.selectMedia) {
            this.props.selectMedia(item);
        }
    };

    private verifyCheckedMedia = (item: MediaListData): boolean => {
        if (this.props.checkedMedia) {
            return this.props.checkedMedia.some(s => s.uuid === item.uuid)
        }
        return false;
    };
}

export const MediaList = withI18n(APP_TRANSLATIONS)(MediaListComponent);
