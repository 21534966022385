import { Extension, Ground } from "@7egend/web.ground/lib/ground";
import { Config } from "../../base/config";
import { HttpFetchOptions } from "@7egend/web.ground/lib/domain/extensions/http";
import { DloInput } from "./dloInput"
import { DloOutput } from "./dloOutput"

export { DloInput, DloOutput }
export * from "./utils"

export class DloExtension implements Extension {
    public name: string = "dlo";

    //#region Private properties

    /** App reference */
    private app: Ground = null as any;

    /** Config reference */
    private config: Config = null as any;

    //#endregion

    public initialize(config: Config, app: Ground): void {
        // Save properties for future use
        this.config = config;
        this.app = app;
    }

    //#region Public methods

    /**
     * Fetches an HTTP resource.
     * This method can handle with authentication and session.
     * Also, it knows how to connect with the API
     * @param input Request Dlo Input
     * @param options Request options. This will be used to complement global options
     */
    public async call(input: DloInput, options?: { api?: Partial<Config["api"]> }): Promise<DloInput["body"]> {
        // Will use this config or the base
        const api: Config["api"] = Object.assign({},
            this.config.api,
            input.config && input.config in this.config && "api" in (this.config as any)[input.config] ? (this.config as any)[input.config].api as Config["api"] : {},
            options?.api,
        )

        // Execute the transform
        if (typeof input.transform === "function") {
            input = input.transform(input);
        }

        // Create a new instance of the options request
        const inputOptions: HttpFetchOptions = {
            method: input.method,
            authentication: input.authentication,
            body: input.body || {},
            serializeParams: true,
            headers: input.headers,
        }

        // Add locale to uri
        if (!inputOptions.body.locale) {
            inputOptions.body.locale = input.locale || this.app.framework.i18n.getCurrentLanguage()
        }

        // Calculate the new uri
        let uri: string = (input.uri as string).startsWith("http") ? input.uri : [api.uri, api.version, input.uri].filter(Boolean).join("/");

        // Replace all URI params for given in input
        uri = uri.replace(/\${(\w+)}/g, (match, token) => (input as any)[token] || ((input as any).body && (input as any).body[token]) || "");

        // Execute the request
        const fetchOptions: HttpFetchOptions = { ...inputOptions };
        const response = await this.app.framework.http.fetch(uri, fetchOptions);

        // TODO: Do something with the response

        // Build back the output
        const output = new DloOutput();
        output.status = response.status;
        output.headers = response.headers;
        output.body = response.body.data != null ? response.body.data : response.body;

        return output;
    }

    //#endregion
}
