import React from "react";
import { CorePureComponent } from "@7egend/web.core"
import { memoizeOne } from "@7egend/web.core/lib/utils";
import styled, { css } from "styled-components"
import { StyledModule } from "../styles"
import { Link } from "../../../Link"
import { withI18n, WithI18nProps } from "@7egend/web.core/lib/components/withI18n";
import { translateStringOrObject } from "../../../../utils";
import { withSession, WithSessionProps } from "@7egend/web.core.security/lib/components/withSession/withSession";
import { Config } from "../../../../base/config";
import { roleHasPermission } from "../../../../utils/permissions";

interface ItemWrapperProps {
    /** item name */
    name: string | { [locale: string]: string };

    /** item path */
    path: string;

    /** is expandable? */
    expandable: boolean;

    /** active items */
    active?: string[];

    /** items with path but not clickable */
    readOnly?: boolean

    /** item permission */
    permission?: string;
}

interface EventWrapper {
    title?: string | { [locale: string]: string }
}

interface ItemWrapperState {
    active?: boolean;
}

const EVENT_WRAPPER_CLOSE = Symbol("wrapper.close")

const StyledItemWrapper = styled.div`
    margin: 10px 0;

    ${StyledModule.Container} {
        line-height: 18px;
    }
`

const StyledChildWrapper = styled.div<{ active?: boolean }>`
    display: none;
    transition: all 200ms ease;

    ${({ active }) => active && css`
        display: block;
    `}
`

class ItemWrapperComponent extends CorePureComponent<WithSessionProps & ItemWrapperProps & WithI18nProps, ItemWrapperState> {

    public state: ItemWrapperState = {
        active: undefined
    }

    private menuEventSubscription?: () => void

    public componentDidMount() {
        // Listen for menu wrapper changes
        this.menuEventSubscription = this.fw.events.subscribe<EventWrapper>(EVENT_WRAPPER_CLOSE, (options) => {
            if ((options.title !== this.props.name) && this.state.active) {
                this.setState({
                    active: false
                })
            }
        })
    }

    public componentDidUpdate(prevProps: ItemWrapperProps) {
        if (prevProps.active !== this.props.active) {
            this.setState({
                active: this.isActive(this.props.active),
            })
        }
    }

    public componentWillUnmount() {
        if (this.menuEventSubscription) {
            this.menuEventSubscription()
        }
    }

    public render() {
        const isActive = this.state.active !== undefined ? this.state.active : this.isActive(this.props.active);
        const { t, language } = this.props;
        const title: string = translateStringOrObject(this.props.name, language, t);
        const config = (this.fw.config as Config);
        const { session } = this.props!.session!;
        const showItem = roleHasPermission(this.props.permission, config, session, session?.access_token);

        if (showItem) {
            return (
                <StyledItemWrapper>
                    <StyledModule.Container>

                        <StyledModule.Title
                            active={isActive}
                            onClick={this.toggleWrapper}
                        >
                            {!this.props.readOnly ? (
                                <Link href={this.props.path}>{title}</Link>
                            ) : (
                                    <StyledModule.TitleWithoutLink>{title}</StyledModule.TitleWithoutLink>
                                )
                            }
                            {this.props.expandable &&
                                <>
                                    {this.renderExpandableIcon(isActive)}
                                </>
                            }
                        </StyledModule.Title>

                        {
                            this.props.children &&
                            <StyledChildWrapper active={isActive}>
                                {this.props.children}
                            </StyledChildWrapper>
                        }
                    </StyledModule.Container>
                </StyledItemWrapper >
            )
        }

        return null;
    }

    /** Returns if the path (wrapper) is active or not */
    private isActive = memoizeOne((active?: string[]) => {
        const { path } = this.props;

        if (active) {
            return active.some((item) => item === path);
        }
    });

    private renderExpandableIcon = (isActive?: boolean) => {
        return (
            <React.Fragment>
                {!isActive && <StyledModule.ExpandableIcon icon="keyboard_arrow_down" />}
                {isActive && <StyledModule.ExpandableIcon icon="keyboard_arrow_up" />}
            </React.Fragment>
        )
    }

    private toggleWrapper = () => {
        if (this.props.expandable) {
            this.setState({
                active: !this.state.active,
            }, () => {
                this.fw.events.publish<EventWrapper>(EVENT_WRAPPER_CLOSE, {
                    title: this.props.name,
                });
            });
        }
    }
}

export const ItemWrapper = withI18n()(withSession(ItemWrapperComponent));
