import { ModuleSetup } from "../../bootstrap";
import { SYSTEM_MODULES_MENUS_PLACEHOLDER } from "./modulesPlaceholder"
import { dashboardSetup } from "./Dashboard";
import { systemSetup } from "./System";
import { profileSetup } from "./System/Profile";
import { Config } from "../../base/config";

/** # Core Module Setup */
const setup: ModuleSetup = (config: Config) => {
    const flags = config.flags

    return {
        key: "core",
        routes: [
            // dashboard routes
            ...dashboardSetup.routes,
            // profile routes
            ...profileSetup.routes,
            // system routes
            ...(flags.core_system !== false ? systemSetup.routes : []),
        ].filter(Boolean),
        menus: [
            // profile menus
            ...profileSetup.menus,
            // system menus
            ...(flags.core_system !== false ? systemSetup.menus : []),
            // core menu modules placeholder
            ...SYSTEM_MODULES_MENUS_PLACEHOLDER,
        ].filter(Boolean),
    }
}

export default setup;
