import React, { PureComponent } from "react"
import { TooltipPosition } from "../Tooltip"
import { StyledTooltipCard } from "./styles"

export interface TooltipCardProps {
    /** dropdown position */
    position: TooltipPosition;
}

export class TooltipCard extends PureComponent<TooltipCardProps> {

    public render() {
        return (
            <StyledTooltipCard
                position={this.props.position}
            >{this.props.children}</StyledTooltipCard>
        )
    }
}
