import React, { PureComponent } from "react";
import { memoizeOne } from "@7egend/web.core/lib/utils";
import { Block } from "@7egend/web.core.cms/lib/dlos/block";
import { BlockWrapper, BlockWrapperProps } from "./BlockWrapper";
import { BlockMapping, BlockComponent } from "./interfaces";

const BASE_MAPPING: BlockMapping = {
    // empty for now, who knows what the future will be :)
}

export interface BlockDrawerProps {
    /**
     * Block ID.
     * This will only be used for callback identification.
     */
    id?: string

    /**
     * Custom mapping.
     * This will be used in favor to base mapping.
     */
    mapping?: BlockMapping

    /**
     * Block to render
     */
    block: Block

    /** Draggable block position */
    position: number

    /**
     * Resolve component callback.
     * Used to resolve a block to a specific component.
     * It also accepts a string for lookup in the mapping dictionary.
     */
    resolveComponent?: Array<(block: Block) => BlockComponent | string | string[]>

    /**
     * OnChange callback
     * Dispatches a call when a block changes.
     * Includes previous state for comparison.
     * @experimental
     */
    onChange?: (id: string, nextBlock: Block, previousBlock: Block) => void

    fallbackComponent?: any
}

/**
 * # BlockDrawer
 * Renders a dynamic block.
 * This does nothing more than linking multiple interfaces.
 * For a given Block and render mapping, renders the Component with given block.
 * 
 * Manages all internal state for each Block.
 */
export class BlockDrawer extends PureComponent<BlockDrawerProps> {

    /**
     * Block ref
     */
    private blockRef = React.createRef()

    /**
     * Resolved component to display
     * This is saved here to avoid getting new components in the wild
     */
    private targetComponent?: React.ComponentType<BlockWrapperProps>

    public render() {
        const { block, mapping: customMapping, position } = this.props;

        // Compute the mapping using base and custom
        const mapping = this.getMapping(BASE_MAPPING, customMapping!)

        // Resolve the component to be drawn
        let TargetComponent = this.targetComponent;

        if (!TargetComponent) {
            TargetComponent = this.targetComponent = this.resolveComponent(mapping, block) as React.ComponentType<BlockWrapperProps>
        }

        if (!TargetComponent) {
            TargetComponent = this.targetComponent = this.props.fallbackComponent;
        }

        if (!TargetComponent) {
            return null;
        }

        // Go for it!
        return (
            <TargetComponent
                position={position}
                ref={this.blockRef}
                data={block}
                onChange={this.props.onChange && this.onChange} // only activate if there is a parent listening
            />
        )
    }

    /**
     * Returns current block data
     * This is a raw data, so the Block is returned as is.
     */
    public getData(): Block | undefined {
        if (this.blockRef.current) {
            return (this.blockRef.current as any).getData()
        }
    }

    /**
     * Handles data change and bypass to the parent node
     * @experimental
     */
    private onChange = (nextData: Block, prevData: Block) => {
        if (this.props.onChange) {
            this.props.onChange(this.props.id || "", nextData, prevData)
        }
    }

    /**
     * Merge multiple mappings
     * This will also apply an HOC to all mapping that is needed for this component
     */
    private getMapping = memoizeOne((...mappings: BlockMapping[]): BlockMapping => {
        return mappings.reduce((result, mapping) => {
            for (const key in mapping) {
                if (mapping.hasOwnProperty(key)) {
                    const element = mapping[key];
                    result[key] = BlockWrapper(element) // apply the HOC
                }
            }
            return result
        }, {})
    })

    /**
     * Resolves the given block for the component
     * This is a simple resolver that will only looks for block type
     */
    private resolveComponent = memoizeOne((mapping: BlockMapping, block: Block) => {
        let resolverResult: BlockComponent | string | string[] | undefined
        let result: BlockComponent | undefined

        // Try to resolve first with specific resolver
        if (this.props.resolveComponent) {
            for(const eachResolver of this.props.resolveComponent) {
                // (this.props.resolveComponent as Array<(block: Block) => BlockComponent>).some((eachResolver: (block: Block) => BlockComponent | string | undefined) => {
                if (eachResolver) {
                    resolverResult = eachResolver(block);
                    if (!!resolverResult) {
                        break;
                    }
                }
            };
        }

        if (typeof resolverResult === "string") {
            result = mapping[resolverResult];
        }
        else if (Array.isArray(resolverResult)) {
            result = mapping[resolverResult.find(r => !!mapping[r]) || -1]
        }

        // Otherwise just return the block type resolver
        return result || mapping[block.type]
    })

}
