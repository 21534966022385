import { Generic } from "@7egend/web.core";
import { Block } from "@7egend/web.core.cms/lib/dlos/block"
import { Text } from "@7egend/web.core.cms/lib/dlos/text"
import { Medium } from "@7egend/web.core.media/lib/dlos";
import { Config } from "../../base/config";
import { blockTypes as blockTypesDefault } from "./BlockType/config";

export const defaultResolver = (block: Block, blockTypesConfig: any) => {
    const blockTypes = blockTypesConfig || blockTypesDefault;

    // Block text (simple or highlight)
    if (block.type === blockTypes?.text?.type && block.content) {
        return [`${block.type}_${(block as Block<Text>).content.style}`];
    }

    /** Block Container */
    if (block.type === blockTypes?.container?.type && block.content) {
        return [`${block.type}_${(block as any).content.style}`];
    }

    /** CTA || Attachment */
    if ((block.type === blockTypes?.cta?.type || block.type === blockTypes?.attachment?.type) && block.content) {
        return [`${block.type}_${(block as any).content.style}`];
    }

    /** Block multimedia (image, panoramic, video or document) */
    if (block.type === blockTypes?.medium?.type && block.content) {
        if ((block as Block<Medium>).content.panoramic === "1") {
            return [`${block.type}_${(block as Block<Medium>).content.medium_type}_1`];
        }
        return [`${block.type}_${(block as Block<Medium>).content.medium_type}`];
    }

    /* Block widget */
    if (block.type === blockTypes?.widget?.type && block.content) {
        return [`${block.type}_${(block as any).content.code}`];
    }
}

const CONTENT_BLOCKS = (block: Block) => {
    const generic = new Generic();
    const config = (generic.fw.config as Config);

    if (config.blocksResolver) {
        const results = config.blocksResolver(block, () => defaultResolver(block, config?.blockTypes))
        return typeof results === "string" ? [ results ] : results
    }
    return defaultResolver(block, config?.blockTypes);
}

export const RESOLVER = {
    CONTENT_BLOCKS,
};
