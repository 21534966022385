import React, { PureComponent } from "react"
import styled, { css } from "styled-components";
import { font, colors } from "../../../styles";
import { TypographyProps } from "../interface"

export interface SmallTextProps extends React.PropsWithChildren<TypographyProps> { }

const StyledSmallText = styled.p<SmallTextProps>`
    font-size: ${font.size.xs};
    font-weight: ${font.weight.regular};

    ${(props: SmallTextProps) => props.variant === "regular" && css`
        color: ${colors.neutral.darker};
    `}

    ${(props: SmallTextProps) => props.variant === "primary" && css`
        color: ${colors.primary.lighter};
    `}

    ${(props: SmallTextProps) => props.variant === "neutral" && css`
        color: ${colors.neutral.dark};
    `}

    ${(props: SmallTextProps) => props.variant === "error" && css`
        color: ${colors.semantic.error};
    `}

    ${(props: SmallTextProps) => props.variant === "warning" && css`
        color: ${colors.semantic.warning};
    `}
`

export class SmallText extends PureComponent<SmallTextProps> {
    public render() {
        return (
            <StyledSmallText
                variant={this.props.variant}
                className={this.props.className}
            >
                {this.props.children}
            </StyledSmallText>
        )
    }
}
