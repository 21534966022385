import React from "react";
import { withRouter, RouteComponentProps } from "react-router";
import { Button, ButtonProps } from "../Button";
import { FilterProps, Column, SelectProps } from "./interfaces";
import { Input } from "../Input";
import { Link } from "../Link";
import { Typography } from "../Typography";
import { StyledFormatterImage } from "./styles";
import { StyledErrorMessage } from "../Input/styles";
import styled from "styled-components";
import { LinkProps } from "@7egend/web.core.controls/lib/components";
import { PLACEHOLDER_IMAGE } from "../MediaList/Media/MediaPlaceholder"
import { Tooltip } from "../Tooltip";
import { Select } from "../Select";
import { useI18n } from "@7egend/web.core/lib/components/useI18n";
import { APP_TRANSLATIONS } from "../../locale"
import { I18N_KEY_CORE_COMPONENTS_DATATABLE } from "../../base/i18n";
import { DateTime } from "../DateTime";

interface DefaultActionsAddProps extends ButtonProps, RouteComponentProps {
    href: string;
}

export const StyledSearchInput: typeof Input = styled(Input)`
    & {
        margin: 0;

        ${StyledErrorMessage} { display: none }
    }
`;

const StyledDefaultFilterSelect = styled(Select)`
    & {
        margin: 0;
        
        span {
            display: none;
        }
    }
`

export const DefaultActions = {
    /**
     * Add button
     * Renders a "+" button with primary color.
     * Accepts onClick for custom behaviour
     */
    Add: withRouter(
        (props: DefaultActionsAddProps & LinkProps) => {
            return (
                <Tooltip
                    attachOnBody
                    position="bottom"
                    component={
                        <Link href={props.href} key={"add-item"}>
                            <Button
                                layout="main"
                                layoutFill={true}
                                layoutSquare={true}
                                icon="add"
                                {...props}
                            >
                                {props.children}
                            </Button>
                        </Link>
                    }
                >Add New</Tooltip>
            );
        }
    ),
};

export const DefaultFilters = {
    /**
     * Search toolbar
     * Renders a button with search icon in collapsed mode.
     * When expanded, shows an input
     */
    Search: (props: FilterProps) => {
        const [expanded, setExpanded] = React.useState(false);
        const [translate] = useI18n(APP_TRANSLATIONS)

        const searchAction = React.useCallback(() => {
            const hasValue = props.filters && props.filters.search;

            if (hasValue && hasValue.value) {
                props.updateFilter("search", undefined)
            }

            setExpanded(false)
        }, [props.filters])

        return expanded ? (
            <StyledSearchInput
                name="search"
                type="text"
                placeholder={translate(`${I18N_KEY_CORE_COMPONENTS_DATATABLE}.search`)}
                onChange={value =>
                    props.updateFilter("search", { value, regex: false })
                }
                autoFocus={true}
                action={<Button icon="close" onClick={searchAction} />}
            />
        ) : (
                <Button icon="search" onClick={() => setExpanded(true)} />
            );
    },

    /** 
     * Select
     * Renders a Select Input.
     */
    Select: (props: SelectProps) => {
        return (
            <StyledDefaultFilterSelect
                name="select-option"
                value={props.value}
                onChange={value =>
                    props.onChange(value)
                }
                options={props.options}
            />
        )
    }
};

export interface DefaultFormatterProps<T = Record<string, any>> {
    /**
     * Column metadata
     */
    column: Column
    data: T
};

export const DefaultFormatter = {

    /**
     * Regular Formatter
     * Renders the simple text
     */
    Text: ({ column, data }: DefaultFormatterProps): Element => {
        if (data[column.param[0]]) {
            return data[column.param[0]]
        }
        return "-";
    },

    /** 
     * Two Lines Formatter
     * Renders two lines of text
     */
    TextTwoLines: ({ column, data }: DefaultFormatterProps): Element => {
        if (column && column.param.length > 0) {
            return (
                <React.Fragment>
                    <Typography.RegularText>{data[column.param[0]]}</Typography.RegularText>
                    {(data[column.param[0]] && data[column.param[1]]) && <Typography.SmallText variant="neutral">{data[column.param[1]]}</Typography.SmallText>}
                </React.Fragment>
            )
        }
        return <div />
    },

    /** 
     * Image Formatter
     * Renders an image
     * If multiple parameters are given, tries to load the first one that has an image
     */
    Image: ({ column, data }: DefaultFormatterProps): Element => {

        // Try to load the image from all given
        const image = data[typeof column.param === "string" ? column.param : column.param.find(param => !!data[param])!]

        return (
            <StyledFormatterImage
                style={{
                    backgroundImage: `url(${image ? image : PLACEHOLDER_IMAGE || PLACEHOLDER_IMAGE})`,
                    backgroundSize: image ? 'cover' : '30px',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center'
                }}
            />
        )
    },

    /** 
     * Enum Formatter
     * Receive an array of key value and one key and returns its value
     */
    Enum: (map: any) => ({ column, data }: DefaultFormatterProps): Element => {
        return map[data[column.param[0]]] || null;
    },

    /**
     * DateTime
     */
    DateTime: ({ data, column }: DefaultFormatterProps): Element => {
        const value = data[column.param[0]]

        if (!value) {
            return "-"
        }

        return (
            <DateTime>
                {value}
            </DateTime>
        )
    }
}
