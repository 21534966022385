import React, { PureComponent } from "react"
import { Image as BaseImage } from "../../../../Image";
import { PLACEHOLDER_VIDEO } from "../index"
import { StyledMedialaceholder } from "../styles";

/**
 * 
 * # Video Placeholder
 * 
 */
export class Video extends PureComponent {
    public render() {
        return (
            <StyledMedialaceholder>
                <BaseImage src={PLACEHOLDER_VIDEO} />
            </StyledMedialaceholder>
        )
    }
}
