import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { Editor, IAllProps } from '@tinymce/tinymce-react';
import { useFramework } from "@7egend/web.core/lib/components/useFramework";
import { debounce } from "@7egend/web.core/lib/utils";

const StyledEditor = styled.div`
    border: 1px solid #E8E8E8;
    border-radius: 6px;
    padding: 12px 16px;

    .tox:not([dir=rtl]) .tox-toolbar__group:not(:last-of-type),
    .tox-tinymce {
        border: none;
    }

    .tox .tox-toolbar__primary {
        background: none;
        border: 1px solid #E8E8E8;
        border-radius: 6px;
    }
`;

interface TextEditorProps extends Pick<IAllProps, "onBlur"> {
    className?: string
    
    /**
     * Current value
     */
    value: string;

    /**
     * Callback for value changes
     */
    onChange: (value: string) => void;

    /**
     * Toolbar options
     */
    toolbar?: any;
}

/** 
 * # TextEditor Component
 *
 * ## How to use:
 *
 * ```ts
 * <TextEditor
 * toolbar={{options: ["inline", "link"]}}
 * value={data || ""}
 * onChange={this.onChange}
 * 
 * />
 * ```
 *
 * if need more options https://jpuri.github.io/react-draft-wysiwyg/#/docs)
 * 
 */
export const TextEditor: React.FC<TextEditorProps> = ({ className, value: initialStateValue, onChange, onBlur }) => {
    const fw = useFramework();
    const [value, setValue] = useState<string>(initialStateValue !== (undefined || "") ? initialStateValue : "")

    const onChangeWithDebounce = useCallback(debounce((content: string) => {
        onChange(content);
    }, 500), [onChange])

    const onEditorChange = useCallback((content: string) => {
        setValue(content);
        onChangeWithDebounce(content);
    }, [setValue, onChangeWithDebounce])


    return (
        <StyledEditor className={className}>
            <Editor
                init={{
                    menubar: false,
                    language: fw.i18n.getCurrentLanguage() || fw.config.locale.default,
                    plugins: [
                        'advlist lists link image charmap print preview anchor',
                        'searchreplace visualblocks code',
                        'insertdatetime media table paste code media table'
                    ],
                    toolbar:
                        'formatselect | bold italic underline strikethrough forecolor link | \
                    alignleft aligncenter alignright alignjustify |\
                    bullist numlist table media | removeformat'
                }}
                tinymceScriptSrc={"https://cdnjs.cloudflare.com/ajax/libs/tinymce/5.7.0/tinymce.min.js"}
                onEditorChange={onEditorChange}
                value={value}
                onBlur={onBlur}
            />
        </StyledEditor>
    )
}
