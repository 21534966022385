import React, { PureComponent } from "react"
import styled, { css } from "styled-components";
import { font, queries, colors } from "../../../styles";
import { TypographyProps } from "../interface"

export interface HighlightProps extends React.PropsWithChildren<TypographyProps> {
    className?: string;
}

const StyledHighlight = styled.h1<HighlightProps>`
    font-size: ${font.size.xxl};
    font-weight: ${font.weight.light};

    ${queries.maxXS} {
        font-size: ${font.size.xl};
    }

    ${(props: HighlightProps) => props.variant === "regular" && css`
        color: ${colors.neutral.darker};
    `}

    ${(props: HighlightProps) => props.variant === "primary" && css`
        color: ${colors.primary.lighter};
    `}

    ${(props: HighlightProps) => props.variant === "neutral" && css`
        color: ${colors.neutral.dark};
    `}
`

export class Highlight extends PureComponent<HighlightProps> {
    public render() {
        return (
            <StyledHighlight className={this.props.className} variant={this.props.variant}>
                {this.props.children}
            </StyledHighlight>
        )
    }
}
