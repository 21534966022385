import React from "react";
import { CorePureComponent, ReactDOM } from "@7egend/web.core"
import styled, { css } from "styled-components";
import { Button } from "../Button"
import { StyledWrapper } from "../Wrapper"
import { queries } from "../../styles"
import { Container, Row, Column } from "../Structure"

export interface ModalProps {

    /** Modal Overlay */
    overlay?: boolean

    /** Modal Close Button Layout */
    ButtonLayout?: "main" | "error" | "warning";

    /** Modal Visibility */
    isVisible: boolean

    /** Handle Visibility Modal */
    handleModal?: () => void

    /** className */
    className?: string

    /** full width */
    fullWidth?: boolean
}

const StyledModal = styled.div<ModalProps>`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    visibility: hidden;
    transition: opacity .2s ease-in,
                visibility .2s ease-in;

    ${(props) => props.isVisible && css`
            opacity: 1;
            visibility: visible;
        `
    }

    ${(props) => props.overlay && css`
            background-color: rgba(0, 0, 0, 0.2);
        `
    }
`

export const StyledModalContent = styled.div<ModalProps>`
    position: relative;
    width: auto;
    z-index: 1;
    opacity: 0;
    visibility: hidden;
    max-width: calc(100% - 400px);
    margin: 0 auto;
    display: inline-block;
    text-align: left;

    ${({ isVisible }) => isVisible && css`
            opacity: 1;
            visibility: visible;
            transition: opacity .2s ease-in,
                        visibility .2s ease-in;
            transition-delay: .2s;
        `
    }

    ${queries.maxSM} {
        max-width: calc(100% - 10px);
    }

    ${StyledWrapper} {
        box-shadow: 20px 40px 60px 0 rgba(0, 0, 0, 0.12);
    }

    ${({ fullWidth }) => fullWidth && css`
        width: 100%;
    `}
`

const StyledModalButton = styled(Button)`
    position: absolute;
    top: -20px;
    right: -20px;
    z-index: 2;

    ${queries.maxSM} {
        top: -10px;
        right: -10px;
    }
`

const DefaultProps: ModalProps = {
    overlay: false,
    ButtonLayout: "main",
    isVisible: false,
}

/**
 *
 * # Modal Component
 *
 * ## How to use:
 *
 * ```js
 * <Modal
 *  handleModal={this.toggleModal}
 *  isVisible={this.state.modalVisible}
 *  overlay>{children}</ModalProps>
 * ```
 *
 */
export class Modal extends CorePureComponent<ModalProps> {

    public static defaultProps = DefaultProps;
    private readonly document = this.fw.dom.getDocument();

    constructor(props: ModalProps) {
        super(props);

        this.escFunction = this.escFunction.bind(this);
    }

    public componentDidMount() {
        const document = this.fw.dom.getDocument();

        document.addEventListener("keydown", this.escFunction, false);
    }

    public componentWillUnmount() {
        const document = this.fw.dom.getDocument();

        document.removeEventListener("keydown", this.escFunction, false);
    }

    public render() {
        if (this.props.children && this.props.isVisible) {
            return ReactDOM.createPortal(
                <StyledModal
                    className={this.props.className}
                    isVisible={this.props.isVisible}
                    overlay={this.props.overlay}
                    onClick={this.closeModal}
                >
                    <Container>
                        <Row>
                            <Column contentAlign="center">
                                <StyledModalContent fullWidth={this.props.fullWidth} isVisible={this.props.isVisible} onClick={(e) => { e.stopPropagation() }}>
                                    <StyledModalButton onClick={this.closeModal} icon="close" layout={this.props.ButtonLayout} layoutFill layoutRound />
                                    {this.props.children}
                                </StyledModalContent>
                            </Column>
                        </Row>
                    </Container>
                </StyledModal>,
                this.document.body
            )
        }

        return (<div />)
    }

    private closeModal = () => {
        if (this.props.handleModal) {
            this.props.handleModal();
        }
    }

    private escFunction = (e: React.KeyboardEvent) => {
        if (e.keyCode === 27 && this.props.isVisible) {
            this.closeModal();
        }
    }
}
