import { ButtonComponent as CoreButton } from "@7egend/web.core.controls/lib/components/button/button"
import { Icon } from "../Icon";
import styled, { css } from "styled-components"
import { colors, font } from "../../styles"
import { ButtonLayouts, IconPositions } from "./Button"
import { StyledSpinner, StyledCircle } from "../Spinner/Spinner";

interface StyledButtonProps {
    layout?: ButtonLayouts
    layoutFill?: boolean
    layoutOutline?: boolean
    layoutFaded?: boolean
    layoutRound?: boolean
    layoutIconPosition?: IconPositions
    layoutSquare?: boolean
    fullWidth?: boolean
    isFocused?: boolean
}

interface StyledButtonSpinnerProps {
    icon?: boolean
    iconPosition?: IconPositions
    layout?: ButtonLayouts
    layoutFill?: boolean;
    layoutRound?: boolean
    layoutSquare?: boolean
    layoutOutline?: boolean
    layoutFaded?: boolean
}

export const StyledIcon = styled(Icon)`
    font-size: 20px;
`
export const StyledButtonSpinner = styled.span<StyledButtonSpinnerProps>`
    ${({ iconPosition }) => iconPosition === "right" && css`
        ${StyledSpinner} {
            margin-left: 10px;
        }
    `}

    ${({ iconPosition }) => iconPosition === "left" && css`
        ${StyledSpinner} {
            margin-right: 10px;
        }
    `}

    ${({ layout, layoutFill, layoutRound, layoutSquare }) => (layout === "main" || layout === "error" || layout === "warning") && (layoutFill || layoutRound || layoutSquare) && css`
        ${StyledCircle} {
            stroke: ${colors.light};
        }
    `} 

    ${({ layout }) => layout === "alt" && css`
        ${StyledCircle} {
            stroke: ${colors.primary.lighter};
        }
    `} 

    ${({ layout, layoutFaded, layoutOutline }) => layout === "main" && (layoutFaded || layoutOutline) && css`
        ${StyledCircle} {
            stroke: ${colors.primary.lighter};
        }
    `}

    ${({ layout, layoutFaded, layoutOutline }) => layout === "error" && (layoutFaded || layoutOutline) && css`
        ${StyledCircle} {
            stroke: ${colors.semantic.error};
        }
    `}

    ${({ layout, layoutFaded, layoutOutline }) => layout === "warning" && (layoutFaded || layoutOutline) && css`
        ${StyledCircle} {
            stroke: ${colors.semantic.warning};
        }
    `}
`
export const StyledButton = styled(CoreButton) <StyledButtonProps>`
    & {
        padding: 10px 15px;
        border-radius: 10px;
        min-height: 40px;
        display: inline-flex;
        align-items: center;
        flex-direction: row;
        justify-content: center;
        border: 0;
        background-color: transparent;
        outline: none;

        span {
            display: flex;
            align-items: center;
        }

        &,
        span {
            font-size: ${font.size.sm};
            font-weight: ${font.weight.medium};
            color: ${colors.neutral.dark};
            transition: all .1s ease-in-out;
        }

        ${({ fullWidth} ) => fullWidth && css`
            width: 100%;
        `}

        ${({ layoutSquare }) => layoutSquare && css`
            width: 40px;
            height: 40px;
        ` }

        ${({ disabled }) => !disabled && css`
            &:hover {
                &,
                span {
                     color: ${colors.neutral.darker};
                }
            }
        ` }

        ${({ isFocused }) => isFocused && css`
            &,
            span {
                color: ${colors.neutral.darker};
            }
        `}

        ${({ disabled, isLoading }) => disabled && css`
            
            ${!isLoading && css`
                box-shadow: none !important;
            `}

            &:hover {
                cursor: not-allowed !important;
            }

            &,
            span {
                color: ${colors.neutral.medium};
            }
        ` }

        ${({ layout, disabled, isFocused }) => layout === "light" && css`
            border: 0;
            background-color: transparent;
            box-shadow: none;

            &,
            *,
            &:hover {
                color: ${colors.light} !important;
            }

            ${!disabled && css`
                &:hover {
                    &,
                    span {
                        color: ${colors.primary.lighter} !important;
                    }
                }

                ${isFocused && css`
                    &,
                    span {
                        color: ${colors.primary.lighter} !important;
                    }
                `}
            `}
        `}

        ${({ layout, layoutFill, layoutOutline, layoutFaded, layoutRound, disabled, isLoading, isFocused }) => layout === "main" && css`
            ${layoutFill && css`
                border: 0;
                background-color: ${colors.primary.lighter};
                box-shadow: 2px 4px 20px 0 rgba(20, 192, 196, 0.4);

                ${!disabled && css`
                    &:hover {
                        background-color: ${colors.primary.light};
                    }

                    &,
                    *,
                    &:hover {
                        color: ${colors.light} !important;
                    }
                    
                    ${isFocused && css`
                        & {
                            background-color: ${colors.primary.light};
                        }
    
                        &,
                        span {
                            color: ${colors.light} !important;
                        }
                    `}
                `}


                ${disabled && isLoading && css`
                    &,
                    span {
                        color: ${colors.light} !important;
                    }
                `}
            `}

            ${layoutOutline && css`
                background-color: transparent;
                border: 1px solid ${colors.primary.lighter};

                ${!disabled && css`
                    &:hover {
                        border-color: ${colors.primary.light};

                        &,
                        span {
                           color: ${colors.primary.light};
                           stroke: ${colors.primary.light};
                        }

                    }

                    ${isFocused && css`
                        & {
                            border-color: ${colors.primary.light};
                            
                            &,
                            span {
                                color: ${colors.primary.light} !important;
                                stroke: ${colors.primary.light} !important;
                            }
                        }
                    `}
                `}

                &,
                span {
                    color: ${colors.primary.lighter};
                }
            `}

            ${layoutFaded && css`
                background-color: ${colors.semantic.light};

                &,
                span {
                    color: ${colors.primary.lighter};
                }
                ${!disabled && css`
                    &:hover {
                        background-color: ${colors.primary.lighter};

                        &,
                        span {
                            color: ${colors.light};
                            stroke: ${colors.light};
                        }
                    }

                    ${isFocused && css`
                        & {
                            background-color: ${colors.primary.lighter};
    
                            &,
                            span {
                                color: ${colors.light};
                                stroke: ${colors.light};
                            }
                        }
                    `}
                `}

            `}

            ${layoutRound && css`
                border: 0;
                border-radius: 100%;
                background-color: ${colors.primary.lighter};
                width: 40px;
                height: 40px;
                padding: 0;
                box-shadow: 0px 20px 34px 0 rgba(0, 0, 0, 0.2);

                ${!disabled && css`
                    &:hover {
                        background-color: ${colors.primary.light};

                        &,
                        span {
                           color: ${colors.light};
                        }

                    }

                    ${isFocused && css`
                        & {
                            background-color: ${colors.primary.light};

                            &,
                            span {
                            color: ${colors.light};
                            }
                        }
                    `}
                `}
                

                &,
                span {
                    color: ${colors.light};
                }
            `}

            ${disabled && !isLoading && css`
                background-color: ${colors.neutral.light};
                border-color: ${colors.neutral.light};

                ${layoutOutline && css`
                    background-color: transparent;
                `}

                &,
                span {
                    color: ${colors.neutral.medium};
                }
            `}
        `}

        ${({ layout, layoutFaded, layoutFill, layoutOutline, layoutRound, disabled, isLoading, isFocused }) => layout === "error" && css`
            ${layoutFill && css`
                border: 0;
                background-color: ${colors.semantic.error};
                box-shadow: 2px 4px 20px 0 rgba(244, 83, 56, 0.4);

                ${!disabled && css`
                    &:hover {
                        background-color: ${colors.semantic.errorDark};
                    }

                    &,
                    *,
                    &:hover {
                        color: ${colors.light} !important;
                    }

                    ${isFocused && css`
                        & {
                            background-color: ${colors.semantic.errorDark};

                            &,
                            span {
                                color: ${colors.light} !important;
                            }
                        }
                    `}
                `}

                ${disabled && isLoading && css`
                    &,
                    span {
                        color: ${colors.light} !important;
                    }
                `}
            `}

            ${layoutOutline && css`
                background-color: transparent;
                border: 1px solid ${colors.semantic.error};

                ${!disabled && css`
                    &:hover {
                        border-color: ${colors.semantic.errorDark};

                        &,
                        span {
                            color: ${colors.semantic.errorDark};
                            stroke: ${colors.semantic.errorDark};
                        }
                    }

                    ${isFocused && css`
                        & {
                            border-color: ${colors.semantic.errorDark};

                            &,
                            span {
                                color: ${colors.semantic.errorDark} !important;
                                stroke: ${colors.semantic.errorDark} !important;
                            }
                        }`
                    }
                `}

                &,
                span {
                    color: ${colors.semantic.error};
                }
            `}

            ${layoutFaded && css`
                background-color: ${colors.semantic.errorLight};

                ${!disabled && css`
                    &:hover {
                        background-color: ${colors.semantic.error};

                        &,
                        span {
                            color: ${colors.light};
                            stroke: ${colors.light};
                        }
                    }

                    ${isFocused && css`
                        & {
                            background-color: ${colors.semantic.error};

                            &,
                            span {
                                color: ${colors.light} !important;
                                stroke: ${colors.light} !important;
                            }
                        }
                    `}
                `}

                &,
                span {
                    color: ${colors.semantic.error};
                }
            `}

            ${layoutRound && css`
                border: 0;
                border-radius: 100%;
                background-color: ${colors.semantic.error};
                width: 40px;
                height: 40px;
                padding: 0;
                box-shadow: 0px 20px 34px 0 rgba(0, 0, 0, 0.2);

                ${!disabled && css`
                    &:hover {
                        background-color: ${colors.semantic.errorDark};

                        &,
                        span {
                            color: ${colors.light};
                        }
                    }

                    ${isFocused && css`
                        & {
                            background-color: ${colors.semantic.errorDark};

                            &,
                            span {
                                color: ${colors.light};
                            }
                        }
                    `}
                `}

                &,
                span {
                    color: ${colors.light};
                }
            `}

            ${disabled && !isLoading && css`
                background-color: ${colors.neutral.light};
                border-color: ${colors.neutral.light};

                ${layoutOutline && css`
                    background-color: transparent;
                `}

                &,
                span {
                    color: ${colors.neutral.medium};
                }
            `}
        `}

        ${({ layout, layoutFaded, layoutFill, layoutOutline, layoutRound, disabled, isLoading, isFocused }) => layout === "warning" && css`
            ${layoutFill && css`
                border: 0;
                background-color: ${colors.semantic.warning};
                box-shadow: 2px 4px 20px 0 rgba(255, 140, 0, 0.4);

                ${!disabled && css`
                    &:hover {
                        background-color: ${colors.semantic.warningDark};
                    }

                    &,
                    *,
                    &:hover {
                        color: ${colors.light} !important;
                    }

                    ${isFocused && css`
                        & {
                            background-color: ${colors.semantic.warningDark};
                        }

                        &,
                        *{
                            color: ${colors.light} !important;
                        }
                    `}
                `}

                ${disabled && isLoading && css`
                    &,
                    span {
                        color: ${colors.light} !important;
                    }
                `}
            `}

            ${layoutOutline && css`
                background-color: transparent;
                border: 1px solid ${colors.semantic.warning};

                ${!disabled && css`
                    &:hover {
                        border-color: ${colors.semantic.warningDark};

                        &,
                        span {
                            color: ${colors.semantic.warningDark};
                            stroke: ${colors.semantic.warningDark};
                        }
                    }

                    ${isFocused && css`
                        & {
                            border-color: ${colors.semantic.warningDark};

                            &,
                            span {
                                color: ${colors.semantic.warningDark} !important;
                                stroke: ${colors.semantic.warningDark} !important;
                            }
                        }
                    `}
                `}

                &,
                span {
                    color: ${colors.semantic.warning};
                }
            `}

            ${layoutFaded && css`
                background-color: ${colors.semantic.warningLight};

                ${!disabled && css`
                    &:hover {
                        background-color: ${colors.semantic.warning};

                        &,
                        span {
                            color: ${colors.light};
                            stroke: ${colors.light};
                        }
                    }

                    ${isFocused && css`
                        & {
                            background-color: ${colors.semantic.warning};

                            &,
                            span {
                                color: ${colors.light} !important;
                                stroke: ${colors.light} !important;
                            }
                        }
                    `}
                `}

                &,
                span {
                    color: ${colors.semantic.warning};
                }
            `}

            ${layoutRound && css`
                border: 0;
                border-radius: 100%;
                background-color: ${colors.semantic.warning};
                width: 40px;
                height: 40px;
                padding: 0;
                box-shadow: 0px 20px 34px 0 rgba(0, 0, 0, 0.2);

                ${!disabled && css`
                    &:hover {
                        background-color: ${colors.semantic.warningDark};

                        &,
                        span {
                            color: ${colors.light};
                        }
                    }

                    ${isFocused && css`
                        & {
                            background-color: ${colors.semantic.warningDark};

                            &,
                            span {
                                color: ${colors.light};
                            }
                        }
                    `}
                `}

                &,
                span {
                    color: ${colors.light};
                }
            `}

            ${disabled && !isLoading && css`
                background-color: ${colors.neutral.light};
                border-color: ${colors.neutral.light};

                ${layoutOutline && css`
                    background-color: transparent;
                `}

                &,
                span {
                    color: ${colors.neutral.medium};
                }
            `}
        `}

        ${({ layout, disabled, isLoading, isFocused }) => layout === "alt" && css`
            width: 40px;
            height: 40px;
            padding: 0;
            background-color: ${colors.light};
            border-radius: 100%;
            box-shadow: 0 20px 34px 0 rgba(0,0,0,0.2);

            &,
            span {
                color: ${colors.primary.lighter};
            }

            ${!disabled && css`
                &:hover {
                    background-color: ${colors.primary.lighter};

                    &,
                    span {
                        color: ${colors.light};
                        stroke: ${colors.light};
                    }
                }

                ${isFocused && css`
                    & {
                        background-color: ${colors.primary.lighter};

                        &,
                        span {
                            color: ${colors.light};
                            stroke: ${colors.light};
                        }
                    }
                `}
            `}

            ${disabled && !isLoading && css`
                background-color: ${colors.neutral.light};
                border-color: ${colors.neutral.light};

                &,
                span {
                    color: ${colors.neutral.medium};
                }
            `}

            ${disabled && isLoading && css`
                &,
                span {
                    color: ${colors.primary.lighter};
                }
            `}
        `}

        ${({ layoutIconPosition }) => layoutIconPosition === "left" && css`
            span {
                display: flex;
                flex-flow: row-reverse;
            }

            ${StyledIcon} {
                margin-right: 8px;
            }
        `}

        ${({ layoutIconPosition }) => layoutIconPosition === "right" && css`
            ${StyledIcon} {
                margin-left: 8px;
            }
        `}
    }
`
