import React from "react";
import { useFramework } from "@7egend/web.core/lib/components/useFramework";
import { useDropzone } from 'react-dropzone';
import { LimitSizeUpload } from "../../../constants"
import { SnackbarLayout } from "../../Snackbar";
import styled, { css } from 'styled-components';
import { MediumType } from "@7egend/web.core.media/lib/dlos";
import { FileInfo } from "../domain";

const ACCEPTED_FILES_DEFAULT = undefined // all, previous was 'image/jpeg, image/png, application/pdf, x-application/zpl';
const ACCEPTED_IMAGES = 'image/jpeg, image/png';
const ACCEPTED_DOCUMENTS = undefined // all, previous was 'application/pdf, x-application/zpl';

/** Styled Component */
const Styled = {
    /** DnD Box Area */
    Container: styled.div`
        position: relative;
        outline: none;
    `,
    /** DnD Overlay when releasing the file */
    Overlay: styled.div<{ isDragActive?: boolean }>`
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255,255,255, 0.7);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
        opacity: 0;
        visibility: hidden;
        transition: opacity .2s ease,
                    visibility .2s ease;

        ${({ isDragActive }) => isDragActive && css`
            opacity: 1;
            visibility: visible;
        `}
    `
}

/** DnD Props */
export interface DnDProps {
    /**
     * Enable or disable the DnD
     * @default true
     */
    enabled?: boolean

    /**
     * dropped files fn
     */
    droppedFiles: (
        /** files */
        files: FileInfo[],
    ) => void

    /**
     * Show error message
     */
    onError?: (message: string, layout?: SnackbarLayout) => void;

    /** mediumType */
    mediumType?: MediumType | string
}

export const DnD: React.FC<DnDProps> = ({ children, droppedFiles, onError, mediumType, enabled }) => {

    const framework = useFramework();

    /** It returns a transformed object with file base64, file name and if has error */
    const getFileFromInput = (file: any): Promise<FileInfo> => {
        return new Promise((res, rej) => {
            const reader: any = new (framework.dom.getGlobal().FileReader)();
            reader.onerror = rej;
            reader.onload = () => {
                res({
                    base64: reader.result,
                    name: file.name,
                    error: file.size > LimitSizeUpload ? true : false,
                });
            };
            reader.readAsDataURL(file);
        });
    }

    const uploadFiles = (files: any) => {
        Promise.all(
            Object.keys(files).map(key => getFileFromInput(files[key]))
        ).then(file => {
            droppedFiles(file);
        })
        .catch(err => {
            framework.log.error("File Error", err);
        });
    }

    const validateAcceptedFiles = () => {
        if (!mediumType) {
            return ACCEPTED_FILES_DEFAULT;
        }

        if (mediumType === MediumType.Image) {
            return ACCEPTED_IMAGES;
        } else if (mediumType === MediumType.File) {
            return ACCEPTED_DOCUMENTS;
        }
    }

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        disabled: !enabled,
        accept: validateAcceptedFiles(),
        onDrop: (acceptedFiles, rejectedFiles) => {

            if (mediumType === MediumType.Video || mediumType === MediumType.Stream) {
                if (onError) {
                    onError(`Unsupported file format!`, 'error');
                    return;
                }
            }

            if (acceptedFiles && acceptedFiles.length > 0) {
                uploadFiles(acceptedFiles);
            }

            if (rejectedFiles && rejectedFiles.length > 0) {
                if (onError) {
                    rejectedFiles.forEach((rejectedFile) => {
                        onError(`${rejectedFile.name} - Unsupported file format!`, 'error');
                    })
                }
            }
        },
    });

    return (
        <Styled.Container {...getRootProps() as any} onClick={e => e.stopPropagation()}>
            <input {...getInputProps() as any} />
            <Styled.Overlay isDragActive={isDragActive}>
                {isDragActive && 'Release to drop'}
            </Styled.Overlay>
            {children}
        </Styled.Container>
    );
}

DnD.defaultProps = {
    enabled: true,
}