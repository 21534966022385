import React from "react";
import { PureComponent, ReactHTML } from "@7egend/web.core/lib/base";
import DOMPurify from "dompurify";

export interface HtmlProps {
    html: string,
    /** Implements list of html tags that are forbidden */
    forbidTags?: string[],
    /** Implements list of html attributes that are forbidden */
    forbidAttr?: string[],
    /** List of tags to add to allowed tags */
    addTags?: string[],
    /** List of attributes to add to allowed attributes */
    addAttr?: string[],
    /**
     * @deprecated
     * List of classes for this element
     */
    classNames?: string
    /** List of classes */
    className?: string
    /** Component to use */
    component?: keyof ReactHTML
}

/**
 * define all default props
 */
const defaultProps = {
    html: "",
    forbidTags: [""],
    forbidAttr: [""],
    addTags: [""],
    addAttr: ["target"],
    classNames: "",
    className: "",
}

/**
 * # Component Html
 * This component renders a div with custom html
 * It uses dompurify to sanitize all code, to prevent untrusted code and other security issues
 *
 * ## How to use
 *
 * Example:
 * ```<IframeComponent source={`https://www.google.pt`} />```
 *
 *
 * ### Parameters:
 *  html: '',
 *  forbidTags: [''],
 *  forbidAttr: ['']
 *
 */

export class Html extends PureComponent<HtmlProps> {

    /* set default props */
    public static defaultProps = defaultProps;

    public render() {
        const CustomComponent: any = this.props.component || "div"

        return (
            <CustomComponent
                className={this.props.className || this.props.classNames}
                dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(this.props.html, {
                        FORBID_TAGS: this.props.forbidTags,
                        FORBID_ATTR: this.props.forbidAttr,
                        ADD_TAGS: this.props.addTags,
                        ADD_ATTR: this.props.addAttr,
                    }),
                }}
            />
        )
    }
}
