import React from "react";
import { Block } from "@7egend/web.core.cms/lib/dlos/block";
import { BlockComponentProps } from "../../interfaces";
import { blockTypes } from "../config";
import { I18N_KEY_CMS_COMPONENTS_BLOCK } from "../../../../base/i18n";
import { Text } from "@7egend/web.core.cms/lib/dlos";
import { Input } from "../../../Input";
import { useI18n } from "@7egend/web.core/lib/components/useI18n";

const Structure = {
    type: blockTypes.videoYoutube.type,
    content: {
        style: blockTypes.videoYoutube.style
    }
} as Block<Text>;

const I18N_NAMESPACE = `${I18N_KEY_CMS_COMPONENTS_BLOCK}.types.videoYoutube`

const Component: React.FC<BlockComponentProps<Text>> = ({ className, data, updateData }) => {
    const [t] = useI18n()
    return (
        <Input
            className={className}
            name="value"
            label={t(`${I18N_NAMESPACE}.label`)}
            type="text"
            placeholder={t(`${I18N_NAMESPACE}.placeholder`)}
            value={data.content?.value}
            onChange={(value) => updateData({
                ...data,
                content: {
                    ...data.content,
                    value,
                }
            })}
            pattern="https?:\/\/(www\.)?youtube\.com\/watch\?v=.+"
            required
        />
    );
}

const Button = {
    icon: "videocam",
    name: {
        key: `${I18N_NAMESPACE}.name`,
    },
};

export const VideoYoutube = {
    key: `${blockTypes.videoYoutube.type}_${blockTypes.videoYoutube.style}`,
    Structure,
    Component,
    Button,
};
