import React from "react";
import { CorePureComponent } from "@7egend/web.core";
import styled, { css } from "styled-components";
import { Icon } from "../Icon";
import { Button } from "../Button";
import { colors, font } from "../../styles"

export type SnackbarLayout = "error" | "warning" | "info" | "success";

export interface SnackbarProps {
    /** snackbar key */
    id?: string;
    /** snackbar type */
    layout?: SnackbarLayout;
    /** snackbar classname */
    className?: string;
    /** snackbar close */
    close?: (id: string) => void;
    /** autoclose */
    autoClose?: {
        timeout: number;
    };
}

interface SnackbarStyledProps {
    layout?: SnackbarLayout
}

const StyledIcon = styled(Icon)`
    & {
        font-size: 21px;
    }
`;

const StyledMessage = styled.div`
    color: ${colors.neutral.darker};
    padding: 19px;
    font-size: ${font.size.sm};
    font-weight: ${font.weight.medium};
`;

const StyledIconWrapper = styled.div`
    color: ${colors.light};
    padding: 15px;
    width: 56px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    & + ${StyledMessage} {
        padding-left: calc(19px + 56px);
    }
`;

const StyledButton = styled(Button)`
    & {
        ${StyledIcon} {
            font-size: 21px;
        }
    }
`;

export const StyledSnackbar = styled.div<SnackbarStyledProps>`
    box-shadow: 20px 40px 60px 0 rgba(24, 24, 24, 0.12);
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    background-color: ${colors.light};
    border-radius: 10px;
    overflow: hidden;
    width: auto;
    margin-bottom: 16px;

    &:last-child {
        margin-bottom: 40px;
    }

    ${(props: SnackbarStyledProps) => props.layout === "error" && css`
        ${StyledIconWrapper} {
            background-color: ${colors.semantic.error};
        }

        ${StyledMessage} {
            color: ${colors.semantic.error};
        }
    `};

    ${(props: SnackbarStyledProps) => props.layout === "info" && css`
        ${StyledIconWrapper} {
            background-color: ${colors.semantic.info};
        }

        ${StyledMessage} {
            color: ${colors.semantic.info};
        }
    `};

    ${(props: SnackbarStyledProps) => props.layout === "warning" && css`
        ${StyledIconWrapper} {
            background-color: ${colors.semantic.warning};
        }

        ${StyledMessage} {
            color: ${colors.semantic.warning};
        }
    `};

    ${(props: SnackbarStyledProps) => props.layout === "success" && css`
        ${StyledIconWrapper} {
            background-color: ${colors.primary.lighter};
        }

        ${StyledMessage} {
            color: ${colors.primary.lighter};
        }
    `};
`;

/**
 * # Snackbar Component
 *
 * ## How to use
 *
 * ```tsx
 * <Snackbar
 *      autoClose={item.timeout ? {
 *          timeout: item.timeout,
 *      } : undefined}
 *      id={item.id}
 *      key={item.id}
 *      close={this.closeSnackbar(item)}
 *      layout={item.layout}
 *      >
 *          {item.message}
 * </Snackbar>
 * ```
 *
 */
export class Snackbar extends CorePureComponent<SnackbarProps> {
    private snackbarSubscriptionTimeout?: () => void;

    public snackbarIcon = (props: SnackbarProps) => {
        const { layout } = props;

        let icon: string = "info";

        switch (layout) {
            case "error":
                icon = "sentiment_dissatisfied";
                break;
            case "warning":
                icon = layout;
                break;
            case "success":
                icon = "check";
                break;
        }

        return icon;
    };

    public onClose = () => {
        const { close, id } = this.props;

        if (close && id) {
            close(id);
        }
    };

    public componentDidMount() {
        const { autoClose, id } = this.props;

        if (autoClose && autoClose.timeout && id) {
            this.snackbarSubscriptionTimeout = this.fw.dom.setTimeout(
                this.onClose,
                autoClose.timeout,
            );
        }
    }

    public componentWillUnmount() {
        /** Clean the timeout subscription */
        if (this.snackbarSubscriptionTimeout) {
            this.snackbarSubscriptionTimeout();
        }
    }

    public render() {
        const { children, layout } = this.props;

        return (
            <StyledSnackbar layout={layout} className={this.props.className}>
                {layout &&
                    <StyledIconWrapper>
                        <StyledIcon icon={this.snackbarIcon(this.props)}></StyledIcon>
                    </StyledIconWrapper>
                }
                <StyledMessage>
                    {children}
                </StyledMessage>
                <StyledButton
                    onClick={this.onClose}
                    aria-label="Clear"
                >
                    <StyledIcon icon="clear" />
                </StyledButton>
            </StyledSnackbar>
        )
    }
}
