import React from "react";
import { Block } from "@7egend/web.core.cms/lib/dlos/block";
import { BlockComponentProps } from "../../interfaces";
import { Form as FormDLO } from "../../../../dlos/cms/form";
import { FormPicker } from "../../../../modules/cms/Forms";
import { blockTypes } from "../config";
import { I18N_KEY_CMS_COMPONENTS_BLOCK } from "../../../../base/i18n";

const { form } = blockTypes;

const Structure = {
    type: form.type,
    content: {},
} as Block<FormDLO>;

const I18N_NAMESPACE = `${I18N_KEY_CMS_COMPONENTS_BLOCK}.types.form`

const Component: React.FC<BlockComponentProps<FormDLO>> = ({ className, data, updateData }) => {
    return (
        <FormPicker
            className={className}
            picked={[data.content]}
            pickForm={(checkedItems: FormDLO[]) =>
                updateData({
                    ...data,
                    content: checkedItems[0] as any,
                })
            }
        />
    )
}

const Button = {
    icon: "assignment",
    name: {
        key: `${I18N_NAMESPACE}.name`,
        en: "Form",
        es: "Formulario",
        pt: "Formulário",
    },
};

export const Form = {
    key: form.type,
    Structure,
    Component,
    Button,
};
