import React, { PureComponent } from "react";
import { DynamicPage } from "../../../../dlos/cms/dynamic-page"
import { DynamicPages } from "../DynamicPages"
import { Styles } from "./styles"
import { Icon, Typography, Modal, Column, Button } from "../../../../components";
import { withI18n, WithI18nProps } from "@7egend/web.core/lib/components/withI18n";
import { Trans } from "@7egend/web.core/lib/components/i18n";
import { APP_TRANSLATIONS } from "../../../../locale";
import { I18N_KEY_CMS_PAGES_BOOSTER, I18N_KEY_CMS_PAGES_DYNAMIC_PAGE, I18N_KEY_GLOBAL } from "../../../../base/i18n";

interface PickerProps {
    /** list of picked pages */
    picked?: DynamicPage[];

    /** pick form */
    pickForm?: (picked: DynamicPage[]) => void;

    disabled?: boolean;
}

interface PickerState {
    active?: boolean;
    pickedPages: DynamicPage[]
}

const I18N_NAMESPACE = I18N_KEY_CMS_PAGES_BOOSTER

/**
 * # Picker Component
 * 
 * ## How to use:
 *  <PagesPicker 
 * 
 *  />
 */
class PickerComponent extends PureComponent<PickerProps & WithI18nProps, PickerState> {

    /** state */
    public state: PickerState = {
        active: false,
        pickedPages: []
    }

    /** render */
    public render() {
        const hasPicked = this.props.picked && this.props.picked.length > 0 && this.props.picked[0].internal_name;

        return (
            <React.Fragment>
                <Styles.Picker disabled={this.props.disabled}>
                    {!hasPicked ? (
                        <Styles.Placeholder onClick={this.togglePage}>
                            <Icon icon="search" />
                            <Typography.RegularText><Trans id={`${I18N_KEY_CMS_PAGES_DYNAMIC_PAGE}.picker.fieldSearchTitle`} /></Typography.RegularText>
                        </Styles.Placeholder>
                    ) : (
                            <Styles.Picked>
                                <Typography.RegularText>
                                    {hasPicked && this.props.picked?.map((page) => page.internal_name)}
                                </Typography.RegularText>
                                <div onClick={this.removePicked}>
                                    <Icon icon="delete" />
                                </div>
                            </Styles.Picked>
                        )}

                </Styles.Picker>
                <Modal
                    overlay={true}
                    handleModal={this.togglePage}
                    isVisible={this.state.active}
                    fullWidth
                >
                    <DynamicPages
                        picker={true}
                        pickItem={this.pickItem}
                        pickedItems={this.state.pickedPages}
                        footer={
                            <Column contentAlign="right">
                                <Button
                                    layout="main"
                                    layoutOutline
                                    icon="clear"
                                    layoutIconPosition="right"
                                    onClick={this.togglePage}
                                >
                                    <Trans id={`${I18N_KEY_GLOBAL}.cancel`} />
                                </Button>
                                <Button
                                    layout="main"
                                    layoutFill
                                    icon="check"
                                    layoutIconPosition="right"
                                    onClick={this.addItems}
                                    disabled={
                                        this.state.pickedPages.length < 1
                                    }
                                >
                                    <Trans id={`${I18N_NAMESPACE}.picker.addForm`} />
                                </Button>
                            </Column>
                        } />
                </Modal>
            </React.Fragment >
        )
    }

    private togglePage = () => {
        this.setState(prevState => ({
            active: !prevState.active,
        }))
    }

    private addItems = () => {
        if (this.state.pickedPages && this.props.pickForm) {

            /** sent to parent component, checked items */
            this.props.pickForm([{
                id: this.state.pickedPages[0].id,
                internal_name: this.state.pickedPages[0].name,
            } as any]);

            /** hide modal */
            this.togglePage();

            /** clean state */
            this.setState({
                pickedPages: [],
            });
        }
    };

    private pickItem = (pickedPages?: DynamicPage[]) => {
        if (pickedPages) {
            this.setState({
                pickedPages,
            });
        }
    }

    private removePicked = () => {
        if (this.props.pickForm && this.state.pickedPages) {
            this.props.pickForm([]);

            this.setState({
                pickedPages: []
            });
        }
    }

}

export const Picker = withI18n(APP_TRANSLATIONS)(PickerComponent);
