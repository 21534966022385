import React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Styled } from "./styles";
import { Button } from "../../../components";
import { withI18n, WithI18nProps } from "@7egend/web.core/lib/components/withI18n";
import { Trans } from "@7egend/web.core/lib/components/i18n";
import { APP_TRANSLATIONS } from "../../../locale";
import { CorePureComponent } from "@7egend/web.core";
import { I18N_KEY_CORE_COMPONENTS_NOT_FOUND } from "../../../base/i18n";

const IMAGE_PLACEHOLDER = "https://cdn.vcf.development.7egend.cr/thumbs/2x/bD2QS29EzWJWGYoqAhhxc2KHd9Qua0XfKQezpSQEMQIcZP15GRWWINlOouEUkhWK.png";

interface NotFoundProps {
    title?: string;
    message?: string;
}

const I18N_NAMESPACE = I18N_KEY_CORE_COMPONENTS_NOT_FOUND

/**
 * # Not Found Page
 * ### 404
 */
class NotFoundComponent extends CorePureComponent<RouteComponentProps & NotFoundProps & WithI18nProps> {
    public render() {
        const { title, message, t } = this.props;
        return (
            <Styled.Wrapper>
                {
                    IMAGE_PLACEHOLDER &&
                    <React.Fragment>
                        <Styled.Image src={IMAGE_PLACEHOLDER} />
                        <Styled.Title>
                            {
                                title
                                    ? title
                                    : t(`${I18N_NAMESPACE}.errorMessage`)
                            }
                        </Styled.Title>
                        <Styled.Description variant="neutral">
                            {
                                message
                                    ? message
                                    : t(`${I18N_NAMESPACE}.errorDescription`)
                            }
                        </Styled.Description>
                        <Button layout="main" layoutFill icon="home" layoutIconPosition="left" onClick={() => this.props.history.push(`/${this.fw.i18n.getCurrentLanguage()}/`)}><Trans id={`${I18N_NAMESPACE}.returnHome`} /></Button>
                    </React.Fragment>
                }
            </Styled.Wrapper>
        )
    }
}

export const NotFound = withRouter(withI18n(APP_TRANSLATIONS)(NotFoundComponent));
