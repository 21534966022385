import ImagePlaceholder from "./assets/placeholder_image.svg";
import ImagePanoramicPlaceholder from "./assets/placeholder_image_panoramic.svg";
import VideoPlaceholder from "./assets/placeholder_video.svg";
import DocumentPlaceholder from "./assets/placeholder_document.svg";
import { getSystemLocaleShortDateFormat } from "./utils/dateTimeSystem";

//#region Grid
/** Default value to gutter of the Grid System */
export const GridGutter = 24;
/** Default number of grid columns */
export const GridColumns = 12;
/** Default Grid Maximum (Container width) */
export const GridMaxWidth = 1632;
//#endregion

//#region Media
/** Default Size Limit of Upload */
export const LimitSizeUpload = 1048576; // 1MB in Bytes
/** Default placholders */
export const DEFAULT_PLACHOLDERS = {
    IMAGE: ImagePlaceholder,
    IMAGE_PANORAMIC: ImagePanoramicPlaceholder,
    VIDEO: VideoPlaceholder,
    DOCUMENT: DocumentPlaceholder
}
//#endregion

//#region People
export const GENDER_OPTIONS = [
    {
        label: "Unknown",
        value: "1",
        option: {
            value: "1",
        },
    },
    {
        label: "Male",
        value: "2",
        option: {
            value: "2",
        },
    },
    {
        label: "Female",
        value: "3",
        option: {
            value: "3",
        },
    }
];
//#endregion

//#region DateTime
export const DATE_FORMAT = getSystemLocaleShortDateFormat()
export const DATETIME_FORMAT = `${DATE_FORMAT} HH:mm`

export const DATE_FORMAT_API = "YYYY-MM-DD"
export const DATETIME_FORMAT_API = "YYYY-MM-DD HH:mm"
//#endregion
