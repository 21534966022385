/** Based on Bootstrap Grid System */
export const breakingPoints = {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
}

export const queries = {
    /**
     * Extra Small Screen / Phone
     */
    /** Minimum XS (0) */
    minXS: `@media (min-width: ${breakingPoints.xs}px)`,
    /** Minimum XS (0) */
    xs: `@media (min-width: ${breakingPoints.xs}px)`, // shortcut of minXS
    /** Maximum XS (575) */
    maxXS: `@media (max-width: ${(breakingPoints.sm - 1)}px)`,

    /** 
     * Small Screen / Phone
     */
    /** Minimum SM (576) */
    minSM: `@media (min-width: ${breakingPoints.sm}px)`,
    /** Minimum SM (576) */
    sm: `@media (min-width: ${breakingPoints.sm}px)`,
    /** Maximum SM (767) */
    maxSM: `@media (max-width: ${(breakingPoints.md - 1)}px)`,
   
    /**
     * Medium Screen / Tablet
     */
    /** Minimum MD (768) */
    minMD: `@media (min-width: ${breakingPoints.md}px)`,
    /** Minimum MD (768) */
    md: `@media (min-width: ${breakingPoints.md}px)`,
    /** Maximum MD (991) */
    maxMD: `@media (max-width: ${(breakingPoints.lg - 1)}px)`,

    /**
     * Large Screen / Desktop
     */
    /** Minimum LG (992) */
    minLG: `@media (min-width: ${breakingPoints.lg}px)`,
    /** Minimum LG (992) */
    lg: `@media (min-width: ${breakingPoints.lg}px)`,
    /** Maximum LG (1199) */
    maxLG: `@media (max-width: ${(breakingPoints.xl - 1)}px)`,

    /**
     * Extra Large Screen / Wide Desktop
     */
    /** Minimum XL (1200) */
    minXL: `@media (min-width: ${breakingPoints.xl}px)`,
    /** Minimum XL (1200) */
    xl: `@media (min-width: ${breakingPoints.xl}px)`,
}
