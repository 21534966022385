import { BaseModel } from "@7egend/web.core.dlos";
import { Medium } from "./medium";
import { DloInput, DloOutput } from "@7egend/web.core/lib/extensions/dlo";
import { Taggable } from "@7egend/web.core.dlos/lib/dlos/tags";

export interface Album extends BaseModel, Taggable {
    uuid: string;
    title: string;
    description: string;
    itemsCount: number;
    cover: Medium;
    date: string;
    media?: Medium[];
}

export class AlbumGetBySlugInput extends DloInput<{
    slug: string,
}> {
    public uri = "albums/${slug}/public?fields=${fields}"
    public fields = "uuid,cover(uuid,image,thumb),title,date,description,items_count,media(uuid,image,thumb,medium_type,url,panoramic),tags,tag_relations,categories(id,name)"
}

export class AlbumGetRelatedInput extends DloInput<{
    slug: string,
}> {
    public uri = "albums/${slug}/related?fields=${fields}"
    public fields = "uuid,cover(uuid,image,thumb),title,date,description,items_count,media(uuid,image,thumb,medium_type,url,panoramic),categories(id,name)"
}

export class AlbumGetRelatedOutput extends DloOutput<Album[]> {

}
