import React from "react";
import { CorePureComponent } from "@7egend/web.core"
import styled, { CSSProperties } from "styled-components";
import { DropdownCardProps } from "../DropdownCard/DropdownCard";

interface WithPortalProps extends Pick<DropdownCardProps, "position" | "align"> {
    anchorTo: React.RefObject<any>;
    onClick?: () => void;
    children: (position: DropdownCardProps["position"], align: DropdownCardProps["align"]) => any;
}

const StyledPortal = styled.div`
    position: absolute;
    z-index: 100;
`

export class WithPortal extends CorePureComponent<WithPortalProps> {

    private readonly window = this.fw.dom.getGlobal();
    private readonly portalRef = React.createRef<React.ReactElement>() as any;
    private readonly DEFAULT_MARGIN: number = 16;

    public componentDidMount() {
        if (this.window) {
            this.window.addEventListener('resize', this.forceRender);
            this.window.addEventListener('scroll', this.forceRender);
        }

        this.forceRender();
    }

    public componentWillUnmount() {
        this.window.removeEventListener("resize", this.forceRender);
        this.window.removeEventListener("scroll", this.forceRender);
    }

    public render() {

        if (!this.window) {
            return null;
        }

        const { styles, position, align } = this.calcPosition();

        return (
            <StyledPortal
                className="with-portal"
                ref={this.portalRef}
                style={styles}
            >
                {this.props.children(position, align)}
            </StyledPortal>
        )
    }

    /** Sets the children position on window */
    private calcPosition = () => {
        let { align } = this.props;
        const { position, anchorTo } = this.props;


        if (!this.portalRef.current || !anchorTo.current) {
            return {
                position,
                align,
                styles: {
                    visibility: "hidden"
                } as CSSProperties,
            };
        }

        const styles: CSSProperties = {};

        const scrollY = (this.window.scrollY !== undefined) ? this.window.scrollY : this.window.pageYOffset
        const scrollX = (this.window.scrollX !== undefined) ? this.window.scrollX : this.window.pageXOffset

        /** anchor item sizes */
        const elBounding = anchorTo.current.getBoundingClientRect();

        const top = scrollY + elBounding.top
        const left = scrollX + elBounding.left

        const elWidth = anchorTo.current.clientWidth;
        const elHeight = anchorTo.current.clientHeight;

        const newWidth = this.portalRef.current.offsetWidth
        const newHeight = this.portalRef.current.offsetHeight

        switch (position) {
            case "bottom":
                styles.top = top + elHeight + (this.DEFAULT_MARGIN / 2);
                break;
            case "top":
                styles.top = top - newHeight - this.DEFAULT_MARGIN
                break;
            default:
                styles.top = top + elHeight + (this.DEFAULT_MARGIN / 2);
                break;
        }

        if (left < newWidth) {
            align = "left";
        }

        switch (align) {
            case "right":
                styles.left = left - newWidth + (this.DEFAULT_MARGIN * 2) + elWidth / 2;
                break;
            case "left":
                styles.left = left + elWidth / 2 - this.DEFAULT_MARGIN + (- elWidth / 2 + 8);
                break;
            case "center":
                styles.left = left - (newWidth / 2) + (elWidth / 2) + this.DEFAULT_MARGIN / 2;
                break;
            default:
                styles.left = left - (newWidth / 2) + (elWidth / 2) + this.DEFAULT_MARGIN / 2;
                break;
        }

        return {
            position,
            align,
            styles
        };
    };

    private forceRender = () => {
        this.forceUpdate();
    }
}
