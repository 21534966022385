import React from "react";
import { CorePureComponent } from "@7egend/web.core"
import styled from "styled-components";
import { Link } from "@7egend/web.core.controls/lib/components/link"
import { colors, font, queries } from "../../../styles"
import { Container } from "../Container";
import { Language } from "../../Language";
import { Typography } from "../../Typography";
import { Config } from "../../../base/config";
import { ConfigFlags } from "../../../base/flags";

interface FooterConfig {
    /** available languages */
    languages: string[];

    /** updates the current language */
    updateLanguage: (currentLanguage: string) => void;

    /** current language */
    currentLanguage: string;
}


export interface FooterProps extends FooterConfig { }

const StyledFooter = styled.footer`
    position: relative;
    width: calc(100% - 284px);
    margin-left: 284px;
    min-height: 64px;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid ${colors.neutral.medium};
    margin-top: 48px;

    ${queries.maxMD} {
        width: 100%;
        margin-left: 0;
    }

    ${queries.maxXS} {
        flex-direction: column-reverse;
        justify-content: space-evenly;
        border: 0;
        padding-bottom: 14px;
        min-height: 1px;
        margin-top: 32px;
    }
`

const StyledFooterActionsLink = styled(Link)`
    color: ${colors.neutral.dark};
    font-size: ${font.size.sm};
    font-weight: ${font.weight.light};
    text-decoration: none;

    &:hover {
        color: ${colors.neutral.darker};
    }

    &:last-of-type {
        margin-right: 20px;
    }
`

const StyledFooterActions = styled.div`
    display: inline-flex;
    align-items: center;

    ${StyledFooterActionsLink} {
        margin-left: 30px;
    }

    ${queries.maxXS} {
        border-bottom: 1px solid ${colors.neutral.medium};
        width: 100%;
        justify-content: center;
        padding-bottom: 14px;
        margin-bottom: 14px;
    }
`

/**
 * # Footer Component
 */
export class Footer extends CorePureComponent<FooterProps> {
    public render() {
        /** Props export */
        const { currentLanguage, languages, updateLanguage } = this.props;
        /** Current Year */
        const CURRENT_YEAR = new Date().getFullYear();
        const flags = (this.fw.config as Config).flags as ConfigFlags

        return (
            <Container fluid>
                <StyledFooter>
                    <Typography.RegularText variant="neutral">Copyright © {CURRENT_YEAR} 7egend.cr</Typography.RegularText>
                    <StyledFooterActions>
                        {flags.core_wiki && (
                            <StyledFooterActionsLink>Wiki</StyledFooterActionsLink>
                        )}
                        {flags.core_terms && (
                            <React.Fragment>
                                <StyledFooterActionsLink>About</StyledFooterActionsLink>
                                <StyledFooterActionsLink>Terms</StyledFooterActionsLink>
                            </React.Fragment>
                        )}
                        <Language
                            align="center"
                            position="top"
                            languages={languages}
                            currentLanguage={currentLanguage}
                            updateLanguage={updateLanguage}
                            showLabel={true}
                        />
                    </StyledFooterActions>
                </StyledFooter>
            </Container>
        )
    }
}
