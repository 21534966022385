import React, { PureComponent } from "react";
import { Props as CoreButtonProps } from "@7egend/web.core.controls/lib/components/button/button";
import { Spinner, SpinnerSize } from "../Spinner/Spinner";
import { StyledButton, StyledButtonSpinner, StyledIcon } from "./styles";

export type ButtonLayouts = "main" | "error" | "warning" | "alt" | "light";
export type IconPositions = "left" | "right";

export interface ButtonProps extends React.PropsWithChildren<CoreButtonProps> {
    /** button layout */
    layout?: ButtonLayouts;
    /** button background filled @default false */
    layoutFill?: boolean;
    /** button border @default false */
    layoutOutline?: boolean;
    /** button background color faded @default false */
    layoutFaded?: boolean;
    /** button icon position */
    layoutIconPosition?: IconPositions;
    /** button round @default false */
    layoutRound?: boolean;
    /** button square style */
    layoutSquare?: boolean;
    /** full width */
    fullWidth?: boolean;
    /** icon */
    icon?: string;
    /** is loading */
    isLoading?: boolean;
    /** set hover style active */
    isFocused?: boolean;
}

interface LoadingComponentProps {
    size?: SpinnerSize;
    icon?: boolean;
    iconPosition?: IconPositions;
    layout?: ButtonLayouts;
    layoutFill?: boolean;
    layoutRound?: boolean;
    layoutSquare?: boolean;
    layoutOutline?: boolean;
    layoutFaded?: boolean;
}

const LoadingComponent: React.FC<LoadingComponentProps> = ({
    size,
    icon,
    iconPosition,
    children,
    layout,
    layoutFill,
    layoutRound,
    layoutSquare,
    layoutFaded,
    layoutOutline,
}): React.ReactElement => {
    return (
        <StyledButtonSpinner
            layout={layout}
            layoutFill={layoutFill}
            layoutRound={layoutRound}
            layoutSquare={layoutSquare}
            layoutFaded={layoutFaded}
            layoutOutline={layoutOutline}
            icon={icon}
            iconPosition={iconPosition}
        >
            {children}
            <Spinner size={size} />
        </StyledButtonSpinner>
    );
};

/**
 * # Button Component
 *
 * ## How to use
 *
 * ```js
 * <Button>BUTTON TEXT</Button>
 * ```
 *
 * ### Props Available
 *
 * #### Styling Props
 *  `layout`: this prop is used to set the layout of your button
 *  `layoutFill`: this prop is used to set the background color of your button
 *  `layoutOutline`: this prop is used to set the border line to your button
 *  `layoutFaded`: this prop is used to set the background color in faded style
 *  `layoutIconPosition`: this prop is used to set the position of the icon on your button
 *  `layoutRound`: this prop is used to set your button with rounded style
 *  `layoutSquare`: this prop is used to set your button with square style
 *
 * #### Default Props
 *  `target`: [add documentation here]
 *
 *  `isLoading`: this is great to your beautiful spinner
 *
 *  `isFocused`: this is great to set active the hover style on your button
 *
 *  `inputSubmit`: this will render one <button> with **type="submit"**
 *
 *  `disabled`: this will add one class and the **attr** to your component
 *
 */
export class Button extends PureComponent<ButtonProps> {
    /** default props */
    public static defaultProps: ButtonProps = {
        isLoading: false,
        isFocused: false,
        inputSubmit: false,
        disabled: false,
        icon: "",
    };

    public render() {
        return (
            <StyledButton
                isFocused={this.props.isFocused}
                isLoading={this.props.isLoading}
                layout={this.props.layout}
                loadingComponent={
                    <LoadingComponent
                        {...this.props}
                        icon={this.props.icon ? true : false}
                        iconPosition={this.props.layoutIconPosition}
                        size="tiny"
                    >
                        {this.props.children}
                    </LoadingComponent>
                }
                {...this.props}
                disabled={this.props.disabled || this.props.isLoading}
                icon={this.props.icon && <StyledIcon icon={this.props.icon} />}
            />
        );
    }
}
