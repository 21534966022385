import React from "react";
import { CorePureComponent } from "@7egend/web.core";
import { AclContext, AclContextConsumer } from "../modules/core/AclContext";

/** CanView props */
export interface CanViewProps {
    /** component to render if user has permission */
    children?: React.ReactNode;
    /** action to validate in user permission's list */
    action: string;
}

/** Function that validate if current user has role to see something */
function checkIfUserCanView(action: string, context?: string[]) {

    /** Return true if project won't use ACL */
    if (context === undefined) {
        return true;
    }

    let actions = action;

    while (actions !== "") {
        if (context.includes(actions)) {
            return true;
        }
        actions = actions.substring(0, actions.lastIndexOf(".") + 0);
    }

    return false;
}

/**
 * # CanView Component
 *
 * ## How to use:
 *
 * ```tsx
 *  <CanView action="actionName">
 *      {children}
 *  </CanView>
 * ```
 *
 */
export class CanView extends CorePureComponent<CanViewProps> {
    public render() {
        const { children, action } = this.props;

        return (
            <AclContextConsumer>
                {context => {
                    if (checkIfUserCanView(action, context)) {
                        return children;
                    }
                    return null;
                }}
            </AclContextConsumer>
        )
    }
}

/**
 * # CanView Hook
 *
 * ## How to use:
 *
 * ```tsx
 *  const canView = useCanView("action.name");
 *  if(canView) show()/return;
 * ```
 *
 */
export function useCanView(action: string) {
    const context = React.useContext(AclContext);
    return checkIfUserCanView(action, context);
}

/**
 * # CanView Hoc
 *
 * ## How to use:
 *
 * ```tsx
 *  import { withCanView, WithCanViewProps } from "components/CanView";
 *  const canView = this.props.canView("action.name");
 *  if(canView) show()/return;
 *  export const ComponentName = withCanView(Component);
 * ```
 *
 */
export interface WithCanViewProps {
    canView: (action: string) => boolean;
}

export const withCanView = <P extends any>(
    WrappedComponent: React.ComponentType<P>,
): React.ComponentType<Omit<P, keyof WithCanViewProps>> => {
    const HOC: React.FC<Omit<P, keyof WithCanViewProps>> = (props) => {
        const context = React.useContext(AclContext);
        const canView = React.useCallback((action: string) => {
            return checkIfUserCanView(action, context);
        }, [context]);
        return (
            <WrappedComponent
                canView={canView}
                {...(props as any)}
            />
        );
    };

    return HOC;
}
