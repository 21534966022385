import {
    DloInput,
    DloOutput,
    buildQLQuery,
    flattenComplexValues,
    QueryQLParams,
} from "@7egend/web.core/lib/extensions/dlo";
import { Booster as BoostersCore } from "@7egend/web.core.cms/lib/dlos/booster";
import { Visibility } from "../core/channel";

interface BoostersInputParams extends Boosters {
    /** current language */
    locale: string;
}

export interface Boosters extends BoostersCore, Visibility {}

const BOOSTERS_FIELDS: QueryQLParams = [
    "uuid",
    "name",
    ["header",
        ["uuid", "medium_type", "image", "thumb"]
    ],
    "line1",
    "line2",
    "line3",
    "style",
    "type",
    ["booster_media",
        ["format", "device_type",
            ["medium",
                ["uuid", "medium_type", "image", "thumb"]
            ]
        ]
    ],
];


/**
 * # Boosters Get all
 */
export class BoostersGetAllInput extends DloInput<{}> {
    public timeStamp = Date.now();
    public authentication = true;
    public uri = "cms-boosters/datatables?draw=${timeStamp}";
}

/**
 * # Boosters Delete
 */
export class BoostersDeleteInput extends DloInput<{
    uuid: string | number;
}> {
    public method = "DELETE";
    public uri = "cms-boosters/${uuid}";
}

/**
 * # Boosters Create <Input>
 */
export class BoostersCreateInput extends DloInput<BoostersInputParams> {
    public method = "POST";
    public uri = "cms-boosters";
    public fields = buildQLQuery(BOOSTERS_FIELDS);
    public transform = (input: any) => {
        flattenComplexValues(input.body, "header", "uuid");
        input.body.fields = input.fields;
        return input;
    };
}

export class BoostersUpdateInput extends DloInput<
    BoostersInputParams & {
        uuid: string;
    }
    > {
    public method = "PUT";
    public uri = "cms-boosters/${uuid}";
    public fields = buildQLQuery(BOOSTERS_FIELDS);
    public transform = (input: any) => {
        flattenComplexValues(input.body, "header", "uuid");
        input.body.fields = input.fields;
        return input;
    };
}

const BoostersGetBySlugFields: QueryQLParams = [
    "name",
    ["header",
        ["uuid", "medium_type", "image", "thumb"]
    ],
    "line1",
    "line2",
    "line3",
    "call_to_action",
    "link",
    "anchor_target",
    "style",
    ["booster_media",
        ["format", "device_type",
            ["medium",
                ["uuid", "medium_type", "image", "thumb"]
            ],
        ]
    ],
    ["type",
        ["id", "name"]
    ],
    ["visibility",
        ["app", "website"]
    ],
];

export class BoostersGetBySlugInput extends DloInput<{
    slug: number | string;
    locale: string;
}> {
    public uri = "cms-boosters/${slug}?fields=${fields}";
    public fields = buildQLQuery(BoostersGetBySlugFields);
}

/** Album Create <Output> */
export class BoostersCreateOutput extends DloOutput<Boosters> { }
