import React from "react";
import { Block } from "@7egend/web.core.cms/lib/dlos/block"
import { BlockComponent } from "./interfaces"
import { Row, Column } from "../Structure"

export interface BlockWrapperProps extends React.ComponentPropsWithRef<any> {
    /** Block data */
    data: Block
    /** On Change callback */
    onChange?: (nextBlock: Block, prevBlock: Block) => void
    /** Draggable block position */
    position: number;
}

/**
 * BlockWrapper component.
 * Provides an interface between the Block drawer and the Block component by giving only what is needed
 * @param TargetComponent The target component to be rendered
 */
export const BlockWrapper: (TargetComponent: BlockComponent) => React.FC<BlockWrapperProps> = (TargetComponent) => React.memo(React.forwardRef((props, ref) => {

    // Set imperative handle for red
    React.useImperativeHandle(ref, () => ({
        getData: () => props.data
    }), [props.data])

    // Set the callback action
    const updateData = React.useCallback((newData: any) => {
        if (props.onChange) {
            props.onChange(newData, props.data)
        }
    }, [props.data])

    return (
        <Row>
            <Column>
                <TargetComponent
                    position={props.position}
                    data={props.data}
                    updateData={updateData}
                />
            </Column>
        </Row>
    )
}))
