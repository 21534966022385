import React, { PureComponent } from "react"
import { Styled } from "./styles";
import { Typography } from "../Typography";
import { Label, LabelLayout } from "../Label";
import { debounce } from "@7egend/web.core/lib/utils";
import { Dropdown } from "../Dropdown";

interface ChipsProps {
    /**
     * Input label
     */
    label?: string

    /**
     * Input placeholder
     */
    placeholder?: string

    /** handle item */
    handleItem: (item: string) => void;

    /**
     * ## fetch options
     * 
     * ### When is it called?
     * - On Update of the component, every time the value of the input is changed;
     * 
     * ### What it returns?
     * - An array of strings, string[]
     * 
     * ### What `search` param means?
     * - `search` is the param that is used to do the fetch every time you type on it.
     */
    fetchOptions: (
        search: string,
    ) => Promise<string[]>

    checkedOptions?: string[]
}

interface ChipsState {
    /** value searched */
    value: string;
    /** array of items that the fetch returns */
    options: string[];
    /** dropdown visibility */
    dropdownVisible: boolean
}

/** Chips OPTION Mini Component */
const ChipsOption: React.FC<
    {
        option: string;
        onClick: (option: string) => void
    }> = ({ option, onClick }) => {

        const addOption = () => {
            onClick(option);
        }

        return (
            <Styled.Option
                key={option}
                layout={LabelLayout.Default}
                onClick={addOption}
            >{option}</Styled.Option>
        )
    }

/** Checked Options Mini Component */
const CheckedOption: React.FC<
    {
        option: string,
        onClick: (option: string) => void
    }> = ({ option, onClick }) => {
        const removeOption = () => {
            onClick(option);
        }

        return (
            <Label
                key={option}
                icon="delete"
                layout={LabelLayout.Default}
                iconClick={removeOption}
            >{option}</Label>
        )
    }

/**
 * # Chips Component
 * 
 * # How to use:
 * 
 * ```jsx
 * <Chips
 * 
 * />
 * ```
 */
class ChipsComponent extends PureComponent<ChipsProps, ChipsState> {

    public state: ChipsState = {
        value: "",
        options: [],
        dropdownVisible: false,
    }

    public componentDidUpdate(prevProps: ChipsProps, prevState: ChipsState) {
        if (prevState.value !== this.state.value) {
            this.fetchOptions();
        }
    }

    public render() {
        const { label, checkedOptions } = this.props;
        const { options, dropdownVisible } = this.state;

        return (
            <Styled.Chips>
                {label && <Typography.SmallCapsTitle>{label}</Typography.SmallCapsTitle>}
                <Dropdown
                    align="left"
                    position="bottom"
                    attachOnBody
                    styles={{
                        maxWidth: "100%",
                    }}
                    active={dropdownVisible && this.state.value ? true : false}
                    component={
                        <Styled.Wrapper>
                            {checkedOptions && checkedOptions.length > 0 &&
                                <Styled.Labels>
                                    {checkedOptions.map(option => (
                                        <CheckedOption
                                            key={option}
                                            option={option}
                                            onClick={this.removeOption}
                                        >{option}</CheckedOption>
                                    ))}
                                </Styled.Labels>
                            }
                            <Styled.Input
                                name="chips"
                                type="text"
                                placeholder={this.props.placeholder}
                                value={this.state.value}
                                onKeyDown={this.onKeyDown}
                                onChange={this.handleInputChange}
                            />
                        </Styled.Wrapper>
                    }
                >
                    <Styled.Options>
                        {options && options.map(option => (
                            <div>
                                <ChipsOption
                                    key={option}
                                    option={option}
                                    onClick={this.addOption}
                                />
                            </div>
                        ))}
                    </Styled.Options>
                </Dropdown>
            </Styled.Chips>
        )
    }

    /** Remove Option from Array */
    private removeOption = (option: string) => {
        this.props.handleItem(option);
    }

    /** Add Option to Array */
    private addOption = (option: string) => {
        if (option && this.props.checkedOptions && this.props.checkedOptions.indexOf(option) < 0) {
            this.props.handleItem(option);

            this.setState({
                value: "",
                dropdownVisible: false,
            })
        }
    }

    /** On Input Change */
    private handleInputChange = (value: string) => {
        this.setState({ value })
    }

    private fetchOptions = debounce(() => {
        this.props.fetchOptions(this.state.value)
            .then(res => {
                this.setState({
                    options: res,
                    dropdownVisible: res.length > 0 ? true : false,
                })
            })
    }, 300);

    private onKeyDown = (key: string) => {
        const { value } = this.state;

        if (key === "Enter" && (value && this.props.checkedOptions && this.props.checkedOptions.indexOf(value) < 0)) {

            this.props.handleItem(value);

            this.setState({
                value: "",
                dropdownVisible: false,
            });
        }
    }
}

export const Chips = ChipsComponent
