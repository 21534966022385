import React, { PureComponent } from "react"
import styled, { css } from "styled-components";
import { font, queries, colors } from "../../../styles";
import { TypographyProps } from "../interface"

export interface HeadlineProps extends React.PropsWithChildren<TypographyProps> { }

const StyledHeadline = styled.h2<HeadlineProps>`
    font-size: ${font.size.xl};
    font-weight: ${font.weight.bold};

    ${queries.maxXS} {
        font-size: ${font.size.lg};
    }

    ${(props: HeadlineProps) => props.variant === "regular" && css`
        color: ${colors.neutral.darker};
    `}

    ${(props: HeadlineProps) => props.variant === "primary" && css`
        color: ${colors.primary.lighter};
    `}

    ${(props: HeadlineProps) => props.variant === "neutral" && css`
        color: ${colors.neutral.dark};
    `}
`

export class Headline extends PureComponent<HeadlineProps> {
    public render() {
        return (
            <StyledHeadline
                className={this.props.className}
                variant={this.props.variant}
            >
                {this.props.children}
            </StyledHeadline>
        )
    }
}
