import React, { Component } from "react"
import classNames from "classnames";
import styled from "styled-components";
import {
    RangeSelector as CoreRangeSelector,
    RangeSelectorProps as CoreRangeSelectorProps
} from "@7egend/web.core.controls/lib/components/rangeSelector/rangeSelector";
import { colors } from "../../styles";

export interface RangeSelectorProps {
    /** range selector values */
    values: number[];
    /** range selector step
     * @default 1
     */
    step?: number;
    /** range selector min
     * @default 0
     */
    min: number;
    /** range selector max
     * @default 100
     */
    max: number;
    /** range selector onChange event */
    onChange: (values: number[]) => void;
    /** range selector disabled */
    disabled?: boolean;
}

/**
 * Note: This component is not fully as the styled component way, because the component on Core is a external component.
 *
 * So the only way to style it was to set classes to the component.
 * In the future we can use the styled-component in the core component, and then refactor this component ;)
 */
const StyledWrapper = styled.div`
    width: 100%;
    position: relative;
    height: auto;

    .range-selector {
        width: 100%;
        position: relative;
        height: auto;

        .bar {
            position: relative;
            background-color: ${colors.neutral.light};
            height: 2px;
            width: 100%;
            border-radius: 2px;
        }

        .bar-shadow {
            height: 2px;
            width: 1px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            background-color: ${colors.primary.lighter};
            box-shadow: 0 2px 8px 0 rgba(20, 192, 196, 0.6);
            z-index: 1;
        }

        .bar-shadow--disabled {
            box-shadow: none;
            background-color: ${colors.neutral.medium};
        }

        .thumb {
            width: 12px;
            height: 12px;
            box-shadow: 0 2px 4px 0 rgba(20, 192, 196, 0.4);
            background-color: ${colors.primary.lighter};
            border: none;
            box-sizing: border-box;
            border-radius: 100%;
            transition: box-shadow .1s ease-in-out;
            cursor: pointer !important; // to override default style
            z-index: 2 !important; // to override default style

            &:hover:not(.thumb--disabled) {
                box-shadow: 0 0 0 5px rgba(20, 192, 196, 0.2);
            }
        }

        .thumb--disabled {
            box-shadow: 0 0 0 3px ${colors.neutral.lighter};
            background-color: ${colors.neutral.medium};

            &:hover {
                cursor: not-allowed !important;
            }
        }
    }
`

/**
 * # range selector Component
 * This class represents the input type range selector component
 *
 * #How to use
 *
 * ```tsx
 * <RangeSelector step={1} min={0} max={100} onChange={() => {}} values={[0, 0]} disabled={true|false} />
 * ```
 *
 */
export class RangeSelector extends Component<RangeSelectorProps> {
    public render() {
        const { values, min, max, step, disabled, onChange } = this.props;
        const rangeSelectorThumbClasses = classNames("thumb", { ["thumb--disabled"]: disabled });
        const rangeSelectorShadowClasses = classNames("bar bar-shadow", { ["bar-shadow--disabled"]: disabled });
        const colorHighlight = !disabled ? colors.primary.lighter : colors.neutral.medium;
        const colorDefault = colors.neutral.light;

        return (
            <StyledWrapper>
                <CoreRangeSelector
                    values={values}
                    min={min}
                    max={max}
                    step={step}
                    onChange={onChange}
                    className="range-selector"
                    disabled={disabled}
                    track={{
                        className: "bar",
                        colors: [values.length > 1 ? colorDefault : "", colorHighlight, colorDefault],
                        shadowClass: rangeSelectorShadowClasses,
                    }}
                    thumb={{
                        className: rangeSelectorThumbClasses,
                    }}>
                </CoreRangeSelector>
            </StyledWrapper>
        );
    }
}
