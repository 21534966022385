import React from "react";
import { CorePureComponent } from "@7egend/web.core";
import { Row } from "../../Structure";
import { colors, queries } from "../../../styles";
import { Button } from "../../Button";
import styled from "styled-components";
import { Block } from "@7egend/web.core.cms/lib/dlos/block";
import { BlockInfo } from "../BlocksEditor";
import { translateStringOrObject } from "../../../utils";
import { withI18n, WithI18nProps } from "@7egend/web.core/lib/components/withI18n";

const StyledTypesContainer = styled.div`
    display: flex;
    flex-flow: row wrap;
    list-style: none;
    width: 100%;
	background-color: ${colors.neutral.lighter};
    border-radius: 10px;
    padding-top: 7px;
    padding-bottom: 7px;
    justify-content: flex-start;
`;

const StyledBlockType = styled.div`
    margin-top: 7px;
    margin-bottom: 7px;
    border-left: 1px solid ${colors.neutral.light};
    display: flex;
    flex-basis: 50%;

    ${queries.minSM} {
        flex-basis: 33.333%;
    }

    ${queries.minMD} {
        flex-basis: 25%;
    }

    ${queries.minLG} {
        flex-basis: 50%;
    }
    
    @media (min-width: 1300px) {
        flex-basis: 33.333%;
    }

    @media (min-width: 1600px) {
        flex-basis: 20%;
    }

    &:first-child {
        border-left: none;
    }

    i {
        color: ${colors.primary.lighter};
    }
`;

const StyledTypeButton = styled(Button)`
    min-height: 0;
    padding: 0 24px;
    text-align: left;
`;

interface ButtonsProps {
    /**
     * List of blocks to load
     */
    blocksList: BlockInfo[];

    /**
     * Function to add blocks structure
     */
    addBlock: (type: Block) => void;
}

class ButtonsComponent extends CorePureComponent<ButtonsProps & WithI18nProps> {
    public render() {
        const { t, language } = this.props;

        return (
            <Row>
                <StyledTypesContainer>
                    {this.props.blocksList && this.props.blocksList.length > 0 && this.props.blocksList.map((eachBlock) => {
                        const blockName: string = translateStringOrObject(eachBlock.Button.name, language, t);

                        return (
                            <StyledBlockType key={`${blockName}`}>
                                <StyledTypeButton
                                    icon={eachBlock.Button.icon}
                                    layoutIconPosition={"left"}
                                    text={blockName}
                                    onClick={() => this.props.addBlock(eachBlock.Structure)}
                                />
                            </StyledBlockType>
                        );
                    })}
                </StyledTypesContainer>
            </Row>
        );
    }
}

export const Buttons: any = withI18n()(ButtonsComponent);
