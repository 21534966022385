import React, { PureComponent } from "react"
import styled, { css } from "styled-components";
import { font, colors } from "../../../styles";
import { TypographyProps } from "../interface"

export interface SmallCapsTitleProps extends React.PropsWithChildren<TypographyProps> { }

const StyledSmallCapsTitle = styled.h5<SmallCapsTitleProps>`
    font-size: ${font.size.xs};
    font-weight: ${font.weight.semiBold};
    text-transform: uppercase;
    letter-spacing: 1.4px;

    ${(props: SmallCapsTitleProps) => props.variant === "regular" && css`
        color: ${colors.neutral.darker};
    `}

    ${(props: SmallCapsTitleProps) => props.variant === "primary" && css`
        color: ${colors.primary.lighter};
    `}

    ${(props: SmallCapsTitleProps) => props.variant === "neutral" && css`
        color: ${colors.neutral.dark};
    `}
`

export class SmallCapsTitle extends PureComponent<SmallCapsTitleProps> {
    public render() {
        return (
            <StyledSmallCapsTitle variant={this.props.variant}>
                {this.props.children}
            </StyledSmallCapsTitle>
        )
    }
}
