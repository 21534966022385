import React, { PureComponent } from "react";
import { Image as BaseImage } from "../../../../Image";
import { PLACEHOLDER_IMAGE_PANORAMIC } from "../index"
import { StyledMedialaceholder } from "../styles";

/**
 * 
 * # Image Panoramic Placeholder
 * 
 */
export class ImagePanoramic extends PureComponent {
    public render() {
        return (
            <StyledMedialaceholder>
                <BaseImage src={PLACEHOLDER_IMAGE_PANORAMIC} />
            </StyledMedialaceholder>
        )
    }
}
