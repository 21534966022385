import { BaseModel } from "@7egend/web.core.dlos";
import { DloInput, buildQLQuery, DloOutput, QueryQLParams } from "@7egend/web.core/lib/extensions/dlo";

export enum PollingStatus {
    UNKNOWN = "0",
    OPENED = "1",
    CLOSED = "2",
    SCHEDULED = "3",
}

export interface PollingAnswer extends BaseModel {
    uuid: string;
    position: string;
    text?: string;
}

/** Polling DLO */
export interface Polling extends BaseModel {
    uuid: string;
    votes_count: number;
    name?: string;
    status: PollingStatus;
    start_date?: string;
    end_date?: string;
    title?: string;
}

const POLLING_FIELDS: QueryQLParams = [
    "uuid",
    "name",
    "status",
    "start_date",
    "end_date",
    "title",
    ["answers",
        ["uuid", "position", "text"],
    ],
    ["match",
        ["id", "date",
            ["competition",
                ["name"],
            ],
            ["home_team",
                ["short_name",
                    ["badge",
                        ["image"],
                    ],
                ],
            ],
            ["away_team",
                ["short_name",
                    ["badge",
                        ["image"],
                    ],
                ],
            ],
            "home_team_score",
            "away_team_score",
        ],
    ],
    ["sponsor",
        ["id", "uuid", "acronym", "short_name"],
    ],
    "sponsor_title",
    ["visibility",
        ["app", "website"],
    ],
];

/** Get All Polls [DATATABLE] */
export class PollsGetAllDatatableInput extends DloInput<{}> {
    public timeStamp = Date.now();
    public authentication = true;
    public uri = "polling-polls/datatables?draw=${timeStamp}";
}

/** Create Poll */
export class PollsCreateInput extends DloInput<Polling> {
    public method = "POST";
    public uri = "polling-polls";
    public fields = buildQLQuery(POLLING_FIELDS);
}

/** Update Poll */
export class PollsUpdateInput extends DloInput<Polling> {
    public method = "PUT";
    public uri = "polling-polls/${id}";
    public fields = buildQLQuery(POLLING_FIELDS);
}

/** Delete Poll */
export class PollsDeleteInput extends DloInput<{
    id: string | number;
}> {
    public method = "DELETE";
    public uri = "polling-polls/${id}";
}

/** Get Poll */
export class PollsGetByIdInput extends DloInput<{
    id: number | string;
    locale: string;
}> {
    public uri = "polling-polls/${id}?fields=${fields}";
    public fields = buildQLQuery(POLLING_FIELDS);
}

/** Get Poll Statistics */
export class PollsStatisticsGetByIdInput extends DloInput<{
    id: number | string;
}> {
    public uri = "polling-polls/${id}/statistics";
}

/** Polls Create <Output> */
export class PollsCreateOutput extends DloOutput<Polling> { }
