import React, { FC, useMemo } from 'react';
import styled from "styled-components";
import { MapContainer, MapContainerProps, TileLayer, Marker, Popup, useMapEvents } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import regularIcon from 'leaflet/dist/images/marker-icon.png';
import bigIcon from 'leaflet/dist/images/marker-icon-2x.png';
import L, { LeafletEventHandlerFnMap } from 'leaflet';

interface Size {
    width: number
    height: number
}

export interface OpenStreetMapProps {
    dragging?: boolean
    position: [number, number]
    popup?: string
    zoom?: number
    zoomControl?: boolean
    showMarker?: boolean
    marker?: {
        regular: string
        big: string
        size?: Size
    }
    size?: Size
    style?: React.CSSProperties
    /**
     * New marker position event
     */
    onPositionChange?: (position: [number, number]) => void
}

const MapWrapper = styled(MapContainer) <MapContainerProps>`
    width: ${(props) => (props as any).size?.width || "100%"};
    height: ${(props) => (props as any).size?.height || "300px"};
`;

export const OpenStreetMap: FC<OpenStreetMapProps> = props => {
    const { dragging, popup, position, showMarker, zoom, zoomControl, marker, style, onPositionChange } = props;

    return (
        <MapWrapper
            center={position}
            zoom={zoom || 14}
            zoomControl={zoomControl}
            style={style}
            scrollWheelZoom={zoomControl}
            dragging={dragging}
        >
            <TileLayer
                url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
                attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
            />
            {showMarker &&
                <MapMarker
                    position={position}
                    popup={popup}
                    marker={marker}
                    onChange={onPositionChange}
                />
            }
        </MapWrapper>
    );
};

const MapMarker: FC<Pick<OpenStreetMapProps, "position" | "popup" | "marker"> & { onChange?: OpenStreetMapProps["onPositionChange"] }> = ({ position, marker, popup, onChange }) => {
    const icon = useMemo(() => new L.Icon({
        iconUrl: marker?.regular || regularIcon,
        iconRetinaUrl: marker?.big || bigIcon,
        iconSize: (marker?.size?.width && marker?.size?.height) ? new L.Point(marker.size.width, marker.size.height) : undefined,
		iconAnchor: [12, 41], // hard coded from leaflet source code
    }), [marker])

    useMapEvents({
        click: (e) => {
            if (onChange) {
                onChange([e.latlng.lat, e.latlng.lng])
            }
        },
    })

    const eventHandlers = useMemo<LeafletEventHandlerFnMap>(() => ({
            dragend: (e) => {
                const markerEl = e.target
                if (markerEl != null && onChange) {
                    const latLng = markerEl.getLatLng()
                    onChange([latLng.lat, latLng.lng])
                }
            },
        } as LeafletEventHandlerFnMap), [onChange])

    return (
        <Marker
            position={position}
            icon={icon}
            draggable={true}
            eventHandlers={eventHandlers}
        >
            {popup && <Popup>{popup}</Popup>}
        </Marker>
    )
}
