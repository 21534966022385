import { Module } from "../../../bootstrap";
import loadable from "@loadable/component";

export { Polls } from "./Polls";

export const pollsSetup: Module = {
    routes: [
        // Polls list Page
        {
            component: loadable(async () => (await import('./Polls')).Polls),
            path: "/cms/polls",
            exact: true,
        },
        // Polls Create
        {
            component: loadable(async () => (await import('./PollsEditor')).PollsEditor),
            path: "/cms/polls/add",
        },
        // Polls Update
        {
            component: loadable(async () => (await import('./PollsEditor')).PollsEditor),
            path: "/cms/polls/edit/:id",
        },
        // Polls View Votes
        {
            component: loadable(async () => (await import('./PollsCounter')).PollsCounter),
            path: "/cms/polls/view/:id",
        },
    ],
    menus: [
        {
            title: {
                key: "cms.polls.menu.title",
                en: "Polls",
                es: "Votación",
                pt: "Votação",
            },
            path: "cms/polls",
            permission: "cms.polls.list",
            children: [
                {
                    title: {
                        key: "cms.polls.create.menu.title",
                        en: "Create Polls",
                        es: "Crear Votación",
                        pt: "Criar Votação",
                    },
                    path: "cms/polls/add",
                    visible: false,
                    permission: "cms.polls.add"
                },
                {
                    title: {
                        key: "cms.polls.edit.menu.title",
                        en: "Edit Polls",
                        es: "Editar Votación",
                        pt: "Editar Votação",
                    },
                    path: "cms/polls/edit",
                    visible: false,
                    permission: "cms.polls.edit"
                },
                {
                    title: {
                        key: "cms.polls.statistics.menu.title",
                        en: "Statistics Polls",
                        es: "Estadísticas Votación",
                        pt: "Estatisticas Votação",
                    },
                    path: "cms/polls/view",
                    visible: false,
                    permission: "cms.polls.view"
                }
            ]
        },
    ]
}
