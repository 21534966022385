import { Generic } from "@7egend/web.core";
import { blockTypes as blockTypesDefault } from "../components/BlockDrawer/BlockType/config";

declare var console: any;

export const generateListBlock = (values: string[], position: number) => {
    const list: Array<{}> = [];
    values.forEach((value) => {
        list.push({
            value,
            position,
            style: '1',
        });
    });

    return list;
}

/** Generates each block based on his type */
export const generateBlocks = (blocks: any[], locale: string) => {
    const generic = new Generic();
    const config = (generic.fw.config as any);
    const blockTypes = config.blockTypes ? config.blockTypes : blockTypesDefault;

    const formatedBlocks: any[] = blocks.map((block, i) => {
        if (!block) {
            return undefined
        }

        const commonData = {
            language: locale,
            type: block.type,
            position: i + 1, // position should start at 1
        };

        /** News */
        if (blockTypes.news && blockTypes.news.type === block.type) {
            return {
                ...commonData,
                id: block.content.id,
            }
        }
        /** Title, Text, Highlight, Iframe block */
        else if (blockTypes.text && blockTypes.text.type === block.type) {
            const properties: Array<{ [key: string]: string; }> = [];
            if (block.properties) {
                const objectKeys = Object.keys(block.properties);
                objectKeys.forEach(eachKey => {
                    properties.push({
                        "type": eachKey,
                        "value": block.properties[eachKey],
                    });
                });
            }
            return {
                ...commonData,
                ...block.content,
                properties,
            }
        }
        /** List block */
        else if (blockTypes.list && blockTypes.list.type === block.type) {
            return {
                ...commonData,
                texts: generateListBlock(block.content.values, commonData.position),
            }
        }
        /** Block Container */
        else if (blockTypes.container && blockTypes.container.type === block.type) {
            return {
                ...commonData,
                style: block.content.style,
                blocks: generateBlocks(block.content.blocks, locale),
            }
        }
        /** Boosters Container */
        else if (blockTypes.booster && blockTypes.booster.type === block.type) {
            return {
                ...commonData,
                booster_type: "1",
                ...block.content,
                // origin: "1",
            }
        }
        /** Separator block */
        else if (blockTypes.separator && blockTypes.separator.type === block.type) {
            return {
                ...commonData,
                style: block.content.style,
            }
        }
        /** CTA / Attachment Block */
        else if (blockTypes.cta && blockTypes.cta.type === block.type) {
            return {
                ...commonData,
                ...block.content,
                value: block.properties && block.properties.uuid ? block.properties.uuid : block.content.value || undefined,
                anchor_target: block.properties && block.properties.anchor_target || block.content.anchor_target.id || undefined
            }
        }
        /** HTML block */
        if (blockTypes.html && blockTypes.html.type === block.type) {
            return {
                ...commonData,
                value: block.content.value,
            }
        }
        /** Image and Text block */
        else if (blockTypes.imageAndText && blockTypes.imageAndText.type === block.type) {
            const ImageAndTextProperties: Array<{ [key: string]: string; }> = [];
            if (block.properties) {
                const objectKeys = Object.keys(block.properties);
                objectKeys.forEach(eachKey => {
                    ImageAndTextProperties.push({
                        "type": eachKey,
                        "value": block.properties[eachKey],
                    });
                });
            }
            return {
                ...commonData,
                ...block.content,
                cta_anchor_target: block.content.cta_anchor_target_id,
                picture: block.content.picture && block.content.picture.uuid,
                picture_secondary: block.content.picture_secondary && block.content.picture_secondary.uuid,
                properties: ImageAndTextProperties,
            }
        }
        /** Form block */
        else if (blockTypes.form && blockTypes.form.type === block.type) {
            return {
                ...commonData,
                title: block.content.title,
                secret: block.content.secret,
            }
        }
        /** Widget Block type */
        else if (blockTypes.widget && blockTypes.widget.type === block.type) {
            const newProperties: Array<{ [key: string]: string; }> = [];
            
            if (block.properties) {
                const objectKeys = Object.keys(block.properties);
                objectKeys.forEach((eachKey, index) => {
                    newProperties.push({
                        "type": eachKey,
                        "value": block.properties[eachKey]
                    });
                });
            }

            return {
                ...commonData,
                code: block.content.code,
                properties: newProperties,
            }
        }
        /** Album block */
        else if (blockTypes.album && blockTypes.album.type === block.type) {
            return {
                ...commonData,
                uuid: block.content.uuid,
            }
        }
        /** Medium block */
        else if (blockTypes.medium && blockTypes.medium.type === block.type) {
            return {
                ...commonData,
                uuid: block.content.uuid,
            }
        }
        else {
            // This is unknown, let it go as it is
            // tslint:disable-next-line
            console.warn("Generating unknown block data", block)
            return {
                ...commonData,
                ...block,
            }
        }
    })

    return formatedBlocks.filter(Boolean);
}
