import loadable from "@loadable/component";
import { Config, Store } from "@7egend/web.core";
import { reducer as securityServices } from "@7egend/web.core.security/lib/services"
import { reducer as cmsServices } from "@7egend/web.core.cms/lib/services"
import { services as geoServices } from "@7egend/web.core.geo/lib/services"
import { Module } from "./bootstrap";
import { App } from "./modules/core/App";
import { NotFound } from "./modules/core/NotFound";

interface ConfigWithModules extends Config {
    modules?: Module[];
}

/**
 * Permissions interface
 */
type Permission = string
type Permissions = Permission[]
export interface RolesPermissions { [role: string]: { permissions: Permissions } }

function load(config: ConfigWithModules): ConfigWithModules {
    const routes: object[] = [];

    Store.loadAndRegisterReducer("security", securityServices);
    Store.loadAndRegisterReducer("cms", cmsServices);
    Store.loadAndRegisterReducer("geo", geoServices);

    if (config.modules) {
        config.modules.map(eachModule => {
            eachModule.routes.map(item => {
                routes.push(item);
            });
        });
    }

    config.routes = [
        {
            path: "",
            exact: true,
            redirectTo: "/{language}",
        },
        {
            id: "app",
            path: ":language",
            component: App,
            routes: [
                {
                    path: "login",
                    exact: true,
                    component: loadable(async () => (await import('./modules/core/Login')).Login),
                },
                {
                    id: "shell",
                    path: "",
                    component: loadable(async () => (await import('./modules/core/Shell')).Shell),
                    routes: [
                        ...routes,
                        {
                            path: "*",
                            exact: true,
                            component: NotFound,
                        },
                    ],
                },
            ]
        },
    ];

    return config;
}

export default load;
