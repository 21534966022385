import React from "react";
import { CorePureComponent } from "@7egend/web.core";
import { withRouter, RouteComponentProps } from "react-router";
import { withI18n, WithI18nProps } from "@7egend/web.core/lib/components/withI18n";
import { Trans } from "@7egend/web.core/lib/components/i18n";
import { Image } from "../../Image"
import { Icon } from "../../Icon"
import DXPIcon from "../../../assets/DXP.svg";
import { Dropdown } from "../../Dropdown"
import { Typography } from "../../Typography"
import { Breadcrumb } from "../../Breadcrumb"
import {
    StyledHeader,
    StyledHeaderActions,
    StyledHeaderButton,
    StyledHeaderContent,
    StyledHoverUser,
    StyledLink,
    StyledLogo,
    StyledLogoAndPlatform,
    StyledMenuButton,
    StyledPlatform,
    StyledRightSide,
    StyledUserArea,
    StyledUserAvatar,
    StyledUserAvatarPlaceholder,
    StyledUserButton,
    StyledHoverUserAction,
    StyledHoverUserActions,
    StyledHoverUserLogout,
    StyledUserFavorites
} from "./styles";
import { Divider } from "../../Divider";
import { MenuItems } from "../Menu";
import { User } from "@7egend/web.core.people/lib/dlos";
import { Config } from "../../../base/config";
import { Container } from "../Container";
import { getActivePaths } from "../../../utils";
import { ConfigFlags } from "../../../base/flags";
import { APP_TRANSLATIONS } from "../../../locale";
import { I18N_KEY_CORE_STRUCTURE_HEADER, I18N_KEY_GLOBAL } from "../../../base/i18n";

/** Header Props */
export interface HeaderProps {
    /** to toggle the state of the menu */
    openMenu?: () => void

    /** user data */
    user: User;

    /** logout */
    logout?: () => void

    /** menu items */
    menuItems?: MenuItems[]
}

interface HeaderState {
    userAreaVisible: boolean
    userFavoritesVisible: boolean
}

const I18N_NAMESPACE = I18N_KEY_CORE_STRUCTURE_HEADER

/**
 * # Header Component
 */
class HeaderComponent extends CorePureComponent<HeaderProps & RouteComponentProps<{ language: string }> & WithI18nProps, HeaderState> {

    public state: HeaderState = {
        userAreaVisible: false,
        userFavoritesVisible: false,
    }

    public renderUserAvatar = () => {
        const { user } = this.props;
        const { first_name, last_name, profile } = user;
        const AVATAR_PLACEHOLDER = `${(typeof first_name !== "object") ? first_name.charAt(0) : ""}${(typeof last_name !== "object") ? last_name.charAt(0) : ""}` || `${user.username.charAt(0)}${user.username.charAt(1)}`;
        return (
            <StyledUserAvatar>
                {profile && profile.thumb
                    ?
                    <Image alt={`${first_name} profile image`} src={profile.thumb} />
                    :
                    <StyledUserAvatarPlaceholder>{AVATAR_PLACEHOLDER}</StyledUserAvatarPlaceholder>
                }
            </StyledUserAvatar>
        )

    }

    /** TODO: This component isn't finished yet, user favourites are unfinshed. */
    public render() {
        const { first_name, last_name, username } = this.props.user;
        const clientName = (this.fw.config as Config).client.name;
        const flags = (this.fw.config as Config).flags as ConfigFlags;
        const { t } = this.props;

        return (
            <StyledHeader>
                <Container>
                    <StyledHeaderContent>
                        <Breadcrumb paths={getActivePaths(this.props.location.pathname, this.props.match.params.language, this.props.menuItems || [])} />
                        <StyledHeaderActions>
                            <StyledLogoAndPlatform>
                                <StyledMenuButton icon="menu" onClick={this.openMenu} />
                                <StyledLink href="/">
                                    <StyledLogo alt={this.props.t(`${I18N_KEY_GLOBAL}.title`)} src={DXPIcon} />
                                    <StyledPlatform>
                                        <span className="platform"><Trans id={`${I18N_KEY_GLOBAL}.title`} /></span>
                                        <small className="client">{clientName}</small>
                                    </StyledPlatform>
                                </StyledLink>
                            </StyledLogoAndPlatform>
                            <StyledRightSide>
                                {(flags?.core_search === true) && <StyledHeaderButton icon="search" />}
                                {/* ---- User Favorites ---- */}
                                <Dropdown
                                    position="bottom"
                                    align="center"
                                    active={this.state.userFavoritesVisible}
                                    onClick={this.toggleFavouritesArea}
                                    component={<StyledHeaderButton icon="star" isFocused={this.state.userFavoritesVisible} />}
                                >
                                    <StyledUserFavorites onClick={this.toggleFavouritesArea}>
                                        <StyledHoverUserAction href="/cms/news/add">
                                            <Typography.RegularText variant="regular">{t(`${I18N_NAMESPACE}.favourites.createNews`)}</Typography.RegularText>
                                            <Icon icon="format_align_left" />
                                        </StyledHoverUserAction>
                                        <StyledHoverUserAction href="/cms/albums/add">
                                            <Typography.RegularText variant="regular">{t(`${I18N_NAMESPACE}.favourites.createAlbum`)}</Typography.RegularText>
                                            <Icon icon="photo_library" />
                                        </StyledHoverUserAction>
                                        <StyledHoverUserAction href="/cms/multimedia">
                                            <Typography.RegularText variant="regular">{t(`${I18N_NAMESPACE}.favourites.createVideo`)}</Typography.RegularText>
                                            <Icon icon="videocam" />
                                        </StyledHoverUserAction>
                                        <StyledHoverUserAction href="/cms/dynamic-pages">
                                            <Typography.RegularText variant="regular">{t(`${I18N_NAMESPACE}.favourites.managePages`)}</Typography.RegularText>
                                            <Icon icon="web_asset" />
                                        </StyledHoverUserAction>
                                    </StyledUserFavorites>
                                </Dropdown>
                                {/* ---- User Favorites ---- */}
                                {(flags?.core_notifications === true) && <StyledHeaderButton icon="notifications" />}
                                {/* ---- User Favorites ---- */}
                                <StyledUserArea>
                                    <Dropdown
                                        position="bottom"
                                        align="right"
                                        active={this.state.userAreaVisible}
                                        onClick={this.openUserArea}
                                        component={
                                            <StyledUserButton>
                                                {this.renderUserAvatar()}
                                                <Icon icon={!this.state.userAreaVisible ? "keyboard_arrow_down" : "keyboard_arrow_up"} />
                                            </StyledUserButton>
                                        }
                                    >
                                        <React.Fragment>
                                            <StyledHoverUser>
                                                {this.renderUserAvatar()}
                                                <div>
                                                    <Typography.BoldText>{`${typeof first_name !== "object" ? first_name : ""} ${typeof last_name !== "object" ? last_name : ""}`}</Typography.BoldText>
                                                    <Typography.SmallText variant="neutral">{username}</Typography.SmallText>
                                                </div>
                                            </StyledHoverUser>
                                            <Divider layout="lighter" />
                                            <StyledHoverUserActions>
                                                <StyledHoverUserAction href="/profile" onClick={this.openUserArea}>
                                                    <Typography.RegularText variant="regular">{t(`${I18N_NAMESPACE}.myProfile`)}</Typography.RegularText>
                                                    <Icon icon="person" />
                                                </StyledHoverUserAction>
                                                {/* <StyledHoverUserAction href="/activity">
                                                    <Typography.RegularText variant="regular">My Activity</Typography.RegularText>
                                                    <Icon icon="assessment" />
                                                </StyledHoverUserAction> */}
                                            </StyledHoverUserActions>
                                            <Divider layout="lighter" />
                                            <StyledHoverUserActions>
                                                <StyledHoverUserLogout onClick={this.logout} icon="exit_to_app">
                                                    <Typography.RegularText variant="regular">{t(`${I18N_NAMESPACE}.logout`)}</Typography.RegularText>
                                                </StyledHoverUserLogout>
                                            </StyledHoverUserActions>
                                        </React.Fragment>
                                    </Dropdown>
                                </StyledUserArea>
                            </StyledRightSide>
                        </StyledHeaderActions>
                    </StyledHeaderContent>
                </Container>
            </StyledHeader >
        )
    }

    /** It opens the menu in mobile */
    private openMenu = () => {
        if (this.props.openMenu) {
            this.props.openMenu();
        }
    }

    private openUserArea = () => {
        this.setState({
            userAreaVisible: !this.state.userAreaVisible,
        });
    };

    private toggleFavouritesArea = () => {
        this.setState({
            userFavoritesVisible: !this.state.userFavoritesVisible
        });
    }

    private logout = () => {
        if (this.props.logout) {
            this.props.logout();
        }
    }
}

export const Header: React.ComponentClass<any> = withRouter(withI18n(APP_TRANSLATIONS)(HeaderComponent));
