import { CorePureComponent } from "@7egend/web.core";
import { Trans } from "@7egend/web.core/lib/components/i18n";
import { withI18n, WithI18nProps } from "@7egend/web.core/lib/components/withI18n";
import React, { ComponentClass } from "react"
import { compose } from "redux";
import { I18N_KEY_CORE_COMPONENTS_PAGE_EDITOR } from "../../../base/i18n";
import { CMSConfig } from "../../../modules/cms/config";
import { translateStringOrObject } from "../../../utils/translations";
import { Divider, Space } from "../../Divider";
import { ExpansionPanel } from "../../ExpansionPanel";
import { PageEditorRenderOptions } from "../../PageEditor/PageEditor";
import { Column } from "../../Structure";
import { Switch } from "../../Switch";
import { Typography } from "../../Typography";
import { APP_TRANSLATIONS } from "../../../locale"

export interface ChannelsProps extends PageEditorRenderOptions {

}

const I18N_NAMESPACE = I18N_KEY_CORE_COMPONENTS_PAGE_EDITOR

class ChannelsComponent extends CorePureComponent<ChannelsProps & WithI18nProps> {

    private VISIBILITY_DEFAULT = {
        ...(this.fw.config as CMSConfig).channels?.reduce<Record<string, "0">>((r, channel) => {
            r[channel.key] = "0"
            return r
        }, {})
    }

    public componentDidUpdate() {
        const { data, setData } = this.props
        
        // If there is data but no visibility, force to create it
        if (data && !(data as any).visibility) {
            setData({
                ...data,
                visibility: this.VISIBILITY_DEFAULT,
            })
        }
    }

    public render() {
        const { data, setData } = this.props
        const channels = (this.fw.config as CMSConfig).channels;

        return (
            <ExpansionPanel
                isExpanded={true}
                summary={(
                    <Typography.RegularText>
                        <Trans id={`${I18N_NAMESPACE}.widgets.channels.title`} />
                    </Typography.RegularText>
                )}
                >
                {channels?.map((channel) => {
                    // Iterate for over all channels
                    // And build the object
                    const value = (data as any)?.visibility?.[channel.key] === "1"

                    return (
                        <Column key={channel.key}>
                            <Switch
                                name={channel.key}
                                value={value}
                                onChange={(newValue: boolean) =>
                                    setData({
                                        ...data,
                                        visibility: {
                                            ...(data as any)?.visibility,
                                            [channel.key]: newValue ? "1" : "0",
                                        }
                                    })
                                }
                                fullWidth
                                reverseLayout
                            >
                                {translateStringOrObject(channel.name, this.fw.i18n.getCurrentLanguage(), this.props.t)}
                            </Switch>
                            <Divider space={Space.Small} />
                        </Column>
                    )
                })}
            </ExpansionPanel>
        )
    }
}

export const Channels = compose<ComponentClass<ChannelsProps>>(
    withI18n(APP_TRANSLATIONS),
)(ChannelsComponent)