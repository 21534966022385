import React, { PureComponent } from "react";
import { Form } from "../../../../dlos/cms/form";
import { Forms } from "../Forms";
import { Styles } from "./styles";
import { Icon, Typography, Modal, Column, Button } from "../../../../components";
import { withI18n, WithI18nProps } from "@7egend/web.core/lib/components/withI18n";
import { Trans } from "@7egend/web.core/lib/components/i18n";
import { APP_TRANSLATIONS } from "../../../../locale";
import { I18N_KEY_CMS_PAGES_FORM, I18N_KEY_GLOBAL } from "../../../../base/i18n";

interface PickerProps {
    className?: string

    /** list of picked forms */
    picked?: Form[];

    /** pick form */
    pickForm?: (picked: Form[]) => void;
}

interface PickerState {
    active?: boolean;
    pickedForms: Form[]
}

const I18N_NAMESPACE = I18N_KEY_CMS_PAGES_FORM

/**
 * # Picker Component
 * 
 * ## How to use:
 *  <FormPicker 
 * 
 *  />
 */
class PickerComponent extends PureComponent<PickerProps & WithI18nProps, PickerState> {

    /** state */
    public state: PickerState = {
        active: false,
        pickedForms: []
    }

    /** render */
    public render() {
        const { className } = this.props
        const hasPicked = this.props.picked && this.props.picked[0].secret;

        return (
            <React.Fragment>
                <Styles.Picker onClick={this.toggleForm} className={className}>
                    {!hasPicked ? (
                        <Styles.Placeholder>
                            <Icon icon="search" />
                            <Typography.RegularText><Trans id={`${I18N_NAMESPACE}.picker.fieldSearchTitle`} /></Typography.RegularText>
                        </Styles.Placeholder>
                    ) : (
                        <Styles.Picked>
                            <Typography.RegularText>
                                {hasPicked && this.props.picked?.map((form) => form.internal_name || form.title)}
                            </Typography.RegularText>
                            <Icon icon="delete" />
                        </Styles.Picked>
                    )}

                </Styles.Picker>
                <Modal
                    overlay={true}
                    handleModal={this.toggleForm}
                    isVisible={this.state.active}
                    fullWidth
                >
                    <Forms
                        picker={true}
                        pickItem={this.pickItem}
                        pickedItems={this.state.pickedForms}
                        footer={
                            <Column contentAlign="right">
                                <Button
                                    layout="main"
                                    layoutOutline
                                    icon="clear"
                                    layoutIconPosition="right"
                                    onClick={this.toggleForm}
                                >
                                    <Trans id={`${I18N_KEY_GLOBAL}.cancel`} />
                                </Button>
                                <Button
                                    layout="main"
                                    layoutFill
                                    icon="check"
                                    layoutIconPosition="right"
                                    onClick={this.addItems}
                                    disabled={
                                        this.state.pickedForms.length < 1
                                    }
                                >
                                    <Trans id={`${I18N_NAMESPACE}.picker.addForm`} />
                                </Button>
                            </Column>
                        } />
                </Modal>
            </React.Fragment >
        )
    }

    private toggleForm = () => {
        this.setState(prevState => ({
            active: !prevState.active,
        }))
    }

    private addItems = () => {
        if (this.state.pickedForms && this.props.pickForm) {
            /** sent to parent component, checked items */
            this.props.pickForm(this.state.pickedForms);

            /** hide modal */
            this.toggleForm();

            /** clean state */
            this.setState({
                pickedForms: [],
            });
        }
    };

    private pickItem = (pickedForms?: Form[]) => {
        if (pickedForms) {
            this.setState({
                pickedForms,
            });
        }
    }
}

export const Picker = withI18n(APP_TRANSLATIONS)(PickerComponent);
