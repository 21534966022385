import React, { PureComponent } from "react"
import styled, { css } from "styled-components";
import { Icon } from "../Icon";
import { colors, font } from "../../styles";
import { Button } from "../Button";

export enum LabelLayout {
    Primary = "primary",
    Info = "info",
    Error = "error",
    Warning = "warning",
    Default = "default",
}

export interface LabelProps {
    /** className */
    className?: string;
    /** label layout */
    layout: LabelLayout;
    /** label faded */
    faded?: boolean;
    /** icon */
    icon?: string;
    /** iconClick */
    iconClick?: () => void;
    /** on click */
    onClick?: () => void;
}

const StyledIcon = styled(Icon)`
    & {
        font-size: 16px;
        margin-left: 7px;
    }
`;

const StyledButton = styled(Button)`
    padding: 0;
    min-height: 0;

    &:hover {
        cursor: pointer;

        ${StyledIcon} {
            color: ${colors.neutral.darker};
        }
    }
`;

const StyledLabel = styled.div<LabelProps>`
    border-radius: 6px;
    padding: 4px 12px;
    color: ${colors.light};
    font-size: ${font.size.xs};
    font-weight: ${font.weight.light};
    text-align: center;
    display: inline-flex;
    align-items: center;

    ${(props: LabelProps) =>
        props.layout === "primary" &&
        css`
            background-color: ${colors.primary.lighter};

            ${props.faded &&
            css`
                    background-color: ${colors.semantic.light};
                    color: ${colors.primary.lighter};
                `}
        `}

    ${(props: LabelProps) =>
        props.layout === "error" &&
        css`
            background-color: ${colors.semantic.error};

            ${props.faded &&
            css`
                    background-color: ${colors.semantic.errorLight};
                    color: ${colors.semantic.error};
                `}
        `}

    ${(props: LabelProps) =>
        props.layout === "warning" &&
        css`
            background-color: ${colors.semantic.warning};

            ${props.faded &&
            css`
                    background-color: ${colors.semantic.warningLight};
                    color: ${colors.semantic.warning};
                `}
        `}

    ${(props: LabelProps) =>
        props.layout === "info" &&
        css`
            background-color: ${colors.semantic.info};

            ${props.faded &&
            css`
                    background-color: ${colors.semantic.infoLight};
                    color: ${colors.semantic.info};
                `}
        `}

    ${(props: LabelProps) =>
        props.layout === "default" &&
        css`
            background-color: ${colors.neutral.lighter};
            color: ${colors.neutral.darker};
        `}
`;

/**
 * # Label Component
 * This class represents the label component
 *
 * # How to use
 * ````<Label layout="primary" faded />````
 *
 * # Layout Props
 *
 * `layout`: `"primary"` | `"info"` | `"error"` | `"warning"` | `"default"`
 *
 * `faded`: `{boolean}`
 *
 * `icon`: string
 *
 */
export class Label extends PureComponent<LabelProps> {
    public render() {
        return (
            <StyledLabel
                layout={this.props.layout}
                faded={this.props.faded}
                className={this.props.className}
                onClick={this.props.onClick}
            >
                {this.props.children}
                {this.props.icon && !this.props.iconClick ? (
                    <StyledIcon icon={this.props.icon} />
                ) : this.props.icon && this.props.iconClick ? (
                    <StyledButton onClick={this.props.iconClick}>
                        <StyledIcon icon={this.props.icon} />
                    </StyledButton>
                ) : (
                            undefined
                        )}
            </StyledLabel>
        );
    }
}
