import React, { PureComponent } from "react"
import styled, { css } from "styled-components";
import { Icon } from "../Icon";
import { colors, font } from "../../styles";

export interface LabelStatusProps {
    /** status */
    status: "on" | "off";
    /** className */
    className?: string;
}

const StyledLabel = styled.div<LabelStatusProps>`
    border-radius: 6px;
    padding: 8px 12px;
    color: ${colors.light};
    font-size: ${font.size.xs};
    font-weight: ${font.weight.light};
    display: inline-flex;
    align-items: center;

    ${(props: LabelStatusProps) => props.status === "on" && css`
        color: ${colors.primary.lighter};
        font-weight: ${font.weight.semiBold};
    `}

    ${(props: LabelStatusProps) => props.status === "off" && css`
        color: ${colors.neutral.dark};
        font-weight: ${font.weight.semiBold};
    `}
`

const StyledIcon = styled(Icon)`
    & {
        font-size: ${font.size.xs};
        margin-right: 8px;
    }
`

/**
 *
 * # Label Status Component
 *
 * ## How to use:
 *
 * ```js
 * <LabelStatus status="on">ON</LabelStatus>
 * <LabelStatus status="off">OFF</LabelStatus>
 * ```
 */
export class LabelStatus extends PureComponent<LabelStatusProps> {
    public render() {
        const LABEL_ICON = this.props.status === "on" ? "radio_button_checked" : "radio_button_unchecked"
        return (
            <StyledLabel status={this.props.status} className={this.props.className}>
                <StyledIcon icon={LABEL_ICON} />
                {this.props.children}
            </StyledLabel>
        )
    }
}
