import React from "react";
import { CoreComponent } from "@7egend/web.core/lib/base/component";
import { memoizeOne } from "@7egend/web.core/lib/utils";

import styles from "./image.module.css";

export interface ImageProps {
    source?: string
    src?: string
    placeholder?: boolean
    className?: string
    alt?: string
    title?: string
    width?: string | number
    height?: string | number
    srcSet?: SrcSetItem[]
    masked?: boolean
    onError?: (e: React.SyntheticEvent<HTMLImageElement>) => void;
}

export interface SrcSetItem {
    path: string
    width: string
}

interface ImageState {
    loaded: boolean
}

/**
 * define all default props
 */
const defaultProps = {
    placeholder: true,
    className: "",
    source: "",
    src: "",
    alt: "",
    title: "",
    srcSet: [],
    masked: false,
}

/**
 * # Component Image
 * This component renders an image html tag
 *
 * ## Parameters:
 *  className: '',
 *  source: '',
 *  alt: '',
 *  title: '',
 *  srcSet: []
 *
 * ## How to use
 * ```HTML
 * <ImageComponent source="/src/component/image" alt="alternative" title="my image" srcSet={srcSetExample} />
 * ```
 */

const TRANSPARENT_PLACEHOLDER = "data:image/png;base64, iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=";

export class Image extends CoreComponent<ImageProps, ImageState> {

    /* set default props */
    public static defaultProps = defaultProps;

    /* set initial state */
    public state = {
        loaded: false,
    }

    /**
     * Get a array of image's urls and transform to srcSet attribute string
     * @param srcSet array of images
     */
    private srcSetObjectToString = memoizeOne((srcSet: SrcSetItem[] = []): string => {
        let srcSetString: string = "";

        srcSet.map((item: SrcSetItem, i: number) => {
            /* add comma to separate values */
            srcSetString += i > 0 ? ", " : "";

            /* add image url + width of image */
            srcSetString += `${item.path} ${item.width}w`;
        });

        return srcSetString;
    })

    public componentDidMount() {
        const isClientSide = this.fw.dom.isClientSide();

        if (isClientSide && this.props.placeholder) {
            this.loadImage();
        } else {
            this.setState({ loaded: true });
        }
    }

    public render() {
        return (
            <React.Fragment>
                {this.state.loaded
                    ? this.renderImage()
                    : this.renderPlaceholder()
                }
            </React.Fragment>
        )
    }

    /**
     * Starts loading the image
     */
    private loadImage = () => {
        const image = new (this.fw.dom.getGlobal()).Image();
        image.onload = () => {
            this.setState({ loaded: true });
        }
        image.onerror = (e: React.SyntheticEvent<HTMLImageElement>) => {
            if (this.props.onError) {
                this.setState({ loaded: true });
                this.props.onError(e);
            }
        }
        image.src = this.props.source || this.props.src;
    }

    /**
     * Renders an empty div while the image has not loaded
     */
    private renderPlaceholder() {
        return (
            <div className={styles.placeholder}></div>
        )
    }

    /**
     * Renders the <img /> tag once the image loads
     */
    private renderImage() {

        if (this.props.masked) {
            return this.renderMaskedImage()
        }

        return (
            <img
                src={this.props.source || this.props.src}
                alt={this.props.alt}
                title={this.props.title}
                className={`${styles.image} ${this.props.className}`}
                width={this.props.width}
                height={this.props.height}
                srcSet={this.srcSetObjectToString(this.props.srcSet)}
                onError={this.props.onError}
            />
        )
    }

    /**
     * Renders the <img /> tag as a mask with a background color
     */
    private renderMaskedImage() {
        return (
            <img
                src={TRANSPARENT_PLACEHOLDER}
                alt={this.props.alt}
                title={this.props.title}
                className={`${styles.image} ${styles.masked} ${this.props.className}`}
                width={this.props.width}
                height={this.props.height}
                srcSet={this.srcSetObjectToString(this.props.srcSet)}
                onError={this.props.onError}
                style={{
                    maskImage: `url(${this.props.source || this.props.src})`,
                    WebkitMaskImage: `url(${this.props.source || this.props.src})`,
                }}
            />
        )
    }
}
