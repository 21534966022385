import { Album } from './Album';
import { Attachment } from './Attachment';
import { Booster } from './Booster';
import { CTA } from './CTA';
import { ExpansionPanel } from './ExpansionPanel';
import { Form } from './Form';
import { Highlight } from './Highlight';
import { HTML } from './Html';
import { Iframe } from './IFrame';
import { Image } from './Image';
import { ImageAndText } from './ImageAndText';
import { ImagePanoramic } from './ImagePanoramic';
import { Lead } from './Lead';
import { List } from './List';
import { Media } from './Media';
import { Separator } from './Separator';
import { SmallText } from './SmallText';
import { Text } from './Text';
import { Title } from './Title';
import { Video } from './Video';
import { BoosterList } from "./BoosterList";
import { CTAList } from "./CTAList";
import { ColumnHighlight } from "./ColumnHighlight";
import { News } from "./News";
import { Stream } from "./Stream"
import { ImageList } from "./ImageList"
import { VideoList } from "./VideoList"
import { VideoYoutube } from "./VideoYoutube"

export const BlockType = {
    Album,
    Attachment,
    Booster,
    CTA,
    ExpansionPanel,
    Form,
    Highlight,
    HTML,
    Iframe,
    Image,
    ImageAndText,
    ImagePanoramic,
    Lead,
    List,
    Media,
    Separator,
    SmallText,
    Text,
    Title,
    Video,
    Stream,
    CTAList,
    BoosterList,
    ColumnHighlight,
    News,
    ImageList,
    VideoList,
    VideoYoutube,
};
