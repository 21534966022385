/** Import dependencies */
import React, { Fragment, ReactNode } from "react";
import {
    CorePureComponent,
} from "@7egend/web.core";
import { withDloService, WithDloServiceProps } from "@7egend/web.core/lib/components/withDloService"
import { debounce } from "@7egend/web.core/lib/utils";
import { withI18n, WithI18nProps } from "@7egend/web.core/lib/components/withI18n";
import { APP_TRANSLATIONS } from "../../locale";

/** Import components */
import { Button } from "../Button";
import { Link } from "../Link";
import { Column } from "../Structure/Column";
import { Placeholder, PlaceholderType } from "../Placeholder";
import { Row, Container } from "../Structure";
import {
    StyledButton,
    StyledFooter,
    StyledDiv,
    StyledWrappButtons,
    StyledColumnPlaceholder,
    StyledDatatableWrapper,
    StyledError,
} from "./styles";
import { WrapperWithTitleActions } from "../Wrapper/WithTitleActions";
import {
    Draggable,
    DragDropContext,
    Droppable,
    DropResult,
} from "react-beautiful-dnd";

/** Import self components */
import { Counter } from "./Counter";
import Line from "./Line";
import Paginator from "./Paginator";

/** Import interfaces */
import { DatatableActions, Pages, DatatableProps, Order, ActionsRenderType, DloInputConstructor, DloInputBuilder, Columns } from "./interfaces";
import { Checkbox } from "../Checkbox";
import { DropdownMoreActions } from "./DropdownMoreActions";
import { Tooltip } from "../Tooltip";
import { convertDatatableRowToObject } from "./utils"
import { Trans } from "@7egend/web.core/lib/components/i18n";
import { I18N_KEY_CORE_COMPONENTS_DATATABLE } from "../../base/i18n";

/**
 * Component's state
 */
export interface State extends Pages {
    /**
     * Holds all filters values
     * This is a blue bag for all filters to write down their values if they need
     */
    filters?: { [key: string]: any };
    /**
     * Order object
     * Set a column to order and toggle asc/desc
     */
    order: Order;
    /**
     * Var to controll loading while ordering
     */
    isOrdering: boolean | undefined;
}

const I18N_NAMESPACE = I18N_KEY_CORE_COMPONENTS_DATATABLE

function findPickedItem(value: string, key: string | number, array: any[]) {

    if (typeof key !== "string" && typeof key !== "number") {
        return;
    }

    for (const item of array) {
        if (item[key] === value) {
            return item;
        }
    }
}

class DatatableComponent extends CorePureComponent<
    WithI18nProps & DatatableProps & WithDloServiceProps<any[]>,
    State
> {
    private readonly limitOfActions: number = 2;

    public state: State = {
        length: this.props.rows.lengths[0],
        start: 1,
        total: 0,
        filters: this.props.defaultFilters,
        order: {
            by: 0,
            direction: "desc",
        },
        isOrdering: false,
    };

    public componentDidMount() {
        const { length, start } = this.state;

        if (this.props.order) {
            this.setState({
                order: this.props.order
            })
        }

        this.loadData(length, start - 1);
    }

    public componentDidUpdate(prevProps: DatatableProps, prevState: State) {
        // If successfully delete row, do loadData();
        if (
            this.props.actionSuccess &&
            prevProps.isLoading &&
            !this.props.isLoading
        ) {
            const { length, start, total } = this.state;
            let startPage = start;
            const pageStep = (startPage - 1) * length;

            if (pageStep >= total - 1) {
                startPage -= 1;
            }
            if (startPage < 1) {
                startPage = 1;
            }

            this.setState({
                start: startPage
            });

            this.loadData(length, pageStep);
        }

        if (
            this.props.actionSuccess &&
            !prevProps.isLoading &&
            !this.props.isLoading &&
            this.state.isOrdering
        ) {
            this.setState({
                isOrdering: false
            });
        }

        // If the filters change, dispatch a new fetch
        if (JSON.stringify(prevState.filters) !== JSON.stringify(this.state.filters)) {
            // Loads new data
            this.selectPage(1);
        }

        // If the default filters change, dispatch a new fetch
        if (JSON.stringify(prevProps.defaultFilters) !== JSON.stringify(this.props.defaultFilters)) {
            // Loads new data
            this.selectPage(1);
        }

        // If order params change, do loadData();
        if (
            prevState.order.by !== this.state.order.by ||
            prevState.order.direction !== this.state.order.direction
        ) {
            this.selectPage(this.state.start);
        }

        if (prevProps.refresh !== this.props.refresh) {
            this.selectPage(1);
        }
    }

    public render() {
        const { loaded, error, loading } = this.props.dloRequest;
        const { title, rows } = this.props;
        const { total, length, start, isOrdering } = this.state;

        return (
            <WrapperWithTitleActions
                title={title}
                actions={this.renderActions()}
                heightLimited={this.props.footer ? true : false}
                footer={this.props.footer && this.props.footer}
            >
                {!!error && !loading && (
                    <StyledError>
                        <Trans id={`${I18N_NAMESPACE}.error.message`} /><br /><br />
                        <Button layout="main" layoutFill onClick={() => this.loadData(length, start - 1)}><Trans id={`${I18N_NAMESPACE}.error.action`} /></Button>
                    </StyledError>
                )}
                {(loading || !error) && (
                    <Fragment>
                        <StyledDatatableWrapper fluid>
                            <table>
                                {this.renderContentList()}
                            </table>
                        </StyledDatatableWrapper>
                        <StyledFooter>
                            <Container fluid>
                                <Row>
                                    <Column sm={6}>
                                        {!loaded && !isOrdering ? (
                                            <Placeholder
                                                config={PlaceholderType.Rect}
                                            />
                                        ) : (
                                            <Counter
                                                total={total}
                                                length={length}
                                                setAmountToShow={this.setAmountToShow}
                                                amounts={rows.lengths}
                                            />
                                        )}
                                    </Column>
                                    <Column contentAlign={"right"} sm={6}>
                                        {!loaded && !isOrdering ? (
                                            <Placeholder
                                                config={PlaceholderType.Rect}
                                            />
                                        ) : (
                                            <Paginator
                                                selectPage={this.selectPage}
                                                pages={this.state}
                                            />
                                        )}
                                    </Column>
                                </Row>
                            </Container>
                        </StyledFooter>
                    </Fragment>
                )}
            </WrapperWithTitleActions>
        );
    }

    /**
     * function to help us with reordering the result
     */
    private reorder = (list: any, startIndex: number, endIndex: number) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    /**
     * start dragging reordable element
     */
    private onDragEnd = (result: DropResult) => {
        if (!result.destination) {
            return;
        }
        this.setState({ isOrdering: true });
        const items = this.reorder(
            this.props.dloRequest.data,
            result.source.index,
            result.destination.index
        );

        const id = result.draggableId;
        const after: any = items[result.destination.index + 1];
        const afterId = after.uuid ? after.uuid : after.id;
        if (this.props.onSortChange) {
            this.props?.onSortChange(id, afterId);
        }
    }

    /** It select the new page */
    private selectPage = (start: number) => {
        this.setState({
            start,
        });

        // Set the value of 'start' API param
        const { length } = this.state;
        const pageStep = (start - 1) * length;
        this.loadData(length, pageStep);
    };

    /** Set amount of content to show per page */
    private setAmountToShow = (length: number) => {
        this.setState({
            length,
            start: 1,
        });

        // Set the value of 'lenght' of content to show
        const { start } = this.state;
        this.loadData(length, start - 1);
    };

    private renderActions = () => {
        // This will render 2 types of components
        // First, all filters
        // and then, custom actions
        return (
            <>
                {this.renderActionsFilters()}
                {this.renderActionsActions()}
            </>
        );
    };

    private renderActionsFilters = () => {
        const { filters } = this.props;
        const { filters: filtersData } = this.state;
        if (!filters) {
            return null;
        }
        return filters.map(filter =>
            filter({
                filters: filtersData as any,
                updateFilter: this.updateFilter,
            })
        );
    };

    private updateFilter = (name: string, value: any) => {
        this.setState({
            filters: {
                ...this.state.filters,
                [name]: value,
            },
        });
    };

    private renderActionsActions = () => {
        const { actions } = this.props;
        if (!actions) {
            return null;
        }
        return actions;
    };

    private renderPlaceholder = () => {
        return (
            <StyledColumnPlaceholder key={Math.random()} xs={12}>
                <Placeholder config={PlaceholderType.Datatable} />
            </StyledColumnPlaceholder>
        );
    };

    private verifyPickedItems = (item: any, param: any) => {
        if (this.props.pickedItems) {
            return this.props.pickedItems.indexOf(item) >= 0 || findPickedItem(item[param], param, this.props.pickedItems) || findPickedItem(item[param], "id", this.props.pickedItems) || findPickedItem(item[param], "uuid", this.props.pickedItems);
        }

        return false;
    }

    private renderActionType = (
        type: DatatableActions,
        i: number,
        actionFunction: any,
        id: string,
        other: any,
        param: string | undefined,
        icon: string | undefined,
        render?: ActionsRenderType,
        label?: string
    ) => {
        const { t } = this.props;
        const value = other[param as string]

        switch (type) {
            case "common":
                const button = (
                    <Button
                        key={`${i}-${id}`}
                        icon={icon}
                        onClick={() => actionFunction(value)}
                    />
                )

                if (label) {
                    return (
                        <Tooltip
                            key={`${i}-${id}`}
                            className="tooltip"
                            attachOnBody={true}
                            component={button}
                            position={"bottom"}
                        >
                            {label}
                        </Tooltip>
                    );
                }

                return button;
            case "link":
                const link = (
                    <Link
                        key={`${i}-${id}`}
                        href={actionFunction(value)}
                    >
                        <Button icon={icon} />
                    </Link>
                )
                if (label) {
                    return (
                        <Tooltip
                            className="tooltip"
                            attachOnBody={true}
                            key={`${i}-${id}`}
                            component={link}
                            position={"bottom"}
                        >
                            {label}
                        </Tooltip>
                    )
                }

                return link;
            case "picker":
                return (
                    <Checkbox
                        value={this.verifyPickedItems(other, param)}
                        key={`${i}-${id}`}
                        name={`item-${i}-${id}`}
                        rounded={true}
                        onChange={() => {
                            actionFunction(value, other)
                        }}
                    />
                );
            case "custom":
                if (!render) {
                    return;
                }
                return render({
                    item: other
                });
            default:
                return <p key={`${i}-${id}`}>{t(`${I18N_NAMESPACE}.noContentFound`)}</p>;
        }
    };

    private renderRowActions = (id: string, other: any) => {
        const { actions } = this.props.rows;

        if (!actions) {
            return null;
        }

        const content: JSX.Element | JSX.Element[] = [];
        const iconsVisible = this.props.rows.actions?.showAllActions ? actions.list.length : Math.min(this.limitOfActions, actions.list.length);

        // it will show the visible actions, outside of a dropdown
        for (let i = 0; i < iconsVisible; i++) {
            content.push(
                this.renderActionType(actions!.list[i].type, i, actions!.list[i].function, id, other, actions!.list[i].param, actions!.list[i].icon || id, actions!.list[i].render, actions!.list[i].label)
            )
        }

        // it will show a dropdown with the rest of the actions inside of it.
        if (iconsVisible !== actions.list.length) {
            const items: ReactNode[] = [];

            for (let index = iconsVisible; index < actions.list.length; index++) {
                items.push(this.renderActionType(actions.list[index].type, index, actions.list[index].function, id, other, actions.list[index].param, actions.list[index].icon || id, actions.list[index].render))
            }

            content.push(
                <DropdownMoreActions>
                    {items}
                </DropdownMoreActions>
            )
        }

        return (
            <td className="td-actions">
                <StyledWrappButtons>{content}</StyledWrappButtons>
            </td>
        );
    };

    private renderOrderButtons = (i: number) => {
        const { order } = this.state;
        let orderDirection = "";
        let iconDirection = "";

        if (order.direction === "asc" && order.by === i) {
            orderDirection = "desc";
            iconDirection = "arrow_upward";
        } else if (order.by !== i) {
            orderDirection = "desc";
            iconDirection = "arrow_downward";
        } else {
            orderDirection = "asc";
            iconDirection = "arrow_downward";
        }
        return (
            <StyledButton
                isFocused={i === order.by}
                onClick={() => this.updateOrderColumn(i, orderDirection)}
                icon={iconDirection}
            />
        );
    };

    private renderContentList = () => {
        const { loading } = this.props.dloRequest;
        const { rows, columns, isSortable, t } = this.props;
        const { isOrdering } = this.state;
        let tableContent: JSX.Element | JSX.Element[];
        const hasActions: boolean = rows.actions && rows.actions.list.length ? true : false;

        // Convert flatten fields with : to object
        const data = this.props.dloRequest.data?.map(d => ({ ...d, ...convertDatatableRowToObject(d) }));

        // Table header
        const tableColumns = columns.map((column, index) => column.hidden || !column.render ? null : (
            <th key={`${column.title}-${index}`}>
                <Line isTitle>
                    {column.title}
                    {column.order && this.renderOrderButtons(index)}
                </Line>
            </th>
        ))

        // Table content
        if (!loading && !isOrdering && (data && data.length > 0)) {
            tableContent = data.map((item: any, index: number) => {
                const id = item.uuid || item.id
                const key = `${id}-${index}`

                const rowContent = (
                    <Fragment>
                        {columns.map((column, columnIndex) => column.hidden || !column.render ? null : (
                            <td key={columnIndex}>
                                <Line>
                                    <StyledDiv>
                                        {column.render({ column, data: item })}
                                    </StyledDiv>
                                </Line>
                            </td>
                        ))}
                        {hasActions && this.renderRowActions(id, item)}
                    </Fragment>
                )

                return (
                    <React.Fragment key={key}>
                        {isSortable ? (
                            <Draggable key={key} draggableId={id} index={index}>
                                {(provided: any) => (
                                    <tr
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                    >
                                        {rowContent}
                                    </tr>
                                )}
                            </Draggable>
                        ) : (
                            <tr key={key}>
                                {rowContent}
                            </tr>
                        )}
                    </React.Fragment>
                )
            });
        } else if (!loading && !isOrdering && (data && data.length === 0)) {
            tableContent = (
                <Line>
                    {t(`${I18N_NAMESPACE}.noContentFound`)}
                </Line>
            )
        } else {
            tableContent = this.renderPlaceholder()
        }

        return (
            <React.Fragment key={"isTitle"}>
                <thead>
                    <tr>
                        {tableColumns}
                        {hasActions && <th className="th-actions"></th>}
                    </tr>
                </thead>
                {isSortable ? (
                    <DragDropContext onDragEnd={this.onDragEnd}>
                        <Droppable droppableId="droppable" type="droppableItem">
                            {(provided: any) => (
                                <React.Fragment>
                                    <tbody
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                    >
                                        {tableContent}
                                    </tbody>
                                    {provided.placeholder}
                                </React.Fragment>
                            )}
                        </Droppable>
                    </DragDropContext>
                ) : (
                    <tbody>
                        {tableContent}
                    </tbody>
                )}
            </React.Fragment>
        )
    };

    private createColumns(columns: Columns, startingOffset: number = 0) {
        // First create all columns by the first param
        // Meanwhile, save the other params for later
        // This will ensure that the columns fit with their index for sorting
        let index = startingOffset
        const otherParams: string[] = []

        const result = columns.reduce<Record<string, string>>((content, column) => {
            if (typeof column.param === "object" && Array.isArray(column.param)) {
                content[`columns[${index++}][data]`] = column.param[0]
                otherParams.push(...column.param.slice(1))
            } else {
                content[`columns[${index++}][data]`] = column.param;
            }
            return content;
        }, {});

        // Now that the first passing is ok, let's add the other params
        for (const param of otherParams) {
            result[`columns[${index++}][data]`] = param
        }

        return result
    }

    private updateOrderColumn = (by: number, direction: string) => {
        this.setState({
            order: {
                by,
                direction,
            },
        });
    };

    private createOrderColumn() {
        const { order } = this.state;
        return {
            "order[0][column]": order.by,
            "order[0][dir]": order.direction,
        };
    }

    private transformData(data: any): void {
        this.setState({
            total: data.headers["x-datatables-records-total"],
        });
    }

    private loadData = debounce((length: number, start: number) => {
        const { fetch, rows } = this.props;
        const columnParams = this.createColumns(this.props.columns);
        const order = this.createOrderColumn();
        let columns = {};
        let input: any;

        const filters = {
            ...this.state.filters,
            ...this.props.defaultFilters
        }

        if (rows.actions) {
            const actionsParams = this.createColumns(
                rows.actions.list as unknown as Columns,
                Object.keys(columnParams).length,
            );
            columns = { ...columnParams, ...actionsParams };
        } else {
            columns = { ...columnParams };
        }

        const body = {
            length,
            start,
            ...columns,
            ...filters,
            ...order,
        }

        if (typeof (fetch as any).prototype !== "undefined") {
            input = new (fetch as DloInputConstructor)();
            input.body = body;
        } else {
            input = (fetch as DloInputBuilder)(body);
        }

        this.props.executeDloRequest(input, start)
            .then(res => {
                this.transformData(res);
            })
            .catch(err => {
                this.fw.log.error("Failed to fetch datatable", err)
            });
    }, 300);
}

export const Datatable = withDloService(withI18n(APP_TRANSLATIONS)(DatatableComponent));
