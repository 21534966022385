import React from "react";
import { Block } from "@7egend/web.core.cms/lib/dlos/block";
import { Booster as BoosterDLO } from "@7egend/web.core.cms/lib/dlos/booster";
import { BlockComponentProps } from "../../interfaces";
import { BoostersPicker } from "../../../../modules/cms/Boosters";
import { blockTypes } from "../config";
import { I18N_KEY_CMS_COMPONENTS_BLOCK } from "../../../../base/i18n";

const { booster } = blockTypes;

const Structure = {
    type: booster.type,
    content: {},
} as Block<BoosterDLO>;

const Component: React.FC<BlockComponentProps<BoosterDLO>> = ({ className, data, updateData }) => {
    return (
        <BoostersPicker
            className={className}
            renderColumns={{
                xs: 3,
            }}
            checkedItems={[data.content]}
            selectItem={(checkedItems: BoosterDLO[]) =>
                updateData({
                    ...data,
                    content: {
                        ...checkedItems[0] as any,
                        type: booster.type
                    }
                })
            }
        ></BoostersPicker>
    )
}

const Button = {
    icon: "whatshot",
    name: {
        key: `${I18N_KEY_CMS_COMPONENTS_BLOCK}.types.booster.name`,
        en: "Booster",
        es: "Booster",
        pt: "Booster",
    },
};

export const Booster = {
    key: booster.type,
    Structure,
    Component,
    Button,
};
