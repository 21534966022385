import React from "react";
import { Block } from "@7egend/web.core.cms/lib/dlos/block";
import { Medium, MediumType } from "@7egend/web.core.media/lib/dlos";
import { BlockComponentProps } from "../../interfaces";
import { Uploader } from "../../../Uploader";
import { Typography } from "../../../Typography";
import { MediaListData, MediaList } from "../../../MediaList";
import styled from "styled-components";
import { withI18n, WithI18nProps } from "@7egend/web.core/lib/components/withI18n";
import { blockTypes } from "../config";
import { Config } from "../../../../base/config";
import { useFramework } from "@7egend/web.core/lib/components/useFramework";
import { I18N_KEY_CMS_COMPONENTS_BLOCK, I18N_KEY_GLOBAL } from "../../../../base/i18n";

const { medium } = blockTypes;

const Wrapper = styled.div`
    display: grid;
`;

const Structure = {
    type: medium.type,
    content: {
        medium_type: MediumType.Image,
    }
} as Block<Medium>;

const I18N_NAMESPACE = `${I18N_KEY_CMS_COMPONENTS_BLOCK}.types.image`

const ImageComponent: React.FC<WithI18nProps & BlockComponentProps<Medium>> = ({ className, data, updateData, t }) => {
    const hasMedia = !!data.content?.uuid;
    const fw = useFramework();
    const config = (fw.config as Config);

    Structure.type = config?.blockTypes?.medium?.type ? config.blockTypes.medium.type : Structure.type;

    return (
        <Wrapper className={className}>
            {!hasMedia &&
                <Uploader.MediaList
                    limitSelectable={1}
                    mediumType={MediumType.Image}
                    context={{ 'panoramic': false }}
                    heightLimited={hasMedia ? true : false}
                    info={
                        hasMedia ? (
                            <React.Fragment>
                                <Typography.RegularText variant="neutral">
                                    <strong>{t(`${I18N_KEY_GLOBAL}.selectFromLibrary`)}</strong> {t(`${I18N_KEY_GLOBAL}.or`)} <strong>{t(`${I18N_KEY_GLOBAL}.uploadFiles`)}</strong>
                                </Typography.RegularText>
                            </React.Fragment>
                        ) : (
                            ""
                        )
                    }
                    renderColumns={{
                        xs: hasMedia ? 6 : 3,
                    }}
                    checkedMedia={(checkedMedia: MediaListData[]) =>
                        updateData({
                            ...data,
                            content: checkedMedia[0],
                        })
                    }
                />
            }
            {hasMedia && (
                <MediaList
                    renderColumns={{
                        xs: 3,
                    }}
                    list={[data.content]}
                    isCheckable={false}
                    deleteMedia={(item: MediaListData) => {
                        updateData({
                            content: { medium_type: MediumType.Image },
                            type: Structure.type,
                            id: item.uuid,
                        } as any)
                    }
                    }
                />
            )}
        </Wrapper>
    );
}

const Component = withI18n()(ImageComponent);

const Button = {
    icon: "photo_library",
    name: {
        key: `${I18N_NAMESPACE}.name`,
        en: "Image",
        es: "Imagen",
        pt: "Imagem",
    },
};

export const Image = {
    key: `${medium.type}_${MediumType.Image}`,
    Structure,
    Component,
    Button,
};
