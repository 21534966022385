import React, { ReactNode, useCallback, useState } from 'react'
import { Button } from "../../Button";
import { Dropdown } from "../../Dropdown"
import styled from "styled-components";
import { queries } from "../../../styles";


const StyledDropdownOptions = styled.div`
    button {
        min-height: 0;
        padding-top: 0;
        padding-bottom: 0;
    }
    
    a {
        button { padding: 0};
    }

    & > :first-child {
        padding-left: 0;
    }

    & > * {
        padding-left: 15px;
        padding-right: 0;
    }

    ${queries.maxXS} {
        button {
            padding: 0 4px;
        }
    }
`;

interface Props {
    children: ReactNode
}

export const DropdownMoreActions = ({ children }: Props) => {
    const [isActive, setActive] = useState<boolean>(false)

    const toggleActive = useCallback(() => setActive(!isActive), [setActive, isActive]);

    return (
        <Dropdown
            align="center"
            position="right"
            attachOnBody
            styles={{
                maxWidth: "100%",
            }}
            onClick={toggleActive}
            active={isActive}
            component={
                <Button onClick={toggleActive} icon="more_vert" />
            }
        >
            <StyledDropdownOptions>
                {children}
            </StyledDropdownOptions>
        </Dropdown>
    )
}
