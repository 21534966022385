import styled from "styled-components"
import { colors } from "../../styles"
import { StyledButton } from "../Button/styles"

const DeleteItems = styled.div`
    border-right: 1px solid ${colors.neutral.light};
    float: left;
    margin-right: 15px;
    display: flex;
    align-items: center;
    
    ${StyledButton} {
        min-height: 0;
        padding: 0;
        padding-right:30px;
    }
`

const EmptyItems = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px dashed ${colors.neutral.light};
    padding: 40px;
    width: 100%;
    margin: 20px 0;
`

export const Styled = {
    DeleteItems,
    EmptyItems,
}
