import React from "react";
import { Component } from "@7egend/web.core/lib/base/component";
import { GridItemDimensions, GridItemComponent, GridItem, GridItemDimension } from "./gridItem";

// import styles from "./gridRow.scss";
import styles from "./gridRow.module.scss";

//#region Exports

export { GridItem, GridItemDimensions, GridItemDimension }

//#endregion

export interface GridRowProps {
    /** Checks if grid blocks height are oriented by aspect ratio or content size */
    autoHeight: boolean,

    /** Data with block dimensions */
    items: GridItem[],

    /** Number of columns the grid is going to have inside the 12 columns of the page */
    columns?: number,
}

export interface Props extends GridRowProps { }

/**
 * # Grid Component
 * This is the grid component.
 *
 * ## How to use
 * Use a grid component for each Block Component that needs different height definition
 *
 * ## Example
 * ```
 *  <GridComponent blocks={blocksArray} autoHeight={true} />
 * ```
 */

export class GridRow extends Component<GridRowProps> {

    public static defaultProps = {
        columns: 12,
    }

    public render() {
        const gridClasses = this.props.autoHeight ? styles.gridAuto : styles.grid;
        const allowOffset = this.props.autoHeight || this.props.items.length === 1
        const gridStyle = {
            gridTemplateColumns: `repeat(${this.props.columns}, 1fr)`,
        }

        return (
            <div className={gridClasses} style={gridStyle}>
                {this.props.items.map((item, index) => {
                    return (
                        <GridItemComponent key={index} item={item} allowOffset={allowOffset}></GridItemComponent>
                    );
                })}
            </div>
        );
    }
}

export const GridRowComponent = GridRow
