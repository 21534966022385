import { DloInput, DloOutput } from "@7egend/web.core";
import { buildQLQuery, QueryQLParams } from "@7egend/web.core/lib/extensions/dlo";
import { Section } from "@7egend/web.core.cms/lib/dlos";

const GROUP_FIELDS: QueryQLParams = [
    "id",
    "name",
    "created_at",
];

export class GroupsGetAllInput extends DloInput {
    public uri = "cms-groups?fields=${fields}&draw=${timeStamp}"
    public timeStamp = Date.now()
    public fields = buildQLQuery(GROUP_FIELDS)
}

export class GroupsGetAllOutput extends DloOutput<Section[]> { }
