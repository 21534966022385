export interface ErrorMessageProps {
    /**
     * Error code
     * Will be shown only if present
     */
    code?: number;
    
    /**
     * Error message
     */
    message?: string;

    /**
     * Error default message
     * This will be used if there is no message
     */
    defaultMessage?: string

    /**
     * Error context
     * This will be used to 
     */
    context?: string
}

/**
 * Generates an error message and code
 * @param error 
 * @returns 
 */
export const errorMessage = (error: ErrorMessageProps) => {
    return [
        error.context && `${error.context}:`,
        error.message || error.defaultMessage || "Sorry, something went wrong!", // TODO translate
        error.code && `(code: ${error.code})`,
    ].filter(Boolean).join(" ")
}
