import React from "react";
import { CorePureComponent } from "@7egend/web.core";
import { Block } from "@7egend/web.core.cms/lib/dlos/block";
import { BlockDrawer, BlockDrawerProps } from "../BlockDrawer";

export interface BlocksDrawerProps extends Pick<BlockDrawerProps, "resolveComponent" | "mapping" | "fallbackComponent"> {
    /**
     * List of Block to render
     */
    blocks: Block[];

    /**
     * Function to update blocks data
     */
    onChange: (blocks: Block[]) => void;
}

/**
 * # BlocksDrawer
 * Renders a list of blocks dynamically.
 * This is a wrapper for BlockDrawer component.
 */
export class BlocksDrawer extends CorePureComponent<BlocksDrawerProps> {
    public render() {
        const { blocks, resolveComponent } = this.props;

        return (
            <>
                {blocks.map((block, i) => {
                    return (
                        <BlockDrawer
                            key={block.id || block.content.id || (block.content as any).uuid || (block as any)._id || i.toString()}
                            position={i}
                            block={block}
                            mapping={this.props.mapping}
                            resolveComponent={resolveComponent}
                            onChange={this.updateData}
                            fallbackComponent={this.props.fallbackComponent}
                        />
                    )
                })
                }
            </>
        );
    }

    private updateData = (id: string, nextBlock: Block, previousBlock: Block) => {

        const removeElement = this.props.blocks.indexOf(previousBlock);

        if (removeElement > -1) {
            const updatedBlocks = [
                ...this.props.blocks.slice(0, removeElement),
                nextBlock,
                ...this.props.blocks.slice(removeElement + 1),
            ];

            this.props.onChange(updatedBlocks);
        }
        else {
            this.fw.log.error(`Trying to update data from unknown block id: ${id}`);
        }

    }
}
