import React, { PureComponent } from "react"
import copy from 'copy-to-clipboard';

interface ClipboardProps {
    /** text to copy */
    text: string;
    /** on copy callback */
    onCopy?: (text: string, result?: boolean) => void
    /** options */
    options?: {
        debug: boolean;
        message: string;
        format: string;
    };
}

const DefaultProps: ClipboardProps = {
    text: "",
    onCopy: undefined,
    options: undefined
}

/**
 * # Copy to Clipboard
 * 
 * ## How to use:
 * ```jsx
 * <CopyToClipboard 
 *  text={this.state.value} 
 *  onCopy={(text: string, result: boolean) => this.setState({copied: true})}
 * >
 *  <button>Copy to clipboard with button</button>
 * </CopyToClipboard>
 * ```
 */
export class CopyClipboard extends PureComponent<ClipboardProps> {
    public static defaultProps = DefaultProps;

    public render() {
        const {
            text,
            onCopy,
            options,
            children,
            ...props
        } = this.props;

        const elem: any = React.Children.only(children);

        return React.cloneElement(elem, { ...props, onClick: this.onClick });
    }

    private onClick = (event: any) => {
        const {
            text,
            onCopy,
            children,
            options
        } = this.props;

        const elem: any = React.Children.only(children);

        const result = copy(text, options);

        if (onCopy) {
            onCopy(text, result);
        }

        // Bypass onClick if it was present
        if (elem && elem.props && typeof elem.props.onClick === 'function') {
            elem.props.onClick(event);
        }
    }
}
