import React from "react";
import { CorePureComponent } from "@7egend/web.core"
import styled, { css } from "styled-components";
import {
    Select as CoreSelect,
    SelectProps as CoreSelectProps,
    SelectOption
} from "@7egend/web.core.controls/lib/components/select"
import { Icon } from "../Icon";
import { font, colors } from "../../styles";

export { SelectOption }

export interface SelectProps extends React.PropsWithChildren<CoreSelectProps> {
    note?: string;
    /** classname */
    className?: string;
    /** error message */
    errorMessage?: string;
}

interface FieldsetProps {
    disabled?: boolean
    errorMessage?: string
}

const StyledLabel = styled.label`
    font-size: ${font.size.xs};
    color: ${colors.neutral.darker};
    font-weight: ${font.weight.semiBold};
    letter-spacing: 1.4px;
    text-transform: uppercase;
`

const StyledErrorMessage = styled.span`
    font-size: ${font.size.xs};
    color: ${colors.semantic.error};
    font-weight: ${font.weight.light};
    line-height: 16px;
    min-height: 16px;
    display: block;
`

const StyledCaret = styled(Icon)`
    & {
        font-size: ${font.size.xs};
        color: ${colors.neutral.dark};
        position: absolute;
        top: 11px;
        right: 0;
        transition: transform .2s ease-in-out;
    }
`

const StyledSelect = styled(CoreSelect)`
    & {
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        position: relative;
        padding: 10px 0;
        padding-right: 20px;
        font-weight: ${font.weight.light};
        font-size: ${font.size.sm};
        color: ${colors.neutral.darker};
        background-color: transparent;
        outline: none;
        border: 0;
        display: block;
        width: 100%;
        border-bottom: 1px solid ${colors.neutral.light};
        border-radius: 0;

        &::-webkit-input-placeholder {
            color: ${colors.neutral.dark};
        }

        &:-moz-placeholder {
            color: ${colors.neutral.dark};
        }

        &::-moz-placeholder {
            color: ${colors.neutral.dark};
        }

        &:-ms-input-placeholder {
            color: ${colors.neutral.dark};
        }

        &:focus + ${StyledCaret} {
            transform: rotate(-180deg);
        }
    }
`

const StyledNotes = styled.span`
    font-size: ${font.size.sm};
    font-weight: ${font.weight.light};
    color: ${colors.neutral.dark};
`

const StyledWrapper = styled.div`
    position: relative;
`

const StyledFieldset = styled.fieldset<FieldsetProps>`
    position: relative;
    margin: 0 0 16px;
    padding: 0;
    border: 0;

    ${(props: FieldsetProps) => props.disabled && css`
        * {
            cursor: not-allowed !important;
            pointer-events: none !important;
        }

        ${StyledLabel},
        ${StyledSelect},
        ${StyledNotes} {
            color: ${colors.neutral.dark};
        }
    `}

    ${(props: FieldsetProps) => !props.disabled && css`
        ${StyledSelect}:focus,
        ${StyledSelect}:active {
            border-color: ${colors.primary.lighter};
        }
    `}

    ${(props: FieldsetProps) => props.errorMessage && css`
        ${StyledSelect} {
            border-color: ${colors.semantic.error};

            &:focus,
            &:active {
                border-color: ${colors.semantic.error} !important;
            }
        }
    `}
`

/**
 * # Select Component
 *
 * ## How to use:
 *
 * ```js
 * <Select
 *   label="select"
 *   onChange={() => { }}
 *   name="select"
 *   options={[{
 *       label: "option 1",
 *       option: {
 *           disabled: true,
 *           value: "option 1"
 *       },
 *       value: "option 1",
 *   },
 *   {
 *       label: "option 2",
 *       option: {
 *           disabled: true,
 *           value: "option 2"
 *       },
 *       value: "option 2",
 *   }]}
 * />
 * ```
 *
 */
export class Select extends CorePureComponent<SelectProps> {
    public render() {
        return (
            <StyledFieldset
                errorMessage={this.props.errorMessage}
                disabled={this.props.disabled}
                className={this.props.className}
            >
                <StyledLabel
                    htmlFor={this.props.name}
                >
                    {this.props.label}
                </StyledLabel>
                <StyledWrapper>
                    <StyledSelect
                        {...this.props}
                    />
                    <StyledCaret icon="keyboard_arrow_down" />
                </StyledWrapper>
                {this.props.errorMessage && (
                    <StyledErrorMessage>{this.props.errorMessage}</StyledErrorMessage>
                )}
                {this.props.note && (
                    <StyledNotes>{this.props.note}</StyledNotes>
                )}
            </StyledFieldset>
        )
    }
}
