import React from "react";
import { CorePureComponent } from "@7egend/web.core";
import { withI18n, WithI18nProps } from "@7egend/web.core/lib/components/withI18n";
import { Trans } from "@7egend/web.core/lib/components/i18n";
import { Button } from "../../Button"
import { WrapperWith, Column, Typography, Switch } from "../../../components"
import { CopyClipboard } from "../../../components/CopyClipboard"
import { Language } from "../../Language"
import { PageEditorPublishOptions } from "../../PageEditor"
import { Config } from "../../../base/config"
import { BaseModel } from "@7egend/web.core.dlos/lib/dlos/base"
import { Divider, Space } from "../../Divider"
import { Icon } from "../../Icon"
import Styles from "./styles"
import { APP_TRANSLATIONS } from "../../../locale"
import { Tooltip } from "../../Tooltip";
import { colors } from "../../../styles";
import { I18N_KEY_CORE_COMPONENTS_PUBLISH_DEFAULT, I18N_KEY_GLOBAL } from "../../../base/i18n";

export interface PublishProps extends PageEditorPublishOptions<BaseModel & { slug?: string, active?: boolean }> {
    /** reset your form data */
    resetData?: () => void

    /** 
     * show languages
     * @default true
     */
    showLanguage?: boolean;
}

interface PublishState {
    slugCopied?: boolean;
}


export const PublishActions: React.FC<{
    cancelAction?: () => void,
    saveAction?: () => void,
    dataToSave?: boolean,
    saving: boolean,
}> = ({ cancelAction, saveAction, dataToSave, saving }) => {
    return (
        <Styles.Actions>
            <Button onClick={cancelAction}><Trans id={`${I18N_KEY_GLOBAL}.editor.discard`} /></Button>
            <Button
                layout="main"
                layoutFill
                icon="check"
                layoutIconPosition="right"
                onClick={saveAction}
                isLoading={saving}
                disabled={!dataToSave}
            >
                <Trans id={`${I18N_KEY_GLOBAL}.editor.save`} />
            </Button>
        </Styles.Actions>
    )
}

const I18N_NAMESPACE = I18N_KEY_CORE_COMPONENTS_PUBLISH_DEFAULT

/** Page Editor Publish Default */
class PublishComponent extends CorePureComponent<WithI18nProps & PublishProps, PublishState>{

    public state: PublishState = {
        slugCopied: false
    }

    public componentDidUpdate(prevProps: any, prevState: PublishState) {
        // clean state to false if previous state as true;
        if (!prevState.slugCopied && this.state.slugCopied) {
            this.fw.dom.setTimeout(() => this.setState({ slugCopied: false }), 1000)
        }
    }

    public render() {

        const { cancelAction, dataToSave, saving, saveAction, data, setData, updateLanguage, language, showLanguage, t } = this.props;

        return (
            <React.Fragment>
                <WrapperWith.TitleActions
                    title={<Typography.RegularText><Trans id={`${I18N_NAMESPACE}.publishSettings`} /></Typography.RegularText>}
                    actions={
                        showLanguage === true || typeof showLanguage === "undefined" ?
                            <Language
                                align="center"
                                position="bottom"
                                currentLanguage={language}
                                updateLanguage={(currentLanguage: string) => updateLanguage && updateLanguage(currentLanguage)}
                                languages={(this.fw.config as Config).content.locale}
                                showLabel={true}
                            /> : <div />
                    }
                >
                    <Column>
                        {data?.id &&
                            <>
                                <Styles.Details>
                                    <Typography.SmallCapsTitle>
                                        {t(`${I18N_NAMESPACE}.id`)}
                                    </Typography.SmallCapsTitle>
                                    {data?.id}
                                </Styles.Details>
                                <Divider layout="lighter" space={Space.Normal} />
                            </>
                        }
                        {data?.slug &&
                            <React.Fragment>
                                <Styles.Details>
                                    <Typography.SmallCapsTitle>
                                        {t(`${I18N_NAMESPACE}.slug`)}
                                    </Typography.SmallCapsTitle>
                                    <Styles.SlugBox>
                                        <input className="slugBox__input" readOnly value={data.slug} />
                                        <Tooltip
                                            className="slugBox__tooltip"
                                            position="top"
                                            component={
                                                <CopyClipboard
                                                    text={data.slug}
                                                    onCopy={() => this.setState({ slugCopied: true })}
                                                >
                                                    <Button layoutSquare icon="content_paste" />
                                                </CopyClipboard>
                                            }
                                        >
                                            {
                                                this.state.slugCopied
                                                    ? t(`${I18N_NAMESPACE}.copiedToClipboard`)
                                                    : t(`${I18N_NAMESPACE}.copyToClipboard`)
                                            }
                                        </Tooltip>
                                    </Styles.SlugBox>
                                </Styles.Details>
                                <Divider layout="lighter" space={Space.Normal} />
                            </React.Fragment>
                        }
                        {data?.modified_at &&
                            <>
                                <Divider layout="lighter" space={Space.Normal} />
                                <Styles.Details>
                                    <Typography.SmallCapsTitle>
                                        {t(`${I18N_NAMESPACE}.lastUpdate`)}
                                    </Typography.SmallCapsTitle>
                                    {data?.modified_at}
                                </Styles.Details>
                            </>
                        }
                        {data?.is_active !== undefined && (
                            <React.Fragment>
                                <Switch
                                    fullWidth
                                    reverseLayout
                                    value={data?.is_active || false}
                                    name="is_active"
                                    onChange={(value: boolean, name: string) =>
                                        setData({
                                            ...data,
                                            is_active: value,
                                        })
                                    }
                                >
                                    <Trans id={`${I18N_NAMESPACE}.publishActive`} />
                                </Switch>
                                <Divider layout="lighter" space={Space.Normal} />
                            </React.Fragment>
                        )}
                        {data?.active !== undefined && (
                            <React.Fragment>
                                <Switch
                                    fullWidth
                                    reverseLayout
                                    value={data?.active || false}
                                    name="active"
                                    onChange={(active: boolean) =>
                                        setData({
                                            ...data,
                                            active,
                                        })
                                    }
                                >
                                    <Trans id={`${I18N_NAMESPACE}.publishActive`} />
                                </Switch>
                                <Divider layout="lighter" space={Space.Normal} />
                            </React.Fragment>
                        )}
                        <Styles.ResetContent onClick={this.resetContent}>
                            <Typography.RegularText>
                                <Trans id={`${I18N_NAMESPACE}.resetContent`} />
                            </Typography.RegularText>
                            <Icon icon="undo" color={colors.neutral.dark} />
                        </Styles.ResetContent>
                        {saveAction &&
                            <>
                                <Divider layout="lighter" space={Space.Normal} />
                                <Divider space={Space.Small} />
                                <PublishActions
                                    saving={saving}
                                    saveAction={saveAction}
                                    cancelAction={cancelAction}
                                    dataToSave={dataToSave}
                                />
                            </>
                        }
                    </Column>
                </WrapperWith.TitleActions>
                <Divider space={Space.Tiny} />
            </React.Fragment>
        )
    }

    /** Resets your form data */
    private resetContent = () => {
        if (this.props.resetData) {
            this.props.resetData();
        }

    }
}

export const Publish = withI18n(APP_TRANSLATIONS)(PublishComponent);
