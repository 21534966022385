import React, { PureComponent } from "react"
import styled, { css } from "styled-components";
import ContentLoader from "react-content-loader";
import { colors, font } from "../../styles";

const datatableHeight: number = 18;

interface LineProps {
    isTitle?: boolean;
}

const Line = styled.div<LineProps>`
    color: ${colors.neutral.darker};
    font-size: 14px;
    font-weight: ${font.weight.regular};
    line-height: 18px;
    border-bottom: 1px solid ${colors.neutral.light};
    padding-top: 19px;
    padding-bottom: 18px;
    width: 100%;

    ${(props: LineProps) =>
        props.isTitle &&
        css`
            color: ${colors.neutral.darker};
            font-size: ${font.size.xs};
            font-weight: ${font.weight.semiBold};
            letter-spacing: 1.4px;
            line-height: 16px;
            margin-top: 10px;
            padding-bottom: 8px;
            text-transform: uppercase;
        `}
`;

const StyledContentLoader = styled(ContentLoader)`
    width: 100%;
    height: ${datatableHeight}px;
`;

export class Datatable extends PureComponent {
    public render() {
        const line = {
            x: 0,
            y: 0,
            width: 400,
            height: datatableHeight,
            radius: 2,
        };
        const content = [];
        for (let i = 0; i < 10; i++) {
            content.push(
                <Line key={i}>
                    <StyledContentLoader
                        primaryColor={colors.neutral.light}
                        secondaryColor={colors.neutral.lighter}
                        height={16}
                    >
                        <rect
                            x={line.x}
                            y={line.y}
                            rx={line.radius}
                            ry={line.radius}
                            width={line.width}
                            height={line.height}
                        />
                    </StyledContentLoader>
                </Line>
            );
        }
        return content;
    }
}
