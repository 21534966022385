import React from "react";
import { PureComponent } from "@7egend/web.core/lib/base/component";
import { Slider } from "../../libs/react-slick"

import styles from "./carousel.module.css";

/**
 * define all default params
 */
export interface Settings {
    className?: string,
    /* display arrows for navigation */
    arrows?: boolean,
    /* play automatically slider */
    autoPlay?: boolean,
    /* duration of active slider on autoPlay */
    autoPlaySpeed?: number,
    /* active slide show always on the center of slider */
    centerMode?: boolean,
    /* Side padding when in center mode (px or %) */
    centerPadding?: string,
    /* show navigation by dots */
    dots?: boolean,
    /* number os slides to scroll when an action is made */
    slidesToScroll?: number,
    /* number of slides to show at the same time */
    slidesToShow?: number,
    /* animation speed in ms */
    speed?: number,
    /** variable height */
    adaptiveHeight?: boolean,
    /* determine if slides have the same witdh, or auto for each one */
    variableWidth?: boolean,
    /* orientation of slider vertical or not, instead of default horizontal */
    vertical?: boolean,
    /** option to add infinite loop. False by default */
    infinite?: boolean,
    /* optional function responsible for returning active slide if, when component is updated */
    onActiveChange?: (slide: number) => void,
    /** slide change by dragging */
    draggable?: boolean,
    /** slide change by swiping */
    swipe?: boolean,
    /** slide transition is fade if true, or animation is from right to left and vice-versa if false  */
    fade?: boolean,
    pauseOnHover?: boolean,
    /** Lazy load mode. Progressive: loads initial images and others after page load; Ondemand: loads only visible */
    lazyLoad?: "progressive" | "ondemand",
    /** after slider change */
    afterChange?: (currentSlide: number) => void,
    /** Next Arrow Element */
    nextArrow?: React.ComponentType<any> | React.ComponentClass<any> | JSX.Element,
    /** Previous Arrow Element */
    prevArrow?: React.ComponentType<any> | React.ComponentClass<any> | JSX.Element,
    /** initial slide */
    initialSlide?: number,
}

/**
 * define structure for responsive settings
 * settings correspond to an array of the same settings as the default carousel
 */
export interface ResponsiveSettings {
    /* number to determine the breakpoint for the slider to adapt */
    breakpoint: number,
    /* array of settings. same as default slider */
    settings: Settings
}

/**
 * extend from default settings and responsive.
 * responsive expects the same structure as the default params
 */
export interface CarouselProps extends Settings {
    responsive?: ResponsiveSettings[]
}

/**
 * define state with active, to set the id of active slide
 */
interface State {
    active: number
}

/**
 * define all default props
 */
const defaultProps = {
    arrows: true,
    autoPlay: false,
    autoPlaySpeed: 1000,
    centerMode: false,
    className: "",
    dots: false,
    slidesToScroll: 1,
    slidesToShow: 1,
    speed: 500,
    variableWidth: false,
    vertical: false,
    infinite: false,
    responsive: [],
    draggable: true,
    swipe: true,
    fade: false,
    pauseOnHover: false,
}

/**
 * # Component Carousel
 * This component renders a slick based carousel
 * It supports multiple type of contents: video, image, html
 *
 * ## How to use
 *
 * Example:
 * ```
 * <CarouselComponent ref={this.sliderRef} dots={true} variableWidth={true} centerMode={true} responsive={responsive}>
 *    <div>
 *       <MediaComponent data={data[0]} options={options} /
 *    </div
 *    <div>
 *       <MediaComponent data={data[1]} options={options} /
 *    </div
 *    <div>
 *       <h2>Lorem ipsum dolor sit amet</h2>
 *       <p>Morbi eros justo, tincidunt et dolor a, convallis efficitur quam. Integer non ipsum non urna convallis interdum vel sit amet lectus. Nullam volutpat libero nisi, quis rutrum tellus convallis ut. Maecenas ultrices lacus molestie magna malesuada, quis dignissim augue varius. Ut dignissim sem eu mi tempor, sit amet ultricies odio dictum. Aenean et sodales lectus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Phasellus ultricies enim vel odio molestie maximus. Sed posuere leo pulvinar, hendrerit libero sit amet, porttitor leo. Sed id consequat lorem. </p
 *    </div>
 * </CarouselComponent>
 * ```
 *
 *
 * ### Parameters:
 * - arrows: true,
 * - autoPlay: false,
 * - autoPlaySpeed: 500
 * - centerMode: false,
 * - className: '',
 * - dots: false,
 * - slidesToScroll: 1,
 * - slidesToShow: 1,
 * - speed: 500,
 * - variableWidth: false,
 * - vertical: false,
 * - infinite: false,
 * - responsive: []
 *
 * ## Events
 * - goToNext: show the next slide
 * - goToPrev: show the previous slide
 * - goToSlide: show the slide wanted, by specifing the number slide
 * - getActive return the slide that is being shown (active)
 * - setActive: update state with the new slide id
 *
 * ### Using Events
 *  Each carousel should have a reference. Example: `<CarouselComponent ref={this.sliderRef} />`.
 *  Then events can be triggered with that reference, Example: `this.sliderRef.current.getActive()`;
 *
 *  Note: Dont't forget `current` after reference
 *
 */

export class CarouselComponent extends PureComponent<CarouselProps, State> {

    // set default props
    public static defaultProps = defaultProps;

    public state: State = {
        active: 0,
    }

    // define type for slider reference
    private sliderRef: React.RefObject<any> = React.createRef();

    public componentDidUpdate(preProps: CarouselProps, prevState: State) {
        if (prevState.active !== this.state.active) {
            if (typeof this.props.onActiveChange === "function") {
                try {
                    this.props.onActiveChange(this.getActive());
                } catch (error) {
                    // do nothing?
                }
            }
        }
    }

    /**
     * function responsible for making slider show the next slide
     * if it's the last slide, shows the first one
     */
    public goToNext = () => {
        if (this.sliderRef.current) {
            this.sliderRef.current.slickNext();
        }
    }

    /**
     * function responsible for making slider show the previous slide
     * if it's the first slide, shows the last one
     */
    public goToPrev = () => {
        if (this.sliderRef.current) {
            this.sliderRef.current.slickPrev();
        }
    }

    /**
     * function responsible for making slider show the slide wanted, by specifing the number slide
     * @param slide id of slide to show
     */
    public goToSlide = (slide: number) => {
        if (this.sliderRef.current) {
            this.sliderRef.current.slickGoTo(slide);
        }
    }

    /**
     * function to return the slide that is being shown (active)
     */
    public getActive = () => {
        return this.state.active;
    }

    public render() {
        const SliderComponent = Slider as any // hack here, ref is not recognized by loadable

        return (
            <div className={styles.container}>
                <SliderComponent
                    ref={this.sliderRef}
                    arrows={this.props.arrows}
                    autoplay={this.props.autoPlay}
                    centerMode={this.props.centerMode}
                    className={this.props.className}
                    dots={this.props.dots}
                    slidesToScroll={this.props.slidesToScroll}
                    slidesToShow={this.props.slidesToShow}
                    speed={this.props.speed}
                    variableWidth={this.props.variableWidth}
                    vertical={this.props.vertical}
                    responsive={this.props.responsive}
                    beforeChange={this.setActive}
                    infinite={this.props.infinite}
                    autoplaySpeed={this.props.autoPlaySpeed}
                    pauseOnHover={this.props.pauseOnHover}
                    fade={this.props.fade}
                    draggable={this.props.draggable}
                    swipe={this.props.swipe}
                    centerPadding={"0"} // up to this moment, this is not a parameter to be changed
                    lazyLoad={this.props.lazyLoad}
                    afterChange={this.props.afterChange}
                    nextArrow={this.props.nextArrow}
                    prevArrow={this.props.prevArrow}
                    initialSlide={this.props.initialSlide || 0}
                >
                    {this.props.children}
                </SliderComponent>
            </div>
        )
    }

    /**
     * function to update state with the new slide id
     * @param current id of currently active slide
     * @param next id of next active slide
     */
    private setActive = (current: number, next: number) => {
        this.setState({ active: next });
    }
}
