import { HttpPostProcessor } from "../../../domain/extensions/http";
import { ground } from "../../../ground";

export const httpPostJson: HttpPostProcessor = (status, headers, body) => {
    if ("content-type" in headers && headers["content-type"] === "application/json" && typeof body === "string") {
        try {
            // Try to parse the json directly
            return JSON.parse(body)
        } catch (err) {
            // Failed to parse
            ground.framework.log.warn("Failed to parse HTTP JSON response, trying recover mode", {body, err})

            // Maybe there is something more
            // Try to locate the json
            const start = body.indexOf("{\"") || body.indexOf("[{")
            const end = body.lastIndexOf("}") || body.lastIndexOf("}]")

            const bodyExtracted = body.slice(start, end + 1)

            try {
                const result = JSON.parse(bodyExtracted)
                ground.framework.log.debug("HTTP JSON response recovered", result)
                return result
            } catch (err) {
                // True failure, throw an error
                ground.framework.log.error("Failed to parse HTTP JSON response", err, {body, bodyExtracted})
                // For now do nothing
                throw err
            }
        }
    }

    return body
}
