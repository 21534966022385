import { Module } from "../../../bootstrap";
import loadable from "@loadable/component";
import { CMSConfig } from "../config"

const facebook = {
    route:
    {
        component: loadable(async () => (await import('./Facebook')).Facebook),
        path: "cms/social/facebook",
        exact: true
    },
    menu:
    {
        title: "Facebook",
        path: "cms/social/facebook",
        permission: "cms.social.facebook"
    }
}

const instagram = {
    route: {
        component: loadable(async () => (await import('./Instagram')).Instagram),
        path: "cms/social/instagram",
        exact: true,
    },
    menu:
    {
        title: "Instagram",
        path: "cms/social/instagram",
        permission: "cms.social.instagram"
    },
}

const twitter = {
    route: {
        component: loadable(async () => (await import('./Twitter')).Twitter),
        path: "cms/social/twitter",
        exact: true,
    },
    menu:
    {
        title: "Twitter",
        path: "cms/social/twitter",
        permission: "cms.social.twitter"
    },
}

export const socialSetup = (config: CMSConfig) => {
    const route = [];
    const menu = [];

    if (config.social?.facebook) {
        route.push(facebook.route)
        menu.push(facebook.menu)
    }
    if (config.social?.instagram) {
        route.push(instagram.route)
        menu.push(instagram.menu)
    }
    if (config.social?.twitter) {
        route.push(twitter.route)
        menu.push(twitter.menu)
    }

    return {
        routes: route,
        menus: [{
            path: "cms/social",
            readOnly: true,
            title: {
                key: "cms.social.menu.title",
                en: "Social",
                es: "Social",
                pt: "Social",
            },
            children: menu,
            expandable: true,
            permission: "cms.social.list"
        }
        ]
    } as Module
}
