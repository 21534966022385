import React from "react";
import {
    RouteComponentProps,
    withRouter
} from "react-router";
import { WithQueryParamsProps, withQueryParams } from "@7egend/web.core/lib/components/withQueryParams";
import { useFramework } from "@7egend/web.core/lib/components/useFramework";
import { WrapperWithTitleActions } from "../WithTitleActions"
import { Language } from "../../Language"
import { Config } from "../../../base/config";

interface WrapperWithLanguageProps {
    /** wrapper title */
    title: React.ReactComponentElement<any> | React.ComponentType<any> | JSX.Element | string;

    /** wrapper actions */
    actions?: React.ReactComponentElement<any> | React.ComponentType<any> | JSX.Element | string;

    /** current language selected */
    currentLanguage: string;

    /** event handler on change language */
    onChangeLanguage?: (language: string) => void

}



const Component: React.FC<
    WrapperWithLanguageProps &
    RouteComponentProps<{ language: string }> &
    WithQueryParamsProps<{ language: string }>
> = ({ title, children, history, onChangeLanguage, currentLanguage, actions }) => {
    const fw = useFramework();

    const updateLanguage = React.useCallback((language: string) => {
        history.push({
            search: `?language=${language}`
        });

        if (onChangeLanguage) {
            onChangeLanguage(language)
        }
    }, [currentLanguage]);

    return (
        <WrapperWithTitleActions
            title={title}
            actions={
                <React.Fragment>
                    <Language
                        languages={(fw.config as Config).content.locale}
                        position="bottom"
                        align="center"
                        currentLanguage={currentLanguage}
                        updateLanguage={(language: string) => updateLanguage(language)}
                    />
                    {actions}
                </React.Fragment>
            }
        >
            {children}
        </WrapperWithTitleActions>
    )
}

/**
 *
 * # WrapperWithLanguage
 *
 * ## How to use:
 *
 * ```jsx
 * <WrapperWithLanguage
 *    title="title"
 *    currentLanguage={this.state.language}
 *    onChangeLanguage={...onChange}
 * >
 *   <div>
 *      hello - {this.state.language}
 *   </div>
 * </WrapperWithLanguage>
 * ```
 */
export const WrapperWithLanguage = withRouter(withQueryParams(Component))
