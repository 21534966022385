import React from "react";
import { CorePureComponent } from "@7egend/web.core";
import {
    withRouter,
    RouteComponentProps
} from "react-router";
import { Trans } from "@7egend/web.core/lib/components/i18n";
import { WithSessionProps, withSession } from "@7egend/web.core.security/lib/components/withSession/withSession";
import DXPIcon from "../../../assets/DXP.svg";
import {
    StyledLink,
    StyledLogo,
    StyledLogoAndPlatform,
    StyledMenu,
    StyledMenuButton,
    StyledMenuWrapper,
    StyledPlatform
} from "./styles"
import { Divider, Space } from "../../Divider";
import { Typography } from "../../Typography";
import { Module } from "./Module";
import { Config } from "../../../base/config";
import { getActivePaths } from "../../../utils";
import { withI18n, WithI18nProps } from "@7egend/web.core/lib/components/withI18n";
import { APP_TRANSLATIONS } from "../../../locale";
import { I18N_KEY_CMS_PAGES_NAVIGATION, I18N_KEY_GLOBAL } from "../../../base/i18n";

export interface MenuItems {
    /** menu title */
    title: string | { [locale: string]: string }
    /** menu icon */
    icon?: string
    /** menu path */
    path: string
    /** menu readOnly */
    readOnly?: boolean
    /** menu visibility
     * @default true
     */
    visible?: boolean
    /** menu expandable
     * @default false
     */
    expandable?: boolean
    /** menu enabled */
    enabled?: boolean,
    /** sub menu item */
    children?: MenuItems[],
    /** permission key */
    permission?: string,
}

export interface MenuProps {
    items: MenuItems[]
    menuOpen: boolean
    closeMenu: () => void
}

const I18N_NAMESPACE = I18N_KEY_CMS_PAGES_NAVIGATION

/**
 *
 * # Menu Component
 *
 */
class MenuComponent extends CorePureComponent<WithI18nProps & MenuProps & RouteComponentProps<{ language?: string }> & WithSessionProps> {

    public render() {
        const clientName = (this.fw.config as Config).client.name;
        const flags = (this.fw.config as Config).flags;
        const { t } = this.props;

        return (
            <StyledMenu {...this.props}>
                <StyledLogoAndPlatform>
                    <StyledMenuButton icon="close" onClick={this.closeMenu} />
                    <StyledLink href="/">
                        <StyledLogo alt="7EGEND DXP" src={DXPIcon} />
                        <StyledPlatform>
                            <span className="platform"><Trans id={`${I18N_KEY_GLOBAL}.title`} /></span>
                            <small className="client">{clientName}</small>
                        </StyledPlatform>
                    </StyledLink>
                </StyledLogoAndPlatform>
                <StyledMenuWrapper>
                    {this.renderMenu(this.props.items, 0)}
                    {flags.core_menu_featured && (
                        <React.Fragment>
                            <Divider space={Space.Small} />
                            <Typography.BoldText> {t(`${I18N_NAMESPACE}.other`)} <Trans id={`${I18N_KEY_GLOBAL}.title`} /> {t(`${I18N_NAMESPACE}.products`)}</Typography.BoldText>
                            <Divider space={Space.Small} />
                            {this.renderFeatureProducts(this.props.items)}
                        </React.Fragment>
                    )}
                </StyledMenuWrapper>
            </StyledMenu>
        )
    }

    private renderMenu(items: MenuItems[], level: number): any {
        const activeItems = items
            .filter((item) => item.enabled !== false)
            .filter((item) => item.visible !== false)

        const isSingle = activeItems.length === 1

        return activeItems
            .map((item: MenuItems) => {
                return (
                    <Module
                        key={item.path}
                        module={item}
                        items={item.children || []}
                        active={
                            getActivePaths(this.props.location.pathname, this.props.match.params.language, this.props.items).map((itemMenu: any) => itemMenu.path)
                        }
                        initialActiveState={isSingle ? true : undefined}
                    />
                )
            })
    }

    private renderFeatureProducts(items: MenuItems[]): any {
        return items
            .filter((itemFilter: MenuItems) => itemFilter.enabled === false)
            .map((item: MenuItems, index: number) => {

                if (item.visible === false) {
                    return null;
                }

                return (
                    <Module
                        key={item.path}
                        module={item}
                    />
                )
            })
    }

    /** It closes the menu in mobile */
    private closeMenu = () => {
        if (this.props.closeMenu) {
            this.props.closeMenu();
        }
    }
}

export const Menu = withRouter(withSession(withI18n(APP_TRANSLATIONS)(MenuComponent))) as any
