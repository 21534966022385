import React from "react";
import { CorePureComponent } from "@7egend/web.core"
import { StyledUploader } from "../styles"
import { uniqueId } from "@7egend/web.core/lib/utils"
import { LimitSizeUpload } from "../../../constants"
import { FileInfo, UploaderProps } from "../domain"

interface DocumentProps extends React.PropsWithChildren<UploaderProps> {
    /** upload action */
    uploadAction?: (base64: FileInfo[]) => void
}

const UNIQUE_ID = uniqueId("document_upload")
const ACCEPTED_TYPES = undefined // all, previous was "application/pdf,x-application/zpl";

/**
 * # Uploader Document
 * 
 * ## How to use: 
 * 
 * ```jsx
 * <Uploader.Document
 *      uploadAction={this.handleUpload}
 *      label="Upload Document"
 *      info="docx, pdf"
 * />
 * ```
 */
export class Document extends CorePureComponent<DocumentProps> {

    /** define type for input file */
    private inputRef: React.RefObject<any> = React.createRef();

    public render() {
        const { info, label } = this.props

        return (
            <StyledUploader.Wrapper>
                <StyledUploader.Label>
                    <StyledUploader.Action
                        onClick={() => {
                            this.inputRef.current.click();
                        }}
                        layout="alt"
                        icon="insert_drive_file"
                    />
                    <input
                        ref={this.inputRef}
                        style={{ display: "none" }}
                        type="file"
                        accept={ACCEPTED_TYPES}
                        multiple={true}
                        id={UNIQUE_ID}
                        name={UNIQUE_ID}
                        onChange={this.onFileSelect}
                    />
                </StyledUploader.Label>
                {label && <StyledUploader.Header>{label}</StyledUploader.Header>}
                {info && <StyledUploader.SubHeader variant="neutral">{info}</StyledUploader.SubHeader>}
            </StyledUploader.Wrapper>
        )
    }

    private getFileFromInput(file: any): Promise<FileInfo> {
        return new Promise((res, rej) => {
            const reader: any = new (this.fw.dom.getGlobal()).FileReader();
            reader.onerror = rej;
            reader.onload = () => {
                res({
                    base64: reader.result,
                    name: file.name,
                    error: file.size > LimitSizeUpload ? true : false,
                });
            };
            reader.readAsDataURL(file);
        });
    }

    private onFileSelect = (e: React.ChangeEvent<HTMLInputElement>): void => {
        e.persist();

        const files: any = (e.target as any).files;

        Promise.all(
            Object.keys(files).map(key => this.getFileFromInput(files[key]))
        )
            .then((filesBase64) => {
                if (this.props.uploadAction) {
                    /** send each base64 image and the total number of images to upload */
                    this.props.uploadAction(filesBase64);
                }
            })
            .catch((err) => {
                this.fw.log.error("Get File Error", err);
            });
    }
}
