import styled, { css } from "styled-components"
import { Typography } from "../Typography";
import { Button } from "../Button";
import { StyledModalContent, Modal as ModalComponent } from "../Modal/Modal";
import { colors, queries } from "../../styles";
import { StyledButton } from "../Button/styles";

const ActionSize: string = "40px";

/** Label - Is where the magic happens :D ! */
const Label = styled.label<{rounded?: boolean}>`
    display: block;
    border-radius: 10px;
    position: relative;
    padding-top: 100%;
    width: 100%;
    cursor: pointer;
    background-color: ${colors.neutral.lighter};

    ${({ rounded }) => rounded && css`
       height: 112px; 
       width: 112px; 
       padding-top: 0;
       border-radius: 100%;
    `}
`;

const Actions = styled.div<{ hasPreviewImage?: boolean}>`
    border-radius: 10px;
    position: relative;
    padding-top: 100%;
    width: 100%;
    cursor: pointer;
    background-color: ${colors.neutral.lighter};


    ${({ hasPreviewImage }) => hasPreviewImage && css`
        ${Action} {
            opacity: 0;
        }

        &:hover {
            ${Action} {
                opacity: 1;
            }
        }
    `}
`

/** Action - Button click */
const Action = styled(Button)`
    position:  absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: ${ActionSize};
    height: ${ActionSize};
`

/** Flat text */
const FlatInfo = styled.div`
    position: absolute;
    top: calc(50% + ${ActionSize});
    left: 50%;
    transform: translate(-50%, -50%);
`;

/** Wrapper - Contains the entire uploader */
const Wrapper = styled.div<{heightLimited?: boolean}>`
    position: relative;

    &::after{
        content: "";
        width: 100%;
        display: block;
        white-space: nowrap;
        clear: both;
    }

    ${({ heightLimited }) => heightLimited && css`

        ${Actions} {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            padding-top: 0;
            padding: 40px 0;
        }

        ${Action}, ${FlatInfo} {
            position: relative;
            top: auto;
            left: auto;
            transform: translate(0, 0);
        }

        ${FlatInfo} {
            margin-top: 16px;
            width: 100%;
            display: inline-flex;
            justify-content: center;
            text-align: center;
        }
    `}
`

/** Header - Title of upload type */
const Header = styled(Typography.SmallText)`
    & {
        margin: 6px 0 2px;
    }
`;

/** SubHeader - accepted extensions */
const SubHeader = styled(Typography.SmallText)`
    & {
        margin: 2px 0;
    }
`;

const ActionPreviewClean = styled<any>(Action)`
    & {
        opacity: 0;

        &:hover {
            opacity: 1;
        }
    }
`

const ModalVideo = styled(ModalComponent)`
    & {
        ${StyledModalContent} {
            ${queries.minLG} {
                width: 100%;
                max-width: 480px;
            }
        }

        ${Label} {
            padding-top: 0;
            height: 120px;
        }

        ${Wrapper} {
            margin-top: 8px;
        }
    }
`

const ModalVideoButtons = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    ${StyledButton} {
        margin: 0 8px;
    }
`

/** Styled Uploader */
export const StyledUploader = {
    Wrapper,
    Label,
    Action,
    Actions,
    Header,
    SubHeader,
    ModalVideo,
    ModalVideoButtons,
    ActionPreviewClean,
    FlatInfo
}
