import { React, CoreComponent, Store } from "@7egend/web.core/lib/base"
import { State } from "../../services/state";
import { SessionState, actions } from "../../services/session";
import { Framework } from "../../base/framework";
import { SocialProvider } from "../../services/session/actions";
import { User } from "@7egend/web.core.people/lib/dlos/user"
import { Session } from "../../dlos/session";

export type { SocialProvider }

/**
 * Own component props
 */
export interface OwnProps {
    // nothing
}

/**
 * Store props
 */
export interface StoreProps {
    session: SessionState
}

/**
 * Store dispatch props
 */
export interface StoreDispatchProps {
    /**
     * Loads a new session
     */
    createSessionKeySecret: (key: string, secret: string, terms?: number) => Promise<any>;
    createSessionFacebook: (token: string, terms?: number) => Promise<any>;
    createSessionGoogle: (token: string, terms?: number) => Promise<any>;
    createSessionSocial: (network: SocialProvider | string, token: string, terms?: number) => Promise<any>;
    createSessionMagicLink: (email: string) => Promise<any>;
    confirmSessionMagicLink: (token: string, code: string, terms?: number) => Promise<any>;
    submitForgotPassword: (email: string) => Promise<any>;
    updateUserPassword: (token: string, secret: string) => Promise<any>;
    updateUser: (data: User) => Promise<any>;
    updateUserImage: (image: string) => Promise<any>;
    logoutUser: () => void;
    deleteUserData: (uuid: string) => Promise<any>;
    getSession: () => Promise<Session | undefined>;
}

/**
 * Props that are going to be injected in the target component
 */
export interface WithSessionProps extends StoreProps, StoreDispatchProps {
    isAuthenticated: () => boolean;
    hasAuthenticationToken: () => boolean;
    setAuthenticationToken: (token: string) => void;
}

/** Loading semaphore for multiple instances lock */
let SESSION_LOADING_LOCK: boolean = false

/**
 * HOC that provides access to the session
 * @param WrappedComponent React component
 */
export const withSession: <P extends WithSessionProps>(WrappedComponent: React.ComponentType<P>) => React.ComponentType<Omit<P, keyof WithSessionProps>> = (WrappedComponent) => {

    //#region Store mapping

    /**
     * Map store state to component props
     */
    function mapStateToProps(state: State, ownProps: OwnProps): StoreProps {
        return {
            session: state.security.session,
        }
    }

    /**
     * Map store dispatch actions to props
     */
    function mapDispatchToProps(dispatch: Store.ThunkDispatch<any, void, Store.Action>, ownProps: OwnProps): StoreDispatchProps {
        return {
            createSessionKeySecret: (key, secret, terms) => dispatch(actions.createSessionKeySecret(key, secret, terms)),
            createSessionFacebook: (token, terms?) => dispatch(actions.createSessionSocial(SocialProvider.Facebook, token, terms)),
            createSessionGoogle: (token, terms?) => dispatch(actions.createSessionSocial(SocialProvider.Google, token, terms)),
            createSessionSocial: (network, token, terms?) => dispatch(actions.createSessionSocial(network, token, terms)),
            createSessionMagicLink: (email) => dispatch(actions.createSessionMagicLink(email)),
            confirmSessionMagicLink: (token, code, terms?) => dispatch(actions.confirmSessionMagicLink(token, code, terms)),
            submitForgotPassword: (email) => dispatch(actions.submitForgotPassword(email)),
            updateUserPassword: (token, secret) => dispatch(actions.updateUserPassword(token, secret)),
            updateUser: (data) => dispatch(actions.updateUser(data)),
            updateUserImage: (image) => dispatch(actions.updateUserImage(image)),
            logoutUser: () => dispatch(actions.logoutUser()),
            deleteUserData: (uuid) => dispatch(actions.deleteUserData(uuid)),
            getSession: () => dispatch(actions.getSession()),
        }
    }

    //#endregion

    class WithSessionComponent extends CoreComponent<StoreProps & StoreDispatchProps> {

        public async componentDidMount() {
            // Need to load session if there is a token but not a session in the store
            if (this.hasAuthenticationToken()) {
                if (this.props.session.session === undefined && this.props.session.loading === false) {
                    if (SESSION_LOADING_LOCK === false) {
                        SESSION_LOADING_LOCK = true;
                        await this.props.getSession();
                        SESSION_LOADING_LOCK = false;
                    }
                }
            }
        }

        public componentDidUpdate() {
            // Handle the case the session is wrong or invalid
            // tslint:disable-next-line: triple-equals
            if (this.props.session.error && this.props.session.error.http == 403 && this.hasAuthenticationToken()) {
                const auth = (this.framework as Framework).auth
                auth.clear()
            }
        }

        public render() {
            return (
                <WrappedComponent
                    {...this.props as any}
                    isAuthenticated={this.isAuthenticated}
                    hasAuthenticationToken={this.hasAuthenticationToken}
                    setAuthenticationToken={this.setAuthenticationToken}
                />
            )
        }

        /**
         * Indicates the authentication state
         */
        public isAuthenticated = (): boolean => {
            return this.props.session && this.props.session.session && this.props.session.session.access_token !== undefined || false;
        }

        /**
         * Indicates if the authentication has started and we are wa
         */
        public hasAuthenticationToken = (): boolean => {
            const auth = (this.framework as Framework).auth
            return auth.isAuthenticated() && !!auth.getAuthToken()
        }

        /**
         * Sets a new authentication token and automatically is saved in to the localStorage.
         */
        public setAuthenticationToken = (token: string): void => {
            const auth = (this.framework as Framework).auth
            auth.setAuthToken(token);
        }
    }

    return Store.connect(mapStateToProps, mapDispatchToProps)(WithSessionComponent) as any;
}

/** @deprecated */
export const WithSession = withSession
