import React, { Fragment } from "react";
import { CorePureComponent } from "@7egend/web.core";
import { RouteComponentProps, withRouter } from "react-router";
import { withDloService, WithDloServiceProps } from "@7egend/web.core/lib/components/withDloService";
import { MediaList, MediaListData } from "../MediaList";
import { FileInfo, Uploader } from "../Uploader";
import { Column, Row, Container } from "../Structure";
import { Placeholder, PlaceholderType } from "../Placeholder";
import { StyledFooter } from "../Datatable/styles";
import { Counter } from "../Datatable/Counter";
import Paginator from "../Datatable/Paginator";
import { dlos } from "../../dlos";
import { SnackbarContainer } from "../SnackbarContainer";
import { WrapperWithTitleActions } from "../Wrapper/WithTitleActions";
import { Button } from "../Button";
import { Styled } from "./styles";
import { DialogTypes, Dialog } from "../Dialog";
import { SnackbarLayout } from "../Snackbar";
import { FilterProps, Filters } from "../Datatable/interfaces";
import { DefaultFilters } from "../Datatable"
import { debounce } from "@7egend/web.core/lib/utils";
import { Divider, Space } from "../Divider";
import { errorMessage } from "../../utils";
import { withI18n, WithI18nProps } from "@7egend/web.core/lib/components/withI18n";
import { APP_TRANSLATIONS } from "../../locale";
import { I18N_KEY_CMS_MULTIMEDIA_LIST, I18N_KEY_GLOBAL } from "../../base/i18n";
import { IMAGE_UPLOAD_TYPES } from "../../modules/cms/Multimedia/config";
import { CanView, withCanView, WithCanViewProps } from "../CanView";
import { Trans } from "@7egend/web.core/lib/components/i18n";
import { MimeTypesByExtension } from "../../modules/cms/base/constants";
import { CMSConfig } from "../../modules";
import { MediumType } from "@7egend/web.core.media/lib/dlos";

export { MediaListData }

const MULTIMEDIA_CONFIG = {
    fetch: dlos.cms.MediaGetAllInput,
    title: "Multimedia",
    rows: {
        lengths: [24, 60],
    },
    filters: [(props: FilterProps) =>
        <CanView action="cms.multimedia.search">
            <DefaultFilters.Search key={"search-filter"} {...props} />
        </CanView>
    ],
    columns: [
        {
            param: "date",
            title: "Publication Date",
        },
        {
            param: "title",
            title: "Title",
        },
        {
            param: "url",
            title: "Url",
        },
        {
            param: "file",
            title: "File",
        },
        {
            param: "medium_type_id",
            title: "type",
        },
        {
            param: "panoramic",
            title: "panoramic",
        },
        {
            param: "uuid",
            title: "uuid",
        },
        {
            param: "thumb",
            title: "image",
        },
    ],
};

/** Multimedia Props */
export interface MultimediaProps {
    /** checked media */
    checkedMedia?: (checkedMedia: MediaListData[]) => void,
    /** limit of selectable media item  */
    limitSelectable?: number;
    /** type of media  */
    mediumType?: MediumType | string;
    /** 
     * allow upload
     * @default true
     */
    allowUpload?: boolean;
    /** specific keys needed for each block type */
    context?: {
        [key: string]: any;
    };
    /** footer actions */
    footer?: React.ReactComponentElement<any> | React.ComponentType<any> | Element;

    data?: MediaListData[];
}

/** Multimedia State */
interface MultimediaState {
    /** multimedia length */
    length: number;
    /** multimedia item start */
    start: number;
    /** total of multimedia items */
    total: number;
    /** multimedia items */
    data: MediaListData[];
    /** loading media */
    isLoading?: boolean;
    /** total of items to upload */
    totalUploading?: number;
    /** current item uploading  */
    currentUploading: number;
    /** is image uploading - show placeholder */
    isImageUploading: boolean;
    /** current image uploading */
    currentImageUploading?: string;
    /** percentage of uploading */
    uploadingPercentage?: number;
    /** is document */
    isDocument?: boolean;
    /** checked list items */
    checkedMedia: MediaListData[];
    /** is deleting */
    isDeleting: boolean;
    /** is vidoe uploading */
    isVideoUploading: boolean;
    isVideoVisible: boolean;
    /** dialog state */
    dialog: {
        isVisible: boolean;
        type?: DialogTypes;
        overlay?: boolean;
        title?: string;
        description?: string;
        showIcon?: boolean;
        cancelAction?: () => void;
        cancelText?: string;
        cancelIcon?: string;
        submitAction?: () => void;
        submitText?: string;
        submitIcon?: string;
    };
    filters: Filters,
    noResults?: boolean
}

const I18N_NAMESPACE = I18N_KEY_CMS_MULTIMEDIA_LIST

/**
 * # Multimedia Module
 *
 * ## How to use:
 *
 * ```jsx
 * <Multimedia />
 * ```
 */
class MultimediaComponent extends CorePureComponent<WithI18nProps & MultimediaProps & RouteComponentProps & WithDloServiceProps<MediaListData[] | MediaListData, {}> & WithCanViewProps, MultimediaState> {

    /** Snackbar Ref */
    private snackBarContainerRef = React.createRef<SnackbarContainer>();

    private VISIBILITY_DEFAULT = {
        ...(this.fw.config as CMSConfig).channels?.reduce<Record<string, "0">>((r, channel) => {
            r[channel.key] = "0"
            return r
        }, {})
    }

    /** Component default State */
    public state: MultimediaState = {
        length: MULTIMEDIA_CONFIG.rows.lengths[0],
        start: 1,
        total: 0,
        data: [],
        isImageUploading: false,
        currentUploading: 1,
        uploadingPercentage: 0,
        isDeleting: false,
        isVideoUploading: false,
        isVideoVisible: false,
        checkedMedia: this.props.data || [],
        dialog: {
            isVisible: false,
        },
        filters: [],
        noResults: undefined
    };

    /** Component on Mount */
    public componentDidMount() {
        const { length, start } = this.state;

        this.loadData(length, start - 1);
    }

    public componentDidUpdate({ }, prevState: MultimediaState) {
        // If the filters change, dispatch a new fetch
        if (prevState.filters !== this.state.filters) {
            // Loads new data
            this.selectPage(1); // this will update the page instead, a work around while this component is now reviewed
        }
    }

    /** Component Render */
    public render() {
        const { title, rows } = MULTIMEDIA_CONFIG;
        const { mediumType, dloRequest: { loaded } } = this.props
        const { length, total, noResults, isLoading } = this.state;
        const { t, context } = this.props;
        const canUpload = this.props.canView("cms.multimedia.upload");

        return (
            <CanView action="cms.multimedia.list">
                <SnackbarContainer ref={this.snackBarContainerRef} />
                <WrapperWithTitleActions
                    title={title}
                    heightLimited={this.props.footer ? true : false}
                    footer={this.props.footer && this.props.footer}
                    actions={
                        <React.Fragment>
                            {this.props.canView("cms.multimedia.delete") &&
                                this.state.checkedMedia &&
                                this.state.checkedMedia.length > 0 && (
                                    <Styled.DeleteItems>
                                        <Button
                                            icon="delete"
                                            onClick={() =>
                                                this.toggleDialog(
                                                    "error",
                                                    true,
                                                    t(`${I18N_NAMESPACE}.deleteMessage`),
                                                    `${t(`${I18N_NAMESPACE}.deleteMessageConfirm`)} ${this
                                                        .state.checkedMedia &&
                                                    this.state.checkedMedia
                                                        .length} items?`,
                                                    false,
                                                    this.toggleDialog,
                                                    t(`${I18N_KEY_GLOBAL}.cancel`),
                                                    undefined,
                                                    this.deleteAllCheckedMedia,
                                                    t(`${I18N_KEY_GLOBAL}.delete`),
                                                    "delete"
                                                )
                                            }
                                            layoutIconPosition="left"
                                        >
                                            {t(`${I18N_KEY_GLOBAL}.delete`)}{" "}
                                            {this.state.checkedMedia.length}{" "}
                                            {t(`${I18N_NAMESPACE}.items`)}
                                        </Button>
                                    </Styled.DeleteItems>
                                )}
                            {this.renderSearch()}
                        </React.Fragment>
                    }
                >
                    <Container fluid>
                        <Uploader.DnD
                            enabled={canUpload}
                            mediumType={mediumType}
                            droppedFiles={this.uploadAction}
                            onError={this.addSnackbar}
                        >
                            {canUpload && (
                                <Row>
                                    {!mediumType || (mediumType === MediumType.Image && !context?.panoramic) ?
                                        <Column xs={6} sm={3} xl={2}>
                                            <Uploader.Image
                                                allowUpload={this.props.allowUpload}
                                                label={this.props.allowUpload === false ? t(`${I18N_NAMESPACE}.createImage`) : t(`${I18N_NAMESPACE}.uploadImage`)}
                                                info={IMAGE_UPLOAD_TYPES}
                                                uploadAction={this.uploadAction}
                                                multiple={true}
                                            />
                                            <Divider space={Space.Small} />
                                        </Column>
                                        : null
                                    }
                                    {!mediumType || (mediumType === MediumType.Image && context && context.panoramic) ?
                                        <Column xs={6} sm={3} xl={2}>
                                            <Uploader.ImagePanoramic
                                                allowUpload={this.props.allowUpload}
                                                label={this.props.allowUpload === false ? t(`${I18N_NAMESPACE}.createImage360`) : t(`${I18N_NAMESPACE}.uploadImage360`)}
                                                info={IMAGE_UPLOAD_TYPES}
                                                uploadAction={this.uploadAction}
                                                multiple={true}
                                            />
                                            <Divider space={Space.Small} />
                                        </Column>
                                        : null
                                    }
                                    {!mediumType || mediumType === MediumType.Video ?
                                        <Column xs={6} sm={3} xl={2}>
                                            <Uploader.Video
                                                allowUpload={this.props.allowUpload}
                                                label={this.props.allowUpload === false ? t(`${I18N_NAMESPACE}.createVideo`) : t(`${I18N_NAMESPACE}.uploadVideo`)}
                                                info={t(`${I18N_NAMESPACE}.uploadVideoInfo`)}
                                                uploadAction={this.uploadVideo}
                                                onError={this.addSnackbar}
                                                isUploading={this.state.isVideoUploading}
                                                handleModal={this.toggleModalVideo}
                                                isVideoVisible={this.state.isVideoVisible}
                                            />
                                            <Divider space={Space.Small} />
                                        </Column>
                                        : null
                                    }
                                    {!mediumType || mediumType === MediumType.Stream ?
                                        <Column xs={6} sm={3} xl={2}>
                                            <Uploader.Stream
                                                allowUpload={this.props.allowUpload}
                                                label={this.props.allowUpload === false ? t(`${I18N_NAMESPACE}.createStream`) : t(`${I18N_NAMESPACE}.uploadStream`)}
                                                info={t(`${I18N_NAMESPACE}.uploadStreamInfo`)}
                                                uploadAction={this.uploadVideo}
                                                onError={this.addSnackbar}
                                                isUploading={this.state.isVideoUploading}
                                                handleModal={this.toggleModalVideo}
                                                isVideoVisible={this.state.isVideoVisible}
                                            />
                                            <Divider space={Space.Small} />
                                        </Column>
                                        : null
                                    }
                                    {!mediumType || mediumType === MediumType.File ?
                                        <Column xs={6} sm={3} xl={2}>
                                            <Uploader.Document
                                                label={t(`${I18N_NAMESPACE}.uploadDocument`)}
                                                info={t(`${I18N_NAMESPACE}.uploadDocumentTypes`)}
                                                uploadAction={this.uploadAction}
                                                multiple={true}
                                            />
                                            <Divider space={Space.Small} />
                                        </Column>
                                        : null
                                    }
                                </Row>
                            )}
                            {noResults && (
                                <Styled.EmptyItems>
                                    <Trans id={`${I18N_NAMESPACE}.itemsEmpty`} />
                                </Styled.EmptyItems>
                            )}
                            {(!noResults || isLoading) && this.renderMediaList()}
                        </Uploader.DnD>
                    </Container>
                    <StyledFooter>
                        <Container fluid>
                            <Row>
                                <Column sm={6}>
                                    {!loaded ? (
                                        <Placeholder
                                            config={PlaceholderType.Rect}
                                        />
                                    ) : (
                                        <Counter
                                            total={total}
                                            length={length}
                                            setAmountToShow={
                                                this.setAmountToShow
                                            }
                                            amounts={rows.lengths}
                                        />
                                    )}
                                </Column>
                                <Column contentAlign={"right"} sm={6}>
                                    {!loaded ? (
                                        <Placeholder
                                            config={PlaceholderType.Rect}
                                        />
                                    ) : (
                                        <Paginator
                                            selectPage={this.selectPage}
                                            pages={this.state}
                                        />
                                    )}
                                </Column>
                            </Row>
                        </Container>
                    </StyledFooter>
                    <Uploader.ProgressBar
                        isVisible={this.state.isImageUploading}
                        uploadingTo={"Multimedia"}
                        uploadingImage={this.state.currentImageUploading}
                        uploadingCurrent={this.state.currentUploading}
                        uploadingTotal={this.state.totalUploading}
                        uploadingPercentage={this.state.uploadingPercentage}
                        isDocument={this.state.isDocument}
                    />
                </WrapperWithTitleActions>
                {this.renderDialog()}
            </CanView>
        );
    }

    private renderMediaList = () => {
        const { t } = this.props;
        const { data, isLoading } = this.state;

        return (
            <Row>
                <MediaList
                    deleteMedia={(item: MediaListData) =>
                        this.toggleDialog(
                            "error",
                            true,
                            t(`${I18N_NAMESPACE}.deleteMessage`),
                            `${t(`${I18N_NAMESPACE}.deleteMessageItemConfirm`)}`,
                            false,
                            this.toggleDialog,
                            t(`${I18N_KEY_GLOBAL}.cancel`),
                            undefined,
                            () => {
                                this.deleteSingleMedia(item);
                            },
                            t(`${I18N_KEY_GLOBAL}.delete`),
                            "delete"
                        )
                    }
                    selectMedia={this.selectMedia}
                    list={data}
                    isLoading={isLoading}
                    checkedMedia={this.state.checkedMedia}
                />
            </Row>
        );
    }

    private renderSearch = () => {
        const { filters } = MULTIMEDIA_CONFIG;
        const { filters: filtersData } = this.state;
        if (!filters) {
            return null;
        }
        return filters.map((filter, index) =>
            <Fragment key={index}>
                {filter({
                    filters: filtersData as any,
                    updateFilter: debounce(this.updateFilter, 300),
                })}
            </Fragment>
        );
    };

    private updateFilter = (name: string, value: any) => {
        this.setState({
            filters: {
                ...this.state.filters,
                [name]: value,
            },
        });
    };

    /** Deletes a single media */
    private deleteSingleMedia = (item: MediaListData) => {
        if (item.uuid) {
            const input = new dlos.cms.MediaDeleteInput();

            input.body = {
                uuid: item.uuid,
            };

            this.setState({
                isDeleting: true,
            });

            this.props
                .executeDloRequest(input, `delete image - ${item.uuid}`)
                .then(res => {
                    this.toggleDialog();
                    this.setState({
                        isDeleting: false,
                        total: this.state.total - 1,
                        data:
                            this.state.data &&
                            this.state.data.filter(i => i.uuid !== item.uuid),
                        checkedMedia:
                            this.state.checkedMedia &&
                            this.state.checkedMedia.filter(i => i.uuid !== item.uuid),
                    }, () => {
                        this.setState({
                            noResults: !this.state.data.length
                        })
                    });
                })
                .catch(err => {
                    if (err) {
                        this.addSnackbar(errorMessage(err.body), "error", 4000);
                    }
                    this.setState({
                        isDeleting: false,
                    });
                });
        }

    };

    /** Delete All Checked Media items */
    private deleteAllCheckedMedia = async () => {
        const input = new dlos.cms.MediaDeleteInput();

        if (this.state.checkedMedia && this.state.checkedMedia.length > 0) {
            this.setState({
                isDeleting: true,
            });
            for (const item of this.state.checkedMedia) {
                if (item.uuid) {
                    input.body = {
                        uuid: item.uuid,
                    };

                    await this.props
                        .executeDloRequest(input, `delete image - ${item}`)
                        .then(res => {
                            this.setState({
                                total: this.state.total - 1,
                                data:
                                    this.state.data &&
                                    this.state.data.filter(i => i.uuid !== item.uuid),
                            }, () => {
                                this.setState({
                                    noResults: !this.state.data.length
                                })
                            });
                        })
                        .catch(err => {
                            this.addSnackbar(err, "error");
                            this.setState({
                                isDeleting: false,
                            });
                        });
                }
            }
            this.setState({
                checkedMedia: [],
                isDeleting: false,
                noResults: !this.state.data.length
            });
            /** hide Dialog */
            this.toggleDialog();
        }
    };

    /** Each time we click on Media, it toggles the checked style */
    private selectMedia = (item: MediaListData) => {
        if (
            (item && !this.props.limitSelectable) ||
            (this.props.limitSelectable && this.props.limitSelectable > this.state.checkedMedia.length)
        ) {
            this.setState(
                {
                    checkedMedia:
                        this.state.checkedMedia.some(s => s.uuid === item.uuid)
                            ? this.state.checkedMedia.filter(i => i.uuid !== item.uuid)
                            : [...this.state.checkedMedia, item],
                },
                () => {
                    if (this.props.checkedMedia) {
                        this.props.checkedMedia(this.state.checkedMedia);
                    }
                }
            );
        } else if (this.props.limitSelectable && this.props.limitSelectable === this.state.checkedMedia.length) {
            this.setState(
                {
                    checkedMedia: this.state.checkedMedia.filter(i => i !== item),
                },
                () => {
                    if (this.props.checkedMedia) {
                        this.props.checkedMedia(this.state.checkedMedia);
                    }
                }
            );
        }
    };

    /**
     * Pushes new item to array, and removes the last one, each time he appends to the array.
     */
    private pushToMedia = (media: MediaListData) => {
        /** data to append to array */
        const newData: MediaListData = {
            date: media.date,
            medium_type_id: media.medium_type,
            medium_type: media.medium_type,
            panoramic: media.panoramic?.toString(),
            thumb: media.thumb,
            file: media.file,
            title: media.title || media.url!,
            uuid: media.uuid,
            url: media.url,
            id: media.uuid || media.id,
        };

        /** if is the last item, reset. */
        if (this.state.currentUploading === this.state.totalUploading) {
            this.fw.dom.setTimeout(() => {
                this.cleanState();
                this.setState({
                    totalUploading: 0,
                });
            }, 1000);
        }

        /** remove the last from the array */
        if (this.state.data.length >= this.state.length) {
            this.state.data.pop();
        }

        /** add to the first position of the array */
        this.setState({
            noResults: false,
            data: [newData, ...this.state.data],
        });
    };

    /** Uploads the Video */
    private uploadVideo = async (url: string, thumb?: string, title?: string, description?: string) => {
        const { t } = this.props;

        const request = new dlos.cms.MediaCreateInput();

        // Build the body
        request.body = {
            content: {
                title: title || "",
                description,
                url,
                mime_type: MimeTypesByExtension[url?.split(".").pop() || ""],
                base64: thumb?.split(",").pop()!,
            },
            visibility: this.VISIBILITY_DEFAULT,
            origin: 1,
        };

        this.setState({
            isVideoUploading: true,
        });

        try {
            const response = await this.props.executeDloRequest(request, `upload video - ${url}`)
            const result = response?.body as MediaListData

            // add to array list
            this.pushToMedia(result);

            this.setState({
                isVideoUploading: false,
                isVideoVisible: false,
            });
        } catch (err) {
            this.setState({
                isVideoUploading: false,
            })
            this.addSnackbar(t(`${I18N_NAMESPACE}.somethingWrong`), "error");
        }
    };

    /**
     * ### Upload Action
     * - Image, Image Panoramic and Document
     */
    private uploadAction = async (
        files: FileInfo[],
        panoramic?: number,
        isDocument?: boolean
    ) => {
        // set the total of items to upload
        this.setState({
            totalUploading: files.length,
        });

        const { t } = this.props;

        for (const file of files) {
            const request = new dlos.cms.MediaCreateInput();
            const hasData: boolean = file.base64.indexOf("data:") > -1;

            if (hasData) {
                request.body = {
                    content: {
                        base64: file.base64.split(",").pop(),
                        title: file.name,
                    },
                    visibility: this.VISIBILITY_DEFAULT,
                    origin: 1,
                    panoramic: (panoramic as 0 | 1) || 0,
                }

                /** set total of item to upload */
                this.setState({
                    isImageUploading: true, // show the placeholder of image uploader
                    currentImageUploading: file.base64,
                    isDocument,
                });

                try {
                    const response = await this.props.executeDloRequest(request, `upload image - ${file.name}`)
                    const result = response?.body as MediaListData

                    // add to array list
                    this.pushToMedia(result);

                    if (this.state.totalUploading) {
                        /** calc to set progress bar percentage */
                        const uploadPercentage: number =
                            100 / this.state.totalUploading;

                        this.setState({
                            total: this.state.total + 1,
                            uploadingPercentage: this.state.uploadingPercentage! + uploadPercentage,
                        });

                        if (this.state.currentUploading < this.state.totalUploading) {
                            // increment to counter
                            this.setState({
                                currentUploading:
                                    this.state.currentUploading + 1,
                            });
                        }
                    }
                } catch (err) {
                    this.addSnackbar(errorMessage({
                        ...err.body,
                        defaultMessage: `${t(`${I18N_NAMESPACE}.errorUploading`)}`,
                        context: file.name,
                    }), "error")

                    if (this.state.currentUploading === this.state.totalUploading) {
                        this.fw.dom.setTimeout(() => {
                            this.cleanState();
                        }, 1000);
                    }

                    if (this.state.totalUploading) {
                        this.setState({
                            totalUploading: this.state.totalUploading - 1,
                        });
                    }
                }
            }
        }
    };

    private cleanState = () => {
        this.setState({
            isImageUploading: false,
            currentUploading: 1,
            totalUploading: 0,
            currentImageUploading: "",
            uploadingPercentage: 0,
        });
    };

    private selectPage = (start: number) => {
        this.setState({
            isLoading: true,
            start,
        });

        // Set the value of 'start' API param
        const { length } = this.state;
        const pageStep = (start - 1) * length;
        this.loadData(length, pageStep);
    };

    private setAmountToShow = (length: number) => {
        this.setState({
            isLoading: true,
            length,
            start: 1,
        });

        // Set the value of 'lenght' of content to show
        const { start } = this.state;
        this.loadData(length, start - 1);
    };

    private createColumns() {
        const { columns } = MULTIMEDIA_CONFIG;

        return columns.reduce((result: any, column, i) => {
            result[`columns[${i}][data]`] = column.param;
            return result;
        }, {});
    }

    private transformData(response: any): void {
        this.setState({
            isLoading: false,
            noResults: false,
            data: response.body,
            total: parseInt(response.headers["x-datatables-records-total"], 0),
        });
    }

    private loadData = (length: number, start: number): void => {
        // Fetch the data from server
        const { fetch } = MULTIMEDIA_CONFIG;
        const { filters } = this.state;
        const { mediumType, context } = this.props;
        const columns = this.createColumns();
        const input = new fetch();

        input.body = {
            // @ts-ignore
            length,
            start,
            ...columns,
            ...filters,
            type: mediumType,
            ...context,
        };

        this.setState({
            isLoading: true,
        })

        this.props
            .executeDloRequest(input, start)
            .then((res) => {
                if (res!.body.length === 0) {
                    this.setState({
                        noResults: true,
                        isLoading: false,
                    })
                } else {
                    this.transformData(res);
                }
            })
            .catch(() => this.setState({ isLoading: false }));
    };

    /**
     * Pushes snackbar to container
     *
     * @param {string} message message to snackbar
     * @param {string} layout layout of snackbar
     *
     */
    private addSnackbar = (
        message: string,
        layout?: SnackbarLayout,
        timeout?: number
    ) => {
        if (this.snackBarContainerRef.current) {
            this.snackBarContainerRef.current.pushMessage({
                layout: layout as any,
                message,
                timeout,
            });
        }
    };

    private renderDialog = () => {
        const { dialog } = this.state;
        const buttonLayout = dialog.type === "success" ? "main" : dialog.type;

        return (
            <Dialog
                showIcon={dialog.showIcon}
                handleDialog={this.toggleDialog}
                isVisible={dialog.isVisible}
                type={dialog.type}
                overlay={dialog.overlay}
                title={dialog.title}
                description={dialog.description}
            >
                {dialog.cancelAction && (
                    <Button
                        layoutOutline
                        onClick={dialog.cancelAction}
                        layout={buttonLayout}
                        icon={dialog.cancelIcon}
                    >
                        {dialog.cancelText}
                    </Button>
                )}
                {dialog.submitAction && (
                    <Button
                        layoutFill
                        onClick={dialog.submitAction}
                        layout={buttonLayout}
                        layoutIconPosition={"right"}
                        isLoading={this.state.isDeleting}
                    >
                        {dialog.submitText}
                    </Button>
                )}
            </Dialog>
        );
    };

    private toggleDialog = (
        type?: DialogTypes,
        overlay?: boolean,
        title?: string,
        description?: string,
        showIcon?: boolean,
        cancelAction?: () => void,
        cancelText?: string,
        cancelIcon?: string,
        submitAction?: () => void,
        submitText?: string,
        submitIcon?: string
    ) => {
        const { dialog } = this.state;

        if (this.state.dialog) {
            this.setState({
                dialog: {
                    isVisible: !dialog.isVisible,
                    type,
                    overlay,
                    title,
                    description,
                    showIcon,
                    cancelAction,
                    submitAction,
                    cancelText,
                    submitText,
                    cancelIcon,
                    submitIcon,
                },
            });
        }

        this.renderDialog();
    };

    private toggleModalVideo = () => {
        const { isVideoVisible } = this.state;

        this.setState({
            isVideoVisible: !isVideoVisible,
        });
    };

}

export const Multimedia = withCanView(withDloService(withRouter(withI18n(APP_TRANSLATIONS)(MultimediaComponent))));
