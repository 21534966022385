import { Publish } from "./Publish"
import { Channels } from "./Channels"
import { Tags } from "./Tags"

export const PageEditorDefaultWidgets = {
    /**
     * Publish widget
     * It has the basic information about the entity
     * and a Save button
     */
    Publish,
    /**
     * Channels widget
     * Shows the channels/visibility for given entity
     */
    Channels,
    /**
     * Tags widget
     * Shows all the tags for given entity
     */
    Tags,
}