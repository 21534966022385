import React, { PureComponent } from "react"
import styled from "styled-components";
import { colors } from "../../../styles";
import { StyledButton } from "../../Button/styles";

export interface WrapperFooterProps {
    /** class name */
    className?: string;
}

export const StyledWrapperFooter = styled.div`
    padding: 32px 12px;
    background-color: ${colors.light};
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    ${StyledButton} {
        min-height: 0;
        margin-right: 16px !important;
        &:last-child {
            margin-right: 0 !important;
        }
    }

    &::after {
        content: "";
        display: block;
        width: 100%;
        white-space: nowrap;
        clear: both;
    }
`

export class WrapperFooter extends PureComponent<WrapperFooterProps> {
    public render() {

        if (!this.props.children) {
            return null;
        }

        return (
            <StyledWrapperFooter
                className={this.props.className}
            >
                {this.props.children}
            </StyledWrapperFooter>
        )
    }
}
