/**
 * This helper was built based on this thread
 * https://stackoverflow.com/questions/2388115/get-locale-short-date-format-using-javascript
 * 
 * It was modified to use the moment.js locale format
 */

 const LOCALE_DATE_FORMATS: Record<string, string> = {
    "af-za": "YYYY/MM/DD",
    "am-et": "D/M/YYYY",
    "ar-ae": "DD/MM/YYYY",
    "ar-bh": "DD/MM/YYYY",
    "ar-dz": "DD-MM-YYYY",
    "ar-eg": "DD/MM/YYYY",
    "ar-iq": "DD/MM/YYYY",
    "ar-jo": "DD/MM/YYYY",
    "ar-kw": "DD/MM/YYYY",
    "ar-lb": "DD/MM/YYYY",
    "ar-ly": "DD/MM/YYYY",
    "ar-ma": "DD-MM-YYYY",
    "ar-om": "DD/MM/YYYY",
    "ar-qa": "DD/MM/YYYY",
    "ar-sa": "DD/MM/YY",
    "ar-sy": "DD/MM/YYYY",
    "ar-tn": "DD-MM-YYYY",
    "ar-ye": "DD/MM/YYYY",
    "arn-cl": "DD-MM-YYYY",
    "as-in": "DD-MM-YYYY",
    "az-cyrl-az": "DD.MM.YYYY",
    "az-latn-az": "DD.MM.YYYY",
    "ba-ru": "DD.MM.YY",
    "be-by": "DD.MM.YYYY",
    "bg-bg": "DD.M.YYYY",
    "bn-bd": "DD-MM-YY",
    "bn-in": "DD-MM-YY",
    "bo-cn": "YYYY/M/D",
    "br-fr": "DD/MM/YYYY",
    "bs-cyrl-ba": "D.M.YYYY",
    "bs-latn-ba": "D.M.YYYY",
    "ca-es": "DD/MM/YYYY",
    "co-fr": "DD/MM/YYYY",
    "cs-cz": "D.M.YYYY",
    "cy-gb": "DD/MM/YYYY",
    "da-dk": "DD-MM-YYYY",
    "de-at": "DD.MM.YYYY",
    "de-ch": "DD.MM.YYYY",
    "de-de": "DD.MM.YYYY",
    "de-li": "DD.MM.YYYY",
    "de-lu": "DD.MM.YYYY",
    "dsb-de": "D. M. YYYY",
    "dv-mv": "DD/MM/YY",
    "el-gr": "D/M/YYYY",
    "en-029": "MM/DD/YYYY",
    "en-au": "D/MM/YYYY",
    "en-bz": "DD/MM/YYYY",
    "en-ca": "DD/MM/YYYY",
    "en-gb": "DD/MM/YYYY",
    "en-ie": "DD/MM/YYYY",
    "en-in": "DD-MM-YYYY",
    "en-jm": "DD/MM/YYYY",
    "en-my": "D/M/YYYY",
    "en-nz": "D/MM/YYYY",
    "en-ph": "M/D/YYYY",
    "en-sg": "D/M/YYYY",
    "en-tt": "DD/MM/YYYY",
    "en-us": "M/D/YYYY",
    "en-za": "YYYY/MM/DD",
    "en-zw": "M/D/YYYY",
    "es-ar": "DD/MM/YYYY",
    "es-bo": "DD/MM/YYYY",
    "es-cl": "DD-MM-YYYY",
    "es-co": "DD/MM/YYYY",
    "es-cr": "DD/MM/YYYY",
    "es-do": "DD/MM/YYYY",
    "es-ec": "DD/MM/YYYY",
    "es-es": "DD/MM/YYYY",
    "es-gt": "DD/MM/YYYY",
    "es-hn": "DD/MM/YYYY",
    "es-mx": "DD/MM/YYYY",
    "es-ni": "DD/MM/YYYY",
    "es-pa": "MM/DD/YYYY",
    "es-pe": "DD/MM/YYYY",
    "es-pr": "DD/MM/YYYY",
    "es-py": "DD/MM/YYYY",
    "es-sv": "DD/MM/YYYY",
    "es-us": "M/D/YYYY",
    "es-uy": "DD/MM/YYYY",
    "es-ve": "DD/MM/YYYY",
    "et-ee": "D.MM.YYYY",
    "eu-es": "YYYY/MM/DD",
    "fa-ir": "MM/DD/YYYY",
    "fi-fi": "D.M.YYYY",
    "fil-ph": "M/D/YYYY",
    "fo-fo": "DD-MM-YYYY",
    "fr-be": "D/MM/YYYY",
    "fr-ca": "YYYY-MM-DD",
    "fr-ch": "DD.MM.YYYY",
    "fr-fr": "DD/MM/YYYY",
    "fr-lu": "DD/MM/YYYY",
    "fr-mc": "DD/MM/YYYY",
    "fy-nl": "D-M-YYYY",
    "ga-ie": "DD/MM/YYYY",
    "gd-gb": "DD/MM/YYYY",
    "gl-es": "DD/MM/YY",
    "gsw-fr": "DD/MM/YYYY",
    "gu-in": "DD-MM-YY",
    "ha-latn-ng": "D/M/YYYY",
    "he-il": "DD/MM/YYYY",
    "hi-in": "DD-MM-YYYY",
    "hr-ba": "D.M.YYYY.",
    "hr-hr": "D.M.YYYY",
    "hsb-de": "D. M. YYYY",
    "hu-hu": "YYYY. MM. DD.",
    "hy-am": "DD.MM.YYYY",
    "id-id": "DD/MM/YYYY",
    "ig-ng": "D/M/YYYY",
    "ii-cn": "YYYY/M/D",
    "is-is": "D.M.YYYY",
    "it-ch": "DD.MM.YYYY",
    "it-it": "DD/MM/YYYY",
    "iu-cans-ca": "D/M/YYYY",
    "iu-latn-ca": "D/MM/YYYY",
    "ja-jp": "YYYY/MM/DD",
    "ka-ge": "DD.MM.YYYY",
    "kk-kz": "DD.MM.YYYY",
    "kl-gl": "DD-MM-YYYY",
    "km-kh": "YYYY-MM-DD",
    "kn-in": "DD-MM-YY",
    "ko-kr": "YYYY-MM-DD",
    "kok-in": "DD-MM-YYYY",
    "ky-kg": "DD.MM.YY",
    "lb-lu": "DD/MM/YYYY",
    "lo-la": "DD/MM/YYYY",
    "lt-lt": "YYYY.MM.DD",
    "lv-lv": "YYYY.MM.DD.",
    "mi-nz": "DD/MM/YYYY",
    "mk-mk": "DD.MM.YYYY",
    "ml-in": "DD-MM-YY",
    "mn-mn": "YY.MM.DD",
    "mn-mong-cn": "YYYY/M/D",
    "moh-ca": "M/D/YYYY",
    "mr-in": "DD-MM-YYYY",
    "ms-bn": "DD/MM/YYYY",
    "ms-my": "DD/MM/YYYY",
    "mt-mt": "DD/MM/YYYY",
    "nb-no": "DD.MM.YYYY",
    "ne-np": "M/D/YYYY",
    "nl-be": "D/MM/YYYY",
    "nl-nl": "D-M-YYYY",
    "nn-no": "DD.MM.YYYY",
    "nso-za": "YYYY/MM/DD",
    "oc-fr": "DD/MM/YYYY",
    "or-in": "DD-MM-YY",
    "pa-in": "DD-MM-YY",
    "pl-pl": "YYYY-MM-DD",
    "prs-af": "DD/MM/YY",
    "ps-af": "DD/MM/YY",
    "pt-br": "D/M/YYYY",
    "pt-pt": "DD-MM-YYYY",
    "qut-gt": "DD/MM/YYYY",
    "quz-bo": "DD/MM/YYYY",
    "quz-ec": "DD/MM/YYYY",
    "quz-pe": "DD/MM/YYYY",
    "rm-ch": "DD/MM/YYYY",
    "ro-ro": "DD.MM.YYYY",
    "ru-ru": "DD.MM.YYYY",
    "rw-rw": "M/D/YYYY",
    "sa-in": "DD-MM-YYYY",
    "sah-ru": "MM.DD.YYYY",
    "se-fi": "D.M.YYYY",
    "se-no": "DD.MM.YYYY",
    "se-se": "YYYY-MM-DD",
    "si-lk": "YYYY-MM-DD",
    "sk-sk": "D. M. YYYY",
    "sl-si": "D.M.YYYY",
    "sma-no": "DD.MM.YYYY",
    "sma-se": "YYYY-MM-DD",
    "smj-no": "DD.MM.YYYY",
    "smj-se": "YYYY-MM-DD",
    "smn-fi": "D.M.YYYY",
    "sms-fi": "D.M.YYYY",
    "sq-al": "YYYY-MM-DD",
    "sr-cyrl-ba": "D.M.YYYY",
    "sr-cyrl-cs": "D.M.YYYY",
    "sr-cyrl-me": "D.M.YYYY",
    "sr-cyrl-rs": "D.M.YYYY",
    "sr-latn-ba": "D.M.YYYY",
    "sr-latn-cs": "D.M.YYYY",
    "sr-latn-me": "D.M.YYYY",
    "sr-latn-rs": "D.M.YYYY",
    "sv-fi": "D.M.YYYY",
    "sv-se": "YYYY-MM-DD",
    "sw-ke": "M/D/YYYY",
    "syr-sy": "DD/MM/YYYY",
    "ta-in": "DD-MM-YYYY",
    "te-in": "DD-MM-YY",
    "tg-cyrl-tj": "DD.MM.YY",
    "th-th": "D/M/YYYY",
    "tk-tm": "DD.MM.YY",
    "tn-za": "YYYY/MM/DD",
    "tr-tr": "DD.MM.YYYY",
    "tt-ru": "DD.MM.YYYY",
    "tzm-latn-dz": "DD-MM-YYYY",
    "ug-cn": "YYYY-M-D",
    "uk-ua": "DD.MM.YYYY",
    "ur-pk": "DD/MM/YYYY",
    "uz-cyrl-uz": "DD.MM.YYYY",
    "uz-latn-uz": "DD/MM YYYY",
    "vi-vn": "DD/MM/YYYY",
    "wo-sn": "DD/MM/YYYY",
    "xh-za": "YYYY/MM/DD",
    "yo-ng": "D/M/YYYY",
    "zh-cn": "YYYY/M/D",
    "zh-hk": "D/M/YYYY",
    "zh-mo": "D/M/YYYY",
    "zh-sg": "D/M/YYYY",
    "zh-tw": "YYYY/M/D",
    "zu-za": "YYYY/MM/DD",
}

const getLocaleShortDateFormatIntl = (localeAbbv?: string) => {
    return new Intl.DateTimeFormat(Intl.DateTimeFormat(localeAbbv).resolvedOptions().locale)
        .format(new Date(2021, 0, 2))
        .replace(/0?1/, "MM").replace(/0?2/, "DD").replace(/(?:20)?21/, "YYYY");
}

export const getSystemLocaleShortDateFormat = (localeAbbv?: string) => {
    // Get current locale
    const locale = new Intl.DateTimeFormat(localeAbbv).resolvedOptions().locale

    // Try to get from the list
    if (locale.toLowerCase() in LOCALE_DATE_FORMATS) {
        return LOCALE_DATE_FORMATS[locale.toLowerCase()]
    }

    // Fallback for Intl solution with hardcoded format
    return getLocaleShortDateFormatIntl();
}