import React, { PureComponent } from "react"
import styled, { css } from "styled-components";
import { font, colors } from "../../../styles";
import { TypographyProps } from "../interface"

export interface SectionTitleProps extends React.PropsWithChildren<TypographyProps> { }

const StyledSectionTitle = styled.h4<SectionTitleProps>`
    font-size: ${font.size.md};
    font-weight: ${font.weight.light};

    ${(props: SectionTitleProps) => props.variant === "regular" && css`
        color: ${colors.neutral.darker};
    `}

    ${(props: SectionTitleProps) => props.variant === "primary" && css`
        color: ${colors.primary.lighter};
    `}

    ${(props: SectionTitleProps) => props.variant === "neutral" && css`
        color: ${colors.neutral.dark};
    `}
`

export class SectionTitle extends PureComponent<SectionTitleProps> {
    public render() {
        return (
            <StyledSectionTitle
                variant={this.props.variant}
                className={this.props.className}
            >
                {this.props.children}
            </StyledSectionTitle>
        )
    }
}
