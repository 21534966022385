import React, { PureComponent } from "react"
import styled, { css } from "styled-components";
import { font, colors } from "../../../styles";
import { TypographyProps } from "../interface"

export interface SmallTextBoldProps extends React.PropsWithChildren<TypographyProps> { }

const StyledSmallTextBold = styled.p<SmallTextBoldProps>`
    font-size: ${font.size.xs};
    font-weight: ${font.weight.medium};

    ${(props: SmallTextBoldProps) => props.variant === "regular" && css`
        color: ${colors.neutral.darker};
    `}

    ${(props: SmallTextBoldProps) => props.variant === "primary" && css`
        color: ${colors.primary.lighter};
    `}

    ${(props: SmallTextBoldProps) => props.variant === "neutral" && css`
        color: ${colors.neutral.dark};
    `}
`

export class SmallTextBold extends PureComponent<SmallTextBoldProps> {
    public render() {
        return (
            <StyledSmallTextBold variant={this.props.variant}>
                {this.props.children}
            </StyledSmallTextBold>
        )
    }
}
