import React, { PureComponent } from "react"
import styled, { css } from "styled-components";
import {
    RadioButton as CoreRadioButton,
    Html as HtmlComponent,
} from "@7egend/web.core.controls/lib/components";
import { colors, font } from "../../styles";

export interface RadioButtonProps {
    /** label to describe radio button button */
    label?: string;
    /** radio button name */
    name: string;
    /** radio button value */
    value: string;
    /** if radio button if required @default false */
    required?: boolean;
    /** radio button onchange method */
    onChange: (value: string, name: string, valid: boolean) => void;
    /** radio button disabled */
    disabled?: boolean;
    /** className */
    className?: string;
}

interface CheckmarkProps {
    disabled?: boolean
}

const StyledWrapper = styled.div`
    display: inline-flex;
    position: relative;
    &::after {
        content: "";
        white-space: nowrap;
        clear: both;
        display: block;
    }
`

const StyledLabel = styled(HtmlComponent)`
    & {
        margin-left: 10px;
        font-size: ${font.size.sm};
        font-weight: ${font.weight.light};
        float: left;
    }
`

const StyledCheckmark = styled.span<CheckmarkProps>`
    position: relative;
    width: 20px;
    height: 20px;
    border: 1px solid ${colors.neutral.dark};
    border-radius: 100%;
    background-color: transparent;
    display: inline-block;
    float: left;
    pointer-events: none;

    &:before {
        content: '';
        width: 8px;
        height: 8px;
        border-radius: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: opacity 250ms ease;
        background-color: ${colors.light};
        opacity: 0;
    }

    &:empty {
        &:before {
            margin-right: 0;
        }
    }

    &:hover {
        cursor: pointer;
    }

    ${(props: CheckmarkProps) => props.disabled && css`
        &:hover {
            cursor: not-allowed;
        }
    `}
`

const StyledRadioButton = styled(CoreRadioButton)`
    & {
        position: absolute;
        opacity: 0;
        height: 20px;
        width: 20px;
        cursor: pointer;
        top: -3px;
        left: -5px;

        &:checked {
            &+${StyledCheckmark}::before {
                opacity: 1;
            }

            &+${StyledCheckmark} {
                background-color: ${colors.primary.lighter};
                border-color: ${colors.primary.lighter};
            }

            &:disabled {
                &+${StyledCheckmark} {
                    background-color: ${colors.neutral.light};
                    border-color: transparent;

                    &:before {
                        background-color: ${colors.light};
                    }
                }
            }
        }

        &:not(:checked) {
            &:disabled {
                &+${StyledCheckmark} {
                    border-color: ${colors.neutral.light};

                    &:before {
                        opacity: 0;
                    }
                }
            }
        }

        &:disabled {
            outline: none;
            cursor: default;
        }
    }
`

/**
 * # radio button Component
 * This class represents the input type radio button component
 *
 * #How to use
 *
 * ```tsx
 * <RadioButton
 *  name="name"
 *  value={true | false | your condition}
 *  onChange={event}
 *  label="label text" />
 * ```
 *
 */
export class RadioButton extends PureComponent<RadioButtonProps> {
    public render() {
        const {
            label,
            name,
            value,
            required,
            onChange,
            disabled,
            className
        } = this.props;

        return (
            <StyledWrapper className={className}>
                <label htmlFor={`field-${this.props.name}`}>
                    <StyledRadioButton
                        name={name}
                        required={required}
                        disabled={disabled}
                        onChange={onChange}
                        value={value}
                        checked={value ? true : false}
                    />
                    <StyledCheckmark disabled={disabled} />
                    {label && <StyledLabel html={label} />}
                    {this.props.children}
                </label>
            </StyledWrapper>
        );
    }
}
