import { Session } from "@7egend/web.core.security/lib/dlos";
import { Config } from "../base/config";

export const roleHasPermission = (key: string | undefined, config: Config, session?: Session, sessionToken?: string) => {
    const { roles } = config!;

    if (roles && session?.roles && sessionToken) {
        for (const userRole of session.roles) {
            if (roles[userRole.id] && roles[userRole.id]?.permissions) {
                for (const permission of roles[userRole.id].permissions) {
                    if (permission === key) {
                        return true;
                    }
                }
            }
        }
    } else {
        return true;
    }

    return false;
};
