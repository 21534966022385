import {
    DloInput,
    DloOutput,
    buildQLQuery,
    flattenComplexValues,
    QueryQLParams,
} from "@7egend/web.core/lib/extensions/dlo";
import { News as NewsCore } from "@7egend/web.core.cms/lib/dlos/news";
import { generateBlocks } from "../../utils";

export interface NewsType {
    id: string,
    name: string
}

interface NewsInputParams extends News {
    /** album title */
    title: string;
    /** album introduction */
    introduction: string;
    /** album origin */
    origin: number;
    /** current language */
    locale: string;
}

export interface News extends NewsCore {
    visibility: { [key: string]: string };
    tags: string[];
    type: NewsType;
}

const NEWS_FIELDS: QueryQLParams = [
    "id",
    "title",
    "slug",
    "date",
    "tags",
    ["type", ["id", "name"]],
    ["cover", ["uuid", "medium_type", "image", "thumb"]],
    ["categories", ["id", "name"]],
    ["blocks",
        ["language", "type", "position", "style", "value"]
    ],
];

/**
 * Get all news types
 */
export class NewsTypeGetAllInput extends DloInput<{}> {
    public uri = "cms-news-types?fields=id,name"
}

export class NewsTypeGetAllOutput extends DloOutput<NewsType[]>{ }

/**
 * # News Get all
 */
export class NewsGetAllInput extends DloInput<{}> {
    public timeStamp = Date.now();
    public authentication = true;
    public uri = "news/datatables?draw=${timeStamp}";
}

/**
 * # News Delete
 */
export class NewsDeleteInput extends DloInput<{
    id: string | number;
}> {
    public method = "DELETE";
    public uri = "news/${id}";
}

/**
 * # News Create <Input>
 */
export class NewsCreateInput extends DloInput<NewsInputParams> {
    public method = "POST";
    public uri = "news";
    public fields = buildQLQuery(NEWS_FIELDS);
    public transform = (input: any) => {
        flattenComplexValues(input.body, "cover", "uuid");
        input.body.fields = input.fields;
        input.body.blocks = generateBlocks(input.body.blocks, input.body.locale);
        return input;
    };
}

export class NewsUpdateInput extends DloInput<
    NewsInputParams & {
        uuid: string;
    }
> {
    public method = "PUT";
    public uri = "news/${id}";
    public fields = buildQLQuery(NEWS_FIELDS);
    public transform = (input: any) => {
        flattenComplexValues(input.body, "cover", "uuid");
        input.body.fields = input.fields;
        input.body.blocks = generateBlocks(input.body.blocks, input.body.locale);
        return input;
    };
}

const NewsGetByIdFields: QueryQLParams = [
    "id",
    "slug",
    "title",
    "highlight",
    "introduction",
    "date",
    "tags",
    "tag_relations",
    ["type", ["id", "name"]],
    ["categories",
        ["id", "name"]
    ],
    ["cover",
        ["uuid", "image", "thumb", "medium_type"]
    ],
    ["visibility",
        ["app", "website"]
    ],
    ["blocks",
        ["type",
            ["content",
                ["id", "uuid", "style", "value", "medium_type", "thumb", "image", "url", "panoramic",
                    ["media",
                        ["medium_type", "thumb", "image", "url", "panoramic"]
                    ]
                ]
            ]
        ]
    ]
];

export class NewsGetByIdInput extends DloInput<{
    id: number | string;
    locale: string;
}> {
    public uri = "news/${id}?fields=${fields}";
    public fields = buildQLQuery(NewsGetByIdFields);
}

/** Album Create <Output> */
export class NewsCreateOutput extends DloOutput<News> { }
