import React from "react";
import { CoreComponent } from "@7egend/web.core";
import { RouteComponentProps, withRouter } from "react-router";
import { WithDloServiceProps, withDloService } from "@7egend/web.core/lib/components/withDloService";
import { withI18n, WithI18nProps } from "@7egend/web.core/lib/components/withI18n";
import { Trans } from "@7egend/web.core/lib/components/i18n";
import {
    Button,
    Row,
    Column,
    Datatable,
    DatatableProps,
    DatatableActions,
    Dialog,
    SnackbarContainer,
} from "../../../components";
import { dlos } from "../../../dlos";
import {
    DefaultActions,
    FilterProps,
    DefaultFilters,
    DefaultFormatter,
} from "../../../components/Datatable";
import { News as NewsDLO } from "../../../dlos/cms/news";
import { APP_TRANSLATIONS } from "../../../locale";
import { CanView, withCanView, WithCanViewProps } from "../../../components/CanView";
import { I18N_KEY_CMS_NEWS_LIST, I18N_KEY_GLOBAL } from "../../../base/i18n";

interface State {
    isModalVisible: boolean;
    deleteID?: string | number;
    isDeleting: boolean;
    deletedSuccess?: string | number;
}

interface NewsProps extends RouteComponentProps<{ id: string }> {
    picker?: boolean

    /** pick form */
    pickItem?: (picked?: NewsDLO[]) => void;

    /** list of picked forms */
    pickedItems?: NewsDLO[];

    footer?: React.ReactComponentElement<any> | React.ComponentType<any> | Element;
}

const I18N_NAMESPACE = I18N_KEY_CMS_NEWS_LIST

class NewsComponent extends CoreComponent<
    NewsProps & WithDloServiceProps<{}> & WithI18nProps & WithCanViewProps,
    State
> {
    private snackBarContainerRef = React.createRef<SnackbarContainer>();

    public state: State = {
        isModalVisible: false,
        isDeleting: false,
    };

    public render() {
        const actions = [];
        if (this.props.canView("cms.news.details")) {
            actions.push({
                function: (id: string) => this.editAction(id),
                param: "id",
                type: DatatableActions.Link,
                icon: "edit",
            });
        }
        if (this.props.canView("cms.news.delete")) {
            actions.push({
                function: (id: string) => this.deleteAction(id),
                param: "id",
                type: DatatableActions.Common,
                icon: "delete",
            });
        }

        const dtConfig: DatatableProps = {
            fetch: dlos.cms.NewsGetAllInput,
            rows: {
                lengths: [10, 25, 50],
                actions: {
                    size: 2,
                    list: this.props.picker
                        ? [
                            {
                                function: (uuid: string, item: NewsDLO) => this.pickerAction(item),
                                param: "id",
                                type: DatatableActions.Picker,
                            }
                        ] : actions,
                },
            },
            columns: [
                {
                    param: ["title"],
                    title: this.props.t(`${I18N_NAMESPACE}.columnTitle`),
                    order: true,
                    render: DefaultFormatter.Text,
                },
                {
                    param: ["created"],
                    title: this.props.t(`${I18N_NAMESPACE}.columnPublicationDate`),
                    order: true,
                    render: DefaultFormatter.DateTime,
                },
                {
                    param: ["type:name"],
                    title: this.props.t(`${I18N_NAMESPACE}.columnType`),
                    order: true,
                    render: DefaultFormatter.Text,
                },
            ],
            title: this.props.t(`${I18N_NAMESPACE}.title`),
            actions: [
                <CanView action="cms.news.create">
                    <DefaultActions.Add
                        key={'add-action'}
                        href={"/cms/news/add"}
                    />
                </CanView>
                ,
            ],
            filters: [
                (props: FilterProps) => (
                    <CanView action="cms.news.search">
                        <DefaultFilters.Search
                            key={'search-filter'}
                            {...props}
                        />
                    </CanView>
                ),
            ],
        };

        return (
            <CanView action="cms.news.list">
                {/** Render SnackBar */}
                <SnackbarContainer ref={this.snackBarContainerRef} />
                <Row>
                    <Column xs={12}>
                        <Datatable
                            {...dtConfig}
                            actionSuccess={this.state.deletedSuccess}
                            isLoading={this.state.isDeleting}
                            footer={this.props.footer}
                            pickedItems={this.props.pickedItems}
                        />
                        {/** Render dialog */}
                        {this.renderDialog()}
                    </Column>
                </Row>
            </CanView>
        );
    }

    private renderDialog = () => {
        const { t } = this.props;

        return (
            <Dialog
                overlay={true}
                title={t(`${I18N_NAMESPACE}.deleteTitle`)}
                description={t(`${I18N_NAMESPACE}.deleteDescription`)}
                type="error"
                isVisible={this.state.isModalVisible}
                handleDialog={() => this.toggleDialog()}
            >
                <Button
                    layoutOutline
                    layout={"error"}
                    onClick={() => this.toggleDialog()}
                >
                    <Trans id={`${I18N_KEY_GLOBAL}.cancel`} />
                </Button>
                <Button
                    layoutFill
                    layout={"error"}
                    onClick={() => this.deleteNews()}
                    isLoading={this.state.isDeleting}
                    layoutIconPosition="right"
                >
                    <Trans id={`${I18N_KEY_GLOBAL}.delete`} />
                </Button>
            </Dialog>
        );
    };

    private toggleDialog = () => {
        this.setState({
            isModalVisible: !this.state.isModalVisible,
        });
    };

    private deleteNews = () => {
        if (this.state.deleteID) {
            this.setState({ isDeleting: true });
            const input = new dlos.cms.NewsDeleteInput();

            input.body = {
                id: this.state.deleteID,
            };

            this.props
                .executeDloRequest(input)
                .then(res => {
                    this.toggleDialog();
                    this.setState({
                        isDeleting: false,
                        deletedSuccess: this.state.deleteID,
                    });
                })
                .catch(err => {
                    this.addSnackbar(err.body.code, "error");
                    this.setState({
                        isDeleting: false,
                    });
                });
        }
    };

    private addSnackbar = (
        message: string,
        layout?: string,
        timeout?: number
    ) => {
        if (this.snackBarContainerRef.current) {
            this.snackBarContainerRef.current.pushMessage({
                layout: layout as any,
                message,
                timeout,
            });
        }
    };

    private deleteAction = (id?: string) => {
        this.toggleDialog();
        this.setState({ deleteID: id });
    };

    private editAction = (id?: string) => {
        return `/cms/news/edit/${id}`;
    };

    private pickerAction = (item?: NewsDLO) => {
        if (this.props.pickItem && item) {
            this.props.pickItem([item]);
        }
    }
}

export const News = withCanView(withRouter(withI18n(APP_TRANSLATIONS)(withDloService(NewsComponent))));
