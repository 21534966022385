import React from "react";
import { Component } from "@7egend/web.core/lib/base/component";
import classNames from "classnames";
import styles from "./accordion.module.scss"

export interface AccordionItem {
    id: string
    title: string
    active?: boolean
    content: React.ComponentType<any> | React.StatelessComponent<any> | Element
}

export interface AccordionProps {
    list: AccordionItem[]
    className?: string
    arrows?: {
        icon: boolean
        open: string
        close: string,
    }
}

interface State {
    active: string
}

export class Accordion extends Component<AccordionProps, State> {

    public static defaultProps: AccordionProps = {
        list: [],
        arrows: {
            icon: false,
            open: "+",
            close: "-",
        },
    }

    public state = {
        active: this.returnDefaultValue(),
    }

    public returnDefaultValue() {
        const { list } = this.props;
        const element = list.find((item) => {
            return item.active === true
        });
        return element ? element.id : "0";
    }

    public toggleAccordion = (id: string): void => {
        this.setState({
            active: id !== this.state.active ? id : "0",
        });
    }

    public getClasses = (id: string) => {
        return classNames(styles.content, {
            [styles.closed]: this.state.active !== id,
        });
    }

    public renderLiItem = (data: AccordionItem) => {
        const icon = this.state.active !== data.id ? this.props.arrows!.open : this.props.arrows!.close;

        return (
            <li key={"li-" + data.id}>
                <div className={styles.title} onClick={() => this.toggleAccordion(data.id)} >
                    <span>{data.title}</span>
                    {this.props.arrows!.icon && <i className={icon}></i> || icon}
                </div>
                <div className={this.getClasses(data.id)}>
                    {data.content}
                </div>
            </li>
        );
    }

    public render() {
        const { list } = this.props;

        return (
            <ul className={classNames("_7g-accordion", styles.accordion, this.props.className)}>
                {list.map((element) => this.renderLiItem(element))}
            </ul>
        )
    }
}
