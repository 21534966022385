import React, { Component } from "react";
const AclContext = React.createContext<string[] | undefined>(undefined);
const { Provider, Consumer } = AclContext;

interface AclContextProviderProps {
    value: string[] | undefined
}

class AclContextProvider extends Component<AclContextProviderProps> {
    public render() {
        return (
            <Provider
                value={this.props.value}
            >
                {this.props.children}
            </Provider>
        );
    }
}

export { AclContext, AclContextProvider, Consumer as AclContextConsumer };
