import React from "react";
import loadable from "@loadable/component";
import { Block } from "@7egend/web.core.cms/lib/dlos/block";
import { Text as TextDLO } from "@7egend/web.core.cms/lib/dlos/text";
import { BlockComponentProps } from "../../interfaces";
import { Column, Row } from "../../../Structure";
import { withI18n, WithI18nProps } from "@7egend/web.core/lib/components/withI18n";
import { APP_TRANSLATIONS } from "../../../../locale";
import { blockTypes } from "../config";
import { I18N_KEY_CMS_COMPONENTS_BLOCK } from "../../../../base/i18n";

const { iFrame } = blockTypes;

const Structure = {
    type: iFrame.type,
    content: {
        style: iFrame.style,
    },
    properties: [
        {
            type: "width",
            value: 0,
        },
        {
            type: "height",
            value: 0,
        }
    ]
} as unknown as Block<TextDLO>;

const InputComponent = loadable(async () => (await import("../../../Input")).Input);

const I18N_NAMESPACE = `${I18N_KEY_CMS_COMPONENTS_BLOCK}.types.iframe`

const IFrameComponent: React.FC<WithI18nProps & BlockComponentProps<TextDLO>> = ({ className, data, updateData, t }) => {

    return (
        <Column className={className}>
            <InputComponent
                name=""
                label={t(`${I18N_NAMESPACE}.inputLinkLabel`)}
                type="text"
                placeholder={t(`${I18N_NAMESPACE}.inputLinkPlaceholder`)}
                value={data.content && data.content.value}
                onChange={(value: string) => updateData({
                    ...data,
                    content: {
                        ...data.content,
                        value,
                    }
                })}
            />
            <Row>
                {
                    <React.Fragment>
                        <Column sm={6}>
                            <InputComponent
                                name=""
                                type="number"
                                min={'0'}
                                label={t(`${I18N_NAMESPACE}.inputWidthLabel`)}
                                placeholder={t(`${I18N_NAMESPACE}.inputWidthPlaceholder`)}
                                value={data.properties && data.properties.width}
                                onChange={
                                    (value: string) => updateData({
                                        ...data,
                                        properties: {
                                            ...data.properties,
                                            "width": value
                                        }
                                    })
                                }
                            />
                        </Column>
                        <Column sm={6}>
                            <InputComponent
                                name=""
                                type="number"
                                min={'0'}
                                label={t(`${I18N_NAMESPACE}.inputHeightLabel`)}
                                placeholder={t(`${I18N_NAMESPACE}.inputHeightPlaceholder`)}
                                value={data.properties && data.properties.height}
                                onChange={(value: string) => updateData({
                                    ...data,
                                    properties: {
                                        ...data.properties,
                                        "height": value
                                    }
                                })}
                            />
                        </Column>
                    </React.Fragment>
                }
            </Row>
        </Column>
    )
}

const Component = withI18n(APP_TRANSLATIONS)(IFrameComponent);

const Button = {
    icon: "grid_on",
    name: {
        key: `${I18N_NAMESPACE}.name`,
        en: "Iframe",
        es: "Iframe",
        pt: "Iframe",
    },
};

export const Iframe = {
    key: `${iFrame.type}_${iFrame.style}`,
    Structure,
    Component,
    Button,
};
