import React, { PureComponent } from "react";
import { News } from "../News"
import { Styles } from "./styles"
import { Icon, Typography, Modal, Column, Button } from "../../../../components"
import { News as NewsDLO } from "../../../../dlos/cms/news"
import { withI18n, WithI18nProps } from "@7egend/web.core/lib/components/withI18n";
import { Trans } from "@7egend/web.core/lib/components/i18n";
import { APP_TRANSLATIONS } from "../../../../locale";
import { I18N_KEY_CMS_PAGES_NEWS, I18N_KEY_GLOBAL } from "../../../../base/i18n";

interface PickerProps {
    /** list of picked news */
    picked?: NewsDLO[];

    /** pick news */
    pickItem?: (picked: NewsDLO[]) => void;
}

interface PickerState {
    active?: boolean;
    pickedNews: NewsDLO[]
}

const I18N_NAMESPACE = I18N_KEY_CMS_PAGES_NEWS

/**
 * # Picker Component
 * 
 * ## How to use:
 *  <NewsPicker 
 *  />
 */
class PickerComponent extends PureComponent<PickerProps & WithI18nProps, PickerState> {

    /** state */
    public state: PickerState = {
        active: false,
        pickedNews: []
    }

    /** render */
    public render() {

        const hasPicked = this.props.picked && this.props.picked[0].id;

        return (
            <React.Fragment>
                <Styles.Picker onClick={this.toggleForm}>
                    {!hasPicked ? (
                        <Styles.Placeholder>
                            <Icon icon="search" />
                            <Typography.RegularText><Trans id={`${I18N_NAMESPACE}.picker.searchNews`} /></Typography.RegularText>
                        </Styles.Placeholder>
                    ) : (
                            <Styles.Picked>
                                <Typography.RegularText>
                                    {hasPicked && this.props.picked?.map((form) => form.title)}
                                </Typography.RegularText>
                                <Icon icon="delete" />
                            </Styles.Picked>
                        )}

                </Styles.Picker>
                <Modal
                    overlay={true}
                    handleModal={this.toggleForm}
                    isVisible={this.state.active}
                    fullWidth
                >
                    <News
                        picker={true}
                        pickItem={this.pickItem}
                        pickedItems={this.state.pickedNews}
                        footer={
                            <Column contentAlign="right">
                                <Button
                                    layout="main"
                                    layoutOutline
                                    icon="clear"
                                    layoutIconPosition="right"
                                    onClick={this.toggleForm}
                                >
                                    <Trans id={`${I18N_KEY_GLOBAL}.cancel`} />
                                </Button>
                                <Button
                                    layout="main"
                                    layoutFill
                                    icon="check"
                                    layoutIconPosition="right"
                                    onClick={this.addItems}
                                    disabled={
                                        this.state.pickedNews.length < 1
                                    }
                                >
                                    <Trans id={`${I18N_NAMESPACE}.picker.addNews`} />
                                </Button>
                            </Column>
                        } />
                </Modal>
            </React.Fragment >
        )
    }

    private toggleForm = () => {
        this.setState(prevState => ({
            active: !prevState.active,
        }))
    }

    private addItems = () => {
        if (this.state.pickedNews && this.props.pickItem) {
            /** sent to parent component, checked items */
            this.props.pickItem(this.state.pickedNews);

            /** hide modal */
            this.toggleForm();

            /** clean state */
            this.setState({
                pickedNews: [],
            });
        }
    };

    private pickItem = (pickedNews?: NewsDLO[]) => {
        if (pickedNews) {
            this.setState({
                pickedNews,
            });
        }
    }
}

export const Picker = withI18n(APP_TRANSLATIONS)(PickerComponent);
