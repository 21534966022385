import React, { useMemo } from "react"
import { $di } from "@7egend/web.ground"

/**
 * Builds a Flex key to be used in the DI
 * @param key Flex key
 */
function buildKey(key: string) {
    return `__flex_${key}`
}

/**
 * useFlex Hook
 * Gets a Flex data on load.
 * Warning: it doesn't reload or updates once it is loaded
 * @param key Flex key
 * @param defaultValue Default value
 */
export const useFlex = <T extends any>(key: string, defaultValue?: T) => {
    const data = useMemo<T | undefined>(() => $di.tryGet<T>(buildKey(key), defaultValue), [key, defaultValue])

    return {
        key,
        flex: data,
    }
}

/**
 * HOC Flex Props Interface
 * Must be used with `withFlex` HOC
 */
export interface WithFlexProps<T = any> {
    flex?: T
}

/**
 * HOC to inject Flex configuration
 * All Flex properties will be injected under `props.flex`
 * @param key 
 * @param defaultValue 
 */
export const withFlex = <T extends any>(key: string, defaultValue?: T) => <P extends any>(
    WrappedComponent: React.ComponentType<P>
): React.ComponentType<Omit<P, keyof WithFlexProps>> => {

    const HOC: React.FC = (props) => {
        const { flex } = useFlex(key, defaultValue)

        // There is a change that there are also props named flex
        // In this case, merge them all
        const flexMerged = useMemo(() => {
            if ((props as any).flex) {
                return mergeDeep(flex, (props as any).flex)
            } else {
                return flex
            }
        }, [flex, (props as any).flex])

        return (
            <WrappedComponent
                {...props as any}
                flex={flexMerged}
            />
        )
    }

    // Set name
    if (process.env.NODE_ENV !== "production") {
        HOC.displayName = `withFlex(${WrappedComponent.displayName || WrappedComponent.name})`;
    }

    return HOC
}

/**
 * Adds data to given Flex key.
 * Tries to merge new data with existing one.
 * Arrays are appended, objects are spreaded.
 * @param key Flex key
 * @param data Flex data
 */
export const addToFlex = <T extends any>(key: string, data: T) => {
    // Get from $di
    const previousData = $di.tryGet<T>(buildKey(key))

    // Merge data
    const newData = previousData
        ? mergeDeep(previousData, data)
        : data

    // Save it back
    $di.set(buildKey(key), newData)
}

/**
 * Gets a value for given key. If no value is found, returns undefined
 * @param key Flex key
 */
export const getFromFlex = <T extends any>(key: string) => {
    return $di.tryGet<T>(buildKey(key))
}

/**
 * Clears all values for given key
 * @param key Flex key
 */
export const clearFlex = (key: string) => {
    $di.set(buildKey(key), undefined)
}

/**
 * Performs a deep merge of objects and returns new object. Does not modify
 * objects (immutable) and merges arrays via concatenation.
 *
 * @param {...object} objects - Objects to merge
 * @returns {object} New object with merged key/values
 */
function mergeDeep(...objects: any) {
    const isObject = (obj: any) => obj && typeof obj === 'object';

    return objects.reduce((prev: any, obj: any) => {
        Object.keys(obj || {}).forEach(key => {
            const pVal = prev[key];
            const oVal = obj[key];

            if (Array.isArray(pVal) && Array.isArray(oVal)) {
                prev[key] = pVal.concat(...oVal);
            }
            else if (isObject(pVal) && isObject(oVal)) {
                prev[key] = mergeDeep(pVal, oVal);
            }
            else {
                prev[key] = oVal;
            }
        });

        return prev;
    }, {});
}
