import { createGlobalStyle } from "styled-components";
import { font, colors } from "./theme";
import "./globals.css" // workaround to styled-components limitation https://styled-components.com/releases#v5.0.0

export default createGlobalStyle`
    body {
        font-family: ${font.primary};
        font-weight: ${font.weight.regular};
        color: ${colors.neutral.darker};
        margin: 0;
        padding: 0;
        background-color: ${colors.neutral.lighter};
        overflow-x: hidden;
    }

    * {
        font-family: ${font.primary};
        box-sizing: border-box;
    }

    strong, 
    b {
        font-weight: ${font.weight.medium};
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    ul,
    ol,
    li,
    blockquote {
        margin: 0;
        padding: 0;
    }

`;
