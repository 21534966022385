import React from "react";
import { CoreComponent } from "@7egend/web.core/lib/base/component";
import classNames from "classnames";
import { memoizeOne } from "@7egend/web.core/lib/utils";
import { AppError } from "@7egend/web.core/lib/base/events"
import { withWindowSize, WithWindowSizeProps } from "@7egend/web.core/lib/components/windowSize";

import styles from "./gridItem.module.scss";

//#region Exports

export interface GridItem {
    /** Item key */
    key: string
    /** Component to be used inside the area */
    component: React.ComponentType<any> | React.StatelessComponent<any>;
    /** Component props to render the component */
    componentProps?: any;
    /** Dimensions to use for rendering */
    dimensions: GridItemDimensions;
}

/**
 * Dimensions for each Grid Item
 */
export interface GridItemDimensions {
    /** Desktop dimensions */
    desktop: GridItemDimension;
    /** Tablet dimensions */
    tablet: GridItemDimension;
    /** Mobile dimensions */
    mobile: GridItemDimension;
    /** Other dimensions */
    [key: string]: GridItemDimension;
}

/**
 * Dimension for each Grid Item item
 */
export interface GridItemDimension {
    /** Item width */
    width: number;
    /** Item height */
    height: number;
    /** Left offset */
    offsetLeft?: number;
    /** Right offset */
    offsetRight?: number;
}

//#endregion

export interface GridItemProps {
    /** Item to render in grid single element */
    item: GridItem;
    /** Allow offset flag */
    allowOffset?: boolean
}

export interface Props extends GridItemProps { }

interface State {
    hasError?: boolean
}

/**
 * # Component Block
 * Creates a new block width different dimensions on desktop, tablet and mobile
 *
 * ## How to use
 * Used with parent Grid Component
 */
class GridItemInnerComponent extends CoreComponent<GridItemProps & WithWindowSizeProps, State> {

    public static getDerivedStateFromError(error: Error) {
        return {
            hasError: true,
        }
    }

    public state: State = {}

    //#region Private properties

    /** Auto-height flag */
    private isAutoHeight: boolean = this.props.item.dimensions.desktop.height <= 0;

    //#region Private methods

    /**
     * Calculate classes for given dimensions
     * @param dimensions Grid item dimensions
     */
    private calculateClasses = memoizeOne((key: string, layout: string): string => {
        const { dimensions } = this.props.item

        return classNames(
            `key-${key}`,
            `d-flex`,
            styles.item,
            this.isAutoHeight && styles.heightAuto,
            this.isAutoHeight! && styles.overflowHidden,
            styles[`span-w${dimensions.desktop.width}`],
            styles[`span-h${dimensions.desktop.height}`],
            styles[`span-w${dimensions.tablet.width}-md`],
            styles[`span-h${dimensions.tablet.height}-md`],
            styles[`span-w${dimensions.mobile.width}-xs`],
            styles[`span-h${dimensions.mobile.height}-xs`],
        );
    })

    /**
     * Calculates style for given dimensions and layout
     */
    private calculateStyle = memoizeOne((layout: string, dimensions: GridItemDimensions): any => {
        let resultStyles: React.CSSProperties = {};
        const dimension = dimensions[layout];

        if (this.props.allowOffset && (dimension.offsetLeft || dimension.offsetRight)) {
            if (this.isAutoHeight && dimension) {
                resultStyles = {
                    gridColumnStart: 1 + (dimension.offsetLeft || 0),
                    gridColumnEnd: dimension.width + 1 - (dimension.offsetRight || 0),
                }
            } else {
                // This is a special case
                // It means we want to offset in usual grid
                // In this case we will suppose that this is the only block in this grid
                resultStyles = {
                    gridColumnStart: 1 + (dimension.offsetLeft || 0),
                    gridColumnEnd: 1 + (dimension.offsetLeft || 0) + dimension.width,
                }
            }
        }

        return resultStyles;
    })

    //#endregion

    public componentDidCatch(error: Error, info: React.ErrorInfo) {
        this.fw.events.publish<AppError>(AppError, {
            message: error.message,
            exception: error,
            stackTrace: info,
        })
    }

    public render() {
        // Get the component
        const ComponentToRender = this.props.item.component as any;

        // Get the styles
        const componentStyles = this.calculateStyle(this.props.windowSize.breakpoint, this.props.item.dimensions);
        const classes = this.calculateClasses(this.props.item.key, this.props.windowSize.breakpoint);

        return (
            <div className={classes} style={componentStyles}>
                <div className={styles.padWrapper}>
                    <div className={styles.pad}></div>
                </div>
                <div className={this.isAutoHeight ? styles.contentAuto : styles.content}>
                    {!this.state.hasError &&
                        <ComponentToRender {...this.props.item.componentProps} currentLayout={this.props.windowSize.breakpoint} />
                    }
                </div>
            </div>
        )
    }

    //#endregion
}

export const GridItem = withWindowSize(GridItemInnerComponent);
export const GridItemComponent = GridItem
