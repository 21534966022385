import React, { PureComponent } from "react";
import styled from "styled-components";
import { Dropdown, DropdownAlign, DropdownPosition } from "../Dropdown";
import { Button as ButtonComponent } from "../Button";
import { Divider, Space } from "../Divider";
import { Image } from "../Image";
import { font } from "../../styles";

/** Styled Component */
const Styled = {
    Button: styled(ButtonComponent)`
        & {
            text-transform: uppercase;
            padding-right: 0;
            padding-top: 0;
            padding-bottom: 0;
            min-height: 0;

            span {
                font-size: ${font.size.sm};
                font-weight: ${font.weight.light};
            }

            i {
                font-size: ${font.size.sm};
            }
        }
    `,
    Language: styled.div`
        & {
            padding: 5px 0;
            min-height: 0;
            width: 48px;
            text-transform: uppercase;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: row;
            font-size: ${font.size.sm};
            font-weight: ${font.weight.light};
            cursor: pointer;

            &:first-child {
                padding-top: 0;
            }

            &:last-child {
                padding-bottom: 0;
            }
        }
    `,
    Icon: styled(Image)`
        & {
            max-width: 20px;
            width: 20px;
            height: 20px;
            border-radius: 100%;
            overflow: hidden;
            margin-right: 6px;
        }
    `
}

interface LanguageProps {
    /** array of available languages */
    languages: string[];

    /** current language */
    currentLanguage: string;

    /** update Language */
    updateLanguage: (language: string) => void;

    /** show country flag */
    showLabel?: boolean;

    /** Language Dropdown alignment */
    align?: DropdownAlign;

    /** Language Dropdown position */
    position?: DropdownPosition;

    /** Attach dropdown to body */
    attachOnBody?: boolean;
}

interface LanguageState {
    showLanguage?: boolean;
}

/**
 * # Language Component
 *
 * ## How to use:
 *
 * ```jsx
 *  <Language
 *      languages={} {string[]} // array of available languages
 *      currentLanguage={} {string} // your current language
 *      updateLanguage={(language: string)}  // Set Language
 *      align={} // align dropdown
 *      position={} // dropdown position
 * />
 * ```
 */
export class Language extends PureComponent<LanguageProps, LanguageState> {

    public state: LanguageState = {
        showLanguage: false
    }

    public render() {
        const { languages, currentLanguage, updateLanguage } = this.props;

        return (
            <Dropdown
                active={this.state.showLanguage}
                onClick={() => this.setState({ showLanguage: !this.state.showLanguage })}
                align={this.props.align}
                position={this.props.position || "bottom"}
                small
                attachOnBody={this.props.attachOnBody}
                component={
                    <Styled.Button
                        icon={this.state.showLanguage ? `keyboard_arrow_up` : `keyboard_arrow_down`}
                    >
                        {this.renderIcon(this.props.currentLanguage)}
                        {this.props.showLabel && currentLanguage}
                    </Styled.Button>
                }
            >
                {languages.map((language: string, index: number) => (
                    <React.Fragment key={language}>
                        <Styled.Language
                            onClick={() => {
                                updateLanguage(language);
                                this.setState({
                                    showLanguage: false
                                })
                            }}
                        >
                            {this.renderIcon(language)}
                            {language}
                        </Styled.Language>
                        {index !== (languages.length - 1) &&
                            <Divider
                                layout="lighter"
                                space={Space.Tiny}
                            />
                        }
                    </React.Fragment>
                ))}
            </Dropdown>
        )
    }

    /** Goes to the /public/flags/ folder get the .svg of the language */
    private renderIcon = (language: string) => {
        return <Styled.Icon src={`../flags/${language}.svg`} />
    }
}
