import React from "react";
import loadable from "@loadable/component";
import { Input } from "../../../Input";
import { Button as ButtonComponent } from "../../../Button";
import { BlockComponentProps } from "../../interfaces";
import styled from "styled-components";
import { ExpansionPanel as ExpansionPanelComponnent } from "../../../ExpansionPanel";
import { colors } from "../../../../styles";
import { debounce } from "@7egend/web.core/lib/utils";
import { Divider, Space } from "../../../Divider";
import { Block } from "@7egend/web.core.cms/lib/dlos/block";
import { Text } from "@7egend/web.core.cms/lib/dlos/text";
import { withI18n, WithI18nProps } from "@7egend/web.core/lib/components/withI18n";
import { APP_TRANSLATIONS } from "../../../../locale";
import { blockTypes } from "../config";
import { I18N_KEY_CMS_COMPONENTS_BLOCK } from "../../../../base/i18n";

const { container, expansionPanel } = blockTypes;

const Structure = {
    type: container.type,
    content: {
        style: expansionPanel.style,
        blocks: [{
            type: "3",
            content: {
                style: "6",
            }
        }] as unknown as Block<Text>
    }
} as any;

const StyleListWrapper = styled.div`
    border: 1px solid ${colors.neutral.light};
    border-radius: 6px;
    padding: 16px;
    background-color: ${colors.neutral.lighter};
`;

const StyledInput = styled(Input)`
    & {
        margin: 0;
        width: 100%;

        span {
            display: none;
        }
    }
`

const StyledAddButton = styled(ButtonComponent)`
    display: contents;
`;

const StyledRemoveButton = styled(ButtonComponent)`
    float: right;
`

/** update title of each block */
const updateTitle = (valuesList: any, value: string, i: number) => {
    const newValuesList = [...valuesList];
    newValuesList[i].content.value = value;
    return newValuesList;
};

/** update text of each block */
const updateText = (valuesList: any, value: string, i: number) => {
    const newValuesList = [...valuesList];
    newValuesList[i].content.extended_value = value;
    return newValuesList;
};

const removeItem = (valuesList: any, i: number) => {
    const updatedList = [
        ...valuesList.slice(0, i),
        ...valuesList.slice(i + 1),
    ];

    return updatedList;
};

const addItem = (valuesList: any) => {
    const newvaluesList = [...valuesList];
    newvaluesList.push(
        {
            type: "3",
            content: {
                style: "6",
            }
        }
    );

    return newvaluesList;
};

const TOOLBAR = {
    options: ["inline", "link"]
};

const TextEditor = loadable(async () => (await import("../../../TextEditor")).TextEditor);

const I18N_NAMESPACE = `${I18N_KEY_CMS_COMPONENTS_BLOCK}.types.expansionPanel`

const ExpansionPanelComponent: React.FC<WithI18nProps & BlockComponentProps<any>> = ({ className, data, updateData, t }) => {

    if (!data.content.blocks || data.content.blocks.length === 0) {
        return null
    }

    return (
        <StyleListWrapper className={className}>
            {data.content.blocks.map((eachValue: any, i: number) => {
                return (
                    <React.Fragment
                        key={i}
                    >
                        {data.content.blocks.length > 1 &&
                            <StyledRemoveButton
                                icon={"delete"}
                                onClick={() => updateData({
                                    ...data,
                                    content: {
                                        ...data.content,
                                        blocks: removeItem(data.content.blocks, i)
                                    }
                                })}
                            />
                        }
                        <ExpansionPanelComponnent
                            isExpanded={true}
                            summary={
                                <StyledInput
                                    type="text"
                                    name={i.toString()}
                                    placeholder={t(`${I18N_NAMESPACE}.inputTitlePlaceholder`)}
                                    value={eachValue.content.value}
                                    onChange={(value) =>
                                        updateData({
                                            ...data,
                                            content: {
                                                ...data.content,
                                                blocks: updateTitle(data.content.blocks, value, i)
                                            }
                                        })
                                    }
                                />
                            }
                        >
                            <TextEditor
                                toolbar={TOOLBAR}
                                value={eachValue.content.extended_value || ""}
                                onChange={debounce((value: string) => {
                                    updateData({
                                        ...data,
                                        content: {
                                            ...data.content,
                                            blocks: updateText(data.content.blocks, value, i)
                                        }
                                    })
                                }, 500)}
                            />
                        </ExpansionPanelComponnent>
                        <Divider space={Space.Tiny} />
                    </React.Fragment>
                )
            })}
            <StyledAddButton
                text={t(`${I18N_NAMESPACE}.addNewItem`)}
                icon={"add"}
                layoutIconPosition={"left"}
                onClick={() => updateData({
                    ...data,
                    content: {
                        ...data.content,
                        blocks: addItem(data.content.blocks)
                    }
                })}
            />
        </StyleListWrapper>
    );
}

const Component = withI18n(APP_TRANSLATIONS)(ExpansionPanelComponent);

const Button = {
    icon: "view_day",
    name: {
        key: `${I18N_NAMESPACE}.name`,
        en: "Expansion Panel",
        es: "Panel de expansión",
        pt: "Painel de Expansão",
    },
};

export const ExpansionPanel = {
    key: `${expansionPanel.type}_${expansionPanel.style}`,
    Structure,
    Component,
    Button,
};
