import React from "react";
import { PureComponent } from "@7egend/web.core/lib/base/component"

export interface CheckboxProps {
    /** checkbox className */
    className?: string
    /** checkbox name */
    name: string;
    /** checkbox value */
    value: boolean
    /** if checkbox if required
     * @default false
     */
    required?: boolean
    /** checkbox onchange method */
    onChange: (value: boolean, name: string, valid: boolean) => void
    /** checkbox disabled */
    disabled?: boolean
    /** Acessibility label */
    ariaLabel?: string
}

/**
 * # Checkbox Component
 * This class represents the input type checkbox component
 *
 * #How to use
 * ```tsx
 *  <CoreCheckbox
 *      name={name}
 *      value={value}
 *      onChange={onChange}
 *      disabled={disabled}
 *      required={required} />
 * ```
 *
 */
export class Checkbox extends PureComponent<CheckboxProps> {

    public render() {
        const { name, value, required, disabled, className, ariaLabel } = this.props;

        return (
            <input type="checkbox"
                id={`field-${name}`}
                name={name}
                checked={!!value}
                required={required}
                onChange={this.onChange}
                disabled={disabled}
                className={className}
                aria-label={ariaLabel}
            />
        );
    }

    private onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value: boolean = (e.target as any).checked
        const valid: boolean = (e.target as any).validity.valid
        this.props.onChange(value, this.props.name, valid)
    }
}
