import styled, { css } from "styled-components"
import { colors } from "../../../../styles"

const Picker = styled.div<{disabled?: boolean}>`
    border-radius: 10px;
    overflow: hidden;

    ${({ disabled }) => disabled && css`
        opacity: 0.3;
        cursor: not-allowed;
        pointer-events: none;
    `}
`

const Placeholder = styled.div`
    display: flex;
    align-items: center;
    background-color: ${colors.neutral.lighter};
    padding: 16px;
    transition: all .2s ease;
    cursor: pointer;

    &:hover {
        background-color: ${colors.neutral.light};
    }

    i {
        margin-right: 10px;
    }
`

const Picked = styled.div`
    display: flex;
    align-items: center;
    padding: 16px;
    justify-content: space-between;
    background-color: ${colors.neutral.darker};
    color: ${colors.light};

    i {
        cursor: pointer;
    }
`

export const Styles = {
    Picker,
    Placeholder,
    Picked
}
