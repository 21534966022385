import React from "react";
import { CorePureComponent } from "@7egend/web.core";
import { Datatable } from "./Datatable";
import { Rect } from "./Rect";

export enum PlaceholderType {
    Datatable = "datatable",
    Rect = "rect",
}

interface PlaceholderProps {
    config: PlaceholderType;
}

/**
 * Default viewbox width: 400
 * Default viewbox height: 180
 * Use width/height props to use different size
 */
export class Placeholder extends CorePureComponent<PlaceholderProps> {
    private renderType = (config: PlaceholderType) => {
        switch (config) {
            case "datatable":
                return <Datatable />;
            case "rect":
                return <Rect />;
        }
    };

    public render() {
        return this.renderType(this.props.config);
    }
}
