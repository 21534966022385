import {
    buildQLQuery,
    DloInput,
    QueryQLParams,
} from "@7egend/web.core/lib/extensions/dlo";
import { BaseModel } from "@7egend/web.core.dlos/lib/dlos/base";

interface Code {
    code: "string" | "email" | "checkbox" | "media" | "radio";
}

interface Input {
    label: string;
    title: string;
    description: string;
    type: Code;
    id: string;
    medium: {
        image: string
    }
}
export interface Answer {
    value: string;
    input: Input;
    medium: {
        image: string;
        file: string;
    }
}

export interface Answers {
    answers: Answer[];
}

const FormGetByUuidFields: QueryQLParams = [
    "id", "uuid",
    ["answers",
        [
            "value",
            ["medium", ["image", "file"]],
            ["input",
                ["id", "title", "label",
                    ["type",
                        ["code"]
                    ]
                ],
            ],
        ],
    ],
];

export interface Form extends BaseModel {
    /** form title */
    title?: string;

    /** form internal name */
    internal_name?: string;

    /** form secret id */
    secret?: string;

    /** form published */
    published?: string;
}

export class FormsGetAllInput extends DloInput<{}> {
    public timeStamp = Date.now();
    public authentication = true;
    public uri = "forms-forms/datatables?draw=${timeStamp}";
}

export class FormGetByUuidInput extends DloInput<{
    uuid: number | string;
}> {
    public uri = "forms-forms/${uuid}/submissions?fields=${fields}";
    public fields = buildQLQuery(FormGetByUuidFields);
}
