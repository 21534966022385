import React from "react";
import { useI18n } from "../../components/useI18n";
import { I18n } from "@7egend/web.ground/lib/domain/extensions/i18n";

declare var process: any;

export interface WithI18nProps {
  /**
   * Translates given key using active catalog
   */
  t: I18n["translate"];

  /** selected language */
  language?: string;
}

export interface WithI18nCatalog {
  /**
   * Catalog mapping object.
   * Each key should be a language code containing its messages
   */
  [language: string]: object;
}

/**
 * HOC for i18n utilities.
 * Loads given catalog if needed.
 * Loaded catalogs *do not* override existing translations.
 *
 * ## How to use
 *
 * _i18n/en.json_
 * ```json
 * {
 *  "hello": "Hello {name}!"
 * }
 * ```
 *
 * _i18n/pt.json_
 * ```json
 * {
 *  "hello": "Olá {name}!"
 * }
 * ```
 *
 * _component.tsx
 * ```javascript
 * import en from "./i18n/en.json"
 * import pt from "./i18n/pt.json"
 *
 * const Component = () => {
 *  // your component here
 * }
 *
 * export default withI18n({en, pt})(Component)
 * // or
 * export default compose(
 *  withI18n({en, pt})
 * )(Component)
 * ```
 * @param catalog Catalog mapping object
 */
export const withI18n = (catalog?: WithI18nCatalog) => <P extends any>(
  WrappedComponent: React.ComponentType<P>,
): React.ComponentType<Omit<P, keyof WithI18nProps>> => {
  // Create the HOC
  const HOC: React.FC<Omit<P, keyof WithI18nProps>> = (props) => {
    const [translate, , language, context] = useI18n(catalog);
    return (
      <WrappedComponent t={translate} language={language} lastUpdate={context.lastUpdate} {...(props as any)} />
    );
  };

  if (process.env.NODE_ENV !== "production") {
    // Set name
    HOC.displayName = `withI18n(${WrappedComponent.displayName ||
      WrappedComponent.name})`;
  }

  return HOC;
};
