import React from "react";
import { Medium, MediumType } from "@7egend/web.core.media/lib/dlos";
import { Block } from "@7egend/web.core.cms/lib/dlos/block";
import { BlockComponentProps } from "../../interfaces";
import { Uploader } from "../../../Uploader";
import { MediaListData, MediaList } from "../../../MediaList";
import styled from "styled-components";
import { withI18n, WithI18nProps } from "@7egend/web.core/lib/components/withI18n";
import { blockTypes } from "../config";
import { Config } from "../../../../base/config";
import { useFramework } from "@7egend/web.core/lib/components/useFramework";
import { I18N_KEY_CMS_COMPONENTS_BLOCK } from "../../../../base/i18n";

const { medium } = blockTypes;

const Wrapper = styled.div`
    display: grid;
`;

const Structure = {
    type: medium.type,
    content: {
        medium_type: MediumType.Stream,
    }
} as Block<Medium>;

const I18N_NAMESPACE = `${I18N_KEY_CMS_COMPONENTS_BLOCK}.types.stream`

const Component: React.FC<BlockComponentProps<Medium>> = ({ className, data, updateData }) => {
    const hasMedia = !!data.content?.uuid;
    const fw = useFramework();
    const config = (fw.config as Config);

    Structure.type = config?.blockTypes?.medium?.type ? config.blockTypes.medium.type : Structure.type;

    return (
        <Wrapper className={className}>
            {!hasMedia &&
                <Uploader.MediaList
                    limitSelectable={1}
                    mediumType={MediumType.Stream}
                    heightLimited={false}
                    renderColumns={{
                        xs: 3,
                    }}
                    checkedMedia={(checkedMedia: MediaListData[]) =>
                        updateData({
                            ...data,
                            content: checkedMedia[0],
                        })
                    }
                />
            }
            {hasMedia && (
                <MediaList
                    renderColumns={{
                        xs: 3,
                    }}
                    list={[data.content]}
                    isCheckable={false}
                    deleteMedia={(item: MediaListData) => {
                        updateData({
                            content: { medium_type: MediumType.Stream },
                            type: Structure.type,
                            id: item.uuid,
                        } as any)
                    }
                    }
                />
            )}
        </Wrapper>
    );
}

const Button = {
    icon: "ondemand_video",
    name: {
        key: `${I18N_NAMESPACE}.name`,
        en: "Stream",
        es: "Transmisión",
        pt: "Transmissão",
    },
};

export const Stream = {
    key: `${medium.type}_${MediumType.Stream}`,
    Structure,
    Component,
    Button,
};
