import React from "react";
import { CorePureComponent } from "@7egend/web.core";
import {
    withRouter,
    RouteComponentProps
} from "react-router";
import queryString from "query-string";
import { withQueryParams, WithQueryParamsProps } from "@7egend/web.core/lib/components/withQueryParams";
import { withI18n, WithI18nProps } from "@7egend/web.core/lib/components/withI18n";
import { Trans } from "@7egend/web.core/lib/components/i18n";
import { DloOutput } from "@7egend/web.core/lib/extensions/dlo"
import { Column, Row } from "../Structure";
import { OverlayLoading, StickyDiv } from "./styles";
import { Button } from "../Button";
import { SnackbarContainer } from "../SnackbarContainer";
import { Spinner } from "../Spinner";
import { Dialog, DialogTypes } from "../Dialog";
import { SnackbarLayout } from "../Snackbar";
import { NotFound } from "../../modules/core/NotFound";
import { PageEditorDefaultWidgets } from "../PageEditorDefaultWidgets";
import { BaseModel } from "@7egend/web.core.dlos/lib/dlos/base";
import { APP_TRANSLATIONS } from "../../locale";
import { FieldsRules, FieldsRulesOptions } from "../../base/fieldsRules";
import { I18N_KEY_CORE_COMPONENTS_PAGE_EDITOR, I18N_KEY_GLOBAL } from "../../base/i18n";

export interface PageEditorFieldsRules {
    fields: FieldsRules
}

export interface PageEditorRenderOptions<DataType = BaseModel | any, MetadataType = any> {
    /** current data */
    data: DataType;

    /** metadata */
    metadata?: MetadataType;

    /** Use it to send to Page Editor to save your data */
    setData: (data: Partial<DataType>) => void;

    /** current language */
    language: string;

    /** loading state */
    loading?: boolean;

    /** add message */
    addMessage: (
        message: string,
        layout?: SnackbarLayout,
        timeout?: number
    ) => void;

    /** update the current language */
    updateLanguage?: (currentLanguage: string) => void;

    /**
     * Triggers a new fetch.
     * This will update the current data with given response
     */
    refetch: () => Promise<FetchResult>
}

export interface PageEditorSavingOptions<DataType = BaseModel | any, MetadataType = any>
    extends Pick<PageEditorRenderOptions<DataType, MetadataType>, "data" | "metadata" | "updateLanguage" | "language" | "setData" | "addMessage" | "loading"> {
    /** saving state */
    saving: boolean;
    /** current content page editor language */
    targetLanguage?: string;
}

export interface PageEditorPublishOptions<DataType = BaseModel | any> extends Pick<PageEditorSavingOptions<DataType>, "data" | "updateLanguage" | "language" | "setData" | "saving"> {

    /** save action */
    saveAction?: () => void;

    /** cancel action */
    cancelAction?: () => void;

    /** if still data to save */
    dataToSave?: boolean;
}

interface PageEditorFetchOptions<DataType = BaseModel | any, MetadataType = any> extends Pick<PageEditorRenderOptions<DataType, MetadataType>, "data" | "metadata"> {
    /** current language */
    language: string;
    /** add message */
    addMessage?: (
        message: string,
        layout?: SnackbarLayout,
        timeout?: number
    ) => void;
}

export interface FetchResult<Data = any, Metadata = any> { data?: Data, metadata?: Metadata }
export type FetchCallback<Data = any, Metadata = any> = (options: PageEditorFetchOptions) => Promise<FetchResult<Data, Metadata> | undefined>;

interface PageEditorProps {
    /** class name */
    className?: string;

    overrides?: {
        Publish?: React.FC<PageEditorPublishOptions<any>>
    }

    /** fetch action */
    fetch?: FetchCallback;

    /** submit action */
    submit?: (
        options: PageEditorSavingOptions<any>
    ) => Promise<void | DloOutput<void>>;

    /** default language */
    defaultLanguage?: string;

    /** page aside */
    aside?: (
        options: PageEditorRenderOptions<any>
    ) => React.ReactComponentElement<any> | React.ComponentType<any> | Element | null;

    /** page content */
    children?: (
        options: PageEditorRenderOptions<any>
    ) => React.ReactComponentElement<any> | React.ComponentType<any> | Element;

    /** data initial state */
    dataInitialState?: any;

    /** metadata initial state */
    metadataInitialState?: any;

    /** show languages
     * @default true 
     */
    showLanguage?: boolean;

    fieldsRules?: FieldsRules;
}

interface PageEditorState {
    loading: boolean;
    saving: boolean;
    data: any;
    metadata: any;
    dataToSave: boolean;
    hasError: boolean;
    dialog: {
        isVisible: boolean;
        type?: DialogTypes;
        overlay?: boolean;
        title?: React.ComponentType | React.ComponentClass<any> | Element;
        description?: React.ComponentType | React.ComponentClass<any> | Element;
        showIcon?: boolean;
        cancelAction?: () => void;
        submitAction?: () => void;
    };
    /**
     * Last fetch date
     */
    fetchDateTime?: string
}

const I18N_NAMESPACE = I18N_KEY_CORE_COMPONENTS_PAGE_EDITOR

/**
 * # PageEditor Component
 *
 * ## How to use:
 *
 * ```jsx
 * <PageEditor
 *      fetch={(language: string) => Promise<DloOutput<void>>}
 *      submit={(language: string, data: any) => Promise<DloOutput<void>>}
 *      defaultLanguage="en"
 *      aside={(data: any, setData: (data: any) => void, language: string) => React.ReactComponentElement<any> | React.ComponentType<any> | Element}
 * >
 *      {(data: any, language: string) => React.ReactComponentElement<any> | React.ComponentType<any> | Element;}
 * </PageEditor>
 * ```
 *
 */
class PageEditorComponent extends CorePureComponent<
    PageEditorProps & RouteComponentProps<{ id: string, language?: string }> & WithQueryParamsProps<{ language: string }> & WithI18nProps,
    PageEditorState
> {
    private snackBarContainerRef = React.createRef<SnackbarContainer>();

    public state: PageEditorState = {
        data: this.props.dataInitialState || undefined,
        metadata: this.props.metadataInitialState || undefined,
        loading: false,
        saving: false,
        dataToSave: false,
        dialog: {
            isVisible: false,
        },
        hasError: false,
    };

    private getCurrentLanguage = (): string => {
        if (this.props.queryParams && this.props.queryParams.language) {
            return this.props.queryParams.language;
        }

        return this.props.defaultLanguage || this.fw.i18n.getCurrentLanguage();
    }

    public componentDidMount() {
        this.fetchData();
    }

    public render() {
        /** export props */
        const {
            aside,
            className,
            children,
            overrides
        } = this.props;

        /** export state */
        const { data, saving, loading, hasError, dataToSave, metadata } = this.state;

        const PublishComponent = overrides?.Publish || PageEditorDefaultWidgets.Publish;

        /** content object */
        const contentObject = {
            data,
            metadata,
            language: this.getCurrentLanguage(),
            setData: this.setData,
            addMessage: this.addMessage,
            loading,
            hasError,
            refetch: this.refetch
        } as PageEditorRenderOptions;

        /** if has error */
        if (hasError && !loading) {
            return <NotFound />
        }

        /** if loaded and don´t have error */
        if (!hasError) {
            return (
                <div className={className}>
                    <SnackbarContainer ref={this.snackBarContainerRef} />

                    {this.renderDialog()}

                    {loading && this.props.match.params.id && (
                        <OverlayLoading>
                            <Spinner size="huge" />
                        </OverlayLoading>
                    )}

                    <Row>
                        <Column lg={7} xl={8} key={this.state.fetchDateTime || contentObject.language}>
                            {children && children(contentObject)}
                        </Column>
                        <Column lg={5} xl={4}>
                            <StickyDiv>
                                <PublishComponent
                                    data={data}
                                    saving={saving}
                                    dataToSave={dataToSave}
                                    language={this.getCurrentLanguage()}
                                    setData={this.setData}
                                    cancelAction={this.goBack}
                                    saveAction={this.props.submit ? this.onPublishSave : undefined}
                                    updateLanguage={this.updateLanguage}
                                    resetData={this.resetData}
                                    showLanguage={this.props.showLanguage}
                                />
                                {aside && aside(contentObject)}
                            </StickyDiv>
                        </Column>
                    </Row>
                </div>
            );
        }

        return null;
    }

    private onPublishSave = () => {
        return this.saveAction(this.getCurrentLanguage()).catch(() => {
            this.fw.log.info('error saving action');
        });
    }

    private validateFieldsRules = async (fields?: FieldsRules): Promise<boolean> => {
        const { data } = this.state;
        const { t } = this.props;

        // if fields object empty return true (valid)
        if (!fields) {
            return true;
        }

        Object.keys(fields).map(key => {
            const field = fields[key];
            const isEmpty = data[key] === "";
            const isNull = data[key] === null;
            const isUndefined = data[key] === undefined;
            const isRequired = ((field as any).required === true || (field as any).required.value === true);

            if ((isEmpty || isNull || isUndefined) && isRequired) {
                const message = ((field as any).required.message && t((field as any).required.message)) || t(`${I18N_NAMESPACE}.error.missingField`, { field: t((field as FieldsRulesOptions).name) });
                this.addMessage(message, "error")
                throw message
            }
        });

        return true;
    }

    /** It goes back to the Module Item */
    private goBack = () => {
        const currentUrl = this.props.location.pathname.split("/");

        if (currentUrl[2] !== "profile") {
            const current = {
                language: this.fw.i18n.getCurrentLanguage(),
                module: currentUrl[2],
                moduleItem: currentUrl[3],
            }

            this.props.history.push(`/${current.language}/${current.module}/${current.moduleItem}`);
        } else {
            this.props.history.push(`/${this.fw.i18n.getCurrentLanguage()}`);
        }
    };

    /** Render Dialog component */
    private renderDialog = () => {
        const { dialog, saving } = this.state;
        const buttonLayout = dialog.type === "success" ? "main" : dialog.type;

        return (
            <Dialog
                showIcon={dialog.showIcon}
                handleDialog={this.toggleDialog}
                isVisible={dialog.isVisible}
                type={dialog.type}
                overlay={dialog.overlay}
                title={dialog.title}
                description={dialog.description}
            >
                {dialog.cancelAction && (
                    <Button
                        layoutOutline
                        onClick={dialog.cancelAction}
                        layout={buttonLayout}
                    >
                        <Trans id={`${I18N_KEY_GLOBAL}.editor.discard`} />
                    </Button>
                )}
                {dialog.submitAction && (
                    <Button
                        layoutFill
                        onClick={dialog.submitAction}
                        layoutIconPosition="right"
                        isLoading={saving}
                        layout={buttonLayout}
                    >
                        <Trans id={`${I18N_KEY_GLOBAL}.editor.save`} />
                    </Button>
                )}
            </Dialog>
        );
    };
    /** toggle dialog visibility and data */
    private toggleDialog = (
        type?: DialogTypes,
        overlay?: boolean,
        title?: React.ComponentType | React.ComponentClass<any> | Element,
        description?: React.ComponentType | React.ComponentClass<any> | Element,
        showIcon?: boolean,
        cancelAction?: () => void,
        submitAction?: () => void
    ) => {
        const { dialog } = this.state;

        this.setState({
            dialog: {
                isVisible: !dialog.isVisible,
                type,
                overlay,
                title,
                description,
                showIcon,
                cancelAction,
                submitAction,
            },
        });
    };

    /** updates the state of your data */
    private setData = (data: any) => {
        this.setState({
            data: data !== null && typeof (data) === "object" && !Array.isArray(data) ? {
                ...this.state.data,
                ...data,
            } : data,
            dataToSave: true,
        });
    };

    private resetData = async () => {
        const language = this.getCurrentLanguage();
        this.fetchData(language);
    }

    private fetchData = async (language?: string) => {

        if (this.props.fetch) {
            this.setState({ loading: true });

            return this.props.fetch({
                language: language || this.getCurrentLanguage(),
                data: this.state.data,
                metadata: this.state.metadata,
                addMessage: this.addMessage,
            })
                .then(res => {
                    this.setState({
                        loading: false,
                        dataToSave: false,
                        data: res?.data ?? this.props.dataInitialState,
                        metadata: res?.metadata ?? this.state.metadata,
                        fetchDateTime: new Date().getTime().toString(),
                    })
                    return res
                }
                )
                .catch(err => {
                    this.fw.log.error("Failed to fetch data", err)
                    this.setState({
                        hasError: true,
                        loading: false,
                    });
                });
        } else {
            this.setState({
                data: this.props.dataInitialState || undefined
            })
        }
    }

    private refetch = () => {
        return new Promise((resolve, reject) => {
            // Doing this in the next tick so the render can finish and the state updated
            setTimeout(() => {
                const language = this.getCurrentLanguage();
                this.fetchData(language).then(resolve).catch(reject)
            }, 0)
        })
    }

    /** sets the language you selected */
    private updateLanguage = (currentLanguage: string) => {
        /** if there is updates to be saved, show dialog */
        if (this.state.dataToSave) {
            this.toggleDialog(
                "warning",
                true,
                <Trans id={`${I18N_KEY_GLOBAL}.labels.warning`} />,
                <Trans id={`${I18N_NAMESPACE}.messageOnChangeLanguage`} />,
                true,
                () => {
                    /** on cancel/close */
                    this.toggleDialog();
                    this.pushToHistoryPath(currentLanguage);
                    this.fetchData(currentLanguage);
                },
                () => {
                    // submit action
                    this.saveAction(currentLanguage)
                        .then(() => {
                            this.pushToHistoryPath(currentLanguage);
                            this.toggleDialog();
                            this.fetchData(currentLanguage);
                        })
                        .catch((err) => {
                            this.fw.log.error("Failed saving page", err)
                        })
                }
            );
        } else {
            /** no updates... */
            this.pushToHistoryPath(currentLanguage);
            this.fetchData(currentLanguage);
        }
    };

    /**
     * It will deal with multiple params.
     * as default it sets the language on your path name
     */
    private pushToHistoryPath = (language: string) => {

        const search = queryString.stringifyUrl({
            url: "",
            query: {
                ...this.props.queryParams,
                language
            }
        })

        this.props.history.push({
            search
        });
    }

    private saveAction = async (targetLanguage: string) => {
        const { data, loading, saving, metadata } = this.state;
        const { fieldsRules } = this.props;

        if (!await this.validateFieldsRules(fieldsRules)) {
            return false;
        }

        // set loading state
        this.setState({
            saving: true,
        });

        // submit action
        if (this.props.submit) {
            return this.props.submit({
                data,
                language: this.getCurrentLanguage(),
                setData: this.setData,
                addMessage: this.addMessage,
                targetLanguage,
                saving,
                loading,
                metadata,
            })
                .then(() => {
                    this.setState({
                        saving: false,
                        dataToSave: false
                    })
                })
                .catch((err) => {
                    this.setState({ saving: false })
                    throw err;
                });
        }
    };

    /** Function to push snackbar to container
     *
     * @param {string} message message to snackbar
     * @param {string} layout layout of snackbar
     */
    private addMessage = (
        message: string,
        layout?: SnackbarLayout,
        timeout?: number
    ) => {
        if (this.snackBarContainerRef.current) {
            this.snackBarContainerRef.current.pushMessage({
                layout: layout as any,
                message,
                timeout,
            });
        }
    };
}

export const PageEditor = withRouter(withQueryParams(withI18n(APP_TRANSLATIONS)(PageEditorComponent))) as React.ComponentClass<PageEditorProps>;
