import React, { PureComponent } from "react"
import { Styled } from "./styles";
import { Typography } from "../../Typography";
import { PLACEHOLDER_DOCUMENT } from "../../MediaList/Media/MediaPlaceholder";
import { colors } from "../../../styles";
import { withI18n, WithI18nProps } from "@7egend/web.core/lib/components/withI18n";
import { APP_TRANSLATIONS } from "../../../locale";
import { I18N_KEY_CORE_COMPONENTS_PROGRESS_BAR } from "../../../base/i18n";

export interface ProgressBarProps {
    isVisible: boolean
    /** ### Uploading to... *your label* */
    uploadingTo?: string,
    /** ### Cancel action */
    cancelAction?: () => void
    /** ### Uploading image */
    uploadingImage?: string
    /** ### Total of items to upload */
    uploadingTotal?: number,
    /** ### Current item uploading */
    uploadingCurrent?: number,
    /** uploadingPercentage */
    uploadingPercentage?: number,
    /** if is document */
    isDocument?: boolean
}

const I18N_NAMESPACE = I18N_KEY_CORE_COMPONENTS_PROGRESS_BAR

/**
 * # ProgressBar Component
 * 
 * This component is used in Uploader Image, File and 360º Components
 * 
 * ## How to use
 * 
 * ```jsx
 * <ProgressBar
 *  isVisible={this.state.isImageUploading} 
 * />
 * ```
 */
export class ProgressBarComponent extends PureComponent<WithI18nProps & ProgressBarProps> {

    public render() {
        const { t } = this.props;
        return (
            <Styled.Overlay isVisible={this.props.isVisible}>
                <Styled.Wrapper>
                    {
                        (this.props.uploadingImage && !this.props.isDocument) &&
                        <Styled.Image style={{
                            backgroundImage: `url(${this.props.uploadingImage})`
                        }} />
                    }
                    {
                        this.props.isDocument &&
                        <Styled.Image style={{
                            backgroundImage: `url(${PLACEHOLDER_DOCUMENT})`,
                            backgroundSize: "auto",
                            backgroundColor: colors.neutral.lighter,
                        }} />
                    }
                    <Styled.Content>
                        <Styled.UploadingTo>
                            <Typography.SmallCapsTitle>{t(`${I18N_NAMESPACE}.uploading`)}</Typography.SmallCapsTitle>
                            <Typography.RegularText variant="neutral">{this.props.uploadingTo}</Typography.RegularText>
                        </Styled.UploadingTo>
                        {this.renderItemsUploading()}
                    </Styled.Content>
                </Styled.Wrapper>
            </Styled.Overlay>
        )
    }

    private renderItemsUploading = () => {
        let total: string = "";
        let current: string = "";
        const { uploadingTotal, uploadingCurrent } = this.props;

        if (uploadingTotal) {
            if (uploadingTotal < 10) {
                total = `0${uploadingTotal}`;
            } else {
                total = `${uploadingTotal}`;
            }
        }

        if (uploadingCurrent) {
            if (uploadingCurrent < 10) {
                current = `0${uploadingCurrent}`;
            } else {
                current = `${uploadingCurrent}`;
            }
        }

        return (
            <Styled.Progress>
                <Typography.SmallText variant="primary">
                    {`${current} / ${total}`}
                </Typography.SmallText>
                <Styled.ProgressBar percentage={this.props.uploadingPercentage} />
            </Styled.Progress>
        )
    }

}


export const ProgressBar = withI18n(APP_TRANSLATIONS)(ProgressBarComponent);
