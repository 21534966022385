import styled, { css } from "styled-components";
import { colors, font } from "../../styles";

interface LineProps {
    isTitle?: boolean;
    deleted?: boolean;
}

const Line = styled.div<LineProps>`
    color: ${colors.neutral.darker};
    font-size: 14px;
    font-weight: ${font.weight.light};
    line-height: 18px;
    padding-top: 20px;
    padding-bottom: 15px;
    width: 100%;
    display: flex;
    transition: background-color 0.5s ease;
    align-items: center;
    background-color: white;

    p {
        min-height: 18px;
    }

    ${(props: LineProps) =>
        props.isTitle &&
        css`
            color: ${colors.neutral.darker};
            font-size: ${font.size.xs};
            font-weight: ${font.weight.semiBold};
            letter-spacing: 1.4px;
            line-height: 16px;
            padding-top: 10px;
            padding-bottom: 8px;
            text-transform: uppercase;
    `}
`;

export default Line;
