import { Module } from "../../../bootstrap"
import loadable from "@loadable/component"

export const dashboardSetup: Module = {
    menus: [],
    routes: [
        {
            component: loadable(async () => (await import('./Dashboard')).Dashboard),
            path: "/",
            exact: true,
        }
    ]
}
