import React from "react";
import { compose, CorePureComponent } from "@7egend/web.core"
import { StyledUploader } from "../styles"
import { uniqueId } from "@7egend/web.core/lib/utils"
import { LimitSizeUpload } from "../../../constants"
import { RouteComponentProps, withRouter } from "react-router";
import { FileInfo, UploaderProps } from "../domain"

export interface ImagePanoramicProps extends React.PropsWithChildren<UploaderProps> {
    /** upload action */
    uploadAction?: (base64: FileInfo[]) => void

    /** @default true */
    allowUpload?: boolean;

    /** image preview */
    previewImage?: string;

    /** clean preview image */
    cleanPreviewImage?: () => void;
}

const UNIQUE_ID = uniqueId("image_upload")
const ACCEPTED_TYPES = "image/x-png,image/gif,image/jpg";

/**
 * # Uploader Image Panoramic
 * 
 * ## How to use: 
 * 
 * ```jsx
 * <Uploader.ImagePanoramic
 *      uploadAction={this.handleUpload}
 *      label="Upload 360º"
 *      info="jpg, png, tiff"
 * />
 * ```
 */
class ImagePanoramicComponent extends CorePureComponent<ImagePanoramicProps & RouteComponentProps> {

    /** define type for input file */
    private inputRef: React.RefObject<any> = React.createRef();

    public render() {
        const { info, label } = this.props

        return (
            <StyledUploader.Wrapper>
                <StyledUploader.Label
                    style={{
                        backgroundImage: `url(${this.props.previewImage})`,
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                    }}
                >
                    {this.props.previewImage ? (
                        <StyledUploader.ActionPreviewClean
                            onClick={() => {
                                if (this.props.cleanPreviewImage) {
                                    this.props.cleanPreviewImage();
                                }
                            }}
                            layout="alt"
                            icon={"delete"}
                        />
                    ) : (
                        <StyledUploader.Action
                            onClick={() => {
                                if (this.props.allowUpload === false) {
                                    this.props.history.push(`/${this.fw.i18n.getCurrentLanguage()}/cms/multimedia/add?type=image-panoramic`);
                                    return;
                                }
                                this.inputRef.current.click();
                            }}
                            layout="alt"
                            icon="panorama_horizontal" />
                    )}
                    <input
                        ref={this.inputRef}
                        style={{ display: "none" }}
                        type="file"
                        accept={ACCEPTED_TYPES}
                        multiple={true}
                        id={UNIQUE_ID}
                        name={UNIQUE_ID}
                        onChange={this.onFileSelect}
                    />
                </StyledUploader.Label>
                {label && <StyledUploader.Header>{label}</StyledUploader.Header>}
                {info && <StyledUploader.SubHeader variant="neutral">{info}</StyledUploader.SubHeader>}
            </StyledUploader.Wrapper>
        )
    }

    private getFileFromInput(file: any): Promise<FileInfo> {
        return new Promise((res, rej) => {
            const reader: any = new (this.fw.dom.getGlobal()).FileReader();
            reader.onerror = rej;
            reader.onload = () => {
                res({
                    base64: reader.result,
                    name: file.name,
                    error: file.size > LimitSizeUpload ? true : false,
                });
            };
            reader.readAsDataURL(file);
        });
    }

    private onFileSelect = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const files: any = (e.target as any).files;
        e.persist();

        Promise.all(
            Object.keys(files).map(key => this.getFileFromInput(files[key]))
        )
            .then((filesInfo) => {
                if (this.props.uploadAction) {
                    /** send each base64 file and the total number of files to upload */
                    this.props.uploadAction(filesInfo);
                }
            })
            .catch((err) => {
                this.fw.log.error("Get File Error", err);
            });
    }
}

export const ImagePanoramic = compose<React.ComponentClass<ImagePanoramicProps>>(
    withRouter
)(ImagePanoramicComponent)
