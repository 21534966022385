import { ModuleSetup } from "../../bootstrap"
import content from "./../../assets/menuIcons/content.svg";
import { BlockType } from "../../components";
import { Config } from "../../base/config";

// Import modules
import { cmsMenuSetup } from "./Menu"
import { newsSetup } from "./News";
import { albumsSetup } from "./Albums";
import { multimediaSetup } from "./Multimedia";
import { boostersSetup } from "./Boosters";
import { pagesSetup } from "./DynamicPages";
import { staticPagesSetup } from "./StaticPages";
import { formSetup } from "./Forms";
import { pollsSetup } from "./Polls";
import { socialSetup } from "./Social";
import { CMSConfig } from "./config"

// Export pickers
export { NewsPicker } from "./News";
export { AlbumPicker } from "./Albums";
export { FormPicker } from "./Forms";
export { PagesPicker } from "./DynamicPages";
export { BoostersPicker } from "./Boosters";

/** # CMS Module Setup */
const setup: ModuleSetup = (config: Config & CMSConfig) => {
    const flags = config.flags;

    return {
        key: "cms",
        routes: [
            // News Routes
            ...(flags.cms_news !== false ? newsSetup.routes : []),
            // Album Routes
            ...albumsSetup.routes,
            // Multimedia Routes
            ...multimediaSetup.routes,
            // Boosters Routes
            ...(flags.cms_boosters !== false ? boostersSetup.routes : []),
            // Pages Routes
            ...(flags.cms_pages !== false ? pagesSetup.routes : []),
            // Static Pages Routes
            ...(flags.cms_static_pages !== false ? staticPagesSetup.routes : []),
            // Forms Routes
            ...(flags.cms_forms !== false ? formSetup.routes : []),
            // Polls Routes
            ...(flags.cms_polls !== false ? pollsSetup.routes : []),
            // Menu Routes
            ...(flags.cms_menu !== false ? cmsMenuSetup.routes : []),
            // Social Routes
            ...(flags.cms_social !== false ? socialSetup(config).routes : []),
        ].filter(Boolean),
        menus: [
            {
                title: "CMS",
                path: "cms",
                readOnly: true,
                icon: content,
                permission: "cms",
                children: [
                    // Menu Menu
                    ...(flags.cms_menu !== false ? cmsMenuSetup.menus : []),
                    // Pages Menu
                    ...(flags.cms_pages !== false ? pagesSetup.menus : []),
                    // Static Pages Menu
                    ...(flags.cms_static_pages !== false ? staticPagesSetup.menus : []),
                    // News Menu
                    ...(flags.cms_news !== false ? newsSetup.menus : []),
                    // Multimedia Menu
                    ...multimediaSetup.menus,
                    // Albums Menu
                    ...albumsSetup.menus,
                    // Boosters Menu
                    ...(flags.cms_boosters !== false ? boostersSetup.menus : []),
                    // Forms Menu
                    ...(flags.cms_forms !== false ? formSetup.menus : []),
                    // Polls Menu
                    ...(flags.cms_polls !== false ? pollsSetup.menus : []),
                    // Social Menu
                    ...(flags.cms_social !== false ? socialSetup(config).menus : []),
                ].filter(Boolean),
            },
        ],
        blocks: {
            "cms.pages": [
                // Text
                BlockType.Title,
                BlockType.Lead,
                BlockType.Highlight,
                BlockType.Text,
                BlockType.SmallText,
                BlockType.List,
                BlockType.ExpansionPanel,

                // Medium
                BlockType.Image,
                BlockType.Video,
                BlockType.Stream,
                BlockType.Album,
                BlockType.ImagePanoramic,

                // Actions
                BlockType.CTA,
                BlockType.CTAList,
                flags.cms_boosters !== false && BlockType.Booster,
                flags.cms_boosters !== false && BlockType.BoosterList,
                BlockType.Attachment,

                // Mix
                BlockType.ImageAndText,

                // Other
                BlockType.ColumnHighlight,
                BlockType.Iframe,
                BlockType.Form,
                BlockType.HTML,
                BlockType.Separator,
            ].filter(Boolean) as any[],
            "cms.static-pages": [
                // Text
                BlockType.Title,
                BlockType.Lead,
                BlockType.Highlight,
                BlockType.Text,
                BlockType.SmallText,
                BlockType.List,
                BlockType.ExpansionPanel,

                // Medium
                BlockType.Image,
                BlockType.Video,
                BlockType.Stream,
                BlockType.Album,
                BlockType.ImagePanoramic,

                // Actions
                BlockType.CTA,
                BlockType.CTAList,
                flags.cms_boosters !== false && BlockType.Booster,
                flags.cms_boosters !== false && BlockType.BoosterList,
                BlockType.Attachment,

                // Mix
                BlockType.ImageAndText,

                // Cms
                flags.cms_news !== false && BlockType.News,

                // Other
                BlockType.ColumnHighlight,
                BlockType.Iframe,
                BlockType.Form,
                BlockType.HTML,

                BlockType.Separator,
            ].filter(Boolean) as any[],
            "cms.news": [
                // Text
                BlockType.Title,
                BlockType.Highlight,
                BlockType.List,

                // Medium
                BlockType.Image,
                BlockType.Video,
                BlockType.Stream,
                BlockType.Album,
                BlockType.ImagePanoramic,
            ].filter(Boolean) as any[],
        }
    }
};

export {CMSConfig}
export default setup;
