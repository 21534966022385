import React, { PureComponent } from "react";
import { StyledPicker } from "./styles";
import { Column } from "../../../../components/Structure";
import { MediaListData } from "../../../../components/Multimedia";
import { Button } from "../../../../components/Button";
import { Modal } from "../../../../components/Modal";
import { Image } from "../../../../components/Image";
import styled from "styled-components";
import { Albums } from "../Albums";
import { withI18n, WithI18nProps } from "@7egend/web.core/lib/components/withI18n";
import { Trans } from "@7egend/web.core/lib/components/i18n";
import { APP_TRANSLATIONS } from "../../../../locale";
import { I18N_KEY_CMS_PAGES_ALBUM, I18N_KEY_GLOBAL } from "../../../../base/i18n";

interface AlbumPickerProps {
    /** limit the number of items (media) that can be selectable */
    limitSelectable?: number;

    /** return checked media items */
    checkedMedia: (checkedMedia: MediaListData[]) => void;

    /** to override the default number of columns */
    renderColumns?: {
        xs?: number;
        sm?: number;
        md?: number;
        lg?: number;
        xl?: number;
    };

    /** class name */
    className?: string

    /** height limited */
    heightLimited?: boolean;

    /** preview selected image */
    previewImage?: string;

    /** label text */
    label?: string

    /** info text */
    info?: React.ReactComponentElement<any> | React.ComponentClass<any> | string | false

    /** multiple upload */
    multiple?: boolean
}

const StyledPreviewImage = styled.div`
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: inherit;
    overflow: hidden;

    img {
        max-height: 100%;
    }
`

interface AlbumPickerUploaderState {
    modalVisible?: boolean;
    checkedMedia: MediaListData[];
}

const defaultColumns = {
    xl: 2,
};

const I18N_NAMESPACE = I18N_KEY_CMS_PAGES_ALBUM

/**
 * # Uploader Media List
 *
 * ## How to use:
 *
 * ```jsx
 * <Uploader.AlbumPicker
 *      checkedMedia={(checkedMedia: MediaListData[]) => void}
 *      label="Upload Thumbnail"
 *      heightLimited={true|false}
 *      previewImage={string}
 *      limitSelectable={number}
 *      renderColumns={{ xs: 12, sm: 6 ... }}
 * />
 * ```
 */
class AlbumPickerComponent extends PureComponent<
    AlbumPickerProps & WithI18nProps,
    AlbumPickerUploaderState
    > {
    public state: AlbumPickerUploaderState = {
        modalVisible: false,
        checkedMedia: [],
    };

    public render() {
        const { info, label, heightLimited } = this.props;
        const hasPreviewImage: boolean = !!this.props.previewImage;
        const actionIcon: string = hasPreviewImage ? "close" : "add";

        return (
            <React.Fragment>
                <Column {...(this.props.renderColumns || defaultColumns)}>
                    <StyledPicker.Wrapper
                        heightLimited={heightLimited}
                        className={this.props.className}
                    >
                        <StyledPicker.Actions
                            onClick={this.toggleModal}
                            hasPreviewImage={hasPreviewImage}
                        >
                            {hasPreviewImage &&
                                <StyledPreviewImage>
                                    <Image src={this.props.previewImage || " "} />
                                </StyledPreviewImage>
                            }
                            <StyledPicker.Action layout="alt" icon={actionIcon} />
                            <StyledPicker.FlatInfo>
                                {info}
                            </StyledPicker.FlatInfo>
                        </StyledPicker.Actions>
                        <StyledPicker.Header>{label}</StyledPicker.Header>
                    </StyledPicker.Wrapper>
                </Column>
                <Modal
                    handleModal={this.toggleModal}
                    isVisible={this.state.modalVisible}
                    overlay
                    fullWidth
                >
                    <Albums
                        limitSelectable={this.props.limitSelectable}
                        footer={
                            <Column contentAlign="right">
                                <Button
                                    layout="main"
                                    layoutOutline
                                    icon="clear"
                                    layoutIconPosition="right"
                                    onClick={this.toggleModal}
                                >
                                    <Trans id={`${I18N_KEY_GLOBAL}.cancel`} />
                                </Button>
                                <Button
                                    layout="main"
                                    layoutFill
                                    icon="check"
                                    layoutIconPosition="right"
                                    onClick={this.addItems}
                                    disabled={
                                        this.state.checkedMedia.length < 1
                                    }
                                >
                                    <Trans id={`${I18N_NAMESPACE}.add`} />{" "}
                                    {this.state.checkedMedia.length > 0 &&
                                        this.state.checkedMedia.length}{" "}
                                    <Trans id={`${I18N_NAMESPACE}.items`} />
                                </Button>
                            </Column>
                        }
                        checkedMedia={(checkedMedia: MediaListData[]) =>
                            this.setState({
                                checkedMedia,
                            })
                        }
                    />
                </Modal>
            </React.Fragment>
        );
    }

    private toggleModal = () => {
        const { modalVisible } = this.state;

        this.setState({
            modalVisible: !modalVisible,
        });
    };

    private addItems = () => {
        if (this.props.checkedMedia) {
            /** sent to parent component, checked items */
            this.props.checkedMedia(this.state.checkedMedia);

            /** hide modal */
            this.toggleModal();

            /** clean state */
            this.setState({
                checkedMedia: [],
            });
        }
    };
}

export const AlbumPicker = withI18n(APP_TRANSLATIONS)(AlbumPickerComponent);
