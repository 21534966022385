import { I18n } from "@7egend/web.ground/lib/domain/extensions/i18n";

export const translateStringOrObject = (title: any, language: string | undefined, t: I18n["translate"]) => {
    let showTitle: string = "";

    if (typeof title === "string") {
        // Easy, title is only a string
        showTitle = title
    }
    else if (typeof title === "object") {
        // Try to translate if the key exists
        if ("key" in title && title.key) {
            showTitle = t(title.key)
        }
        // At this point, title must be translated
        // If not, try the locale instead
        if ((!showTitle || showTitle === title.key) && language) {
            showTitle = title[language]
        }
        // And... there's nothing more to do now
    }
    else {
        // Something strange here, 
    }

    return showTitle;
}