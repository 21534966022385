import React from "react";
import loadable from "@loadable/component";
import { Block } from "@7egend/web.core.cms/lib/dlos/block";
import { Text as TextDLO } from "@7egend/web.core.cms/lib/dlos/text";
import { BlockComponentProps } from "../../interfaces";
import { withI18n, WithI18nProps } from "@7egend/web.core/lib/components/withI18n";
import { APP_TRANSLATIONS } from "../../../../locale";
import { blockTypes } from "../config";
import { I18N_KEY_CMS_COMPONENTS_BLOCK } from "../../../../base/i18n";

const { html } = blockTypes;

const Structure = {
    type: html.type,
    content: {},
} as Block<TextDLO>;

const HTMLArea = loadable(async () => (await import("../../../TextArea")).TextArea);

const I18N_NAMESPACE = `${I18N_KEY_CMS_COMPONENTS_BLOCK}.types.html`

const HTMLComponent: React.FC<WithI18nProps & BlockComponentProps<TextDLO>> = ({ className, data, updateData, t }) => {
    return (
        <HTMLArea
            className={className}
            name=""
            placeholder={t(`${I18N_NAMESPACE}.inputHTMLPlaceholder`)}
            value={data.content && data.content.value}
            onChange={(value: any) => updateData({
                ...data,
                content: {
                    ...data.content,
                    value,
                }
            })}
        />
    )
}

const Component = withI18n(APP_TRANSLATIONS)(HTMLComponent);

const Button = {
    icon: "blur_linear",
    name: {
        key: `${I18N_NAMESPACE}.name`,
        en: "HTML",
        es: "HTML",
        pt: "HTML",
    },
};

export const HTML = {
    key: html.type,
    Structure,
    Component,
    Button,
};
