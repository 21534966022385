import styled from "styled-components";
import { Column, Container, Row } from "../Structure";
import { Button } from "../Button";
import { font, queries, colors } from "../../styles";
import { Label } from "../Label";
import { StyledDropdown } from "../Dropdown/styles";

/**
 * Styled components
 */
export const StyledFooter = styled.div`
    margin-top: 24px;
`;

export const StyledDiv = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    & > * {
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;

export const StyledWrappButtons = styled(Row)`
    justify-content: flex-end;
    margin: 0;

    button {
        min-height: 0;
        padding-top: 0;
        padding-bottom: 0;
    }

    & > :first-child {
        padding-left: 0;
        padding-right: 0;
    }

    & > * {
        margin-left: 15px;
        padding-left: 0;
        padding-right: 0;
    }

    ${queries.maxXS} {
        button {
            margin: 0;
            padding: 0 4px;
        }
    }

    .tooltip {
        button { 
            padding: 0;
            display: flex;
        };
    }

    a {
        button { 
            padding: 0; 
            margin: 0;
        };
    }
`;

export const StyledColumnPlaceholder = styled(Column)`
    padding-left: 0;
    padding-right: 0;
`;

export const StyledButton = styled(Button)`
    padding: 0;
    padding-left: 5px;
    min-height: 0;

    i {
        font-size: ${font.size.xs};
    }
`;

export const StyledFormatterLabel = styled(Label)`
    margin-right: 10px;

    i {
        margin-left: 5px;
    }
`

export const StyledFormatterImage = styled.div`
    width: 64px;
    height: 64px;
    border-radius: 10px;
    background-color: ${colors.neutral.lighter};
    overflow: hidden;
`

export const StyledDragWrapper = styled.div`
    width: 100%;
`

export const StyledDatatableWrapper = styled(Container)`
    & {
        table {
            display: flex;
            flex-flow: column nowrap;
            flex: 1 1 auto;
        }
        
        th, td {
            border-spacing: 0;
        }
        
        th {
            width: 100%;
            display: flex;
            flex-flow: row nowrap;
            white-space: normal;
            justify-content: flex-start;
            border-bottom: 1px solid ${colors.neutral.light};

            &.th-actions {
                flex: 0 0 180px;

                ${queries.maxSM} {
                    flex: 0 0 100px;
                }
            }
        }

        tr {
            width: 100%;
            display: flex;
            flex-flow: row nowrap;
        }

        td {
            display: flex;
            flex-flow: row nowrap;
            flex-grow: 1;
            flex-basis: 0;
            word-break: break-word;
            text-overflow: ellipsis;
            min-width: 0px;
            white-space: nowrap;
            border-bottom: 1px solid ${colors.neutral.light};
            align-items: center;

            &.td-actions {
                flex: 0 0 180px;
                justify-content: flex-end;
                
                ${queries.maxSM} {
                    flex: 0 0 100px;
                }

                ${StyledDropdown} {
                    & > div:first-child {
                        display: flex;
                        align-items: center;

                        button {
                            padding: 0;
                            margin: 0;
                        }
                    }
                }
            }
        }
    }
`;

export const StyledError = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
