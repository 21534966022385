import React from "react";
import { CorePureComponent } from "@7egend/web.core";
import { memoizeOne } from "@7egend/web.core/lib/utils";
import { MenuItems } from "../../../../bootstrap";
import { ItemWrapper } from "../ItemWrapper";
import { Item } from "../Item";
import { StyledModule } from "../styles";
import { Styles } from "./styles";
import { colors } from "../../../../styles";
import { withI18n, WithI18nProps } from "@7egend/web.core/lib/components/withI18n";
import { APP_TRANSLATIONS } from "../../../../locale";
import { translateStringOrObject } from "../../../../utils";
import { Config } from "../../../../base/config";
import { withSession, WithSessionProps } from "@7egend/web.core.security/lib/components/withSession/withSession";
import { roleHasPermission } from "../../../../utils/permissions";

interface EventWrapper {
    title?: string | { [locale: string]: string }
}

interface ModuleProps {
    /** menu module */
    module: MenuItems;

    /** module items */
    items?: MenuItems[];

    /** active modules */
    active?: string[];

    /** initial active state */
    initialActiveState?: boolean

    /** selected language */
    language?: string;
}

interface ModuleState {
    active?: boolean
}

const EVENT_WRAPPER_CLOSE = Symbol("wrapper.close")

class ModuleComponent extends CorePureComponent<ModuleProps & WithI18nProps & WithSessionProps, ModuleState> {

    public state: ModuleState = {
        active: this.props.initialActiveState
    }

    private menuEventSubscription?: () => void

    public componentDidMount() {
        const { module } = this.props;

        // Listen for menu wrapper changes
        this.menuEventSubscription = this.fw.events.subscribe<EventWrapper>(EVENT_WRAPPER_CLOSE, (options) => {
            if ((options.title !== module.title) && this.state.active) {
                this.setState({
                    active: false
                })
            }
        })
    }

    public componentDidUpdate(prevProps: ModuleProps) {
        if (prevProps.active !== this.props.active) {
            this.setState({
                active: this.isActive(this.props.active),
            })
        }
    }

    public componentWillUnmount() {
        if (this.menuEventSubscription) {
            this.menuEventSubscription()
        }
    }

    public render() {
        const { module, items, language } = this.props;
        const isActive = this.state.active !== undefined ? this.state.active : this.isActive(this.props.active);
        const title: string = translateStringOrObject(module.title, language, this.props.t);
        const config = (this.fw.config as Config);
        const { session } = this.props!.session!;
        const showItem = roleHasPermission(module.permission, config, session, session?.access_token);

        if (showItem) {
            return (
                <Styles.Wrapper
                    isEnabled={module.enabled !== false}
                    isActive={isActive}
                >
                    <StyledModule.Container
                        onClick={this.toggleModule}
                        isActive={isActive}
                        isEnabled={module.enabled !== false}
                    >
                        <Styles.ModuleImage src={module.icon || " "} />
                        <StyledModule.Title>
                            {title}
                            {this.renderExpandableIcon(isActive)}
                        </StyledModule.Title>
                    </StyledModule.Container>
                    {isActive && <Styles.Divider />}
                    {(items && items.length > 0) &&
                        <Styles.Content
                            isActive={isActive}
                        >
                            {items.map((item, key) => {
                                return (
                                    <ItemWrapper
                                        key={item.path || `item-wrapper-${key}`}
                                        name={item.title}
                                        path={item.path}
                                        expandable={item.expandable || false}
                                        active={this.props.active}
                                        readOnly={item.readOnly}
                                        permission={item.permission}
                                    >
                                        {item.children && item.children.map(subItem => {
                                            return (
                                                <Item
                                                    key={subItem.path}
                                                    name={subItem.title}
                                                    path={subItem.path}
                                                    visible={subItem.visible !== false}
                                                    active={this.props.active}
                                                />
                                            )
                                        })}
                                    </ItemWrapper>
                                )
                            })}
                        </Styles.Content>
                    }
                </Styles.Wrapper>
            )
        }

        return null;
    }

    private toggleModule = () => {
        const { module } = this.props;

        if (module.enabled !== false) {
            this.setState({
                active: !this.state.active,
            }, () => {
                this.fw.events.publish<EventWrapper>(EVENT_WRAPPER_CLOSE, {
                    title: module.title,
                });
            });
        }
    }

    /** Returns if the path (wrapper) is active or not */
    private isActive = memoizeOne((active?: string[]) => {
        const { module } = this.props;

        if (active) {
            return active.some((item) => item === module.path);
        }
    });

    /** 
     * Returns the expandable icon if enabled
     * If not enabled, returns the lock icon
     */
    private renderExpandableIcon = (isActive?: boolean): React.ReactElement => {
        const { module } = this.props;

        if (module.enabled === false) {
            return <StyledModule.ExpandableIcon color={colors.neutral.darker} icon="lock" />
        }

        return (
            <React.Fragment>
                {!isActive && <StyledModule.ExpandableIcon icon="add" />}
                {isActive && <StyledModule.ExpandableIcon icon="remove" />}
            </React.Fragment>
        )
    }
}

export const Module = withI18n(APP_TRANSLATIONS)(withSession(ModuleComponent));
