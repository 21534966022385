import styled from "styled-components";
import { Link } from "../../Link"
import { Image } from "../../Image"
import { Button } from "../../Button"
import { colors, queries, font } from "../../../styles"
import { StyledBreadcrumb } from "../../Breadcrumb/Breadcrumb";

export const StyledHeaderButton = styled(Button)`
    & {
        padding: 10px;
        margin-right: 10px;

        ${queries.maxSM} {
            padding: 5px 10px;
            min-height: 1px;
        }

        ${queries.maxXS} {
            margin-right: 0;
        }

        &:nth-child(3) {
            padding-right: 0;
        }
    }
`;

export const StyledMenuButton = styled<any>(StyledHeaderButton)`
    & {
        padding: 0;
        margin-right: 24px;

        ${queries.maxSM} {
            margin-right: 16px;
        }
    }
`

export const StyledHeader = styled.header`
    width: 100%;
    min-height: 85px;
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    background-color: ${colors.neutral.lighter};

    ${queries.maxMD} {
        position: fixed;
        left: 0;
        padding: 0 30px 20px;
        z-index: 12;

        & > div {
            width: 100%;
            padding: 0;
        }
    }

    ${queries.maxSM} {
        padding: 0 12px 20px;
    }
`

export const StyledUserArea = styled.div`
    margin-left: 22px;
    padding-left: 32px;
    border-left: 1px solid rgba(0, 0, 0, .2);
    height: inherit;
    display: flex;
    align-items: center;

    ${queries.maxXS} {
        margin-left: 16px;
        padding-left: 20px;
    }
`;

export const StyledUserAvatar = styled.div`
    width: 32px;
    height: 32px;
    border-radius: 100%;
    margin-right: 5px;
    overflow: hidden;

    img {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
`

export const StyledUserAvatarPlaceholder = styled.div`
    width: 32px;
    height: 32px;
    border-radius: 100%;
    margin-right: 5px;
    overflow: hidden;
    background-color: ${colors.semantic.light};
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: ${font.size.xs};
    font-weight: ${font.weight.medium};
    color: ${colors.primary.lighter};
    text-transform: uppercase;
`

export const StyledUserButton = styled(Button) <{ id?: string }>`
    & {
        padding: 0;

        &:hover {
            ${StyledUserAvatarPlaceholder} {
                color: ${colors.primary.lighter};
            }
        }
    }
`;

export const StyledHoverUser = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-bottom: 16px;

    ${StyledUserAvatar},
    ${StyledUserAvatarPlaceholder} {
        width: 56px;
        height: 56px;
        margin-right: 16px;
    }

    ${StyledUserAvatarPlaceholder} {
        font-size: 24px;
    }
`;

export const StyledHeaderActions = styled.div``

export const StyledLogo = styled(Image)`
    & {
        max-width: 48px;
        max-height: 48px;
        width: auto;
        height: auto;

        ${queries.maxSM} {
            max-width: 28px;
            max-height: 28px;
        }
    }
`

export const StyledPlatform = styled.div`
    margin-left: 12px;

    .platform {
        color: ${colors.primary.lighter};
        font-size: ${font.size.md};
        font-weight: ${font.weight.bold};
        display: block;
    }

    .client {
        color: ${colors.neutral.darker};
        font-size: ${font.size.sm};
        font-weight: ${font.weight.medium};
        display: block;
        margin-top: 3px;
    }
`

export const StyledLogoAndPlatform = styled.div`
    align-items: center;

    ${queries.minLG} {
        display: flex;
        position: absolute;
        left: 12px;

        ${StyledMenuButton} {
            display: none;
        }
    }
`

export const StyledLink = styled(Link)`
    & {
        display: inline-flex;
        align-items: center;
        text-decoration: none;
    }
`

export const StyledHeaderContent = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;

    ${queries.minLG} {
        padding-left: 300px;
    }

    ${queries.maxMD} {
        flex-direction: column-reverse;

        ${StyledHeaderActions} {
            justify-content: space-between;
            margin: 24px 0;
            display: flex;
            align-items: center;
        }

        ${StyledBreadcrumb} {
            justify-content: center;
            line-height: normal;
        }

        ${StyledLogoAndPlatform} {
            display: inline-flex;
        }
    }

    ${queries.maxSM} {
        ${StyledPlatform} {
            display: none;
        }

        ${StyledHeaderActions} {
            margin: 16px 0;
        }
    }
`

export const StyledRightSide = styled.div`
    display: flex;
    align-items: center;
    height: 32px;
`

export const StyledHoverUserActions = styled.div`
    padding: 16px 0;

    &:last-child {
        padding: 16px 0 0;
    }
`

export const StyledHoverUserAction = styled(Link)`
    & {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 0 0 16px;
        min-height: auto;

        h6 {
            transition: font-weight .1s ease;
        }

        span {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
        }

        &:hover {
            h6 {
                font-weight: ${font.weight.semiBold};
            }
        }

        &:last-child{
            padding: 0;
        }

        i {
            font-size: 16px !important;
            color: ${colors.primary.lighter};
        }
    }
`

export const StyledHoverUserLogout = styled(Button)`
    & {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 0 0 16px;
        min-height: auto;

        span {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
        }

        &:hover {
            h6 {
                font-weight: ${font.weight.semiBold};
            }
            
            i {
                color: ${colors.primary.lighter} !important;
            }
        }

        &:last-child{
            padding: 0;
        }

        i {
            font-size: 16px !important;
            color: ${colors.primary.lighter};
        }
    }
`;

export const StyledUserFavorites = styled.div`
    min-width: 220px;
`
