/** Import news DLOs */
import {
    NewsCreateInput,
    NewsDeleteInput,
    NewsGetAllInput,
    NewsGetByIdInput,
    NewsUpdateInput,
} from "./news";

/** Import media DLOs */
import {
    MediaGetAllInput,
    MediaCreateInput,
    MediaDeleteInput,
} from "./media";

/** Import albuns DLOs */
import {
    AlbunsGetAllDatatableInput,
    AlbumsDeleteInput,
    AlbumCreateInput,
    AlbumGetBySlugInput,
    AlbumUpdateInput,
} from "./album";

/** Import tags DLOs */
import {
    TagsGetByTextInput,
} from "./tag";

/** Import boosters DLOs */
import {
    BoostersCreateInput,
    BoostersDeleteInput,
    BoostersGetAllInput,
    BoostersGetBySlugInput,
    BoostersUpdateInput,
} from "./booster";

/** Import pages DLOs */
import {
    DynamicPageCreateInput,
    DynamicPageDeleteInput,
    DynamicPageGetAllInput,
    DynamicPageGetByIdInput,
    DynamicPageUpdateInput,
} from "./dynamic-page";

/** Import forms DLOs */
import {
    FormsGetAllInput
} from "./form"

/** Import groups DLOs */
import {
    GroupsGetAllInput,
} from "./group"

/** Import social DLOs */
import {
    SocialGetAllInput,
    SocialUpdateInput,
    SocialRegisterFacebookTokenInput,
    SocialGetRefetchedDataInput
} from "./social";

/** 
 * Export CMS DLOs 
 */
export const cms = {
    // News
    NewsGetAllInput,
    NewsCreateInput,
    NewsDeleteInput,
    NewsGetByIdInput,
    NewsUpdateInput,
    // Media
    MediaGetAllInput,
    MediaCreateInput,
    MediaDeleteInput,
    // Albums
    AlbunsGetAllDatatableInput,
    AlbumCreateInput,
    AlbumsDeleteInput,
    AlbumGetBySlugInput,
    AlbumUpdateInput,
    TagsGetByTextInput,
    // Boosters
    BoostersCreateInput,
    BoostersDeleteInput,
    BoostersGetAllInput,
    BoostersGetBySlugInput,
    BoostersUpdateInput,
    // Pages
    DynamicPageCreateInput,
    DynamicPageDeleteInput,
    DynamicPageGetAllInput,
    DynamicPageGetByIdInput,
    DynamicPageUpdateInput,
    // Forms
    FormsGetAllInput,
    // Groups
    GroupsGetAllInput,
    // Social
    SocialGetAllInput,
    SocialUpdateInput,
    SocialRegisterFacebookTokenInput,
    SocialGetRefetchedDataInput,
};
