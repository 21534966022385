import React from "react";
import {
    CorePureComponent,
} from "@7egend/web.core";
import { withDloService, WithDloServiceProps } from "@7egend/web.core/lib/components/withDloService";
import { MediaList, MediaListData } from "../../../components/MediaList";
import { WrapperWithTitleActions } from "../../../components/Wrapper/WithTitleActions";
import {
    Column,
    Button,
    Row,
    Container,
    Placeholder,
    PlaceholderType,
    DialogTypes,
    Dialog,
    SnackbarContainer,
} from "../../../components";
import { Counter } from "../../../components/Datatable/Counter";
import Paginator from "../../../components/Datatable/Paginator";
import { StyledFooter } from "../../../components/Datatable/styles";
import { dlos } from "../../../dlos";
import { SnackbarLayout } from "../../../components/Snackbar";
import {
    DefaultActions
} from "../../../components/Datatable";
import { DefaultFilters, FilterProps } from "../../../components/Datatable"
import { Filters } from "../../../components/Datatable/interfaces"
import { Styled } from "../../../components/Multimedia/styles";
import { uniqueId, debounce } from "@7egend/web.core/lib/utils";
import { withI18n, WithI18nProps } from "@7egend/web.core/lib/components/withI18n";
import { Trans } from "@7egend/web.core/lib/components/i18n";
import { APP_TRANSLATIONS } from "../../../locale";
import { CanView, withCanView, WithCanViewProps } from "../../../components/CanView";
import { I18N_KEY_CMS_ALBUM_LIST, I18N_KEY_CMS_PAGES_ALBUM, I18N_KEY_GLOBAL } from "../../../base/i18n";

const ALBUMS_CONFIG = {
    fetch: dlos.cms.AlbunsGetAllDatatableInput,
    title: "Albums",
    rows: {
        lengths: [24, 60],
    },
    actions: [
        <CanView action="cms.albums.create">
            <DefaultActions.Add
                key={uniqueId("action-albuns")}
                href="/cms/albums/add"
            />
        </CanView>,
    ],
    filters: [(props: FilterProps) =>
        <CanView action="cms.albums.search">
            <DefaultFilters.Search key={"search-filter"} {...props} />
        </CanView>
    ],
    columns: [
        {
            param: "created_at",
            title: "Publication Date",
        },
        {
            param: "title",
            title: "Title",
        },
        {
            param: "cover:url",
            title: "Url",
        },
        {
            param: "cover:medium_type_id",
            title: "type",
        },
        {
            param: "uuid",
            title: "uuid",
        },
        {
            param: "cover:thumb",
            title: "image",
        },
    ],
};

export interface AlbumsProps {
    filters?: Filters;

    /** Default filters data */
    defaultFilters?: { key: string, [key: string]: any };

    /** limit of selectable media item  */
    limitSelectable?: number;

    /** checked media */
    checkedMedia?: (checkedMedia: MediaListData[]) => void,

    /** footer actions */
    footer?: React.ReactComponentElement<any> | React.ComponentType<any> | Element;
}

interface AlbumsState {
    /** multimedia length */
    length: number;
    /** multimedia item start */
    start: number;
    /** total of multimedia items */
    total: number;
    /** multimedia items */
    data: MediaListData[];
    /** loading media */
    isLoading?: boolean;
    /** checked list items */
    checkedMedia: MediaListData[];
    /** is deleting */
    isDeleting: boolean;
    /** dialog state */
    dialog: {
        isVisible: boolean;
        type?: DialogTypes;
        overlay?: boolean;
        title?: string;
        description?: string;
        showIcon?: boolean;
        cancelAction?: () => void;
        cancelText?: string;
        cancelIcon?: string;
        submitAction?: () => void;
        submitText?: string;
        submitIcon?: string;
    };
    /**
     * Holds all filters values
     * This is a blue bag for all filters to write down their values if they need
     */
    filters?: { [key: string]: any };
    /** if has no results on fetch */
    noResults?: boolean
}

const I18N_NAMESPACE = I18N_KEY_CMS_ALBUM_LIST

/**
 * # Albums Module
 */
class AlbumsComponent extends CorePureComponent<AlbumsProps & WithDloServiceProps<MediaListData[]> & WithI18nProps & WithCanViewProps, AlbumsState> {
    /** Snackbar Ref */
    private snackBarContainerRef = React.createRef<SnackbarContainer>();

    // private static defaultProps = dtConfig;

    public state: AlbumsState = {
        length: ALBUMS_CONFIG.rows.lengths[0],
        start: 1,
        total: 0,
        data: [],
        checkedMedia: [],
        isDeleting: false,
        dialog: {
            isVisible: false,
        },
        filters: this.props.defaultFilters,
    };

    /** Component on Mount */
    public componentDidMount() {
        const { length, start } = this.state;

        this.loadData(length, start - 1);
    }

    public componentDidUpdate({ }, prevState: AlbumsState) {
        // If the filters change, dispatch a new fetch
        if (prevState.filters !== this.state.filters) {
            // Loads new data
            this.selectPage(1); // this will update the page instead, a work around while this component is now reviewed
        }
    }

    public render() {
        const { title, rows } = ALBUMS_CONFIG;
        const { length, total, data, isLoading, noResults } = this.state;
        const { t } = this.props;
        const { loaded } = this.props.dloRequest;

        return (
            <CanView action="cms.albums.list">
                <WrapperWithTitleActions
                    title={title}
                    heightLimited={this.props.footer ? true : false}
                    footer={this.props.footer && this.props.footer}
                    actions={
                        <>
                            {this.props.canView("cms.albums.delete") &&
                                this.state.checkedMedia &&
                                this.state.checkedMedia.length > 0 && (
                                    <Styled.DeleteItems>
                                        <Button
                                            icon="delete"
                                            onClick={() =>
                                                this.toggleDialog(
                                                    "error",
                                                    true,
                                                    t(`${I18N_NAMESPACE}.deleteQuestion`),
                                                    `${t(`${I18N_NAMESPACE}.deleteAlbumConfirm`)} ${this
                                                        .state.checkedMedia &&
                                                    this.state.checkedMedia
                                                        .length} items?`,
                                                    false,
                                                    this.toggleDialog,
                                                    t(`${I18N_KEY_GLOBAL}.cancel`),
                                                    undefined,
                                                    this.deleteAllCheckedMedia,
                                                    t(`${I18N_KEY_GLOBAL}.delete`),
                                                    "delete"
                                                )
                                            }
                                            layoutIconPosition="left"
                                        >
                                            <Trans id={`${I18N_KEY_GLOBAL}.delete`} /> {this.state.checkedMedia.length}{" "}
                                            <Trans id={`${I18N_KEY_CMS_PAGES_ALBUM}.items`} />
                                        </Button>
                                    </Styled.DeleteItems>
                                )}
                            {this.renderSearch()}
                            {this.renderActions()}
                        </>
                    }
                >
                    {!noResults &&
                        <Container fluid>
                            <Row>
                                <MediaList
                                    deleteMedia={(item: MediaListData) => {
                                        this.toggleDialog(
                                            "error",
                                            true,
                                            t(`${I18N_NAMESPACE}.deleteQuestion`),
                                            `${t(`${I18N_NAMESPACE}.deleteItemConfirm`)}`,
                                            false,
                                            this.toggleDialog,
                                            t(`${I18N_KEY_GLOBAL}.cancel`),
                                            undefined,
                                            () => {
                                                this.deleteSingleMedia(item);
                                            },
                                            t(`${I18N_KEY_GLOBAL}.delete`),
                                            "delete"
                                        );
                                    }}
                                    selectMedia={this.selectMedia}
                                    list={data}
                                    isLoading={isLoading}
                                    isAlbum
                                    checkedMedia={this.state.checkedMedia}
                                />
                            </Row>
                        </Container>
                    }
                    <StyledFooter>
                        <Container fluid>
                            <Row>
                                <Column sm={6}>
                                    {!loaded ? (
                                        <Placeholder
                                            config={PlaceholderType.Rect}
                                        />
                                    ) : (
                                        <Counter
                                            total={total}
                                            length={length}
                                            setAmountToShow={this.setAmountToShow}
                                            amounts={rows.lengths}
                                        />
                                    )}
                                </Column>
                                <Column contentAlign={"right"} sm={6}>
                                    {!loaded ? (
                                        <Placeholder
                                            config={PlaceholderType.Rect}
                                        />
                                    ) : (
                                        <Paginator
                                            selectPage={this.selectPage}
                                            pages={this.state}
                                        />
                                    )}
                                </Column>
                            </Row>
                        </Container>
                    </StyledFooter>
                    {this.renderDialog()}
                </WrapperWithTitleActions>
            </CanView>
        );
    }

    /** Each time we click on Media, it toggles the checked style */
    private selectMedia = (item: MediaListData) => {
        if (
            (item && !this.props.limitSelectable) ||
            (this.props.limitSelectable && this.props.limitSelectable > this.state.checkedMedia.length)
        ) {
            this.setState(
                {
                    checkedMedia:
                        this.state.checkedMedia.indexOf(item) < 0
                            ? [...this.state.checkedMedia, item]
                            : this.state.checkedMedia.filter(i => i !== item),
                },
                () => {
                    if (this.props.checkedMedia) {
                        this.props.checkedMedia(this.state.checkedMedia);
                    }
                }
            );
        } else if (this.props.limitSelectable && this.props.limitSelectable === this.state.checkedMedia.length) {
            this.setState(
                {
                    checkedMedia: this.state.checkedMedia.filter(i => i !== item),
                },
                () => {
                    if (this.props.checkedMedia) {
                        this.props.checkedMedia(this.state.checkedMedia);
                    }
                }
            );
        }
    };

    private renderDialog = () => {
        const { dialog } = this.state;
        const buttonLayout = dialog.type === "success" ? "main" : dialog.type;

        return (
            <Dialog
                showIcon={dialog.showIcon}
                handleDialog={this.toggleDialog}
                isVisible={dialog.isVisible}
                type={dialog.type}
                overlay={dialog.overlay}
                title={dialog.title}
                description={dialog.description}
            >
                {dialog.cancelAction && (
                    <Button
                        layoutOutline
                        onClick={dialog.cancelAction}
                        layout={buttonLayout}
                        icon={dialog.cancelIcon}
                    >
                        {dialog.cancelText}
                    </Button>
                )}
                {dialog.submitAction && (
                    <Button
                        layoutFill
                        onClick={dialog.submitAction}
                        layout={buttonLayout}
                        layoutIconPosition={"right"}
                        isLoading={this.state.isDeleting}
                    >
                        {dialog.submitText}
                    </Button>
                )}
            </Dialog>
        );
    };

    private toggleDialog = (
        type?: DialogTypes,
        overlay?: boolean,
        title?: string,
        description?: string,
        showIcon?: boolean,
        cancelAction?: () => void,
        cancelText?: string,
        cancelIcon?: string,
        submitAction?: () => void,
        submitText?: string,
        submitIcon?: string
    ) => {
        const { dialog } = this.state;

        if (this.state.dialog) {
            this.setState({
                dialog: {
                    isVisible: !dialog.isVisible,
                    type,
                    overlay,
                    title,
                    description,
                    showIcon,
                    cancelAction,
                    submitAction,
                    cancelText,
                    submitText,
                    cancelIcon,
                    submitIcon,
                },
            });
        }

        this.renderDialog();
    };

    /** Deletes a single media */
    private deleteSingleMedia = (item: MediaListData) => {
        const { t } = this.props;

        if (item.uuid) {
            const input = new dlos.cms.AlbumsDeleteInput();

            input.body = {
                uuid: item.uuid,
            };

            this.setState({
                isDeleting: true,
            });

            this.props
                .executeDloRequest(input, `${t(`${I18N_NAMESPACE}.deleteAlbum`)} - ${item.uuid}`)
                .then(res => {
                    this.toggleDialog();
                    this.setState({
                        isDeleting: false,
                        total: this.state.total - 1,
                        data:
                            this.state.data &&
                            this.state.data.filter(i => i.uuid !== item.uuid),
                        checkedMedia:
                            this.state.checkedMedia &&
                            this.state.checkedMedia.filter(
                                i => i.uuid !== item.uuid
                            ),
                    });
                })
                .catch(err => {
                    this.addSnackbar(err, "error");
                    this.setState({
                        isDeleting: false,
                    });
                });
        }
    };

    /** Delete All Checked Media items */
    private deleteAllCheckedMedia = async () => {
        const { t } = this.props;
        const input = new dlos.cms.AlbumsDeleteInput();

        if (this.state.checkedMedia && this.state.checkedMedia.length > 0) {
            this.setState({
                isDeleting: true,
            });
            for (const item of this.state.checkedMedia) {
                if (item.uuid) {
                    input.body = {
                        uuid: item.uuid,
                    };

                    await this.props
                        .executeDloRequest(input, `${t(`${I18N_NAMESPACE}.deleteAlbum`)} - ${item}`)
                        .then(res => {
                            this.setState({
                                total: this.state.total - 1,
                                data:
                                    this.state.data &&
                                    this.state.data.filter(
                                        i => i.uuid !== item.uuid
                                    ),
                            });
                        })
                        .catch(err => {
                            this.addSnackbar(err, "error");
                            this.setState({
                                isDeleting: false,
                            });
                        });
                }
            }
            this.setState({
                checkedMedia: [],
                isDeleting: false,
            });
            /** hide Dialog */
            this.toggleDialog();
        }
    };

    /**
     * Pushes snackbar to container
     *
     * @param {string} message message to snackbar
     * @param {string} layout layout of snackbar
     *
     */
    private addSnackbar = (
        message: string,
        layout?: SnackbarLayout,
        timeout?: number
    ) => {
        if (this.snackBarContainerRef.current) {
            this.snackBarContainerRef.current.pushMessage({
                layout: layout as any,
                message,
                timeout,
            });
        }
    };

    private selectPage = (start: number) => {
        this.setState({
            isLoading: true,
            start,
        });

        // Set the value of 'start' API param
        const { length } = this.state;
        const pageStep = (start - 1) * length;
        this.loadData(length, pageStep);
    };

    private setAmountToShow = (length: number) => {
        this.setState({
            isLoading: true,
            length,
            start: 1,
        });

        // Set the value of 'lenght' of content to show
        const { start } = this.state;
        this.loadData(length, start - 1);
    };

    private createColumns() {
        const { columns } = ALBUMS_CONFIG;

        return columns.reduce((result: any, column, i) => {
            result[`columns[${i}][data]`] = column.param;
            return result;
        }, {});
    }

    private transformData(response: any): void {
        this.setState({
            isLoading: false,
            noResults: false,
            data: response.body,
            total: parseInt(response.headers["x-datatables-records-total"], 0),
        });
    }

    private loadData = debounce((length: number, start: number): void => {
        // Fetch the data from server
        const { fetch } = ALBUMS_CONFIG;
        const { filters } = this.state;
        const columns = this.createColumns();
        const input = new fetch();

        input.body = {
            length,
            start,
            ...columns,
            ...filters,
        };

        this.props
            .executeDloRequest(input, start)
            .then(res => {
                if (res!.body.length === 0) {
                    // if didn't found the searched value
                    this.setState({
                        noResults: true,
                        isLoading: false,
                    })
                } else {
                    this.transformData(res);
                }
            })
            .catch(() => this.setState({ isLoading: false }));
    }, 300);

    private renderActions = () => {
        const { actions } = ALBUMS_CONFIG;

        if (!actions) {
            return null;
        }

        return actions;
    };

    private renderSearch = () => {
        const { filters } = ALBUMS_CONFIG;
        const { filters: filtersData } = this.state;
        if (!filters) {
            return null;
        }
        return filters.map(filter =>
            filter({
                filters: filtersData as any,
                updateFilter: this.updateFilter,
            })
        );
    };

    private updateFilter = (name: string, value: any) => {
        this.setState({
            filters: {
                ...this.state.filters,
                [name]: value,
            },
        });
    };
}

export const Albums = withCanView(withDloService(withI18n(APP_TRANSLATIONS)(AlbumsComponent)));
