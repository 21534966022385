import { Image } from "./Image"
import { ImagePanoramic } from "./ImagePanoramic"
import { Document } from "./Document"
import { Video } from "./Video"

import placeholder_image from "../../../../assets/placeholder_image.svg";
import placeholder_image_panoramic from "../../../../assets/placeholder_image_panoramic.svg";
import placeholder_document from "../../../../assets/placeholder_document.svg";
import placeholder_video from "../../../../assets/placeholder_video.svg";

export const PLACEHOLDER_IMAGE = placeholder_image;
export const PLACEHOLDER_IMAGE_PANORAMIC = placeholder_image_panoramic;
export const PLACEHOLDER_DOCUMENT = placeholder_document;
export const PLACEHOLDER_VIDEO = placeholder_video;

/**
 * # Media Placeholder
 * 
 * This is a media placeholder, only used to replace the thumbnail of the media component.
 * 
 */
export const MediaPlaceholder = {
    /** ## Image Placeholder */
    Image,
    /** ## Panoramic Image(360º) Placeholder */
    ImagePanoramic,
    /** ## Document Placeholder */
    Document,
    /** ## Video Placeholder */
    Video,
}
