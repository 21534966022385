import React, { PureComponent } from "react"
import styled from "styled-components"
import { Link } from "@7egend/web.core.controls/lib/components";
import { colors, font } from "../../styles";
import { Icon } from "../Icon";
import { withI18n, WithI18nProps } from "@7egend/web.core/lib/components/withI18n";
import { translateStringOrObject } from "../../utils/translations";

export interface BreadcrumbData {
    path: string;
    title: string;
}

export interface BreadcrumbProps {
    /** breadcrumb paths */
    paths: BreadcrumbData[];
    /** className */
    className?: string;
}

export const StyledBreadcrumb = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    line-height: 24px;
`

const StyledLink = styled(Link)`
    & {
        color: ${colors.neutral.dark};
        text-decoration: none !important;
        font-size: ${font.size.sm};
        font-weight: ${font.weight.medium};

        &.active,
        &:hover {
            color: ${colors.neutral.darker};
        }
    }
`

const StyledLinkNoPath = styled.p`
    cursor: pointer;

    & {
        color: ${colors.neutral.dark};
        text-decoration: none !important;
        font-size: ${font.size.sm};
        font-weight: ${font.weight.medium};

        &.active,
        &:hover {
            color: ${colors.neutral.darker};
        }
    }
`

const StyledIcon = styled(Icon)`
    & {
        color: ${colors.neutral.dark};
        text-decoration: none !important;
        font-size: ${font.size.sm};
        font-weight: ${font.weight.medium};
        margin: 0 15px;
    }
`

/**
 * # Breadcrumb Component
 *
 * ## How to use:
 *
 * ```jsx
 * <Breacrumb paths={array_of_paths} />
 * ```
 */
export class BreadcrumbComponent extends PureComponent<BreadcrumbProps & WithI18nProps> {
    public render() {
        return (
            <StyledBreadcrumb className={this.props.className}>
                {
                    this.props.paths.length > 0 && this.props.paths.map(({ path, title, readOnly }: any, index: number) => {
                        const { t, language } = this.props;
                        const showTitle: string = translateStringOrObject(title, language, t);

                        return (
                            <React.Fragment key={showTitle}>
                                {/* TODO: this condition on active class is temporary, we need to validate the active link */}
                                {!readOnly ? (
                                    <StyledLink
                                        activeClassName={this.props.paths.length === (index + 1) ? "active" : ""}
                                        title={showTitle}
                                        href={path}
                                    >
                                        {showTitle}
                                    </StyledLink>
                                ) : (
                                        <StyledLinkNoPath>
                                            {showTitle}
                                        </StyledLinkNoPath>
                                    )}
                                {this.props.paths.length !== (index + 1) &&
                                    <StyledIcon icon="keyboard_arrow_right" />
                                }
                            </React.Fragment>
                        )
                    })
                }
            </StyledBreadcrumb>
        )
    }
}

export const Breadcrumb = withI18n()(BreadcrumbComponent);
