import React, { PureComponent } from "react";
import styled, { css } from "styled-components";
import { font, colors, queries } from "../../styles";
import { Icon } from "../Icon";
import { Dropdown } from "../Dropdown";
import { withI18n, WithI18nProps } from "@7egend/web.core/lib/components/withI18n";
import { APP_TRANSLATIONS } from "../../locale";
import { I18N_KEY_CORE_COMPONENTS_DATATABLE } from "../../base/i18n";

const StyledIcon = styled(Icon)`
    & {
        cursor: pointer;
        font-size: ${font.size.md};
        color: ${colors.neutral.dark};
        line-height: 16px;
        margin-right: 2px;
        position: relative;
    }
`;

const Info = styled.div`
    font-size: ${font.size.xs};
    font-weight: ${font.weight.regular};
    color: ${colors.neutral.dark};
    display: inline-flex;
    align-items: center;
    height: 100%;

    ${queries.maxSM} {
        justify-content: center;
        width: 100%;
    }
`;

const Amount = styled.span`
    color: ${colors.neutral.darker};
    margin-left: 8px;
    margin-right: 2px;
`;

const StyledList = styled.ul`
    list-style: none;
`;

const StyledListItem = styled.li<StyledListItemProps>`
    cursor: pointer;
    width: 48px;
    text-align: center;
    border-bottom: 1px solid ${colors.neutral.light};
    color: ${colors.neutral.dark};
    font-size: ${font.size.sm};
    font-weight: ${font.weight.regular};
    line-height: 18px;
    transition: color 0.2s ease-in-out;
    padding-top: 6px;
    padding-bottom: 6px;

    ${(props: StyledListItemProps) =>
        props.active &&
        css`
            color: ${colors.primary.lighter};
            transition: color 0.2s ease-in-out;
        `}

    ${(props: StyledListItemProps) =>
        !props.active &&
        css`
            &:hover {
                color: ${colors.neutral.darker};
                transition: color 0.2s ease-in-out;
            }
        `}

    &:last-child {
        border-bottom: 0;
    }
`;

interface State {
    hoverVisible: boolean;
    hoverVisibleToggle: boolean;
}

interface CounterProps {
    total: number;
    length: number;
    setAmountToShow: (value: number) => void;
    amounts: number[];
}

interface StyledListItemProps {
    active?: boolean;
}

const I18N_NAMESPACE = I18N_KEY_CORE_COMPONENTS_DATATABLE

class CounterComponent extends PureComponent<WithI18nProps & CounterProps> {
    public state: State = {
        hoverVisible: false,
        hoverVisibleToggle: false,
    };

    /** Hover toggle visibility - Hover Component */
    private toggleHover = () => {
        this.setState({
            hoverVisibleToggle: !this.state.hoverVisibleToggle,
        });
    };

    private amountToShow = () => {
        const { amounts } = this.props;
        return (
            <StyledList>
                {amounts.map((amount, i) => (
                    <StyledListItem
                        key={i}
                        onClick={() => this.props.setAmountToShow(amount)}
                        active={this.props.length === amount}
                    >
                        {amount}
                    </StyledListItem>
                ))}
            </StyledList>
        );
    };

    public render() {
        const { t } = this.props;
        if (!this.props.total) {
            return null;
        }

        const amount =
            this.props.length < this.props.total
                ? this.props.length
                : this.props.total;
        return (
            <Info>
                {t(`${I18N_NAMESPACE}.counterShowingLabel`)}
                <Amount>{amount}</Amount>
                <Dropdown
                    small
                    position="top"
                    align="center"
                    active={this.state.hoverVisibleToggle}
                    onClick={this.toggleHover}
                    component={<StyledIcon icon="keyboard_arrow_down" />}
                >
                    {this.amountToShow()}
                </Dropdown>
                {`${t(`${I18N_NAMESPACE}.counterResultsLabel`, { total: this.props.total.toString() })} `}
            </Info>
        );
    }
}

export const Counter = withI18n(APP_TRANSLATIONS)(CounterComponent);
