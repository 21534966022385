import styled from "styled-components";

export const StyledTooltipContent = styled.div`
    display: none;
`;

export const StyledTooltip = styled.div`
    position: relative;
    display: inline-block;
    
    &:hover {
        ${StyledTooltipContent} {
            display: block;
        }
    }
`;

export const StyledWrapper = styled.div`
    display: flex;
`;
