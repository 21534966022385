import {Store} from "@7egend/web.core";
import { Country } from "../../dlos/country";

//#region States

export interface CountriesState extends Store.StoreDataNode<Country[]> {}

//#endregion

//#region Actions

export const LOAD_COUNTRIES_ACTION_KEY = "@@core.geo/LOAD_COUNTRIES"

//#endregion
