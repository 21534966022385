import React from "react"
import { StyledUploader } from "../styles";
import { Uploader } from "../index";
import { WrapperWithTitle } from "../../Wrapper/WithTitle";
import { Input } from "../../Input";
import { Typography } from "../../Typography";
import { Button } from "../../Button";
import { SnackbarLayout } from "../../Snackbar";
import { Column } from "../../Structure";
import { RouteComponentProps, withRouter } from "react-router";
import { compose } from "redux";
import { CorePureComponent } from "@7egend/web.core";
import { UploaderProps } from "../domain"
import { withI18n, WithI18nProps } from "@7egend/web.core/lib/components/withI18n";
import { APP_TRANSLATIONS } from "../../../locale";
import { Trans } from "@7egend/web.core/lib/components/i18n";
import { errorMessage } from "../../../utils";
import { I18N_KEY_CORE_COMPONENTS_UPLOADER, I18N_KEY_GLOBAL } from "../../../base/i18n";

export interface StreamProps extends React.PropsWithChildren<UploaderProps> {
    /** is video modal visible */
    isVideoVisible: boolean;

    /** is Video Uploading */
    isUploading?: boolean;

    /** handleModal */
    handleModal: () => void;

    /** upload action */
    uploadAction?: (url: string, thumb?: string, title?: string, description?: string) => void;

    /** on error */
    onError?: (message: string, layout?: SnackbarLayout) => void;

    /**
     * Enables upload in loco
     *  @default true
     */
    allowUpload?: boolean;
}

interface VideoState {
    thumb?: string | undefined;
    url: string;
    title?: string;
    description?: string;
}

const I18N_NAMESPACE = `${I18N_KEY_CORE_COMPONENTS_UPLOADER}.stream`

/**
 * # Uploader Video
 *
 * ## How to use:
 *
 * ```jsx
 * <Uploader.Stream
 *      onError={this.addSnackbar}
 *      isUploading={this.state.isVideoUploading}
 *      isVideoVisible={this.state.isVideoVisible}
 *      uploadAction={this.handleUpload}
 *      handleModal={this.toggleModalVideo}
 *      label="Upload Video"
 *      info="Video URL"
 * />
 * ```
 */
class StreamComponent extends CorePureComponent<StreamProps & RouteComponentProps & WithI18nProps, VideoState> {
    public static defaultProps = {
        allowUpload: true,
    }

    public state: VideoState = {
        url: "",
    };

    public render() {
        const { info, label, allowUpload, t } = this.props;

        return (
            <React.Fragment>
                {allowUpload && (
                    <StyledUploader.ModalVideo
                        ButtonLayout="main"
                        handleModal={this.handleModal}
                        isVisible={this.props.isVideoVisible}
                        overlay
                    >
                        <WrapperWithTitle title={t(`${I18N_NAMESPACE}.title`)}>
                            <Column>
                                <Input
                                    name="url"
                                    type="text"
                                    required
                                    label={t(`${I18N_NAMESPACE}.form.url.label`)}
                                    placeholder={t(`${I18N_NAMESPACE}.form.url.placeholder`)}
                                    value={this.state.url}
                                    onChange={(value: string) =>
                                        this.setState({ url: value })
                                    }
                                />
                                <Input
                                    name="title"
                                    type="text"
                                    required
                                    label={t(`${I18N_NAMESPACE}.form.title.label`)}
                                    placeholder={t(`${I18N_NAMESPACE}.form.title.placeholder`)}
                                    value={this.state.title}
                                    onChange={(value: string) =>
                                        this.setState({ title: value })
                                    }
                                />
                                <Input
                                    name="description"
                                    type="text"
                                    required
                                    label={t(`${I18N_NAMESPACE}.form.description.label`)}
                                    placeholder={t(`${I18N_NAMESPACE}.form.description.placeholder`)}
                                    value={this.state.description}
                                    onChange={(value: string) =>
                                        this.setState({ description: value })
                                    }
                                />
                                <Typography.SmallCapsTitle>
                                    <Trans id={`${I18N_NAMESPACE}.form.thumbnail.title`} />
                                </Typography.SmallCapsTitle>
                                <Uploader.Image
                                    cleanPreviewImage={() =>
                                        this.setState({ thumb: "" })
                                    }
                                    previewImage={this.state.thumb}
                                    multiple={false}
                                    uploadAction={this.chooseThumbnail}
                                />
                                <br />
                                <br />
                                <StyledUploader.ModalVideoButtons>
                                    <Button
                                        layout="main"
                                        onClick={this.handleModal}
                                        layoutOutline
                                    >
                                        <Trans id={`${I18N_KEY_GLOBAL}.cancel`} />
                                    </Button>
                                    <Button
                                        layout="main"
                                        layoutFill
                                        onClick={this.uploadAction}
                                        isLoading={this.props.isUploading}
                                        layoutIconPosition={"right"}
                                    >
                                        <Trans id={`${I18N_NAMESPACE}.form.action`} />
                                    </Button>
                                </StyledUploader.ModalVideoButtons>
                            </Column>
                        </WrapperWithTitle>
                    </StyledUploader.ModalVideo>
                )}
                <StyledUploader.Wrapper>
                    <StyledUploader.Label>
                        <StyledUploader.Action
                            onClick={this.handleModal}
                            layout="alt"
                            icon="ondemand_video"
                        />
                    </StyledUploader.Label>
                    {label && (
                        <StyledUploader.Header>{label}</StyledUploader.Header>
                    )}
                    {info && (
                        <StyledUploader.SubHeader variant="neutral">
                            {info}
                        </StyledUploader.SubHeader>
                    )}
                </StyledUploader.Wrapper>
            </React.Fragment>
        );
    }

    private handleModal = () => {

        if (this.props.allowUpload === false) {
            this.props.history.push(`/${this.fw.i18n.getCurrentLanguage()}/cms/multimedia/add?type=stream`);
            return;
        }

        /** toggle Modal */
        this.props.handleModal();

        /** clean state */
        this.setState({
            thumb: "",
            url: "",
            title: "",
            description: "",
        });
    };

    private chooseThumbnail = (files: any[]) => {
        if (!files[0].error) {
            this.setState({
                thumb: files[0].base64,
            });
        } else {
            if (this.props.onError) {
                this.props.onError(
                    errorMessage({
                        ...files[0].error,
                        context: files[0].name,
                    }),
                    "error"
                );
            }
        }
    };

    /** Send to parent Component */
    private uploadAction = () => {
        const { uploadAction } = this.props
        const { url, thumb, title, description } = this.state;

        if (uploadAction) {
            uploadAction(url, thumb, title, description);
        }
    };
}

export const Stream = compose<React.ComponentClass<StreamProps>>(
    withI18n(APP_TRANSLATIONS),
    withRouter,
)(StreamComponent)
