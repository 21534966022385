import React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import {
    PureComponent,
} from "@7egend/web.core";
import {
    Row,
    Column,
    Datatable,
    DatatableProps,
} from "../../../components";
import { dlos } from "../../../dlos";
import {
    FilterProps,
    DefaultFilters,
    DefaultFormatter,
    DatatableActions,
} from "../../../components/Datatable";
import { Form } from "../../../dlos/cms/form";
import { withI18n, WithI18nProps } from "@7egend/web.core/lib/components/withI18n";
import { APP_TRANSLATIONS } from "../../../locale";
import { CanView, withCanView, WithCanViewProps } from "../../../components/CanView";
import { I18N_KEY_CMS_FORM_LIST } from "../../../base/i18n";

interface FormProps {
    /** picker action */
    picker?: boolean

    /** pick form */
    pickItem?: (picked?: Form[]) => void;

    /** list of picked forms */
    pickedItems?: Form[];

    footer?: React.ReactComponentElement<any> | React.ComponentType<any> | Element;
}

const I18N_NAMESPACE = I18N_KEY_CMS_FORM_LIST

class FormsComponent extends PureComponent<RouteComponentProps & FormProps & WithI18nProps & WithCanViewProps> {
    public render() {
        const { t } = this.props;
        const actions = [];

        if (this.props.canView("cms.forms.details")) {
            actions.push({
                function: (secret: string) => this.editAction(secret),
                param: "secret",
                type: DatatableActions.Common,
                icon: "equalizer",
            });
        }

        const dtConfig: DatatableProps = {
            fetch: dlos.cms.FormsGetAllInput,
            rows: {
                lengths: [10, 25, 50],
                actions: {
                    size: 2,
                    list: this.props.picker
                        ? [
                            {
                                function: (secret: string, form: Form) => this.pickerAction(form),
                                param: "secret",
                                type: DatatableActions.Picker,
                            }
                        ] : actions,
                }
            },
            columns: [
                {
                    param: ["internal_name"],
                    size: 4,
                    title: t(`${I18N_NAMESPACE}.columnInternalName`),
                    order: true,
                    render: DefaultFormatter.Text,
                },
                {
                    param: ["title"],
                    size: 4,
                    title: t(`${I18N_NAMESPACE}.columnTitle`),
                    order: true,
                    render: DefaultFormatter.Text,
                },
                {
                    param: ["type:label"],
                    size: 2,
                    title: t(`${I18N_NAMESPACE}.columnType`),
                    order: true,
                    render: DefaultFormatter.Text,
                },
            ],
            title: t(`${I18N_NAMESPACE}.title`),
            filters: [
                (props: FilterProps) => (
                    <DefaultFilters.Search
                        key={'search-filter'}
                        {...props}
                    />
                ),
            ],
        };

        return (
            <CanView action="cms.forms.list">
                <Row>
                    <Column xs={12}>
                        <Datatable
                            {...dtConfig}
                            footer={this.props.footer}
                            pickedItems={this.props.pickedItems}
                        />
                    </Column>
                </Row>
            </CanView>
        );
    }

    private pickerAction = (form?: Form) => {
        if (this.props.pickItem && form) {
            this.props.pickItem([form]);
        }
    }

    private editAction = (id?: string) => {
        this.props.history.push(`forms/edit/${id}`);
    };
}

export const Forms = withCanView(withRouter(withI18n(APP_TRANSLATIONS)(FormsComponent)));