import React, { PureComponent } from "react"
import styled from "styled-components";
import {
    Textarea as TextareaCore,
    TextareaProps as TextareaCoreProps
} from "@7egend/web.core.controls/lib/components/textarea"
import { font, colors } from "../../styles";

export interface TextAreaProps extends React.PropsWithChildren<TextareaCoreProps> {
    // Nothing for now
}

const StyledField = styled(TextareaCore)`
    & {
        display: block;
        width: 100%;
        margin: 0;
        padding: 12px 16px;
        position: relative;
        background-color: #FFF;
        border: 1px solid ${colors.neutral.light};
        border-radius: 6px;
        outline: none;
        font-family: ${font.primary};
        font-weight: ${font.weight.light};
        font-size: ${font.size.sm};
        color: ${colors.neutral.darker};
        transition: border-color .2s ease-in;

        &::-webkit-input-placeholder {
            color: ${colors.neutral.dark};
        }

        &:-moz-placeholder {
            color: ${colors.neutral.dark};
        }

        &::-moz-placeholder {
            color: ${colors.neutral.dark};
        }

        &:-ms-input-placeholder {
            color: ${colors.neutral.dark};
        }
    }

    &:focus,
    &:active {
        border-color: ${colors.primary.lighter};
    }
`

/**
 * # Input Component
 *
 * ## How to use:
 *
 * ```ts
 * <TextArea
 *      name="mytextarea"
 *      placeholder="Placeholder"
 *      onChange={() => { }} />
 * ```
 */
export class TextArea extends PureComponent<TextAreaProps> {
    public render() {
        return (
            <StyledField
                {...this.props}
            />
        )
    }
}
