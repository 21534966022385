import { extractPathFromList, memoize } from "@7egend/web.core/lib/utils";

export const getActivePaths = memoize((path: string, language: string, items: any[]) => {
    let newPath: string | string[] = "";

    path = path.replace(`/${language}/`, "");

    //  not on add page
    newPath = path.split("/");
    if ((path.split("add", 4).length < 4 || path.split("edit", 4).length < 4) && newPath.length > 4) {
        newPath.pop();
        newPath = [newPath[0], newPath[1], newPath[2], newPath[3]]
    }
    
    if ((path.split("add", 3).length < 3 || path.split("edit", 3).length < 3) && newPath.length > 3) {
        newPath.pop();
        newPath = [newPath[0], newPath[1], newPath[2]]
    }

    return extractPathFromList(newPath.length ? newPath.join("/") : path, items, "path", "children")
})
