import { BaseModel } from "@7egend/web.core.dlos";
import { DloInput, DloOutput } from "@7egend/web.core/lib/extensions/dlo";
import { Taggable } from "@7egend/web.core.dlos/lib/dlos/tags";

export enum MediumImageFormat {
    _1x1 = "1x1",
    _3x2 = "3x2",
    _5x6 = "5x6",
    _9x16 = "9x16",
    _16x9 = "16x9",
}

export enum MediumImageSize {
    Thumb = "2x",
    Small = "small",
    Normal = "normal",
    Original = "original",
}

export enum MediumType {
    Image = "1",
    Video = "2",
    Stream = "3",
    Sound = "4",
    File = "5",
}

export interface Medium extends BaseModel, Taggable {
    uuid?: string;
    hash?: string;
    secret?: string;
    title: string;
    date: string;
    description?: string;
    medium_type: string;
    mime_type?: string;
    panoramic?: string;
    placeholder?: string;
    url?: string;
    width?: string;
    height?: string;
    length?: number;
    image?: string;
    normal?: string;
    small?: string;
    thumb?: string;
}

export class MediumGetBySlugInput extends DloInput<{
    slug: string,
}> {
    public uri = "media/${slug}/public?fields=${fields}"
    public fields = "uuid,mime_type,medium_type,image,normal,thumb,url,title,date,panoramic,tags,tag_relations"
}

export class MediumGetRelatedInput extends DloInput<{
    slug: string,
}> {
    public uri = "media/${slug}/related?fields=${fields}"
    public fields = "uuid,mime_type,medium_type,image,normal,thumb,url,title,date"
}

export class MediumGetRelatedOutput extends DloOutput<Medium[]> {

}
