import React from "react"
import { StyledUploader } from "../styles";
import { Uploader } from "../index";
import { WrapperWithTitle } from "../../Wrapper/WithTitle";
import { Input } from "../../Input";
import { Typography } from "../../Typography";
import { Button } from "../../Button";
import { SnackbarLayout } from "../../Snackbar";
import { Column } from "../../Structure";
import { RouteComponentProps, withRouter } from "react-router";
import { compose } from "redux";
import { CorePureComponent } from "@7egend/web.core";
import { UploaderProps } from "../domain"

export interface VideoProps extends React.PropsWithChildren<UploaderProps> {
    /** is video modal visible */
    isVideoVisible: boolean;

    /** is Video Uploading */
    isUploading?: boolean;

    /** handleModal */
    handleModal: () => void;

    /** upload action */
    uploadAction?: (url: string, thumb?: string, title?: string, description?: string) => void;

    /** on error */
    onError?: (message: string, layout?: SnackbarLayout) => void;

    /** @default true */
    allowUpload?: boolean;
}

interface VideoState {
    thumb?: string | undefined;
    url: string;
    title?: string;
    description?: string;
}

/**
 * # Uploader Video
 *
 * ## How to use:
 *
 * ```jsx
 * <Uploader.Video
 *      onError={this.addSnackbar}
 *      isUploading={this.state.isVideoUploading}
 *      isVideoVisible={this.state.isVideoVisible}
 *      uploadAction={this.handleUpload}
 *      handleModal={this.toggleModalVideo}
 *      label="Upload Video"
 *      info="Video URL"
 * />
 * ```
 */
class VideoComponent extends CorePureComponent<VideoProps & RouteComponentProps, VideoState> {
    public state: VideoState = {
        url: "",
    };

    public render() {
        const { info, label } = this.props;

        return (
            <React.Fragment>
                <StyledUploader.ModalVideo
                    ButtonLayout="main"
                    handleModal={this.handleModal}
                    isVisible={this.props.isVideoVisible}
                    overlay
                >
                    <WrapperWithTitle title="Upload Video">
                        <Column>
                            <Input
                                name="url"
                                type="text"
                                required
                                label="video url (mp4) *"
                                placeholder="Insert video URL"
                                value={this.state.url}
                                onChange={(value: string) =>
                                    this.setState({ url: value })
                                }
                            />
                            <Input
                                name="title"
                                type="text"
                                required
                                label="Title"
                                placeholder="Insert video title"
                                value={this.state.title}
                                onChange={(value: string) =>
                                    this.setState({ title: value })
                                }
                            />
                            <Input
                                name="description"
                                type="text"
                                required
                                label="Description"
                                placeholder="Insert video description"
                                value={this.state.description}
                                onChange={(value: string) =>
                                    this.setState({ description: value })
                                }
                            />
                            <Typography.SmallCapsTitle>
                                Video Thumbnail
                            </Typography.SmallCapsTitle>
                            <Uploader.Image
                                cleanPreviewImage={() =>
                                    this.setState({ thumb: "" })
                                }
                                previewImage={this.state.thumb}
                                multiple={false}
                                uploadAction={this.chooseThumbnail}
                            />
                            <br />
                            <br />
                            <StyledUploader.ModalVideoButtons>
                                <Button
                                    layout="main"
                                    onClick={this.handleModal}
                                    layoutOutline
                                >
                                    Cancel
                                </Button>
                                <Button
                                    layout="main"
                                    layoutFill
                                    onClick={this.uploadAction}
                                    isLoading={this.props.isUploading}
                                    layoutIconPosition={"right"}
                                >
                                    Upload Video
                                </Button>
                            </StyledUploader.ModalVideoButtons>
                        </Column>
                    </WrapperWithTitle>
                </StyledUploader.ModalVideo>
                <StyledUploader.Wrapper>
                    <StyledUploader.Label>
                        <StyledUploader.Action
                            onClick={this.handleModal}
                            layout="alt"
                            icon="videocam"
                        />
                    </StyledUploader.Label>
                    {label && (
                        <StyledUploader.Header>{label}</StyledUploader.Header>
                    )}
                    {info && (
                        <StyledUploader.SubHeader variant="neutral">
                            {info}
                        </StyledUploader.SubHeader>
                    )}
                </StyledUploader.Wrapper>
            </React.Fragment>
        );
    }

    private handleModal = () => {

        if (this.props.allowUpload === false) {
            this.props.history.push(`/${this.fw.i18n.getCurrentLanguage()}/cms/multimedia/add?type=video`);
            return;
        }

        /** toggle Modal */
        this.props.handleModal();

        /** clean state */
        this.setState({
            thumb: "",
            url: "",
            title: "",
            description: "",
        });
    };

    private chooseThumbnail = (files: any[]) => {
        if (!files[0].error) {
            this.setState({
                thumb: files[0].base64,
            });
        } else {
            if (this.props.onError) {
                this.props.onError(
                    `Error Uploading  ${files[0].name}, 1MB is the maximum!`,
                    "error"
                );
            }
        }
    };

    /** Send to parent Component */
    private uploadAction = () => {
        const { url, thumb, title, description } = this.state;
        if (this.props.uploadAction) {
            this.props.uploadAction(url, thumb, title, description);
        }
    };
}

export const Video = compose<React.ComponentClass<VideoProps>>(
    withRouter
)(VideoComponent)
