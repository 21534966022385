import React, { PureComponent } from "react"
import styled, { css } from "styled-components"
import {
    Checkbox as CoreCheckbox
} from "@7egend/web.core.controls/lib/components";
import { Icon } from "../Icon";
import { colors, font } from "../../styles";

export interface CheckboxProps {
    /** label to describe checkbox button */
    label?: string | React.ComponentClass<any> | React.ComponentType<any> | Element;
    /** checkbox name */
    name: string;
    /** checkbox value */
    value: boolean;
    /** if checkbox if required
     * @default false
     */
    required?: boolean;
    /** checkbox onchange method */
    onChange: (value: boolean, name: string, valid: boolean) => void;
    /** checkbox disabled */
    disabled?: boolean;
    /** className */
    className?: string;
    /** rounded checkbox */
    rounded?: boolean
    /** light border */
    lightBorder?: boolean

    /** overrides */
    overrides?: {
        icon?: string;
    }
}

interface StyledCheckboxProps {
    rounded?: boolean
    lightBorder?: boolean
}

const StyledCheckboxWrapper = styled.div`
    display: inline-flex;
    position: relative;
`;

interface StyledCheckboxIconProps {
    overrides?: {
        icon?: boolean;
    }
}
const StyledCheckboxIcon = styled(Icon) <StyledCheckboxIconProps>`
    & {
        position: absolute;
        left: 2px;
        top: 13px;
        transform: translateY(-50%);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        z-index: 0;
        opacity: 0;
        transition: opacity 0.1s ease-in;
        border-radius: 3.2px;

        ${({ overrides }) => overrides && overrides.icon ? css`
            background-color: transparent;
            color: ${colors.primary.lighter};
            font-size: 26px;
        ` : css`
            color: ${colors.light};
            background-color: ${colors.primary.lighter};
            font-size: ${font.size.md};
        `}
    }
`;

const StyledCheckboxLabel = styled.label`
    position: relative;
    display: inline-block;
    vertical-align: top;
    z-index: 1;
    align-self: center;
    font-family: ${font.primary};
    font-size: ${font.size.sm};
    color: ${colors.neutral.darker};
    font-weight: ${font.weight.light};
    padding-left: 10px;

    a{
        border-bottom: 1px solid ${colors.primary.lighter};
    }

    div {
        display: inline;
    }
`;

const StyledCheckbox = styled(CoreCheckbox) <StyledCheckboxProps>`
        & {
        -webkit-appearance: none;
        -moz-appearance: none;
        -ms-user-select: none;
        appearance: none;
        min-width: 20px;
        width: 20px;
        height: 20px;
        background: transparent;
        border: 1px solid ${colors.neutral.dark};
        position: relative;
        z-index: 1;
        outline: none;
        display: inline-block;
        border-radius: 3.2px;

        ${({ rounded }) => rounded && css`
            border-radius: 100%;
            width: 24px;
            height: 24px;

            & ~ ${StyledCheckboxIcon} {
                width: 24px;
                height: 24px;
                border-radius: 100%;
                top: 15px;
                left: 3px;
            }
        `}

        ${({ lightBorder }) => lightBorder && css`
            border-color: ${colors.light};
        `}

        &:required,
        &:invalid {
            box-shadow: none;
        }

        &:hover:not(:disabled) {
            cursor: pointer;
        }

        &:hover:disabled {
            cursor: not-allowed;
        }

        &:checked{
            border-color: transparent;
        }

        &:disabled:not(:checked) {
            background-color: ${colors.neutral.light};
            border-color: transparent;
        }

        &:checked ~ ${StyledCheckboxIcon} {
            opacity: 1;
            transition: opacity 0.2s ease-in-out;
        }

        &:disabled ~ ${StyledCheckboxIcon} {
            background-color: ${colors.neutral.light};
            color: ${colors.neutral.dark};
        }

        &:disabled + ${StyledCheckboxLabel}:hover {
            cursor: not-allowed;
        }

        &:not(:disabled) + ${StyledCheckboxLabel}:hover {
            cursor: pointer;
        }
    }
`;

/**
 *
 * # Checkbox Component
 *
 * This class represents the input type checkbox component
 *
 * # How to use
 * ```tsx
 *       <Checkbox
 *          rounded
 *          lightBorder
 *          name="nameOfComponent"
 *          value={true | false}
 *          disabled
 *          required
 *          onChange={event}
 *          label="teste" />
 * ```
 * 
 * `rounded` is not required, it only transforms the checkbox to rounded style
 * 
 * `lightBorder` is not required, it sets the border color to white (#FFFFFF)
 *
 */
export class Checkbox extends PureComponent<CheckboxProps> {
    public render() {
        const {
            name,
            value,
            required,
            disabled,
            label,
            onChange,
            className,
            rounded,
            lightBorder,
            overrides
        } = this.props;

        return (
            <StyledCheckboxWrapper className={className}>
                <StyledCheckbox
                    name={name}
                    value={value}
                    onChange={onChange}
                    disabled={disabled}
                    required={required}
                    rounded={rounded}
                    lightBorder={lightBorder}
                />
                {label &&
                    <StyledCheckboxLabel htmlFor={`field-${name}`}>
                        {label}
                    </StyledCheckboxLabel>
                }
                <StyledCheckboxIcon
                    overrides={{ icon: overrides && overrides.icon ? true : false }}
                    icon={overrides && overrides.icon ? overrides.icon : "check"} />
            </StyledCheckboxWrapper>
        );
    }
}
