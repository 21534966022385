import styled, { css } from "styled-components";
import { colors } from "../../../styles";

interface ProgressBarProps {
    percentage?: number
}

const Wrapper = styled.div`
    max-width: 460px;
    width: 100%;
    background-color: ${colors.light};
    box-shadow: 20px 40px 60px 0 rgba(0,0,0,0.4);
    position: fixed;
    bottom: -40px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 10px;
    z-index: 1;
    overflow: hidden;
    opacity: 0;
    visibility: hidden;
    transition: bottom 200ms ease-in-out,
                opacity 200ms ease,
                visibility 200ms ease;
`

const Content = styled.div`
    padding: 16px 16px 16px calc(106px + 16px);
`

const Image = styled.div`
    width: 106px;
    height: 106px;
    border-radius: 10px 0 0 10px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    top: calc(50% - 53px);
`

const UploadingTo = styled.div`
    h5 {
        margin-bottom: 2px;
    }
`

const Progress = styled.div`
    margin-top: 16px;
`

const ProgressBar = styled.div<ProgressBarProps>`
    width: 100%;
    height: 2px;
    border-radius: 2px;
    background-color: ${colors.neutral.light};
    position: relative;
    margin-top: 2px;

    &::before {
        content: "";
        position: absolute;
        top:0;
        left: 0;
        width: 0%;
        ${({ percentage }) => percentage && css`
            width: ${percentage}%;
        `}
        height: 2px;
        z-index: 1;
        background-color: ${colors.primary.lighter};
        box-shadow: 0 2px 8px 0 rgba(41,254,221,0.6);
        transition: width 500ms ease-in;
    }
`

const Overlay = styled.div<{ isVisible?: boolean}>`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    background-color: rgba(250, 250, 250, 0.5);
    opacity: 0;
    visibility: hidden;

    ${({ isVisible }) => isVisible && css`
        opacity: 1;
        visibility: visible;
        transition: opacity 200ms ease,
                    visibility 200ms ease;

        ${Wrapper} {
            bottom: 40px;
            opacity: 1;
            visibility: visible;
        }
    `}
`

/** ## Styled Uploader Progress bar */
export const Styled = {
    /** ### ProgressBar Wrapper */
    Wrapper,
    /** ### ProgressBar Content */
    Content,
    /** ### ProgressBar Image */
    Image,
    /** ### Uploading to text container */
    UploadingTo,
    /** ### Progress Container */
    Progress,
    /** ### Progress Bar */
    ProgressBar,
    /** ### Progress Bar Overlay */
    Overlay,
}
