import React from "react";
import { CorePureComponent } from "@7egend/web.core";
import { RouteComponentProps, withRouter } from "react-router";
import { WithDloServiceProps, withDloService } from "@7egend/web.core/lib/components/withDloService";
import {
    DatatableProps,
    Dialog,
    SnackbarContainer,
    Button,
    Row,
    Column,
    Label,
    LabelLayout,
    Link,
} from "../../../components";

import {
    DefaultFormatter,
    DefaultActions,
    FilterProps,
    DefaultFilters,
    DatatableActions,
    Datatable
} from "../../../components/Datatable";
import {
    DefaultFormatterProps
} from "../../../components/Datatable/constants";
import { PollsGetAllDatatableInput, PollingStatus, PollsDeleteInput } from "@7egend/web.core.polling/lib";
import { ActionsProps } from "../../../components/Datatable/interfaces";
import { Polling } from "./dlos/polling";
import { withI18n, WithI18nProps } from "@7egend/web.core/lib/components/withI18n";
import { Trans } from "@7egend/web.core/lib/components/i18n";
import { APP_TRANSLATIONS } from "../../../locale";
import { withCanView, WithCanViewProps, CanView } from "../../../components/CanView";
import { I18N_KEY_CMS_POLL_LIST, I18N_KEY_GLOBAL } from "../../../base/i18n";

interface PollsState {
    dialogVisible?: boolean
    deleteID?: string | number;
    isDeleting: boolean;
    deletedSuccess?: string | number;
}

const I18N_NAMESPACE = I18N_KEY_CMS_POLL_LIST

class PollsComponent extends CorePureComponent<RouteComponentProps & WithDloServiceProps<{}> & WithI18nProps & WithCanViewProps, PollsState> {

    public state: PollsState = {
        isDeleting: false,
        dialogVisible: false,
    }

    private snackBarContainerRef = React.createRef<SnackbarContainer>();

    private renderList = () => {
        const list = [
            {
                param: "id",
                type: DatatableActions.Custom,
                render: ({ item }: ActionsProps<Polling>) => {
                    if (item && item.votes_count > 0) {
                        return (
                                <CanView action="cms.polls.votes" key="view">
                                    <Button
                                        icon="equalizer"
                                        onClick={() => this.votesAction(item?.uuid)}
                                    />
                                </CanView>
                        )
                    }
                    
                    return (
                        <CanView action="cms.polls.details" key="edit">
                            <Link href={this.editAction(item?.uuid)}>
                                <Button icon="edit" />
                            </Link>
                        </CanView>
                    )
                }
            },
        ];

        if (this.props.canView("cms.polls.delete")) {
            (list as any).push(
                {
                    function: (id: string) => this.deleteAction(id),
                    param: "uuid",
                    type: DatatableActions.Common,
                    icon: "delete",
                }
            );
        }

        return list;
    }

    private readonly POLLS_CONFIG: DatatableProps = {
        fetch: PollsGetAllDatatableInput,
        rows: {
            lengths: [10, 25, 50],
            actions: {
                size: 2,
                list: this.renderList()
            },
        },
        columns: [
            {
                param: ["name"],
                size: 3,
                title: this.props.t(`${I18N_NAMESPACE}.columnName`),
                order: true,
                render: DefaultFormatter.Text,
            },
            {
                param: ["status"],
                size: 2,
                title: this.props.t(`${I18N_NAMESPACE}.columnStatus`),
                order: true,
                render: ({ data, column }: DefaultFormatterProps) => {
                    return (
                        <React.Fragment>
                            <Label
                                layout={data[(column as any).param] as any !== PollingStatus.CLOSED ? LabelLayout.Primary : LabelLayout.Error}
                                faded={true}
                            >{data[(column as any).param] as any !== PollingStatus.CLOSED ? this.props.t(`${I18N_NAMESPACE}.columnStatusValue1`) : this.props.t(`${I18N_NAMESPACE}.columnStatusValue2`)}</Label>
                        </React.Fragment>
                    )
                },
            },
            {
                param: ["start_date"],
                size: 2,
                title: this.props.t(`${I18N_NAMESPACE}.columnStart`),
                order: true,
                render: DefaultFormatter.Text,
            },
            {
                param: ["end_date"],
                size: 2,
                title: this.props.t(`${I18N_NAMESPACE}.columnEnd`),
                order: true,
                render: DefaultFormatter.Text,
            },
            {
                param: ["votes_count"],
                size: 1,
                title: this.props.t(`${I18N_NAMESPACE}.columnNVotes`),
                order: true,
                render: DefaultFormatter.Text,
            },
        ],
        title: this.props.t(`${I18N_NAMESPACE}.title`),
        actions: [
            <CanView action="cms.polls.create" key="add-action">
                <DefaultActions.Add
                    href="/cms/polls/add"
                />
            </CanView>,
        ],
        filters: [
            (props: FilterProps) => (
                <CanView action="cms.polls.search" key="search-filter">
                    <DefaultFilters.Search
                        {...props}
                    />
                </CanView>
            ),
        ],
    }

    public render() {
        return (
            <CanView action="cms.polls.list">
                {/** Render SnackBar */}
                <SnackbarContainer ref={this.snackBarContainerRef} />
                <Row>
                    <Column xs={12}>
                        <Datatable
                            {...this.POLLS_CONFIG}
                            actionSuccess={this.state.deletedSuccess}
                            isLoading={this.state.isDeleting}
                        />
                        {/** Render dialog */}
                        {this.renderDialog()}
                    </Column>
                </Row>
            </CanView>
        )
    }

    private addSnackbar = (
        message: string,
        layout?: string,
        timeout?: number
    ) => {
        if (this.snackBarContainerRef.current) {
            this.snackBarContainerRef.current.pushMessage({
                layout: layout as any,
                message,
                timeout,
            });
        }
    };

    private renderDialog = () => {
        return (
            <Dialog
                overlay={true}
                title={this.props.t(`${I18N_NAMESPACE}.deleteTitle`)}
                description={this.props.t(`${I18N_NAMESPACE}.deleteDescription`)}
                type="error"
                isVisible={this.state.dialogVisible}
                handleDialog={() => this.toggleDialog()}
            >
                <Button
                    layoutOutline
                    layout={"error"}
                    onClick={() => this.toggleDialog()}
                >
                    <Trans id={`${I18N_KEY_GLOBAL}.cancel`} />
                </Button>
                <Button
                    layoutFill
                    layout={"error"}
                    onClick={() => this.deletePoll()}
                    isLoading={this.state.isDeleting}
                    layoutIconPosition="right"
                >
                    <Trans id={`${I18N_KEY_GLOBAL}.delete`} />
                </Button>
            </Dialog>
        );
    };

    private toggleDialog = () => {
        this.setState({
            dialogVisible: !this.state.dialogVisible,
        });
    };

    private deletePoll = () => {
        if (this.state.deleteID) {
            this.setState({ isDeleting: true });

            const input = new PollsDeleteInput();

            input.body = {
                id: this.state.deleteID,
            };

            this.props
                .executeDloRequest(input)
                .then(res => {
                    this.toggleDialog();
                    this.setState({
                        isDeleting: false,
                        deletedSuccess: this.state.deleteID,
                    });
                })
                .catch(err => {
                    this.addSnackbar(err.body.code, "error");
                    this.setState({
                        isDeleting: false,
                    });
                });
        }
    };

    private deleteAction = (id?: string) => {
        this.toggleDialog();
        this.setState({ deleteID: id });
    };

    private editAction = (id?: string) => {
        return `/cms/polls/edit/${id}`;
    };

    private votesAction = (id?: string) => {
        this.props.history.push(`polls/view/${id}`);
    }
}

export const Polls = withCanView(withRouter(withI18n(APP_TRANSLATIONS)(withDloService(PollsComponent))));
