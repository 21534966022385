import styled, { css, CSSProperties } from "styled-components"
import { DropdownPosition, DropdownAlign } from "../Dropdown"
import { colors } from "../../../styles"

interface StylingDropdownCardProps {
    position: DropdownPosition;
    align?: DropdownAlign;
    styles?: CSSProperties;
    small?: boolean;
}

const CardDefaultStyles = {
    maxWidth: "282px",
    position: "absolute",
    padding: "24px"
}

const Card = styled.div<StylingDropdownCardProps>`
    border-radius: 10px;
    background-color: ${colors.light};
    padding: ${({ small }) => small ? "8px" : CardDefaultStyles.padding};
    box-shadow: 0 0 6px 0 rgba(0,0,0,0.06), 20px 40px 60px 0 rgba(0,0,0,0.12);
    width: auto;
    z-index: 2;
    position: ${({ styles }) => styles?.position ? styles.position : CardDefaultStyles.position};
    max-width: ${({ styles }) => styles?.maxWidth ? styles.maxWidth : CardDefaultStyles.maxWidth};

    ${({ align }) => align && css`
        &::after  {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            border-style: solid;
        }

        ${align === "left" && css`
            left: 0;
        `}

        ${align === "right" && css`
            right: 0;
        `}

        ${align === "center" && css`
            left: 50%;
            transform: translateX(-50%);
        `}
    `}

    /* top position */
    ${({ position, align }) => position === "top" && css`        
        bottom: calc(100% + 16px);
        left: 50%;
        transform: translateX(-50%);

        ${align === "center" && css`
            &::after  {
                left: 50%;
                transform: translateX(-50%);
                top: 100%;
                border-width: 8px 8px 0 8px;
                border-color: ${colors.light} transparent
                    transparent transparent;
            }
        `};

        ${align === "left" && css`
            &::after  {
                left: 24px;
                top: 100%;
                border-width: 8px 8px 0 8px;
                border-color: ${colors.light} transparent
                    transparent transparent;
            }
        `}

        ${align === "right" && css`
            &::after  {
                right: 24px;
                top: 100%;
                border-width: 8px 8px 0 8px;
                border-color: ${colors.light} transparent
                    transparent transparent;
            }
        `}
    `}

    /* bottom position */
    ${({ position, align }) => position === "bottom" && css`
        top: calc(100% + calc(16px / 2));

        ${align === "center" && css`
            &::after  {
                bottom: 100%;
                transform: translateX(-50%);
                left: 50%;
                border-width: 0 8px 8px 8px;
                border-color: transparent transparent
                    ${colors.light} transparent;
            }
        `};

        ${align === "left" && css`
            &::after  {
                bottom: 100%;
                left: 24px;
                border-width: 0 8px 8px 8px;
                border-color: transparent transparent
                    ${colors.light} transparent;
            }
        `}

        ${align === "right" && css`
            &::after  {
                bottom: 100%;
                right: 24px;
                border-width: 0 8px 8px 8px;
                border-color: transparent transparent
                    ${colors.light} transparent;
            }
        `}
    `}

    ${({ position, align }) => position === "right" && css`
        left: calc(100% + 16px);
        top: 50%;
        transform: translateY(calc(-50% - 16px)) translateX(-50%);

        ${align === "center" && css`
            &::after  {
                right: 100%;
                top: 50%;
                transform: translateY(calc(-50% - 4px));
                border-width: 8px 8px 8px 0;
                border-color: transparent ${colors.light} transparent transparent;
            }
        `};

        ${align === "left" && css`
            &::after  {
                right: 100%;
                bottom: 24px;
                border-width: 8px 8px 8px 0;
                border-color: transparent ${colors.light} transparent transparent;
            }
        `}

        ${align === "right" && css`
            &::after  {
                right: 100%;
                top: 24px;
                border-width: 8px 8px 8px 0;
                border-color: transparent ${colors.light} transparent transparent;
            }
        `}
    `}
`

export const Styles = {
    Card
}
