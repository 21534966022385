import React, { PureComponent } from "react"
import styled from "styled-components";
import { WrapperContent, StyledWrapperContent } from "./WrapperContent"
import { WrapperHeader, StyledWrapperHeader } from "./WrapperHeader"
import { WrapperFooter } from "./WrapperFooter"
import { colors } from "../../styles";

/** wrapper props */
export interface WrapperProps {
    className?: string
    /** wrapper header */
    header?: React.ReactComponentElement<any> | React.ComponentType<any> | Element;
    /** wrapper footer */
    footer?: React.ReactComponentElement<any> | React.ComponentType<any> | Element;
    /** height of the content limited, it adds a scroll bar */
    heightLimited?: boolean;
}

export const StyledWrapper = styled.div`
    position: relative;
    background-color: ${colors.light};
    min-height: 1px;
    border-radius: 10px;
    box-shadow: 6px 12px 20px 0 rgba(0, 0, 0, 0.04);

    & + & {
        margin-top: 24px;
    }

    /* header + content */
    ${StyledWrapperHeader} + ${StyledWrapperContent} {
        border-top: 1px solid ${colors.neutral.light};
    }
`

/**
 * # Wrapper Component
 *
 * ## How to use:
 *
 * ```tsx
 *  <Wrapper />
 * ```
 *
 * ## Wrapper Props:
 *
 * `header`: element - `{undefined}`
 *
 */
export class Wrapper extends PureComponent<WrapperProps> {

    public render() {
        const { header, children, footer } = this.props;

        return (
            <StyledWrapper className={this.props.className}>
                <WrapperHeader>{header}</WrapperHeader>
                <WrapperContent heightLimited={this.props.heightLimited}>{children}</WrapperContent>
                <WrapperFooter>{footer}</WrapperFooter>
            </StyledWrapper>
        )
    }
}
