import React from "react";
import { PureComponent } from "../../base";
import { withRouter, RouteComponentProps } from "react-router"
import queryString from "query-string";
import { memoizeOne } from "../../utils"

declare var process: any;

interface WithQueryParamsProps<T> {
    /** Query Parameters */
    queryParams: T
}

const withQueryParams: <P extends WithQueryParamsProps<T>, T extends object>(WrappedComponent: React.ComponentType<P>) => React.ComponentType<Omit<P, keyof WithQueryParamsProps<T>>> = (WrappedComponent) => {

    class QueryParamsComponent extends PureComponent<RouteComponentProps<void>> {

        private parse = memoizeOne((query: string) => queryString.parse(query))

        public render() {
            // Parse all parameters from query
            const queryParams = this.parse(this.props.location.search)

            return (
                <WrappedComponent {...this.props as any} queryParams={queryParams as any} />
            )
        }

    }

    if (process.env.NODE_ENV !== "production") {
        (QueryParamsComponent as React.ComponentClass<any>).displayName = `withQueryParams(${WrappedComponent.displayName || WrappedComponent.name})`
    }

    return withRouter(QueryParamsComponent) as any
}

export { withQueryParams, WithQueryParamsProps }
