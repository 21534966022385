import React from "react";
import { TextList } from "@7egend/web.core.cms/lib/dlos/textList";
import { Block } from "@7egend/web.core.cms/lib/dlos/block";
import { Input } from "../../../Input";
import { Button as ButtonComponent } from "../../../Button";
import { BlockComponentProps } from "../../interfaces";
import styled from "styled-components";
import { withI18n, WithI18nProps } from "@7egend/web.core/lib/components/withI18n";
import { APP_TRANSLATIONS } from "../../../../locale";
import { blockTypes } from "../config";
import { I18N_KEY_CMS_COMPONENTS_BLOCK } from "../../../../base/i18n";

const { list } = blockTypes;

const Structure = {
    type: list.type,
    content: {
        values: [""]
    },
} as Block<TextList>;

const StyleListWrapper = styled.div`
    border: 1px solid #E8E8E8;
    border-radius: 6px;
    padding: 16px;
`;

const StyledButton = styled(ButtonComponent)`
    display: contents;
`;

const updateItem = (valuesList: any, value: string, i: number) => {
    const newValuesList = [...valuesList];
    newValuesList[i] = value;

    return newValuesList;
};

const removeItem = (valuesList: any, i: number) => {
    const updatedList = [
        ...valuesList.slice(0, i),
        ...valuesList.slice(i + 1),
    ];

    return updatedList;
};

const addItem = (valuesList: any) => {
    const newvaluesList = [...valuesList];
    newvaluesList.push("");

    return newvaluesList;
};

const I18N_NAMESPACE = `${I18N_KEY_CMS_COMPONENTS_BLOCK}.types.list`

const ListComponent: React.FC<WithI18nProps & BlockComponentProps<TextList>> = ({ className, data, updateData, t }) => {
    if (data.content && data.content.values && data.content.values.length > 0) {
        return (
            <StyleListWrapper className={className}>
                {data.content.values.map((eachValue, i) => {
                    return (
                        <Input
                            key={i}
                            type="text"
                            name={i.toString()}
                            placeholder={t(`${I18N_NAMESPACE}.insertItem`)}
                            value={eachValue}
                            onChange={(value) =>
                                updateData({
                                    ...data,
                                    content: {
                                        ...data.content,
                                        values: updateItem(data.content.values, value, i)
                                    }
                                })}
                            action={
                                data.content.values.length > 1 &&
                                <ButtonComponent
                                    icon={"clear"}
                                    onClick={() => updateData({
                                        ...data,
                                        content: {
                                            ...data.content,
                                            values: removeItem(data.content.values, i)
                                        }
                                    })}
                                />
                            }
                        />
                    )
                })}
                <StyledButton
                    text={t(`${I18N_NAMESPACE}.addNewItem`)}
                    icon={"add"}
                    layoutIconPosition={"left"}
                    onClick={() => updateData({
                        ...data,
                        content: {
                            ...data.content,
                            values: addItem(data.content.values)
                        }
                    })}
                />
            </StyleListWrapper>
        );
    }
    return null;
}

const Component = withI18n(APP_TRANSLATIONS)(ListComponent);

const Button = {
    icon: "format_list_bulleted",
    name: {
        key: `${I18N_NAMESPACE}.name`,
        en: "List",
        es: "Lista",
        pt: "Lista",
    },
};

export const List = {
    key: list.type,
    Structure,
    Component,
    Button,
};
