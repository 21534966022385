import React, { PureComponent } from "react"
import styled from "styled-components";
import { Wrapper, WrapperProps } from "../Wrapper";
import { StyledTitle } from "../WithTitle";

/** container props */
export interface WrapperWithTitleActionsProps extends WrapperProps {
    /** container title */
    title:
    | React.ReactComponentElement<any>
    | React.ComponentType<any>
    | JSX.Element
    | string;
    /** container actions */
    actions: React.ComponentType<any> | JSX.Element;
}

const StyledActions = styled.div`
    position: relative;
    display: flex;
    align-items: center;

    > * {
        min-height: 0;
        padding: 0;
        margin-right: 32px !important;
        &:last-child {
            margin-right: 0 !important;
        }
    }
`;

/**
 * # Container Title Actions Component
 *
 * ## How to use:
 *
 * ```tsx
 *  <WrapperWithTitleActions title="..." actions={(<button>....</button>)} />
 * ```
 *
 * ## Container Props:
 *
 * `title`: element - `{undefined}`
 *
 * `actions`: element - `{undefined}`
 *
 */
export class WrapperWithTitleActions extends PureComponent<
    WrapperWithTitleActionsProps
    > {
    public render() {
        const { title, actions, children } = this.props;

        // if empty prop
        if (title == null && actions == null) {
            return null;
        }

        return (
            <Wrapper
                header={
                    <React.Fragment>
                        <StyledTitle>{title}</StyledTitle>
                        <StyledActions>{actions}</StyledActions>
                    </React.Fragment>
                }
                {...this.props}
            >
                {children}
            </Wrapper>
        );
    }
}
