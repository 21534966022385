import React from "react";
import { CorePureComponent, ReactDOM } from "@7egend/web.core";
import { StyledDropdown, StyledOverlay } from "./styles"
import { DropdownCard, DropdownCardProps } from "./DropdownCard/DropdownCard";
import { WithPortal } from "./WithPortal/WithPortal";

export type DropdownPosition = "top" | "right" | "bottom" | "left";
export type DropdownAlign = "left" | "center" | "right";

interface DropdownProps extends React.PropsWithChildren<DropdownCardProps> {
    /** Dropdown Active */
    active?: boolean;

    /** Dropdown component */
    component?:
    | React.StatelessComponent<any>
    | React.ComponentType<any>
    | JSX.Element

    /** On Mouse Over */
    onMouseOver?: () => void;

    /** On Mouse Leave */
    onMouseLeave?: () => void;

    /** On Click */
    onClick?: () => void;

    /** attach to the body */
    attachOnBody?: boolean;

    /** class name */
    className?: string;
}

const DefaultProps: DropdownProps = {
    position: "bottom",
    active: false,
};

/**
 *
 * # Dropdown Component
 */
export class Dropdown extends CorePureComponent<DropdownProps> {
    private readonly document = this.fw.dom.getDocument();
    public static defaultProps = DefaultProps;
    private readonly rootRef = React.createRef<React.ReactElement>();

    public render() {

        return (
            <StyledDropdown className={this.props.className}>
                <div
                    onMouseOver={this.props.onMouseOver}
                    onMouseLeave={this.props.onMouseLeave}
                    onClick={this.props.onClick}
                    ref={this.rootRef}
                >
                    {this.props.component}
                </div>
                {this.props.active &&
                    <React.Fragment>
                        {this.props.attachOnBody ? ReactDOM.createPortal(
                            <WithPortal
                                align={this.props.align}
                                position={this.props.position}
                                anchorTo={this.rootRef}
                                onClick={this.props.onClick}
                            >{(position, align) => this.contentWrapper(position, align)}</WithPortal>
                            , this.document.body) : this.contentWrapper(this.props.position, this.props.align)}
                    </React.Fragment>
                }
            </StyledDropdown>
        );
    }

    private contentWrapper = (position: DropdownCardProps["position"], align: DropdownCardProps["align"]) => {
        return (
            <React.Fragment>
                {this.props.onClick && <StyledOverlay onClick={this.props.onClick} />}
                <DropdownCard
                    {...this.props}
                    position={position}
                    align={align}
                    styles={this.props.attachOnBody ? {
                        position: "relative",
                        ...this.props.styles
                    } : this.props.styles}
                    onClick={(e: React.MouseEvent) => {
                        e.stopPropagation();
                    }}
                >
                    {this.props.children}
                </DropdownCard>
            </React.Fragment>
        )
    }
}
