import styled, { css } from "styled-components"
import { Link } from "@7egend/web.core.controls/lib/components/link"
import { colors, queries, font } from "../../../styles"
import { Button } from "../../Button"
import { Image } from "../../Image"
import { Icon } from "../../Icon"

export const StyledMenu = styled.aside`
    max-width: 272px;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    background-color: ${colors.neutral.lighter};
    overflow-y: auto;

    &::after {
        content: "";
        white-space: nowrap;
        clear: both;
        display: block;
        width: 100%;
    }

    ${queries.maxMD} {
        padding: 0 24px;
        z-index: 20;
        max-width: 50%;
        transform: translateX(-32px);
        transition: transform .2s ease-in;

        ${(props: any) => !props.menuOpen && css`
            transform: translateX(-100vw);
        `}
    }

    ${queries.maxSM} {
        padding: 0 12px;
        transform: translateX(-12px);

        ${(props: any) => !props.menuOpen && css`
            transform: translateX(-100vw);
        `}
    }

    ${queries.maxXS} {
        max-width: 100%;

        ${(props: any) => !props.menuOpen && css`
            transform: translateX(-150%);
        `}
    }
`;

export const StyledLogo = styled(Image)`
    & {
        max-width: 48px;
        max-height: 48px;
        width: auto;
        height: auto;

        ${queries.maxSM} {
            max-width: 28px;
            max-height: 28px;
        }
    }
`

export const StyledPlatform = styled.div`
    margin-left: 12px;

    .platform {
        color: ${colors.primary.lighter};
        font-size: ${font.size.md};
        font-weight: ${font.weight.bold};
        display: block;

        ${queries.maxSM} {
            font-size: ${font.size.xs};
        }
    }

    .client {
        color: ${colors.neutral.darker};
        font-size: ${font.size.sm};
        font-weight: ${font.weight.medium};
        display: block;
        margin-top: 3px;

        ${queries.maxSM} {
            font-size: ${font.size.xs};
            margin-top: 1px;
        }
    }
`

export const StyledLogoAndPlatform = styled.div`
    & {
        display: inline-flex;
        align-items: center;
        text-decoration: none;
        min-height: 85px;
        margin: 0 0 16px;
        width: 100%;

        ${queries.maxMD} {
            min-height: 60px;
            position: relative;
            margin: 16px 0 32px;

            &::after {
                content: "";
                width: calc(100% + 48px);
                height: 1px;
                background-color: ${colors.neutral.medium};
                position: absolute;
                bottom: -16px;
                left: -24px;
            }
        }

        ${queries.maxMD} {
            margin: 16px 0 26px;
        }

        ${queries.maxSM} {
            min-height: 33px;

            &::after {
                width: calc(100% + 24px);
                left: -12px;
            }
        }

        ${queries.minMD} {
            padding-left: 12px;
        }
    }
`

export const StyledLink = styled(Link)`
    & {
        display: inline-flex;
        align-items: center;
        text-decoration: none;
    }
`

export const StyledMenuButton = styled(Button)`
    & {
        padding: 0;
        margin-right: 24px;
        display: none;

        ${queries.maxMD} {
            display: inline-flex;
        }

        ${queries.maxSM} {
            margin-right: 16px;
        }
    }
`

export const StyledMenuWrapper = styled.div`
    ${queries.maxMD} {
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        position: relative;
        height: calc(100% - 85px);
    }

    ${queries.minMD} {
        h6 {
            padding-left: 20px;
        }
    }
`

const Title = styled.div<{ active?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    &, a {
        font-size: ${font.size.sm};
        font-weight: ${font.weight.light};
        color: ${colors.neutral.darker};
    }

    ${({ active }) => active && css`
        a {
            color: ${colors.primary.lighter};
        }
    `}

    a:hover {
        color: ${colors.primary.lighter};
    }
`;

const Container = styled.div<{ isActive?: boolean, isEnabled?: boolean }>`
    padding-left: 48px;
    padding-right: 16px;
    position: relative;
    line-height: 25px;

    ${({ isActive }) => isActive && css`
        ${Title} {
            & {
                font-weight: ${font.weight.medium};
            }
        }
    `}

    ${({ isEnabled }) => isEnabled && css`
        &:hover {
            cursor: pointer
        }
    `}
`;

const TitleWithoutLink = styled.span`
    cursor: pointer;
`;

const ExpandableIcon = styled(Icon)`
    cursor: pointer;
    & {
        font-size: ${font.size.sm};
    }
`;

/** Module Styles */
export const StyledModule = {
    Container,
    Title,
    TitleWithoutLink,
    ExpandableIcon,
}
