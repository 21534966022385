import React from "react";
import { Component } from "@7egend/web.core/lib/base/component";
import { memoizeOne } from "@7egend/web.core/lib/utils"
import { GridItem } from "./gridItem";
import { GridRowComponent } from "./gridRow";

//#region Exports

export { GridItem }

//#endregion

interface GridRow {
    /** Grid AutoHeight flag */
    autoHeight: boolean;

    /** List of items to show in the grid */
    items: GridItem[];
}

export interface GridProps {
    /** Grid data to create child blocks */
    items: GridItem[];

    /** Number of columns to draw the grid */
    columns?: number;
}

export interface Props extends GridProps { }

/**
 *
 * Component gets grid objects and check how many grids must be created
 * Renders component Grid for every different array of components
 */
export class Grid extends Component<GridProps> {

    //#region Private methods

    /**
     * Splits a given grid into small chunks
     * @param grid The original grid
     */
    private splitGridInChunks = memoizeOne<(items: GridItem[]) => GridRow[]>((items: GridItem[]) => {
        let result: GridRow[] = [];

        result = items.reduce((rows: GridRow[], item: GridItem) => {
            // Check if there's one dimension "height" that is 0 or undefined
            const autoHeight = Object.keys(item.dimensions).some((key) => !(item.dimensions as any)[key].height);

            // If there is no rows, create one
            // Or if the previous one has a different autoHeight
            if (rows.length === 0 || rows[rows.length - 1].autoHeight !== autoHeight) {
                rows.push({
                    autoHeight,
                    items: [item],
                });
            } else {
                rows[rows.length - 1].items.push(item);
            }

            return rows;
        }, []);

        return result;
    })

    public render() {
        const rows = this.splitGridInChunks(this.props.items);

        return (
            <React.Fragment>
                {rows.map((row: GridRow, index: number) => {
                    return (
                        <GridRowComponent
                            key={index}
                            items={row.items}
                            autoHeight={row.autoHeight}
                            columns={this.props.columns} >
                        </GridRowComponent>
                    );
                })}
            </React.Fragment>
        );
    }

    //#endregion
}

export const GridComponent = Grid
