import styled from "styled-components";
import { StyledButton } from "../Button/styles";
import { colors } from "../../styles";

const Actions = styled.div`
    margin: 24px 0;
    
    ${StyledButton} {
        margin-right: 16px;
        &:last-child {
            margin-right: 0;
        }
    }
`

export const OverlayLoading = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    background-color: rgba(250, 250, 250, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;

    * {
        stroke: ${colors.primary.lighter};
    }
`

export const StickyDiv = styled.div`
    position: sticky;
    top: 90px;
    left: 0;
`;

export const Styled = {
    Actions,
}
