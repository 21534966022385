import React, { PureComponent } from "react"
import styled from "styled-components";
import { GridGutter } from "../../../constants";

export interface RowProps {
    /** className */
    className?: string
    /** gutter */
    gutter?: number
}

/** Styled Component */
const StyledRow = styled.div`
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin-left: -${(props: RowProps) => props.gutter && (props.gutter / 2)}px;
    margin-right: -${(props: RowProps) => props.gutter && (props.gutter / 2)}px;

    &::after {
        content: '';
        width: 100%;
        white-space: nowrap;
        clear: both;
        display: block;
    }
`

const DefaultProps: RowProps = {
    gutter: GridGutter,
}
/**
 * # Row Component
 */
export class Row extends PureComponent<RowProps> {

    public static defaultProps = DefaultProps;

    public render() {
        return (
            <StyledRow
                {...this.props}
                gutter={GridGutter}
            >
                {this.props.children}
            </StyledRow>
        )
    }
}
