const MODULE = {
    CMS: 'cms',
    CORE: 'core',
    GLOBAL: 'global'
}

const PAGES = {
    // CMS
    MEDIA: 'media',
    MULTIMEDIA: 'multimedia',
    ALBUM: 'album',
    BOOSTER: 'booster',
    DYNAMIC_PAGE: 'dynamicPage',
    STATIC_PAGE: 'staticPage',
    SOCIAL: 'social',
    FORM: 'form',
    NEWS: 'news',
    NAVIGATION: 'navigation',
    POLL: 'poll',

    // CORE
    DASHBOARD: 'dashboard',
    USER: 'user',
}

const PAGE_TYPES = {
    // CMS
    LIST: 'list',
    EDITOR: 'editor',
    DETAILS: 'details',

    // CORE
    PROFILE: 'profile',
    LOGIN: 'login'
}

const COMPONENTS = {
    // CMS
    BLOCK: 'block',
    CATEGORY: 'category',

    // CORE
    MEDIA: 'media',
    DATATABLE: 'datatable',
    NOT_FOUND: 'notFound',
    PROGRESS_BAR: 'progressBar',
    MULTISELECT: 'multiselect',
    PUBLISH_DEFAULT: 'publishDefault',
    PAGE_EDITOR: 'pageEditor',
    UPLOADER: 'uploader'
}

const STRUCTURE = {
    // CORE
    HEADER: 'header'
}

//#region CMS MODULE

//#region COMPONENTS
export const I18N_KEY_CMS_COMPONENTS_BLOCK = `${MODULE.CMS}.components.${COMPONENTS.BLOCK}`
export const I18N_KEY_CMS_COMPONENTS_CATEGORY = `${MODULE.CMS}.components.${COMPONENTS.CATEGORY}`
//#endregion COMPONENTS

//#region PAGES
export const I18N_KEY_CMS_PAGES_MEDIA = `${MODULE.CMS}.pages.${PAGES.MEDIA}`
export const I18N_KEY_CMS_MEDIA_LIST = `${I18N_KEY_CMS_PAGES_MEDIA}.${PAGE_TYPES.LIST}`
export const I18N_KEY_CMS_MEDIA_EDITOR = `${I18N_KEY_CMS_PAGES_MEDIA}.${PAGE_TYPES.EDITOR}`

export const I18N_KEY_CMS_PAGES_MULTIMEDIA = `${MODULE.CMS}.pages.${PAGES.MULTIMEDIA}`
export const I18N_KEY_CMS_MULTIMEDIA_LIST = `${I18N_KEY_CMS_PAGES_MULTIMEDIA}.${PAGE_TYPES.LIST}`

export const I18N_KEY_CMS_PAGES_ALBUM = `${MODULE.CMS}.pages.${PAGES.ALBUM}`
export const I18N_KEY_CMS_ALBUM_LIST = `${I18N_KEY_CMS_PAGES_ALBUM}.${PAGE_TYPES.LIST}`
export const I18N_KEY_CMS_ALBUM_EDITOR = `${I18N_KEY_CMS_PAGES_ALBUM}.${PAGE_TYPES.EDITOR}`

export const I18N_KEY_CMS_PAGES_BOOSTER = `${MODULE.CMS}.pages.${PAGES.BOOSTER}`
export const I18N_KEY_CMS_BOOSTER_LIST = `${I18N_KEY_CMS_PAGES_BOOSTER}.${PAGE_TYPES.LIST}`
export const I18N_KEY_CMS_BOOSTER_EDITOR = `${I18N_KEY_CMS_PAGES_BOOSTER}.${PAGE_TYPES.EDITOR}`

export const I18N_KEY_CMS_PAGES_DYNAMIC_PAGE = `${MODULE.CMS}.pages.${PAGES.DYNAMIC_PAGE}`
export const I18N_KEY_CMS_DYNAMIC_PAGE_EDITOR = `${I18N_KEY_CMS_PAGES_DYNAMIC_PAGE}.${PAGE_TYPES.EDITOR}`

export const I18N_KEY_CMS_PAGES_STATIC_PAGE = `${MODULE.CMS}.pages.${PAGES.STATIC_PAGE}`
export const I18N_KEY_CMS_STATIC_PAGE_LIST = `${I18N_KEY_CMS_PAGES_STATIC_PAGE}.${PAGE_TYPES.LIST}`
export const I18N_KEY_CMS_STATIC_PAGE_EDITOR = `${I18N_KEY_CMS_PAGES_STATIC_PAGE}.${PAGE_TYPES.EDITOR}`

export const I18N_KEY_CMS_PAGES_SOCIAL = `${MODULE.CMS}.pages.${PAGES.SOCIAL}`
export const I18N_KEY_CMS_SOCIAL_LIST = `${I18N_KEY_CMS_PAGES_SOCIAL}.${PAGE_TYPES.LIST}`

export const I18N_KEY_CMS_PAGES_FORM = `${MODULE.CMS}.pages.${PAGES.FORM}`
export const I18N_KEY_CMS_FORM_LIST = `${I18N_KEY_CMS_PAGES_FORM}.${PAGE_TYPES.LIST}`
export const I18N_KEY_CMS_FORM_EDITOR = `${I18N_KEY_CMS_PAGES_FORM}.${PAGE_TYPES.EDITOR}`

export const I18N_KEY_CMS_PAGES_NEWS = `${MODULE.CMS}.pages.${PAGES.NEWS}`
export const I18N_KEY_CMS_NEWS_LIST = `${I18N_KEY_CMS_PAGES_NEWS}.${PAGE_TYPES.LIST}`
export const I18N_KEY_CMS_NEWS_EDITOR = `${I18N_KEY_CMS_PAGES_NEWS}.${PAGE_TYPES.EDITOR}`

export const I18N_KEY_CMS_PAGES_NAVIGATION = `${MODULE.CMS}.pages.${PAGES.NAVIGATION}`
export const I18N_KEY_CMS_NAVIGATION_LIST = `${I18N_KEY_CMS_PAGES_NAVIGATION}.${PAGE_TYPES.LIST}`
export const I18N_KEY_CMS_NAVIGATION_EDITOR = `${I18N_KEY_CMS_PAGES_NAVIGATION}.${PAGE_TYPES.EDITOR}`

export const I18N_KEY_CMS_PAGES_POLL = `${MODULE.CMS}.pages.${PAGES.POLL}`
export const I18N_KEY_CMS_POLL_LIST = `${I18N_KEY_CMS_PAGES_POLL}.${PAGE_TYPES.LIST}`
//#endregion

//#endregion



// GLOGAL MODULE
export const I18N_KEY_GLOBAL = MODULE.GLOBAL
// GLOGAL MODULE END

// CORE MODULE

// PAGES
export const I18N_KEY_CORE_PAGES_DASHBOARD = `${MODULE.CORE}.pages.${PAGES.DASHBOARD}`

export const I18N_KEY_CORE_PAGES_USER = `${MODULE.CORE}.pages.${PAGES.USER}`
export const I18N_KEY_CORE_USER_PROFILE = `${I18N_KEY_CORE_PAGES_USER}.${PAGE_TYPES.PROFILE}`
export const I18N_KEY_CORE_USER_LOGIN = `${I18N_KEY_CORE_PAGES_USER}.${PAGE_TYPES.LOGIN}`

// STRUCTURE
export const I18N_KEY_CORE_STRUCTURE_HEADER = `${MODULE.CORE}.structure.${STRUCTURE.HEADER}`

// COMPONENTS
export const I18N_KEY_CORE_COMPONENTS_DATATABLE = `${MODULE.CORE}.components.${COMPONENTS.DATATABLE}`
export const I18N_KEY_CORE_COMPONENTS_NOT_FOUND = `${MODULE.CORE}.components.${COMPONENTS.NOT_FOUND}`
export const I18N_KEY_CORE_COMPONENTS_PROGRESS_BAR = `${MODULE.CORE}.components.${COMPONENTS.PROGRESS_BAR}`
export const I18N_KEY_CORE_COMPONENTS_MULTISELECT = `${MODULE.CORE}.components.${COMPONENTS.MULTISELECT}`
export const I18N_KEY_CORE_COMPONENTS_PUBLISH_DEFAULT = `${MODULE.CORE}.components.${COMPONENTS.PUBLISH_DEFAULT}`
export const I18N_KEY_CORE_COMPONENTS_PAGE_EDITOR = `${MODULE.CORE}.components.${COMPONENTS.PAGE_EDITOR}`
export const I18N_KEY_CORE_COMPONENTS_UPLOADER = `${MODULE.CORE}.components.${COMPONENTS.UPLOADER}`
export const I18N_KEY_CORE_COMPONENTS_MEDIA = `${MODULE.CORE}.components.${COMPONENTS.MEDIA}`
// CORE MODULE END
