import React, { FC } from "react"
import { DateTime as DateTimeCore, DateTimeProps } from "@7egend/web.core.controls/lib/components/dateTime"
import { getSystemLocaleShortDateFormat } from "../../utils/dateTimeSystem"

export const DateTime: FC<DateTimeProps> = (props) => (
    <DateTimeCore
        {...props}
    />
)

DateTime.defaultProps = {
    utc: true,
    local: true,
    format: getSystemLocaleShortDateFormat(), // we are overriding this from momentJs, to use the local system format
}
