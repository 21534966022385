import React from "react";
import { CorePureComponent } from "@7egend/web.core";
import { RouteComponentProps, withRouter } from "react-router";
import {
    WithDloServiceProps,
    withDloService
} from "@7egend/web.core/lib/components/withDloService";
import {
    withQueryParams,
    WithQueryParamsProps
} from "@7egend/web.core/lib/components/withQueryParams";
import { MultiSelect, MultiSelectItemsData } from "../MultiSelect"
import { CategoryGetAllInput } from "@7egend/web.core.cms/lib/dlos/category"
import { withI18n, WithI18nProps } from "@7egend/web.core/lib/components/withI18n";
import { APP_TRANSLATIONS } from "../../locale";
import { I18N_KEY_CMS_COMPONENTS_CATEGORY } from "../../base/i18n";

interface PickerProps {
    /** categories picked */
    categories?: MultiSelectItemsData[];

    /** selected categories */
    selectedCategories?: (categories: MultiSelectItemsData[]) => void
}

interface PickerState {
    categories: MultiSelectItemsData[];
}

const I18N_NAMESPACE = `${I18N_KEY_CMS_COMPONENTS_CATEGORY}.picker`

class CategoriesPickerComponent extends CorePureComponent<
    PickerProps &
    WithDloServiceProps<MultiSelectItemsData[]> &
    RouteComponentProps<{ language?: string }> &
    WithQueryParamsProps<{ language: string }> &
    WithI18nProps, PickerState> {

    public state: PickerState = {
        categories: []
    }

    public componentDidMount() {
        this.fetchCategories()
            .then(res => {
                if (res.data) {
                    this.setState({
                        categories: res.data
                    })
                }
            })
            .catch(err => this.fw.log.info('fetch categories', err))
    }

    public render() {

        const { categories } = this.state;

        if (!categories) {
            return null;
        }

        return (
            <MultiSelect
                label={this.props.t(`${I18N_NAMESPACE}.label`)}
                items={categories}
                placeholder={this.props.t(`${I18N_NAMESPACE}.placeholder`)}
                handleItem={this.selectedCategories}
                checkedItems={this.props.categories || []}
            />
        )
    }

    private selectedCategories = async (selectedCategories: MultiSelectItemsData[]) => {
        if (this.props.selectedCategories) {
            this.props.selectedCategories(selectedCategories);
        }
    }

    private getCurrentLanguage = (): string => {
        if (this.props.queryParams && this.props.queryParams.language) {
            return this.props.queryParams.language;
        }

        return this.fw.i18n.getCurrentLanguage();
    }


    private fetchCategories = async () => {
        const categoriesDLO = new CategoryGetAllInput();

        categoriesDLO.body = {
            locale: await this.getCurrentLanguage(),
        };

        /** Request Categories */
        const requestCategories = this.fw.dlo.call(categoriesDLO);

        return Promise.all([requestCategories]).then(res => {
            return {
                data: res[0].body
            }
        });
    }
}

export const CategoriesPicker = withQueryParams(withRouter(withI18n(APP_TRANSLATIONS)(withDloService(CategoriesPickerComponent))));
