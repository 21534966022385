import React, { PureComponent } from "react";
import Globals from "../../styles/globals";
import { RouterOutlet } from "@7egend/web.core/lib/components/routerOutlet";

const GlobalStyles = React.memo(() => <Globals />)

/** This component is the top level of the application */
export class App extends PureComponent {
    public render() {
        return (
            <React.Fragment>
                <RouterOutlet id="app" />
                <GlobalStyles />
            </React.Fragment>
        )
    }
}
