import React from "react";
import { compose, CorePureComponent } from "@7egend/web.core";
import { StyledUploader } from "../styles";
import { uniqueId } from "@7egend/web.core/lib/utils";
import { LimitSizeUpload } from "../../../constants";
import { RouteComponentProps, withRouter } from "react-router";
import { FileInfo, UploaderProps } from "../domain"

export interface ImageProps extends React.PropsWithChildren<UploaderProps> {
    /** upload action */
    uploadAction?: (files: FileInfo[]) => void;

    /** image preview */
    previewImage?: string;

    /** clean preview image */
    cleanPreviewImage?: () => void;

    /** @default true */
    allowUpload?: boolean;
}

const UNIQUE_ID = uniqueId("image_upload");
const ACCEPTED_TYPES = "image/x-png,image/gif,image/jpg";

/**
 * # Uploader Image
 *
 * ## How to use:
 *
 * ```jsx
 * <Uploader.Image
 *      uploadAction={this.handleUpload}
 *      label="Upload Image"
 *      info="jpg, png, tiff"
 * />
 * ```
 */
class ImageComponent extends CorePureComponent<ImageProps & RouteComponentProps> {
    /** define type for input file */
    private inputRef: React.RefObject<any> = React.createRef();

    public render() {
        const { info, label, previewImage, className, cleanPreviewImage, allowUpload, history, multiple } = this.props;

        return (
            <StyledUploader.Wrapper className={className}>
                <StyledUploader.Label
                    style={{
                        backgroundImage: `url(${previewImage})`,
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                    }}
                >
                    {previewImage ? (
                        <StyledUploader.ActionPreviewClean
                            onClick={() => {
                                if (cleanPreviewImage) {
                                    cleanPreviewImage();
                                }
                            }}
                            layout="alt"
                            icon={"delete"}
                        />
                    ) : (
                        <StyledUploader.Action
                            onClick={() => {
                                if (allowUpload === false) {
                                    history.push(`/${this.fw.i18n.getCurrentLanguage()}/cms/multimedia/add?type=image`);
                                    return;
                                }
                                this.inputRef.current.click();
                            }}
                            layout="alt"
                            icon={"photo"}
                        />
                    )}
                    <input
                        ref={this.inputRef}
                        style={{ display: "none" }}
                        type="file"
                        accept={ACCEPTED_TYPES}
                        multiple={multiple}
                        id={UNIQUE_ID}
                        name={UNIQUE_ID}
                        onChange={this.onFileSelect}
                    />
                </StyledUploader.Label>
                {label && (
                    <StyledUploader.Header>{label}</StyledUploader.Header>
                )}
                {info && (
                    <StyledUploader.SubHeader variant="neutral">
                        {info}
                    </StyledUploader.SubHeader>
                )}
            </StyledUploader.Wrapper>
        );
    }

    /**
     * Reads and parses given files
     * @param file 
     * @returns 
     */
    private getFileFromInput(file: any): Promise<FileInfo> {
        return new Promise((res, rej) => {
            const reader: any = new (this.fw.dom.getGlobal().FileReader)();
            reader.onerror = rej;
            reader.onload = () => {
                res({
                    base64: reader.result,
                    name: file.name,
                    error: file.size > LimitSizeUpload ? true : false,
                });
            };
            reader.readAsDataURL(file);
        });
    }

    private onFileSelect = (e: React.ChangeEvent<HTMLInputElement>): void => {
        e.persist();

        const files: any = (e.target as any).files;

        Promise.all(
            Object.keys(files).map(key => this.getFileFromInput(files[key]))
        )
            .then(filesInfo => {
                if (this.props.uploadAction) {
                    /** send each base64 file and the total number of files to upload */
                    this.props.uploadAction(filesInfo);
                }
            })
            .catch(err => {
                this.fw.log.error("Get File Error", err);
            });
    };
}

export const Image = compose<React.ComponentClass<ImageProps>>(
    withRouter
)(ImageComponent)
