import React, { PureComponent } from "react";
import { Divider, Typography, Select, Input, Tooltip } from "../../../components";
import { Space } from "../../Divider";
import { CopyClipboard } from "../../CopyClipboard";
import styled from "styled-components";
import { SelectOption } from "@7egend/web.core.controls/lib/components/select";
import { Button } from "../../Button";
import { MediumType } from "@7egend/web.core.media/lib/dlos";

const StyledDetails = styled.div`
    float: left;
    padding: 24px 24px 24px 0;
    width: 100%;
    height: 700px;

    * {
        text-overflow: ellipsis;
        word-break: break-all;
    }
`;

interface DetailsProps {
    title?: string;
    url?: string;
    medium_type?: string;
}

interface DetailsState {
    optionSelected?: string;
    copyToClipboardVisibile?: boolean;
    copied?: boolean;
}

export class Details extends PureComponent<DetailsProps, DetailsState> {

    private readonly MEDIA_SIZE_OPTIONS: SelectOption[] = [
        {
            label: "Normal",
            value: "Normal",
            option: { value: "Normal" }
        },
        {
            label: "Small",
            value: "Small",
            option: { value: "Small" }
        },
        {
            label: "Thumb",
            value: "Thumb",
            option: { value: "Thumb" }
        }
    ]

    public state: DetailsState = {}

    public componentDidUpdate(prevProps: DetailsProps) {
        if (prevProps.title !== this.props.title) {
            this.setState({
                optionSelected: undefined
            })
        }
    }

    public componentWillUnmount() {
        this.setState({
            optionSelected: undefined,
            copyToClipboardVisibile: undefined,
            copied: undefined,
        })
    }

    public render() {
        const { medium_type } = this.props;
        const mediaURL = this.props.url;

        return (
            <StyledDetails>
                <Divider space={Space.Tiny} />
                {
                    this.props.title &&
                    <React.Fragment>
                        <Typography.SmallCapsTitle>Title</Typography.SmallCapsTitle>
                        <Divider space={Space.Tiny} />
                        <Typography.RegularText variant="neutral">{this.props.title}</Typography.RegularText>
                    </React.Fragment>
                }
                <Divider space={Space.Small} />
                {medium_type === MediumType.Image && <Select
                    label="Media Size"
                    onChange={(value: string) => this.setState({ optionSelected: value })}
                    name={"image_select"}
                    value={this.state.optionSelected}
                    defaultLabel="Select media size"
                    defaultValue="-1"
                    options={this.MEDIA_SIZE_OPTIONS}
                />}
                {
                    mediaURL &&
                    <React.Fragment>
                        <Input
                            name="thumb_url"
                            label="URL"
                            // tslint:disable-next-line: no-empty
                            onChange={() => { }}
                            type="text"
                            value={mediaURL}
                            action={
                                <div
                                    onMouseLeave={() => this.setState({
                                        copyToClipboardVisibile: false,
                                        copied: false
                                    })}
                                    onMouseOver={() => this.setState({ copyToClipboardVisibile: true })}
                                >
                                    <Tooltip
                                        position="right"
                                        component={
                                            <CopyClipboard
                                                text={mediaURL}
                                                onCopy={() => this.setState({ copied: true })}
                                            >
                                                <Button icon="file_copy" />
                                            </CopyClipboard>
                                        }
                                    >
                                        {
                                            this.state.copied
                                                ? `Copied to clipboard!`
                                                : `Copy to clipboard`
                                        }
                                    </Tooltip>
                                </div>
                            }
                        />
                    </React.Fragment>
                }
                <Divider space={Space.Tiny} />
            </StyledDetails>
        )
    }
}
