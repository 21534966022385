import { Module } from "../../../bootstrap";
import loadable from "@loadable/component";

export const cmsMenuSetup: Module = {
    routes: [
        // Menu
        {
            component: loadable(async () => (await import('./Menu')).Menu),
            path: "/cms/menu",
            exact: true
        },
        // Menu update
        {
            component: loadable(async () => (await (await import('./MenuEditor')).MenuEditor)),
            path: "/cms/menu/edit/:id",
        },
        // Menu Create
        {
            component: loadable(async () => (await (await import('./MenuEditor')).MenuEditor)),
            path: "/cms/menu/add",
        }
    ],
    menus: [
        // Menu Configurator
        {
            title: {
                key: "cms.menu.menu.title",
                en: "Navigation (menu)",
                es: "Navegacíon (menu)",
                pt: "Navegação (menu)",
            },
            path: "cms/menu",
            permission: "cms.menu.list",
            children: [
                {
                    title: {
                        key: "cms.menu.edit.menu.title",
                        en: "Edit Menu",
                        es: "Editar Menu",
                        pt: "Editar Menu",
                    },
                    path: "cms/menu/edit",
                    visible: false,
                    permission: "cms.menu.add",
                },
                {
                    title: {
                        key: "cms.menu.create.menu.title",
                        en: "Create Menu",
                        es: "Crear Menu",
                        pt: "Criar Menu",
                    },
                    path: "cms/menu/add",
                    visible: false,
                    permission: "cms.menu.edit",
                }
            ]
        }
    ]
}
