import React, { PureComponent } from "react"
import styled from "styled-components";
import { Image as CoreImage, ImageProps as CoreImageProps } from "@7egend/web.core.controls/lib/components/image";

export interface ImageProps extends React.PropsWithChildren<CoreImageProps> {
    /** alt attr to image */
    alt?: string
    /** src attr to image */
    src: string
    /** className */
    className?: string
}

const StyledImage = styled(CoreImage)`
    & {
        max-width: 100%;
        width: auto;
        display: block;
    }
`

export class Image extends PureComponent<ImageProps> {
    public render() {
        const { alt, src, className } = this.props;

        return (
            <StyledImage
                alt={alt}
                src={src}
                className={className}
            />
        )
    }
}
