import styled, { css } from "styled-components";
import { Image } from "../../../Image";
import { colors, queries } from "../../../../styles";

interface ModuleInterface {
    isEnabled?: boolean;
    isActive?: boolean;
}

export const Styles = {
    Divider: styled.div`
        background-color: ${colors.neutral.light};
        margin: 10px 0;
        height: 1px;
        width: 100%;

        ${queries.maxXS} {
            background-color: transparent;
            margin: 5px 0;
        }
    `,
    Content: styled.div<ModuleInterface>`
        max-height: 0px;
        opacity: 0;
        overflow: hidden;
        
        ${({ isActive }) => isActive && css`
            & {
                opacity: 1;
                max-height: 100%;
                padding-bottom: 10px;
            }
        `}
    `,
    Wrapper: styled.div<ModuleInterface>`
        margin-bottom: 12px;
        padding: 3px 0;
        border-radius: 10px;
        transition: background-color 200ms ease,
                box-shadow 300ms ease;
        
        ${({ isEnabled }) => !isEnabled && css`
            * {
                opacity: 0.6;
            }

            img {
                filter: grayscale(1);
            }
        `}

        ${({ isActive }) => isActive && css`
            padding: 12px 0 0;

            ${queries.minSM} {
                background-color: ${colors.light};
                box-shadow: 2px 4px 8px 0 rgba(0,0,0,0.02);
            }
        `}
    `,
    ModuleImage: styled(Image)`
        & {
            max-width: 24px;
            display: inline-block;
            position: absolute;
            left: 16px;
            top: 50%;
            transform: translateY(-50%);
        }
    `,
    
}
