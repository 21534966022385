import { CorePureComponent } from "@7egend/web.core";
import { Trans } from "@7egend/web.core/lib/components/i18n";
import { withI18n, WithI18nProps } from "@7egend/web.core/lib/components/withI18n";
import React, { ComponentClass } from "react"
import { compose } from "redux";
import { I18N_KEY_CORE_COMPONENTS_PAGE_EDITOR } from "../../../base/i18n";
import { TagsGetByTextInput, TagsGetByTextOutput } from "../../../dlos/cms/tag";
import { Chips } from "../../Chips";
import { ExpansionPanel } from "../../ExpansionPanel";
import { PageEditorRenderOptions } from "../../PageEditor/PageEditor";
import { Column } from "../../Structure";
import { Typography } from "../../Typography";
import { APP_TRANSLATIONS } from "../../../locale";

export interface TagsProps extends PageEditorRenderOptions {

}

const I18N_NAMESPACE = I18N_KEY_CORE_COMPONENTS_PAGE_EDITOR

class TagsComponent extends CorePureComponent<TagsProps & WithI18nProps> {

    public render() {
        const { t, data, setData } = this.props

        return (
            <ExpansionPanel
                isExpanded={true}
                summary={(
                    <Typography.RegularText>
                        <Trans id={`${I18N_NAMESPACE}.widgets.tags.title`} />
                    </Typography.RegularText>
                )}
                >
                    <Column>
                        <Chips
                            placeholder={t(`${I18N_NAMESPACE}.widgets.tags.placeholder`)}
                            fetchOptions={this.fetchAutoComplete}
                            checkedOptions={data?.tags || []}
                            handleItem={(item: string) => {
                                setData({
                                    ...data,
                                    tags:
                                        !data || !data.tags
                                            ? [item]
                                            : data.tags.findIndex((i: any) => i === item) < 0
                                                ? [...data.tags, item]
                                                : data.tags.filter((i: any) => i !== item),
                                });
                            }}
                        />
                    </Column>
            </ExpansionPanel>
        )
    }

    private fetchAutoComplete = async (query: string) => {
        const { language } = this.props

        const request = new TagsGetByTextInput();

        request.body = {
            q: query,
            locale: language,
        };

        const response = (await this.fw.dlo.call(request)) as TagsGetByTextOutput;

        return response.body.map(tag => tag.text);
    }
}

export const Tags = compose<ComponentClass<TagsProps>>(
    withI18n(APP_TRANSLATIONS),
)(TagsComponent)