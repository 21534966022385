import React, { PureComponent } from "react"
import classNames from "classnames";
import styled, { css } from "styled-components";
import { GridGutter, GridColumns } from "../../../constants";
import { queries } from "../../../styles/variables";

export interface ColumnProps {
    /** className */
    className?: string;
    /** gutter */
    gutter?: number;
    /** column properties */
    xs?: number | "auto" | "full";
    sm?: number | "auto" | "full";
    md?: number | "auto" | "full";
    lg?: number | "auto" | "full";
    xl?: number | "auto" | "full";
    xsOffset?: number;
    smOffset?: number;
    mdOffset?: number;
    lgOffset?: number;
    xlOffset?: number;
    contentAlign?: "left" | "right" | "center";
    verticalAlign?: "center";
}

/**
 *
 * It returns the max width or margin left (offset) to each breakingpoint sent.
 *
 * @param breakingPoint
 *
 */
function createBreakingPoint(breakingPoint: string) {
    let styles: string = "";

    for (let index = 1; index <= GridColumns; index++) {
        styles += `
            &.is-${breakingPoint}-${index} {
                width: ${(index / GridColumns) * 100}%;
            }
        `;
    }

    styles += `
        &.is-${breakingPoint}-auto {
            width: auto;
            white-space: nowrap;
        }

        &.is-${breakingPoint}-full {
            flex: 1;
        }
    `

    return css`
        ${styles}
    `;
}

function createBreakingPointOffset(breakingPoint: string) {
    let styles: string = "";

    for (let index = 1; index <= GridColumns; index++) {
        styles += `
            &.is-${breakingPoint}-offset-${index} {
                margin-left: ${(index / GridColumns) * 100}%;
            }
        `;
    }

    return css`
        ${styles}
    `;
}

const StyledColumn = styled.div<ColumnProps>`
    padding: 0 ${(props: ColumnProps) => props.gutter && props.gutter / 2}px;
    position: relative;
    display: inline-block;
    width: 100%;

    ${(props: ColumnProps) =>
        props.xs &&
        `
            ${queries.minXS} {
                ${createBreakingPoint("xs")}
            }
        `}

    ${(props: ColumnProps) =>
        props.xsOffset &&
        `
            ${queries.minXS} {
                ${createBreakingPointOffset("xs")}
            }
        `}

    ${(props: ColumnProps) =>
        props.sm &&
        `
        ${queries.minSM} {
            ${createBreakingPoint("sm")}
        }
    `}

    ${(props: ColumnProps) =>
        props.smOffset &&
        `
            ${queries.minSM} {
                ${createBreakingPointOffset("sm")}
            }
        `}

    ${(props: ColumnProps) =>
        props.md &&
        `
        ${queries.minMD} {
            ${createBreakingPoint("md")}
        }
    `}

    ${(props: ColumnProps) =>
        props.mdOffset &&
        `
            ${queries.minMD} {
                ${createBreakingPointOffset("md")}
            }
        `}

    ${(props: ColumnProps) =>
        props.lg &&
        `
        ${queries.minLG} {
            ${createBreakingPoint("lg")}
        }
    `}

    ${(props: ColumnProps) =>
        props.lgOffset &&
        `
            ${queries.minLG} {
                ${createBreakingPointOffset("lg")}
            }
        `}

    ${(props: ColumnProps) =>
        props.xl &&
        `
        ${queries.minXL} {
            ${createBreakingPoint("xl")}
        }
    `}

    ${(props: ColumnProps) =>
        props.xlOffset &&
        `
            ${queries.minXL} {
                ${createBreakingPointOffset("xl")}
            }
        `}

    ${({ contentAlign }: ColumnProps) =>
        contentAlign && css`
            text-align: ${contentAlign};

            ${contentAlign === "right" && css`
                justify-content: flex-end;
            `}

            ${contentAlign === "left" && css`
                justify-content: flex-start;
            `}

            ${contentAlign === "center" && css`
                justify-content: center;
            `}
        `
    }

    ${({ verticalAlign }: ColumnProps) =>
        verticalAlign && css`
            display: flex;

            ${verticalAlign === "center" && css`
                align-items: center;
            `}
        `
    }
`;

/**
 *
 * # Column Component
 *
 */
export class Column extends PureComponent<ColumnProps> {
    public static defaultProps = {
        gutter: GridGutter,
    };

    public getClassNames = (props: ColumnProps) => {
        return classNames(
            props.className,
            props.xs && `is-xs-${props.xs}`,
            props.sm && `is-sm-${props.sm}`,
            props.md && `is-md-${props.md}`,
            props.lg && `is-lg-${props.lg}`,
            props.xl && `is-xl-${props.xl}`,
            props.xsOffset && `is-xs-offset-${props.xsOffset}`,
            props.smOffset && `is-sm-offset-${props.smOffset}`,
            props.mdOffset && `is-md-offset-${props.mdOffset}`,
            props.lgOffset && `is-lg-offset-${props.lgOffset}`,
            props.xlOffset && `is-xl-offset-${props.xlOffset}`
        );
    };

    public render() {
        return (
            <StyledColumn
                {...this.props}
                gutter={GridGutter}
                className={this.getClassNames(this.props)}
            >
                {this.props.children}
            </StyledColumn>
        );
    }
}
