import React, { PureComponent } from "react";
import styled from "styled-components";
import { Link as CoreLink, LinkProps as LinkCoreProps } from "@7egend/web.core.controls/lib/components/link"
import { font, colors } from "../../styles"

interface LinkProps extends React.PropsWithChildren<LinkCoreProps> {
    className?: string
}

const StyledLink = styled(CoreLink)`
    & {
        display: inline-flex;
        align-items: center;
        flex-direction: row;
        text-decoration: none;
        font-size: ${font.size.sm};
        font-weight: ${font.weight.light};
        color: ${colors.neutral.dark};
        transition: all .1s ease-in-out;

        &:hover {
            color: ${colors.neutral.darker};
        }
    }
`

/**
 * # Link Component
 *
 * This component uses directly the Link Component from the web.core.controls
 *
 */
export class Link extends PureComponent<LinkProps> {
    public render() {
        return (
            <StyledLink
                {...this.props}
            >
                {this.props.children}
            </StyledLink>
        )
    }
}
