import {Store} from "@7egend/web.core"
import { ShopState } from "./state";

import * as countries from "./countries"

export function services(state: ShopState = {} as any, action: Store.Action): ShopState {
  return {
    countries: countries.reducer.root(state.countries, action),
  }
}
