import React from "react";
import { CoreComponent } from "../../base";
import { DloInput, DloOutput } from "../../extensions/dlo";
import { StoreDataNode } from "../../base/store";

declare var process: any;

/**
 * State
 */
interface State<T, C> extends StoreDataNode<T, C> { }

/**
 * Props injected for other components
 */
export interface WithDloServiceProps<T, C = {}> {
    /**
     * Request data property.
     * Contains everything needed to manage the request
     */
    dloRequest: State<T, C>

    /**
     * Executes the given request
     * @param request Request
     */
    executeDloRequest: (request: DloInput, context?: C) => Promise<DloOutput<T> | undefined>
}

/**
 * HOC that handles requests for DLOs.
 * Only supports 1 (one) request per instance.
 * @param WrappedComponent Wrapped component
 */
export const withDloService = <P extends WithDloServiceProps<T, C>, T = any, C = {}>(WrappedComponent: React.ComponentType<P>): React.ComponentType<Omit<P, keyof WithDloServiceProps<T, C>>> => {

    class WithComponent extends CoreComponent<Omit<P, keyof WithDloServiceProps<T, C>>, State<T, C>> {

        public state: State<T, C> = {}

        public render() {
            return (
                <WrappedComponent
                    {...this.props as any}
                    dloRequest={this.state}
                    executeDloRequest={this.executeDloRequest}
                />
            )
        }

        private executeDloRequest = async (request: DloInput, context?: C): Promise<DloOutput<T> | undefined> => {
            // Update the state
            await this.setStateAsync({
                loading: true,
                lastUpdate: new Date(),
                context,
            })

            // Execute request
            try {
                const result = await this.framework.dlo.call(request) as DloOutput<T>
                await this.setStateAsync({
                    loaded: true,
                    loading: false,
                    data: result.body,
                    metadata: result.headers,
                    lastUpdate: new Date(),
                })
                return result
            } catch (err) {
                await this.setStateAsync({
                    loaded: true,
                    loading: false,
                    error: err,
                    lastUpdate: new Date(),
                })
                throw err
            }
        }

    }

    if (process.env.NODE_ENV !== "production") {
        (WithComponent as React.ComponentClass<any>).displayName = `withDloService(${WrappedComponent.displayName || WrappedComponent.name})`
    }

    return WithComponent
}
