import {
    DloInput,
    buildQLQuery,
    DloOutput,
    QueryQLParams,
} from "@7egend/web.core/lib/extensions/dlo";
import { Album as AlbumCore, Medium } from "@7egend/web.core.media/lib/dlos";
import { Visibility } from "../core/channel";

interface CategoriesInterface {
    id: string;
    name: string;
}

interface AlbumInputParams extends Visibility {
    /** album title */
    title: string;
    /** all media */
    media?: Medium[];
    /** album categories */
    categories?: string[];
    /** album tags */
    tags: string[];
    /** album origin */
    origin: number;
    /** current language */
    locale: string;
}

export interface Album extends AlbumCore, Visibility {
    categories: CategoriesInterface[];
    tags: string[];
}

const ALBUM_FIELDS: QueryQLParams = [
    "uuid",
    "title",
    "description",
    "tags",
    ["cover", ["uuid", "medium_type", "image", "thumb", "url", "panoramic"]],
    ["categories", ["id", "name"]],
];

/**
 *
 * # Albums Get All Datatable <Input>
 *
 * It returns all the albums
 */
export class AlbunsGetAllDatatableInput extends DloInput<{
    length: number;
    start: number;
}> {
    public timeStamp = Date.now();
    public authentication = true;
    public uri =
        "albums/datatables?draw=${timeStamp}&order[0][column]=0&order[0][dir]=desc";
}

/**
 * # Album Create <Input>
 */
export class AlbumCreateInput extends DloInput<AlbumInputParams> {
    public method = "POST";
    public uri = "albums";
    public fields = buildQLQuery(ALBUM_FIELDS);
    public transform = (input: any) => {
        input.body.fields = input.fields;
        return input;
    };
}

/**
 * # Album Update <Input>
 */
export class AlbumUpdateInput extends DloInput<
    AlbumInputParams & {
        uuid: string;
    }
> {
    public method = "PUT";
    public uri = "albums/${uuid}";
    public fields = buildQLQuery(ALBUM_FIELDS);
    public transform = (input: any) => {
        input.body.fields = input.fields;
        return input;
    };
}

const AlbumGetBySlugInputFields: QueryQLParams =
    ["uuid", "title", "date", "description", "items_count", "tags", "tag_relations",
        ["categories",
            ["id", "name"]
        ],
        ["visibility",
            ["app", "website"]
        ],
        ["media",
            ["uuid", "image", "thumb", "medium_type", "url", "panoramic"]
        ],
        ["cover",
            ["uuid", "image", "thumb"]
        ],
    ];

export class AlbumGetBySlugInput extends DloInput<{
    slug: string;
    locale: string;
}> {
    public uri = "albums/${slug}/public?fields=${fields}";
    public fields = buildQLQuery(AlbumGetBySlugInputFields);
}

/**
 * # Delete Album <input>
 */
export class AlbumsDeleteInput extends DloInput<{
    /** uuid */
    uuid: string;
}> {
    public method = "DELETE";
    public uri = "albums/${uuid}";
}

/** Album Create <Output> */
export class AlbumCreateOutput extends DloOutput<Album> { }
