import styled from "styled-components"
import { colors } from "../../../../styles"

const Picker = styled.div`
    border-radius: 10px;
    overflow: hidden;
`

const Placeholder = styled.div`
    display: flex;
    align-items: center;
    background-color: ${colors.neutral.lighter};
    padding: 16px;
    transition: all .2s ease;

    &:hover {
        background-color: ${colors.neutral.light};
    }

    i {
        margin-right: 10px;
    }
`

const Picked = styled.div`
    display: flex;
    align-items: center;
    padding: 16px;
    justify-content: space-between;
    background-color: ${colors.neutral.darker};
    color: ${colors.light};
`

export const Styles = {
    Picker,
    Placeholder,
    Picked
}
