import React, { PureComponent } from "react";
import { DropdownPosition, DropdownAlign } from "../Dropdown"
import { Styles } from "./styles";
import { CSSProperties } from "styled-components";

export interface DropdownCardProps {
    /** dropdown small */
    small?: boolean;

    /** dropdown position */
    position: DropdownPosition;

    /** dropdown align */
    align?: DropdownAlign;

    /** custom styling */
    styles?: CSSProperties;
}

interface MergedDropdownCardProps extends DropdownCardProps {
    /** on click */
    onClick: (e: React.MouseEvent) => void;
}

export class DropdownCard extends PureComponent<MergedDropdownCardProps> {

    public render() {
        return (
            <Styles.Card
                small={this.props.small}
                position={this.props.position}
                align={this.props.align}
                onClick={this.onClick}
                styles={this.props.styles}
            >{this.props.children}</Styles.Card>
        )
    }

    private onClick = (e: React.MouseEvent) => {
        this.props.onClick(e);
    }
}
