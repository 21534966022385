export const colors = {
    primary: {
        /** 14C0C4 */
        lighter: "#14C0C4",
        /** 009999 */
        light: "#009999",
        /** 006666 */
        medium: "#006666",
        /** 004949 */
        dark: "#004949",
        /** 003333 */
        darker: "#003333",
    },
    semantic: {
        /** DCF6F6 */
        light: "#DCF6F6",
        /** 009999 */
        dark: "#009999",
        /** F45338 */
        error: "#F45338",
        /** FEE5E1 */
        errorLight: "#FEE5E1",
        /** C3422C */
        errorDark: "#C3422C",
        /** FF8C00 */
        warning: "#FF8C00",
        /** FFEED9 */
        warningLight: "#FFEED9",
        /** CC7000 */
        warningDark: "#CC7000",
        /** 445EEF */
        info: "#445EEF",
        /** E3E7FD */
        infoLight: "#E3E7FD",
        /** 364BBF */
        infoDark: "#364BBF",
    },
    neutral: {
        /** F8F8F8 */
        lighter: "#F8F8F8",
        /** E8E8E8 */
        light: "#E8E8E8",
        /** D0D0D0 */
        medium: "#D0D0D0",
        /** B0B0B0 */
        dark: "#B0B0B0",
        /** 181818 */
        darker: "#181818",
    },
    /** FFFFFF */
    light: "#FFFFFF",
    /** 000000 */
    darker: "#000000",
};

export const font = {
    /** Fira Sans */
    primary: "Fira Sans",
    weight: {
        /** weight 300 */
        light: 300,
        /** weight 400 */
        regular: 400,
        /** weight 500 */
        medium: 500,
        /** weight 600 */
        semiBold: 600,
        /** weight 700 */
        bold: 700,
    },
    size: {
        /** 12px */
        xs: "12px",
        /** 14px */
        sm: "14px",
        /** 16px */
        md: "16px",
        /** 24px */
        lg: "24px",
        /** 32px */
        xl: "32px",
        /** 40px */
        xxl: "40px",
    },
};
