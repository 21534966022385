import React, { PureComponent } from "react"
import { InputProps as InputCoreProps } from "@7egend/web.core.controls/lib/components/input"
import { InputDateTime } from "./InputDateTime"
import * as Styles from "./styles"

export interface InputProps extends React.PropsWithChildren<InputCoreProps> {
    /** label text */
    label?: string

    value?: string | number

    /** label info */
    labelInfo?: React.ReactComponentElement<any> | React.ComponentType<any> | Element

    /** on change fn */
    onChange: (value: string, name: string, valid: boolean) => void

    /**
     * # actions
     *
     * Will render the component passed, his action needs to be in the parent component.
     * This component, only renders the component.
     */
    action?: React.ReactComponentElement<any> | React.ComponentType<any> | Element

    /** error message */
    errorMessage?: string;
}

/**
 * # Input Component
 *
 * ## How to use:
 *
 * ```ts
 * <Input
 *      errorMessage={"Error Message"}
 *      action={
 *          <React.Fragent>
 *              <Button icon={this.state.passwordVisible ? "visibility" : "visibility_off"} onClick={this.togglePasswordVisibility} />
 *          </React.Fragent>
 *      }
 *      placeholder="Placeholder"
 *      name="input2"
 *      label="label"
 *      type={this.state.passwordVisible ? "text" : "password"}
 *      onChange={() => { }} />
 * ```
 */
class InputComponent extends PureComponent<InputProps & { forwardedRef: React.Ref<any> }> {

    public render() {
        const { name, type, label, labelInfo, note, action, disabled, errorMessage, className } = this.props
        const Comp = type === "datetime-local" ? InputDateTime : Styles.StyledField

        return (
            <Styles.StyledFieldset disabled={disabled} errorMessage={errorMessage} className={className}>
                <Styles.StyledLabel
                    labelInfo={labelInfo}
                    htmlFor={name}
                >
                    {label}
                    {labelInfo &&
                        <Styles.StyledLabelInfo>{labelInfo}</Styles.StyledLabelInfo>
                    }
                </Styles.StyledLabel>
                <Styles.StyledGroup action={action}>
                    <Comp
                        ref={this.props.forwardedRef}
                        forwardedRef={this.props.forwardedRef}
                        name={this.props.name}
                        type={this.props.type}
                        ariaLabel={this.props.ariaLabel}
                        autoFocus={this.props.autoFocus}
                        disabled={this.props.disabled}
                        max={this.props.max}
                        maxLength={this.props.maxLength}
                        min={this.props.min}
                        minLength={this.props.minLength}
                        note={this.props.note}
                        onChange={this.props.onChange}
                        onKeyDown={this.props.onKeyDown}
                        pattern={this.props.pattern}
                        placeholder={this.props.placeholder}
                        required={this.props.required}
                        value={this.props.value}
                    />

                    {action &&
                        <Styles.StyledAction>
                            {action}
                        </Styles.StyledAction>
                    }
                    <Styles.StyledErrorMessage>{errorMessage && errorMessage}</Styles.StyledErrorMessage>
                </Styles.StyledGroup>
                {note &&
                    <Styles.StyledNotes>{note}</Styles.StyledNotes>
                }
            </Styles.StyledFieldset>
        )
    }
}

export const Input = React.forwardRef<InputComponent, InputProps>((props, ref) => (
    <InputComponent {...props as any} forwardedRef={ref} />
))
