import { BaseModel } from "@7egend/web.core.dlos/lib/dlos/base";
import { User } from "@7egend/web.core.people/lib/dlos/user";
import { Role } from "./role";
import { DloInput, DloOutput, removeEmptyValues, flattenComplexValues, resetValues } from "@7egend/web.core/lib/extensions/dlo";

const USER_FIELDS = "user(uuid,id,username,profile(thumb),first_name,last_name,phone,phone_country(id,name,phone_code),city,supporters_club(id,name),country(id,name,phone_code),birthday)";
const SESSION_BASE_FIELDS = `expires_at,access_token,${USER_FIELDS}`

export interface Session extends BaseModel {
    user: User;
    roles: Role[];
    access_token: string;
    expires_at: string;
    secret: string;
}

export class SessionGetInput extends DloInput {
    public uri = "sessions?fields=${fields}";
    public fields = `${SESSION_BASE_FIELDS},roles(id,name)`;
    public authentication = true;
}

export class SessionGetOutput extends DloOutput<Session> { }

export class SessionCreateByKeySecretInput extends DloInput<{
    username: string,
    password: string,
    bundle: string,
    platform: string,
    fields?: string,
    tnc_acceptance?: number,
}> {
    public method = "POST";
    public uri = "sessions?fields=${fields}";
    public fields = `${SESSION_BASE_FIELDS},roles(id,name)`;
    public transform = (input: any) => {
        input.body.fields = input.fields;
        return input;
    }
}

export class SessionCreateBySocialInput extends DloInput<{
    token: string,
    bundle: string,
    platform: string,
    fields?: string,
    tnc_acceptance?: number,
}> {
    public method = "POST";
    public uri = "sessions/${network}?fields=${fields}";
    public fields = `${SESSION_BASE_FIELDS}`;
    public network = "";
    public transform = (input: any) => {
        input.body.fields = input.fields;
        return input;
    }
}

export class SessionCreateMagicLinkInput extends DloInput<{
    email: string,
    source_bundle: string,
    target_bundle: string,
}> {
    public method = "POST";
    public uri = "magiclinks";
}

export class SessionConfirmMagicLinkInput extends DloInput<{
    token?: string,
    code?: string,
    platform: string,
    fields?: string,
    tnc_acceptance?: number,
    source_bundle: string,
    target_bundle: string,
}> {
    public method = "POST";
    public uri = "sessions/magiclink";
    public fields = `${SESSION_BASE_FIELDS}`;
    public transform = (input: any) => {
        input.body.fields = input.fields;
        return input;
    }
}

export class ForgotPasswordInput extends DloInput<{
    username: string,
    source_bundle: string,
    target_bundle: string,
}> {
    public method = "POST";
    public uri = "users/recover";
}

export class UpdateUserPasswordInput extends DloInput<{
    token: string,
    password: string,
}> {
    public method = "POST";
    public uri = "users/password";
}

export class UpdateUserInput extends DloInput<User> {
    public method = "PUT";
    public uri = "users/${uuid}";
    public fields = "uuid,id,username,profile(thumb),first_name,last_name,phone,phone_country(id,name,phone_code),city,supporters_club(id,name),country(id,name,phone_code),birthday"
    public transform = (input: any) => {
        const { body } = input;

        removeEmptyValues(body);
        flattenComplexValues(body, ["country", "phone_country", "supporters_club"])
        resetValues(body, ["country", "phone_country", "supporters_club"], "-1")

        body.fields = input.fields

        // Delete profile
        delete body.profile;

        return input;
    }
}

export class DeleteUserDataInput extends DloInput<{
    uuid: string,
}> {
    public method = "DELETE";
    public uri = "users/${uuid}";
}

export class UpdateUserImageInput extends DloInput<{
    fields: string,
    profile: {
        base64: string,
    },
}> {
    public method = "PUT";
    public uri = "users/${uuid}";
    public uuid?: string;
}

export class SessionCreateByKeySecretOutput extends DloOutput<Session> { }
