const hexToRgb = (hex: string) => {

    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;

    hex = hex.replace(shorthandRegex, (m: string, r: string, g: string, b: string) => {
      return r + r + g + g + b + b;
    });
  
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16),
    } : null;
};

/**
 * convertHexToRgba utility
 * this is to your styled comoponents accept rgba with hex value
 * 
 * @param hex string - Hex of the given color
 * @param alpha string - Alpha/Opacity
 */
export const convertHexToRgba = (hex: string, alpha: string) => {
    const color = hexToRgb(hex);
    
    if (color) {
        return `rgba(${color.r}, ${color.g}, ${color.b}, ${alpha})`;
    }

    return null;
};
