import styled, { css } from "styled-components";
import { font, colors } from "../../styles";
import { StyledIcon, StyledButton } from "../Button/styles"
import { Input as InputCore } from "@7egend/web.core.controls/lib/components/input"

interface FieldsetProps {
    errorMessage?: string
    disabled?: boolean
}

interface InputGroupProps {
    action?: React.ReactComponentElement<any> | React.ComponentType<any> | Element
    labelInfo?: React.ReactComponentElement<any> | React.ComponentType<any> | Element
}


export const StyledLabelInfo = styled.div`
    display: inline-block;
    margin-left: 4px;
    text-transform: none;
    letter-spacing: normal;


    * {
        padding: 0;
        min-height: auto;
    }

    ${StyledIcon} {
        font-size: ${font.size.md} !important;
    }
`

export const StyledLabel = styled.label<InputGroupProps>`
    font-size: ${font.size.xs};
    color: ${colors.neutral.darker};
    font-weight: ${font.weight.semiBold};
    letter-spacing: 1.4px;
    text-transform: uppercase;
    margin: 0;
    padding: 0;

    ${(props: InputGroupProps) => props.labelInfo && css`
        display: flex;
        align-items: center;
    `}
`

export const StyledAction = styled.div`
    position: absolute;
    top: 8px;
    right: 0;
    z-index: 1;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    ${StyledButton}{
        min-height: 1px;
        padding: 0;
    }

    ${StyledIcon} {
        font-size: ${font.size.md} !important;
    }
`

export const StyledErrorMessage = styled.span`
    font-size: ${font.size.xs};
    color: ${colors.semantic.error};
    font-weight: ${font.weight.light};
    line-height: 16px;
    min-height: 16px;
    display: block;
`

export const StyledField = styled(InputCore)`
    & {
        margin: 0;
        padding: 0;
        position: relative;
        padding: 10px 0;
        font-weight: ${font.weight.light};
        font-size: ${font.size.sm};
        color: ${colors.neutral.darker};
        background-color: transparent;
        outline: none;
        border: 0;
        display: block;
        width: 100%;
        border-bottom: 1px solid ${colors.neutral.light};
        transition: border-color .2s ease-in;
        font-family: ${font.primary};
        min-height: 38px;
        
        &[type="date"],
        &[type="datetime-local"] {
            appearance: none;
            z-index: 2;
            
            &::-webkit-clear-button,
            &::-webkit-inner-spin-button {
                display: none;
            }
            
            &::-webkit-calendar-picker-indicator {
                opacity: 0;
                width: 10px;
                height: 10px;
                position: absolute;
                right: 0;
                z-index: 1;
            }
        }


        &::-webkit-input-placeholder {
            color: ${colors.neutral.dark};
        }

        &:-moz-placeholder {
            color: ${colors.neutral.dark};
        }

        &::-moz-placeholder {
            color: ${colors.neutral.dark};
        }

        &:-ms-input-placeholder {
            color: ${colors.neutral.dark};
        }

        &:not(:focus):invalid {
            border-bottom-color: ${colors.semantic.error};

            &[value=""] {
                border-bottom-color: ${colors.semantic.errorLight};
            }
        }
    }
`

export const StyledGroup = styled.div<InputGroupProps>`
    position: relative;
    display: block;
    width: 100%;
    margin: 0;
    padding: 0;

    ${(props: InputGroupProps) => props.action && css`
        ${StyledField} {
            padding-right: 20px;
        }
    `}
`

export const StyledNotes = styled.span`
    margin: 0;
    padding: 0;
`

export const StyledFieldset = styled.fieldset<FieldsetProps>`
    position: relative;
    border: 0;
    padding: 0;
    margin: 0 0 16px;

    ${(props: FieldsetProps) => props.disabled && css`
        * {
            cursor: not-allowed !important;
            pointer-events: none !important;
        }

        ${StyledLabel},
        ${StyledField},
        ${StyledNotes} {
            color: ${colors.neutral.dark};
        }
    `}

    ${(props: FieldsetProps) => !props.disabled && css`
        ${StyledField}:focus,
        ${StyledField}:active {
            border-color: ${colors.primary.lighter};
        }
    `}

    ${(props: FieldsetProps) => props.errorMessage && css`
        ${StyledField} {
            border-color: ${colors.semantic.error};

            &:focus,
            &:active {
                border-color: ${colors.semantic.error} !important;
            }
        }
    `}
`
