import { DloInput } from "@7egend/web.core/lib/extensions/dlo";

/** Groups Get All Input */
export class GroupGetAllInput extends DloInput<{
    locale: string;
}> {
    public timeStamp = Date.now();
    public authentication = true;
    public uri = "groups/tree?locale=${locale}";
}
