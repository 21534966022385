import React, { PureComponent } from "react"
import styled, { css } from "styled-components";
import { font, colors, queries } from "../../styles";
import { Icon } from "../Icon";
import { Pages } from "./interfaces";

/**
 * Styled components
 */
const StyledList = styled.ul`
    list-style: none;
    display: inline-flex;
    align-items: center;

    ${queries.maxSM} {
        margin-top: 16px;
        justify-content: center;
        width: 100%;
    }
`;

const StyledListItem = styled.li<StyledListItemProps>`
    width: 24px;
    height: 24px;
    margin-left: 16px;
    cursor: pointer;

    ${queries.maxSM} {
        margin-left: 6px;
        
        &:first-child {
            margin-left: 0;
        }
    }

    ${(props: StyledListItemProps) =>
        props.isArrow &&
        !props.disabled &&
        css`
            width: 24px;
            display: table;
            border-radius: 50%;
            background-color: ${colors.light};
            transition: color 0.2s ease-in-out,
                background-color 0.2s ease-in-out;

            &:hover {
                background-color: rgba(0, 0, 0, 0.05);
                box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
                color: ${colors.neutral.darker};
                transition: color 0.2s ease-in-out,
                    background-color 0.2s ease-in-out;
            }
        `}

    ${(props: StyledListItemProps) =>
        props.disabled &&
        css`
            cursor: default;

            ${StyledIcon} {
                color: ${colors.neutral.light};
            }
        `}
`;

const StyledIcon = styled(Icon)`
    & {
        font-size: ${font.size.md};
        color: ${colors.neutral.dark};
        line-height: 24px;
        padding: 0;
        padding-right: 4px;
    }
`;

const StyledPage = styled.div<StyledPageProps>`
    text-align: center;
    line-height: 24px;
    border-radius: 50%;
    font-size: ${font.size.xs};
    font-weight: ${font.weight.regular};
    color: ${colors.neutral.dark};
    background-color: ${colors.light};
    transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;

    ${(props: StyledPageProps) =>
        !props.isActive &&
        !props.disabled &&
        css`
            &:hover {
                background-color: rgba(0, 0, 0, 0.05);
                box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
                color: ${colors.neutral.darker};
                transition: color 0.2s ease-in-out,
                    background-color 0.2s ease-in-out;
            }
        `}

    ${(props: StyledPageProps) =>
        props.isActive &&
        css`
            background-color: ${colors.primary.lighter};
            color: ${colors.light};
            transition: color 0.2s ease-in-out,
                background-color 0.2s ease-in-out;
        `}
`;

/**
 * Component's interfaces
 */
interface PaginatorProps {
    pages: Pages;
    selectPage: (page: number) => void;
}

interface StyledListItemProps {
    isArrow?: boolean;
    disabled?: boolean;
}

interface StyledPageProps {
    isActive?: boolean;
    disabled?: boolean;
}

export default class Paginator extends PureComponent<PaginatorProps> {
    private pagination(selected: number, pagesAmount: number) {
        const firstPage: number = 1;
        const lastPage: number = pagesAmount;
        const selectedPage: number = selected;
        let loopStart: number = 0;
        let loopEnd: number = 0;
        const list: Array<
            React.ComponentType<any> | React.StatelessComponent<any> | Element
        > = [];

        list.push(
            <StyledListItem key={firstPage}>
                <StyledPage
                    isActive={firstPage === selected}
                    onClick={() => firstPage !== selected && this.props.selectPage(firstPage)}
                >
                    {firstPage}
                </StyledPage>
            </StyledListItem>
        );

        if (selectedPage >= firstPage + 3 && pagesAmount > 7) {
            // left ellipsis
            list.push(
                <StyledListItem key={firstPage - 1}>
                    <StyledPage disabled>...</StyledPage>
                </StyledListItem>
            );
        }

        if (pagesAmount > 7) {
            if (selectedPage < firstPage + 3) {
                loopStart = firstPage + 1;
                loopEnd = loopStart + 3;
            } else if (
                selectedPage >= firstPage + 3 &&
                selectedPage < lastPage - 4
            ) {
                loopStart = selectedPage - 1;
                loopEnd = selectedPage + 1;
            } else {
                loopStart = lastPage - 4;
                loopEnd = lastPage - 1;
            }
        } else {
            loopStart = firstPage + 1;
            loopEnd = lastPage - 1;
        }

        for (let i = loopStart; i <= loopEnd; i++) {
            if (i !== firstPage && i !== lastPage) {
                list.push(
                    <StyledListItem key={i}>
                        <StyledPage
                            isActive={i === selected}
                            onClick={() => i !== selected && this.props.selectPage(i)}
                        >
                            {i}
                        </StyledPage>
                    </StyledListItem>
                );
            }
        }

        if (selectedPage < lastPage - 4 && pagesAmount > 7) {
            // right ellipsis
            list.push(
                <StyledListItem key={lastPage + 1}>
                    <StyledPage disabled>...</StyledPage>
                </StyledListItem>
            );
        }

        if (lastPage > 1) {
            list.push(
                <StyledListItem key={lastPage}>
                    <StyledPage
                        isActive={lastPage === selected}
                        onClick={() => lastPage !== selected && this.props.selectPage(lastPage)}
                    >
                        {lastPage}
                    </StyledPage>
                </StyledListItem>
            );
        }

        return list;
    }

    public render() {
        const { length, start, total } = this.props.pages;

        if (!total) {
            return null;
        }

        const pagesAmount: number =
            total <= length ? 1 : Math.ceil(total / length);

        return (
            <StyledList>
                {start === 1 ? (
                    <StyledListItem isArrow disabled>
                        <StyledIcon icon="keyboard_arrow_left" />
                    </StyledListItem>
                ) : (
                        <StyledListItem
                            isArrow
                            onClick={() => this.props.selectPage(start - 1)}
                        >
                            <StyledIcon icon="keyboard_arrow_left" />
                        </StyledListItem>
                    )}
                {this.pagination(start, pagesAmount)}
                {start === pagesAmount ? (
                    <StyledListItem isArrow disabled>
                        <StyledIcon icon="keyboard_arrow_right" />
                    </StyledListItem>
                ) : (
                        <StyledListItem
                            isArrow
                            onClick={() => this.props.selectPage(start + 1)}
                        >
                            <StyledIcon icon="keyboard_arrow_right" />
                        </StyledListItem>
                    )}
            </StyledList>
        );
    }
}
