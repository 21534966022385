import React, { PureComponent } from "react";
import { Image as BaseImage } from "../../../../Image";
import { PLACEHOLDER_IMAGE } from "../index"
import { StyledMedialaceholder } from "../styles";

/**
 * 
 * # Image Placeholder
 * 
 */
export class Image extends PureComponent {
    public render() {
        return (
            <StyledMedialaceholder>
                <BaseImage src={PLACEHOLDER_IMAGE} />
            </StyledMedialaceholder>
        )
    }
}
