import React, { PureComponent } from "react"
import styled, { css } from "styled-components";
import { font, colors } from "../../../styles";
import { TypographyProps } from "../interface"

export interface BoldTextProps extends React.PropsWithChildren<TypographyProps> { }

const StyledBoldText = styled.h6<BoldTextProps>`
    font-size: ${font.size.sm};
    font-weight: ${font.weight.medium};

    ${(props: BoldTextProps) => props.variant === "regular" && css`
        color: ${colors.neutral.darker};
    `}

    ${(props: BoldTextProps) => props.variant === "primary" && css`
        color: ${colors.primary.lighter};
    `}

    ${(props: BoldTextProps) => props.variant === "neutral" && css`
        color: ${colors.neutral.dark};
    `}

    ${(props: BoldTextProps) => props.variant === "error" && css`
        color: ${colors.semantic.error};
    `}

    ${(props: BoldTextProps) => props.variant === "warning" && css`
        color: ${colors.semantic.warning};
    `}
`

export class BoldText extends PureComponent<BoldTextProps> {
    public render() {
        return (
            <StyledBoldText
                variant={this.props.variant}
                className={this.props.className}
            >
                {this.props.children}
            </StyledBoldText>
        )
    }
}
