import { Module } from "../../../../bootstrap"
import loadable from "@loadable/component"

export const profileSetup: Module = {
    routes: [
        {
            component: loadable(async () => (await import('./Profile')).Profile),
            path: "/profile",
            exact: true,
        },
    ],
    menus: [
        {
            title: "Profile",
            path: "profile",
            visible: false,
            permission: "profile"
        }
    ]
}
