import { Module } from "../../../bootstrap"
import loadable from "@loadable/component"
import system from "../../../assets/menuIcons/system.svg";

export const systemSetup: Module = {
    menus: [
        {
            title: "System",
            path: "system",
            icon: system,
            permission: "system",
            children: [
                {
                    title: "Users",
                    path: "system/users",
                    permission: "system.users",
                    children: [
                        {
                            title: "Create New User",
                            path: "system/users/add",
                            visible: false,
                            permission: "system.users.add",
                        },
                        {
                            title: "Edit User",
                            path: "system/users/edit",
                            visible: false,
                            permission: "system.users.edit",
                        }
                    ]
                },
            ],
        },
    ],
    routes: [
        {
            component: loadable(async () => (await import('./User/UserList/UserList')).UserList),
            path: "/system/users",
            exact: true,
        },
        {
            component: loadable(async () => (await import('./User/UserAccount/UserAccount')).UserAccount),
            path: "/system/users/add",
        },
        {
            component: loadable(async () => (await import('./User/UserAccount/UserAccount')).UserAccount),
            path: "/system/users/edit/:id",
        },
    ]
}
