import React, { PureComponent } from "react"
import styled, { css } from "styled-components";
import { font, colors } from "../../../styles";
import { TypographyProps } from "../interface"

export interface MainTitleProps extends React.PropsWithChildren<TypographyProps> { }

const StyledMainTitle = styled.h3<MainTitleProps>`
    font-size: ${font.size.lg};
    font-weight: ${font.weight.light};

    ${(props: MainTitleProps) => props.variant === "regular" && css`
        color: ${colors.neutral.darker};
    `}

    ${(props: MainTitleProps) => props.variant === "primary" && css`
        color: ${colors.primary.lighter};
    `}

    ${(props: MainTitleProps) => props.variant === "neutral" && css`
        color: ${colors.neutral.dark};
    `}
`

export class MainTitle extends PureComponent<MainTitleProps> {
    public render() {
        return (
            <StyledMainTitle variant={this.props.variant}>
                {this.props.children}
            </StyledMainTitle>
        )
    }
}
