import { Module } from "../../../bootstrap";
import loadable from "@loadable/component";

export { DynamicPages } from "./DynamicPages";
export { PagesPicker } from "./Picker";

export const pagesSetup: Module = {
    routes: [
        // Pages List
        {
            component: loadable(async () => (await import('./DynamicPages')).DynamicPages),
            path: "/cms/dynamic-pages",
            exact: true,
        },
        // Pages Create
        {
            component: loadable(async () => (await import('./DynamicPageEditor')).DynamicPageEditor),
            path: "/cms/dynamic-pages/add",
        },
        // Pages Update
        {
            component: loadable(async () => (await import('./DynamicPageEditor')).DynamicPageEditor),
            path: "/cms/dynamic-pages/edit/:id",
        },
    ],
    menus: [
        {
            title: {
                key: "cms.pages.menu.title",
                en: "Pages",
                es: "Páginas",
                pt: "Páginas",
            },
            path: "cms/dynamic-pages",
            permission: "cms.dynamic-pages.list",
            children: [
                {
                    title: {
                        key: "cms.pages.create.menu.title",
                        en: "Create Pages",
                        es: "Crear Páginas",
                        pt: "Crio Páginas",
                    },
                    path: "cms/dynamic-pages/add",
                    visible: false,
                    permission: "cms.dynamic-pages.add",
                },
                {
                    title: {
                        key: "cms.pages.edit.menu.title",
                        en: "Edit Pages",
                        es: "Editar Páginas",
                        pt: "Editar Páginas",
                    },
                    path: "cms/dynamic-pages/edit",
                    visible: false,
                    permission: "cms.dynamic-pages.edit",
                },
            ],
        },
    ]
}
