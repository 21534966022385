import React, { PureComponent } from "react";
import styled from "styled-components";
import ContentLoader from "react-content-loader";
import { Column } from "../../Structure";

const StyledPlaceholder = styled.div`
    width: 100%;
    padding-bottom: 100%;
    position: relative;
    overflow: hidden;
    margin-bottom: 24px;
    border-radius: 10px;
`;

const StyledContentLoader = styled(ContentLoader)`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
`;

export class MediaListPlaceholder extends PureComponent {
    public render() {
        const line = {
            x: 0,
            y: 0,
            width: 400,
            height: "100%",
            radius: 2,
        };
        const content = [];
        for (let i = 0; i < 10; i++) {
            content.push(
                <Column key={i} xs={4} sm={3} xl={2}>
                    <StyledPlaceholder>
                        <StyledContentLoader>
                            <rect
                                x={line.x}
                                y={line.y}
                                rx={line.radius}
                                ry={line.radius}
                                width={line.width}
                                height={line.height}
                            />
                        </StyledContentLoader>
                    </StyledPlaceholder>
                </Column>
            )
        }
        return content;
    }
}
