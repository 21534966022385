import React from "react";
import { CTA as CTADLO } from "@7egend/web.core.cms/lib/dlos/cta";
import { Block } from "@7egend/web.core.cms/lib/dlos/block";
import { BlockComponentProps } from "../../interfaces";
import styled from "styled-components";
import { Input } from "../../../Input";
import { colors } from "../../../../styles";
import { Column, Row } from "../../../Structure";
import { Button as ButtonComponent } from "../../../Button";
import { Select } from "../../../Select";
import { Wrapper as WrapperComponent } from "../../../Wrapper";
import { SelectOption } from "@7egend/web.core.controls/lib/components/select";
import { withI18n, WithI18nProps } from "@7egend/web.core/lib/components/withI18n";
import { APP_TRANSLATIONS } from "../../../../locale";
import { blockTypes } from "../config";
import { I18N_KEY_CMS_COMPONENTS_BLOCK } from "../../../../base/i18n";

const { container, ctaList, cta } = blockTypes;

const Wrapper = styled.div`
    display: grid;
    border: 1px solid ${colors.neutral.light};
    border-radius: 6px;
    padding: 16px;
    background-color: ${colors.neutral.lighter};

    &::after {
        content: "";
        width: 100%;
        display: block;
        white-space: nowrap;
        clear: both;
    }
`;

const Content = styled(WrapperComponent)``;

const StyledButton = styled(ButtonComponent)`
    justify-self: flex-end;
`;

const Structure = {
    type: container.type,
    content: {
        style: ctaList.style,
        blocks: [{
            type: cta.type,
            content: {
                style: cta.style
            }
        }] as Array<Block<CTADLO>>
    }
} as any;

/** update data of each block */
const updateCTA = (valuesList: any, name: string, value: string, i: number) => {
    const newValuesList = [...valuesList];
    newValuesList[i].content[name] = value;
    return newValuesList[i].content[name];
};

/** update CTA properties */
const updateProperties = (valuesList: any, name: string, value: string, i: number) => {
    const newValuesList = [...valuesList];

    Object.assign(newValuesList[i], {
        properties: {
            [name]: value
        }
    })

    return newValuesList;
};

const removeItem = (valuesList: any, i: number) => {
    const updatedList = [
        ...valuesList.slice(0, i),
        ...valuesList.slice(i + 1),
    ];

    return updatedList;
};

const addItem = (valuesList: any) => {
    const newvaluesList = [...valuesList];
    newvaluesList.push(
        {
            type: cta.type,
            properties: {
                anchor_target: "1",
            } as any,
            content: {
                style: cta.style,
            }
        }
    );

    return newvaluesList;
};

const I18N_NAMESPACE = `${I18N_KEY_CMS_COMPONENTS_BLOCK}.types.cta`

const CTAListComponent: React.FC<WithI18nProps & BlockComponentProps<any>> = ({ className, data, updateData, t }) => {

    const CTAOptions: SelectOption[] = [
        {
            value: "1",
            label: t(`${I18N_NAMESPACE}.inputCTALinkSamePage`),
            option: {
                value: "1"
            }
        },
        {
            value: "2",
            label: t(`${I18N_NAMESPACE}.inputCTALinkNewPage`),
            option: {
                value: "2"
            }
        }
    ];

    if (!data.content.blocks || data.content.blocks.length === 0) {
        return null
    }

    return (
        <Wrapper className={className}>
            {data.content.blocks.map((eachCTA: Block<CTADLO>, index: number) => (
                <React.Fragment key={index}>
                    {data.content.blocks.length > 1 &&
                        <StyledButton
                            icon={"delete"}
                            onClick={() => updateData({
                                ...data,
                                content: {
                                    ...data.content,
                                    blocks: removeItem(data.content.blocks, index)
                                }
                            })}
                        />
                    }
                    <Content>
                        <Row>
                            <Column sm={6}>
                                <Input
                                    name="label"
                                    type="text"
                                    label={t(`${I18N_NAMESPACE}.inputCTALabel`)}
                                    placeholder={t(`${I18N_NAMESPACE}.inputCTAPlaceholder`)}
                                    value={eachCTA.content && eachCTA.content.label}
                                    onChange={(label: string, name: string) => updateData({
                                        ...data,
                                        content: {
                                            ...data.content,
                                            label: updateCTA(data.content.blocks, name, label, index)
                                        }
                                    })}
                                />
                            </Column>
                            <Column sm={6}>
                                <Select
                                    name="anchor_target"
                                    label={t(`${I18N_NAMESPACE}.inputCTALink`)}
                                    options={CTAOptions}
                                    value={eachCTA.properties && eachCTA.properties.anchor_target || eachCTA.content && eachCTA.content.anchor_target && eachCTA.content.anchor_target.id}
                                    onChange={(target: string, name: string) => updateData({
                                        ...data,
                                        properties: {
                                            ...data.properties,
                                            anchor_target: updateProperties(data.content.blocks, name, target, index),
                                        }
                                    })}
                                />
                            </Column>
                        </Row>
                        <Input
                            name="value"
                            type="text"
                            label={t(`${I18N_NAMESPACE}.inputLinkLabel`)}
                            placeholder={t(`${I18N_NAMESPACE}.inputLinkPlaceholder`)}
                            value={eachCTA.content && eachCTA.content.value}
                            onChange={(url: string, name: string) => updateData({
                                ...data,
                                content: {
                                    ...data.content,
                                    value: updateCTA(data.content.blocks, name, url, index)
                                }
                            })}
                        />
                    </Content>
                </React.Fragment>
            ))}
            <StyledButton
                text={t(`${I18N_NAMESPACE}.list.addNewItem`)}
                icon={"add"}
                layoutIconPosition={"left"}
                onClick={() => updateData({
                    ...data,
                    content: {
                        ...data.content,
                        blocks: addItem(data.content.blocks)
                    }
                })}
            />
        </Wrapper>
    );
}

const Component = withI18n(APP_TRANSLATIONS)(CTAListComponent);

const Button = {
    icon: "accessibility",
    name: {
        key: `${I18N_NAMESPACE}.list.name`,
        en: "CTA List",
        es: "Lista CTA",
        pt: "Lista CTA",
    },
};

export const CTAList = {
    key: `${ctaList.type}_${ctaList.style}`,
    Structure,
    Component,
    Button,
};
