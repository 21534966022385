import { PureComponent } from "../../base";
import { withI18n, WithI18nProps } from "../withI18n";

/**
 * This comes directly from Trans component
 */
export interface TransProps {
  id?: string;
  values?: { [key: string]: string | number };
}

/**
 * # Trans Component
 * Translates a given string ID.
 * Please follow this issue for new updates: https://github.com/lingui/js-lingui/issues/400
 * Based on: https://github.com/yahoo/react-intl/blob/763f3fcbf87abc1a2a1021f684217f56bf8d4581/src/components/html-message.js#L86
 *
 * ## Examples
 * ```HTML
 * <Trans id="my.translation" values="{...}" />
 * ```
 */
class TransComponent extends PureComponent<TransProps & WithI18nProps> {
  public render() {
    const { id, values, t } = this.props;

    if (!id) {
      return null;
    }

    const translation = t(id, values as any);
    return translation;
  }
}

export const Trans = withI18n()(TransComponent);
