import { Extension, Ground  } from "@7egend/web.ground/lib/ground";
import { Storage } from "@7egend/web.ground/lib/domain/extensions/storage";
import { Config } from "../../base/config";

export class AuthExtension implements Extension {

    //#region Static properties
    public static readonly STORAGE_KEY = "__auth";
    public static readonly STORAGE_KEY_TOKEN = `${AuthExtension.STORAGE_KEY}.token`;
    public static readonly STORAGE_KEY_USER_UUID = `${AuthExtension.STORAGE_KEY}.user.uuid`;
    //#endregion

    public name: string = "auth";

    //#region Private properties

    private storage: Storage = null as any;

    //#endregion

    public initialize(config: Config, app: Ground): void {
        this.storage = app.framework.localStorage;

        // Setup HTTP processors
        app.framework.http.registerPreProcessor(async (options) => {
            // Check for authentication and inject header
            if (options.authentication !== false && await this.isAuthenticated()) {
                options.headers = options.headers || {};
                options.headers.Authorization = `Bearer ${await this.getAuthToken()}`;
            }

            return options;
        });
    }

    //#region Public methods

    /**
     * Indicates the authentication state
     */
    public isAuthenticated(): boolean {
        return this.storage.has(AuthExtension.STORAGE_KEY_TOKEN);
    }

    /**
     * Gets authorization token
     */
    public getAuthToken(): string {
        return this.storage.get(AuthExtension.STORAGE_KEY_TOKEN);
    }

    /**
     * Sets authorization token
     * @param value token
     */
    public setAuthToken(value: string): boolean {
        return this.storage.set(AuthExtension.STORAGE_KEY_TOKEN, value)
    }

    /**
     * Gets authenticated user
     */
    public getUserUUID(): string {
        return this.storage.get(AuthExtension.STORAGE_KEY_USER_UUID);
    }

    /**
     * Sets authenticated user
     * @param value token
     */
    public setUserUUID(value: string): boolean {
        return this.storage.set(AuthExtension.STORAGE_KEY_USER_UUID, value)
    }

    /**
     * Clears the token from the storage
     */
    public clear(): void {
        this.storage.remove(AuthExtension.STORAGE_KEY_TOKEN);
        this.storage.remove(AuthExtension.STORAGE_KEY_USER_UUID);
    }

    //#endregion

}
