import { Image } from "./Image"
import { ImagePanoramic } from "./ImagePanoramic"
import { Video } from "./Video"
import { Document } from "./Document"
import { MediaList } from "./MediaList"
import { ProgressBar } from "./ProgressBar"
import { DnD } from "./DnD"
import { Stream } from "./Stream"

export { UploaderProps, FileInfo } from "./domain"

/** # Uploader Component */
export const Uploader = {
    /**
     * # Image Uploader 
     */
    Image,
    /**
     * # Panoramic Image Uploader 
     */
    ImagePanoramic,
    /**
     * # Video Uploader 
     */
    Video,
    /**
     * # Document Uploader 
     */
    Document,
    /**
     * # Stream Uploader
     */
    Stream,
    /** 
     * # Media List Modal
     * 
     * ## How to use: 
     * 
     * ```jsx
     * <Uploader.MediaList
     *      checkedMedia={(checkedMedia: MediaListData[]) => void}
     *      label="Upload Thumbnail" 
     * />
     * ```
     */
    MediaList,
    /**
     * # ProgressBar Uploader 
     */
    ProgressBar,
    /**
     * # Drag and drop generic
     */
    DnD,
}
