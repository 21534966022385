import React from "react";
import { PureComponent } from "@7egend/web.core/lib/base/component"

export interface RadioButtonProps {
    /** radio button name */
    name: string;
    /** radio button value */
    value?: string
    /** if radio button if required
     * @default false
     */
    required?: boolean
    /** radio button onchange method */
    onChange: (value: string, name: string, valid: boolean) => void
    /** radio button disabled */
    disabled?: boolean
    /** className */
    className?: string
    /** Accessibility label */
    ariaLabel?: string

    checked?: boolean;
}

/**
 * # radio button Component
 * This class represents the input type radio button component
 *
 * # How to use
 *
 * ````<RadioButtonComponent label="label" id="inputID" />````
 *
 */
export class RadioButton extends PureComponent<RadioButtonProps> {

    public render() {

        const { className, name, value, required, disabled, ariaLabel, checked } = this.props;

        return (
            <input
                type="radio"
                id={`field-${name}-${value}`}
                name={`${name}`}
                checked={checked}
                required={required}
                onChange={this.onChange}
                disabled={disabled}
                className={className}
                aria-label={ariaLabel}
                value={value}
            />
        );
    }

    private onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value: boolean = (e.target as any).checked
        const valid: boolean = (e.target as any).validity.valid

        if (value) {
            this.props.onChange(this.props.value!, this.props.name, valid)
        }
    }
}
