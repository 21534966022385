import React, { PureComponent } from "react"
import styled from "styled-components";
import { Wrapper, WrapperProps } from "../Wrapper";
import { font, colors } from "../../../styles";

/** container props */
export interface WrapperWithTitleProps extends WrapperProps {
    /** container title */
    title:
    | React.ReactComponentElement<any>
    | React.ComponentType<any>
    | JSX.Element
    | string;
}

export const StyledTitle = styled.div`
    font-size: ${font.size.md};
    font-weight: ${font.weight.light};
    color: ${colors.neutral.darker};
`

/**
 * # Container Title Component
 *
 * ## How to use:
 *
 * ```tsx
 *  <WrapperWithTitle title="..." />
 * ```
 *
 * ## Container Props:
 *
 * `title`: element - `{undefined}`
 */
export class WrapperWithTitle extends PureComponent<WrapperWithTitleProps> {
    public render() {
        const { title, children } = this.props;

        // if empty prop
        if (!title) {
            return null;
        }

        return (
            <Wrapper
                header={(
                    <StyledTitle>{title}</StyledTitle>
                )}
                {...this.props}
            >
                {children}
            </Wrapper>
        );
    }
}
