import React from "react";
import { CorePureComponent, ReactDOM } from "@7egend/web.core";
import styled from "styled-components";
import { Snackbar } from "../Snackbar";
import { uniqueId } from "@7egend/web.core/lib/utils";
import { SnackbarLayout, SnackbarProps } from "../Snackbar";

export interface SnackbarData {
    id?: string;
    layout: SnackbarLayout;
    message: string;
    timeout?: number;
}

export interface SnackbarContainerProps {
    //
    className?: string;
}

interface State {
    items: SnackbarData[];
}

const StyledSnackbarContainer = styled.div`
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 101;
`;

/**
 * # Snackbar Container
 *
 * ## How to use:
 *
 * ```tsx
 * private snackBarContainerRef = React.createRef<SnackbarContainer>()
 *
 * <SnackbarContainer ref={snackBarContainerRef} />
 * ```
 */
export class SnackbarContainer extends CorePureComponent<SnackbarProps, State> {

    private readonly document = this.fw.dom.getDocument();

    public state: State = {
        items: [],
    };

    /** Shows a new snackbar */
    public pushMessage(data: SnackbarData) {
        if (!data.id) {
            data.id = uniqueId("snackbar");
        }

        this.setState({
            items: [...this.state.items, data],
        });
    }

    /** Cleans all the snackbars */
    public clearSnackbars() {
        this.state.items = [];
    }

    public render() {
        const { items } = this.state;

        if (items && items.length > 0) {
            return ReactDOM.createPortal(
                <StyledSnackbarContainer className={this.props.className}>
                    {items.map(item => (
                        <Snackbar
                            autoClose={
                                item.timeout
                                    ? {
                                        timeout: item.timeout,
                                    }
                                    : undefined
                            }
                            id={item.id}
                            key={item.id}
                            close={this.closeSnackbar}
                            layout={item.layout}
                        >
                            {item.message}
                        </Snackbar>
                    ))}
                </StyledSnackbarContainer>,
                this.document.body
            );
        }

        return null;
    }

    private closeSnackbar = (item: string) => {
        this.setState({
            items: this.state.items.filter(i => i.id !== item),
        });
    };
}
