import { Module } from "../../../bootstrap"
import loadable from "@loadable/component"

export { Forms } from "./Forms"
export { FormPicker } from "./Picker"

export const formSetup: Module = {
    routes: [
        // Forms list Page
        {
            component: loadable(async () => (await import('./Forms')).Forms),
            path: "/cms/forms",
            exact: true,
        },
        // Forms Update
        {
            component: loadable(async () => (await import('./FormEditor')).FormEditor),
            path: "/cms/forms/edit/:id",
        },
    ],
    menus: [
        {
            title: {
                key: "cms.forms.menu.title",
                en: "Forms",
                es: "Formularios",
                pt: "Formulários",
            },
            path: "cms/forms",
            permission: "cms.forms.list",
            children: [
                {
                    title: {
                        key: "cms.forms.edit.menu.title",
                        en: "Edit Forms",
                        es: "Editar Formularios",
                        pt: "Editar Formulários",
                    },
                    path: "cms/forms/edit",
                    visible: false,
                    permission: "cms.forms.edit",
                },
            ],
        },
    ]
}