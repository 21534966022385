import React, { PureComponent } from "react"
import { memoizeOne } from "@7egend/web.core/lib/utils";
import styled from "styled-components";
import { StyledModule } from "../styles"
import { colors } from "../../../../styles";
import { Link } from "../../../Link";

interface ItemProps {
    name: string | { [locale: string]: string }
    path?: string;
    visible?: boolean;
    active?: string[];
}

const StyledItem = styled.div`
    margin: 10px 0;
    position: relative;
    padding-left: 16px;

    &::before {
        content: "";
        width: 2px;
        height: 2px;
        border-radius: 100%;
        background-color: ${colors.neutral.darker};
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
    }
`;

export class Item extends PureComponent<ItemProps> {
    public render() {
        const isActive = this.isActive(this.props.active);

        if (!this.props.visible || !this.props.path) {
            return null;
        }

        return (
            <StyledItem>
                <StyledModule.Title active={isActive}><Link href={this.props.path}>{this.props.name}</Link></StyledModule.Title>
            </StyledItem>
        )
    }

    private isActive = memoizeOne((active?: string[]) => {
        if (active) {
            return active.some(item => item === this.props.path);
        }
    });
}
