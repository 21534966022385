import styled, { css } from "styled-components";
import { colors } from "../../../styles";
import { Icon } from "../../Icon";
import { Button } from "../../Button";
import { Typography } from "../../Typography";
import { Checkbox as CheckboxComponent } from "../../Checkbox";

interface MediaProps {
    mediaChecked?: boolean | undefined;
    optionsVisible: boolean;
}

/** Thumbnail Header (Title of the thumbnail) */
const Header = styled(Typography.SmallText)`
    & {
        margin: 6px 0 2px;
        word-break: break-all;
    }
`;

/** Thumbnail Sub-Header (sub-title of the thumbnail) */
const SubHeader = styled(Typography.SmallText)`
    & {
        margin: 6px 0 2px;
    }
`;

/** Thumbnail Wrapper */
const Wrapper = styled.div`
    position: relative;
    margin-bottom: 22px;

    ${Header} + ${SubHeader} {
        margin: 2px 0;
    }
`;

/** Thumbnail Image */
const Thumb = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 10px;
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
`;

const MediaIcon = styled(Icon)`
    & {
        position: absolute;
        bottom: 12px;
        right: 12px;
        z-index: 2;
        font-size: 20px;
    }
`;

/** Thumbnail Actions */
const Actions = styled.div<{ isCheckable?: boolean }>`
    position: absolute;
    top: 12px;
    left: 0;
    width: 100%;
    padding: 0 12px;
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: ${({ isCheckable }) =>
        isCheckable === false ? "flex-end" : "space-between"};
`;

/** Checkbox of actions */
const Checkbox = styled(CheckboxComponent)`
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s ease, visibility 0.2s ease;
`;

const OptionsButton = styled(Button)`
    & {
        opacity: 0;
        visibility: hidden;

        &:focus {
            opacity: 1;
            visibility: visible;
        }

        &,
        * {
            font-size: 30px !important;
            padding: 0;
            min-height: 0;
        }
    }
`;

/** Thumbnail Media */
const Media = styled.div<MediaProps>`
    border-radius: 10px;
    position: relative;
    padding-top: 100%;
    width: 100%;
    cursor: pointer;
    z-index: 0;
    background-color: ${colors.neutral.lighter};

    ${({ mediaChecked }) =>
        mediaChecked &&
        css`
            ${Checkbox} {
                opacity: 1;
                visibility: visible;
            }
        `}

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border: 4px solid ${colors.primary.lighter};
        border-radius: 10px;
        z-index: 1;
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.2s ease, visibility 0.2s ease;

        ${({ mediaChecked }) =>
        mediaChecked &&
        css`
                opacity: 1;
                visibility: visible;
            `}
    }

    &::after {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(24, 24, 24, 0.2);
        z-index: 1;
        opacity: 0;
        visibility: hidden;
        border-radius: 10px;
        transition: opacity 0.2s ease, visibility 0.2s ease;
    }

    &:hover {
        &::after {
            opacity: 1;
            visibility: visible;
        }

        ${Thumb} {
            filter: blur(2px);
        }

        ${Checkbox} {
            opacity: 1;
            visibility: visible;
        }

        ${OptionsButton} {
            opacity: 1;
            visibility: visible;
        }
    }

    ${({ optionsVisible }) =>
        optionsVisible &&
        css`
            z-index: 2;
        `}
`;

const TagNew = styled.div`
    position: absolute;
    bottom: 16px;
    left: -5px;
    padding: 4px 8px;
    background-color: ${colors.primary.lighter};
    border-radius: 6px;
    z-index: 2;

    * {
        color: ${colors.light};
    }
`;

export const StyledMedia = {
    Checkbox,
    Wrapper,
    Media,
    Thumb,
    Header,
    SubHeader,
    Actions,
    MediaIcon,
    OptionsButton,
    TagNew,
};
