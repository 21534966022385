import React, { PureComponent } from "react"
import moment from "moment";
import { Medium, MediumType } from "@7egend/web.core.media/lib/dlos";
import { StyledMedia } from "./styles";
import { MediaPlaceholder } from "./MediaPlaceholder";
import { colors } from "../../../styles";
import { Typography } from "../../Typography";
import { Dropdown } from "../../Dropdown";
/**
 * Import this directly from @7egend/web.core.media/lib/dlos
 * @deprecated
 */
export { MediumType };
import { withI18n, WithI18nProps } from "@7egend/web.core/lib/components/withI18n";
import { APP_TRANSLATIONS } from "../../../locale";
import { DateTime } from "../../DateTime";
import { DATE_FORMAT } from "../../../constants";
import { Trans } from "@7egend/web.core/lib/components/i18n";
import { I18N_KEY_CORE_COMPONENTS_MEDIA } from "../../../base/i18n";

const I18N_NAMESPACE = I18N_KEY_CORE_COMPONENTS_MEDIA
const NOW = moment(new Date()); // todays date

/** Media Props */
interface MediaProps extends Medium {
    isChecked: boolean;
    selectMedia: (id: string) => void;
    options:
    | React.ReactComponentElement<any>
    | React.ComponentType<any>
    | Element;
    isAlbum?: boolean;
    isCheckable?: boolean;
    previewMedia?: () => void;
}

interface MediaState {
    optionsVisible: boolean;
}

class MediaComponent extends PureComponent<WithI18nProps & MediaProps, MediaState> {
    public state: MediaState = {
        optionsVisible: false,
    };

    public render() {
        const { title, date, isChecked, options } = this.props;

        return (
            <StyledMedia.Wrapper>
                <StyledMedia.Media
                    onClick={this.previewMedia}
                    mediaChecked={isChecked}
                    optionsVisible={this.state.optionsVisible}
                >
                    <StyledMedia.Actions onClick={(e) => e.stopPropagation()} isCheckable={this.props.isCheckable}>
                        {(this.props.isCheckable ||
                            this.props.isCheckable === undefined) && (
                                <StyledMedia.Checkbox
                                    rounded
                                    lightBorder
                                    value={isChecked}
                                    name={`check-${title}`}
                                    onChange={() => {
                                        if (this.props.id) {
                                            this.props.selectMedia(this.props.id);
                                        }
                                    }}
                                />
                            )}
                        <Dropdown
                            position="bottom"
                            align="right"
                            attachOnBody={true}
                            active={this.state.optionsVisible}
                            onClick={this.toggleOptions}
                            component={
                                <StyledMedia.OptionsButton
                                    layout="light"
                                    icon="more_horiz"
                                />
                            }
                        >
                            <React.Fragment>{options}</React.Fragment>
                        </Dropdown>
                    </StyledMedia.Actions>
                    {this.renderThumb()}
                    {this.renderMediaIcon()}
                    {this.renderTagNew()}
                </StyledMedia.Media>
                <StyledMedia.Header>{title}</StyledMedia.Header>
                {date && (
                    <StyledMedia.SubHeader variant="neutral">
                        <DateTime format={DATE_FORMAT}>
                            {date}
                        </DateTime>
                    </StyledMedia.SubHeader>
                )}
            </StyledMedia.Wrapper>
        );
    }

    private previewMedia = () => {
        if (this.props.previewMedia) {
            if ([MediumType.Image, MediumType.Video, MediumType.Stream, MediumType.File].includes(this.props.medium_type as MediumType) && !this.props.isAlbum) {
                this.props.previewMedia()
            }
        }
    }

    private renderTagNew = () => {
        const { date } = this.props;

        if (date) {
            const mediaDate = moment(date); // another date
            const duration = moment.duration(NOW.diff(mediaDate));
            const diffDays = duration.asDays();

            /** if media is younger than 1 day */
            if (diffDays <= 1) {
                return (
                    <StyledMedia.TagNew>
                        <Typography.SmallText>
                            <Trans id={`${I18N_NAMESPACE}.labelNew`} />
                        </Typography.SmallText>
                    </StyledMedia.TagNew>
                );
            }
        }
    };

    /** It renders the icon according to the media type */
    private renderMediaIcon = () => {
        const disabledImage = !this.props.thumb
            ? colors.neutral.dark
            : undefined;

        if (this.props.isAlbum) {
            return (
                <StyledMedia.MediaIcon
                    color={disabledImage || colors.light}
                    icon="photo_library"
                />
            );
        }

        switch (this.props.medium_type) {
            case MediumType.Image:
                if (this.props.panoramic === "1") {
                    return (
                        <StyledMedia.MediaIcon
                            color={disabledImage || colors.light}
                            icon="panorama_horizontal"
                        />
                    );
                }
                return;
            case MediumType.File:
                return (
                    <StyledMedia.MediaIcon
                        color={colors.neutral.dark}
                        icon="insert_drive_file"
                    />
                );
            case MediumType.Video:
                return (
                    <StyledMedia.MediaIcon
                        color={disabledImage || colors.light}
                        icon="videocam"
                    />
                );
            case MediumType.Stream:
                return (
                    <StyledMedia.MediaIcon
                        color={disabledImage || colors.light}
                        icon="ondemand_video"
                    />
                );
            default:
                break;
        }
    };

    /** It renders the thumbnail */
    private renderThumb = () => {
        // Image
        if (this.props.medium_type === MediumType.Image) {
            if (this.props.thumb) {
                return (
                    <StyledMedia.Thumb
                        style={{
                            backgroundImage: `url(${this.props.thumb})`,
                        }}
                    />
                );
            }

            return this.props.panoramic === "1" ? (
                <MediaPlaceholder.ImagePanoramic />
            ) : (
                <MediaPlaceholder.Image />
            );
        }

        // File
        if (this.props.medium_type === MediumType.File) {
            return <MediaPlaceholder.Document />;
        }
        
        // Video or Stream
        if (this.props.medium_type === MediumType.Video || this.props.medium_type === MediumType.Stream) {
            if (this.props.thumb) {
                return (
                    <StyledMedia.Thumb
                        style={{
                            backgroundImage: `url(${this.props.thumb})`,
                        }}
                    />
                );
            }
            return <MediaPlaceholder.Video />;
        }
    };

    private toggleOptions = () => {
        this.setState({
            optionsVisible: !this.state.optionsVisible,
        });
    };
}

export const Media = withI18n(APP_TRANSLATIONS)(MediaComponent);
