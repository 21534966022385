import { Config } from "../../domain/config";
import { Logger } from "../../domain/extensions/logger";
import { Ground } from "../../domain/ground";
import { Di } from "../../domain/extensions/di";

declare var globalThis: any;
declare var window: any;
export type Container = Map<string | symbol, any>

class KeyValueDi implements Di {

    //#region Public properties

    /** Extension name */
    public name: string = "keyvaluedi";

    /** Parent container */
    public parent?: Di = (globalThis || window as any).$di

    /** Container */
    public container: Container = new Map<any, any>()

    //#endregion

    //#region Private properties

    private logger: Logger = null as any;

    //#endregion

    public initialize(config: Config, app: Ground) {
        this.logger = app.framework.log;
        if ((app as any).container) {
            this.parent = (app as any).container
        }
    }

    public create(parent?: any) {
        if (parent) {
            this.parent = parent
        }

        return this.container
    }

    public bindValue<T>(key: string | symbol, value: T): void {
        this.container.set(key, value);
    }

    public bindClass<T>(key: string | symbol, value: new (...args: any[]) => T): void {
        this.container.set(key, value);
    }

    public get<T>(key: string | symbol): T | undefined {
        return this.container.get(key) as T || (this.parent && this.parent.get(key) as T);
    }

    public set<T>(key: string | symbol, value: T): void {
        this.bindValue(key, value);
    }

    public tryGet<T>(key: string | symbol, fallback?: T): T | undefined {
        try {
            return this.get<T>(key);
        } catch (error) {
            this.logger.warn(`Could not get binding for ${key.toString()}`);
            return fallback;
        }
    }
}

// Create a global DI container
const $di = (globalThis || window as any).$di = new KeyValueDi()
export { $di }

export default KeyValueDi;
