import React, { Fragment } from "react";
import loadable from "@loadable/component";
import { Block } from "@7egend/web.core.cms/lib/dlos/block";
import { Medium, MediumType } from "@7egend/web.core.media/lib/dlos";
import { BlockComponentProps } from "../../interfaces";
import { Uploader } from "../../../Uploader";
import { Typography } from "../../../Typography";
import { MediaListData, MediaList } from "../../../MediaList";
import styled from "styled-components";
import { Select } from "../../../Select";
import { Column, Row } from "../../../Structure";
import { Divider, Space } from "../../../Divider";
import { Button as ButtonComponent } from "../../../Button";
import { Tooltip } from "../../../Tooltip";
import { font, colors } from "../../../../styles";
import { withI18n, WithI18nProps } from "@7egend/web.core/lib/components/withI18n";
import { APP_TRANSLATIONS } from "../../../../locale";
import { blockTypes } from "../config";
import { useFramework } from "@7egend/web.core/lib/components/useFramework";
import { CMSConfig } from "../../../../modules";
import { I18N_KEY_CMS_COMPONENTS_BLOCK, I18N_KEY_GLOBAL } from "../../../../base/i18n";

const { imageAndText } = blockTypes;

const TextEditor = loadable(async () => (await import("../../../TextEditor")).TextEditor);
const InputComponent = loadable(async () => (await import("../../../Input")).Input);

const I18N_NAMESPACE = `${I18N_KEY_CMS_COMPONENTS_BLOCK}.types.imageAndText`

export interface TextImageData {
    style?: string
    image: string,
    title: string,
    subtitle?: string,
    /** text to show on preview mode */
    text_highlight?: string,
    /** textPreview missing text to be complete */
    text: string,
    /** cta text to show on button/link */
    cta_label?: string,
    /** cta description */
    cta_description?: string,
    /** cta_anchor_target_id */
    cta_anchor_target_id?: string,
    /** cta link */
    cta_value?: string,
    /** picture medium */
    picture?: Medium,
    /** picture secondary medium */
    picture_secondary?: Medium
}

const Wrapper = styled.div`
    border: 1px solid ${colors.neutral.light};
    padding: 16px;
    border-radius: 10px;

    @media (max-width: 1500px) {
        .items-aligned .is-xl-4 {
            width: 100%;
        }
    }
`;

const StyledButton = styled(ButtonComponent)`
    padding: 0;
    min-height: 0;
`;

const StyledFlexContent = styled.div`
    display: flex;
    align-items: center;

    h5 {
        margin-right: 4px;    
    }

    i {
        font-size: ${font.size.md};
    }
`;

const TOOLBAR = {
    options: ["inline", "link"]
};

const Structure = {
    type: imageAndText.type,
    content: {
        medium_type: MediumType.Image,
    }
} as Block<any>;

const ImageAndTextComponent: React.FC<WithI18nProps & BlockComponentProps<TextImageData>> = ({ className, data, updateData, t }) => {
    const config = (useFramework().config as CMSConfig).cms?.blocks?.textImage
    const hasMedia1 = !!data.content.picture;
    const hasMedia2 = !!data.content.picture_secondary;

    const SeparatorOptions = [
        {
            value: "1",
            label: t(`${I18N_NAMESPACE}.openPageOptions.samePage`),
            option: {
                value: "1"
            }
        },
        {
            value: "2",
            label: t(`${I18N_NAMESPACE}.openPageOptions.newPage`),
            option: {
                value: "2"
            }
        }
    ];

    const ImageAlignmentOptions = [
        {
            value: "left",
            label: t(`${I18N_NAMESPACE}.imageAlignOptions.left`),
            option: {
                value: "left"
            }
        },
        {
            value: "right",
            label: t(`${I18N_NAMESPACE}.imageAlignOptions.right`),
            option: {
                value: "right"
            }
        }
    ];

    const TextColorOptions = [
        {
            value: "black",
            label: t(`${I18N_NAMESPACE}.textColorOptions.black`),
            option: {
                value: "black"
            }
        },
        {
            value: "white",
            label: t(`${I18N_NAMESPACE}.textColorOptions.white`),
            option: {
                value: "white"
            }
        }
    ];

    return (
        <Wrapper className={className}>
            {config?.styles && (
                <Fragment>
                    <Select
                        name="style"
                        label={t(`${I18N_NAMESPACE}.inputStyleLabel`)}
                        options={config.styles.map(style => ({ label: style.name, value: style.value }))}
                        defaultValue={""}
                        defaultLabel={t(`${I18N_NAMESPACE}.inputStylePlaceholder`)}
                        value={data.content.style}
                        onChange={(value: string) => updateData({
                            ...data,
                            content: {
                                ...data.content,
                                style: value,
                            }
                        })}
                    />
                    <Divider space={Space.Small} />
                </Fragment>
            )}
            <Row>
                <Column xs={6}>
                    <StyledFlexContent>
                        <Typography.SmallCapsTitle>
                            {t(`${I18N_NAMESPACE}.inputDesktopImage`)}
                        </Typography.SmallCapsTitle>
                    </StyledFlexContent>
                </Column>
                <Column xs={6}>
                    <StyledFlexContent>
                        <Typography.SmallCapsTitle>
                            {t(`${I18N_NAMESPACE}.inputMobileImage`)}
                        </Typography.SmallCapsTitle>
                        <Tooltip
                            position="top"
                            component={<StyledButton icon="help" />}
                        >
                            {t(`${I18N_NAMESPACE}.inputNotRequired`)}
                        </Tooltip>
                    </StyledFlexContent>
                </Column>
            </Row>
            <Row>
                <Column xs={6}>
                    <Divider space={Space.Tiny} />
                    {!hasMedia1 &&
                        <Row>
                            <Uploader.MediaList
                                limitSelectable={1}
                                mediumType={MediumType.Image}
                                context={{ 'panoramic': false }}
                                renderColumns={{
                                    xs: 12,
                                }}
                                checkedMedia={(checkedMedia: MediaListData[]) =>
                                    updateData({
                                        ...data,
                                        content: {
                                            ...data.content,
                                            picture: checkedMedia[0],
                                        }
                                    })
                                }
                            />
                        </Row>
                    }
                    {hasMedia1 && (
                        <Row>
                            <MediaList
                                renderColumns={{
                                    xs: 12,
                                }}
                                list={[data.content.picture!]}
                                isCheckable={false}
                                deleteMedia={(item: MediaListData) => {
                                    updateData({
                                        ...data,
                                        content: {
                                            ...data.content,
                                            picture: undefined
                                        }
                                    } as any)
                                }
                                }
                            />
                        </Row>
                    )}
                </Column>
                <Column xs={6}>
                    <Divider space={Space.Tiny} />
                    {!hasMedia2 &&
                        <Row>
                            <Uploader.MediaList
                                limitSelectable={1}
                                mediumType={MediumType.Image}
                                context={{ 'panoramic': false }}
                                heightLimited={hasMedia2 ? true : false}
                                info={
                                    hasMedia2 ? (
                                        <React.Fragment>
                                            <Typography.RegularText variant="neutral">
                                                <strong>{t(`${I18N_KEY_GLOBAL}.selectFromLibrary`)}</strong> {t(`${I18N_KEY_GLOBAL}.or`)} <strong>{t(`${I18N_KEY_GLOBAL}.uploadFiles`)}</strong>
                                            </Typography.RegularText>
                                        </React.Fragment>
                                    ) : (
                                        ""
                                    )
                                }
                                renderColumns={{
                                    xs: 12,
                                }}
                                checkedMedia={(checkedMedia: MediaListData[]) =>
                                    updateData({
                                        ...data,
                                        content: {
                                            ...data.content,
                                            picture_secondary: checkedMedia[0]
                                        },
                                    })
                                }
                            />
                        </Row>
                    }
                    {hasMedia2 && (
                        <Row>
                            <MediaList
                                renderColumns={{
                                    xs: 12,
                                }}
                                list={[data.content.picture_secondary!]}
                                isCheckable={false}
                                deleteMedia={(item: MediaListData) => {
                                    updateData({
                                        ...data,
                                        content: {
                                            ...data.content,
                                            picture_secondary: undefined
                                        }
                                    } as any)
                                }
                                }
                            />
                        </Row>
                    )}
                </Column>
            </Row>
            <InputComponent
                name="Title"
                type="text"
                label={t(`${I18N_NAMESPACE}.inputTitleLabel`)}
                placeholder={t(`${I18N_NAMESPACE}.inputTitlePlaceholder`)}
                value={data.content && data.content.title}
                onChange={
                    (value: string) => updateData({
                        ...data,
                        content: {
                            ...data.content,
                            title: value
                        }
                    })
                }
            />
            <InputComponent
                name="Subtitle"
                type="text"
                label={t(`${I18N_NAMESPACE}.inputSubTitleLabel`)}
                placeholder={t(`${I18N_NAMESPACE}.inputSubTitlePlaceholder`)}
                value={data.content && data.content.subtitle}
                onChange={
                    (value: string) => updateData({
                        ...data,
                        content: {
                            ...data.content,
                            subtitle: value
                        }
                    })
                }
            />
            <Typography.SmallCapsTitle>{t(`${I18N_NAMESPACE}.inputHighlightTitle`)}</Typography.SmallCapsTitle>
            <Divider space={Space.Tiny} />
            <TextEditor
                toolbar={TOOLBAR}
                value={data.content && data.content.text_highlight || ""}
                onChange={
                    (value: string) => updateData({
                        ...data,
                        content: {
                            ...data.content,
                            text_highlight: value
                        }
                    })
                }
            />
            <Divider space={Space.Normal} />
            <StyledFlexContent>
                <Typography.SmallCapsTitle>{t(`${I18N_NAMESPACE}.inputExtendedTitle`)}</Typography.SmallCapsTitle>
                <Tooltip
                    position="top"
                    component={<StyledButton icon="help" />}
                >
                    {t(`${I18N_NAMESPACE}.inputFieldOptional`)}
                </Tooltip>
            </StyledFlexContent>
            <Divider space={Space.Tiny} />
            <TextEditor
                toolbar={TOOLBAR}
                value={data.content && data.content.text || ""}
                onChange={
                    (value: string) => updateData({
                        ...data,
                        content: {
                            ...data.content,
                            text: value
                        }
                    })
                }
            />
            <Divider space={Space.Normal} />
            <InputComponent
                name="cta_label"
                type="text"
                label={t(`${I18N_NAMESPACE}.inputCTALabel`)}
                placeholder={t(`${I18N_NAMESPACE}.inputCTAPlaceholder`)}
                value={data.content && data.content.cta_label}
                onChange={
                    (value: string) => updateData({
                        ...data,
                        content: {
                            ...data.content,
                            cta_label: value
                        }
                    })
                }
            />
            <InputComponent
                name="cta_value"
                type="text"
                label={t(`${I18N_NAMESPACE}.inputCTAUrlLabel`)}
                placeholder={t(`${I18N_NAMESPACE}.inputCTAUrlPlaceholder`)}
                value={data.content && data.content.cta_value}
                onChange={
                    (value: string) => updateData({
                        ...data,
                        content: {
                            ...data.content,
                            cta_value: value
                        }
                    })
                }
            />
            <Select
                options={SeparatorOptions}
                label={t(`${I18N_NAMESPACE}.inputCTATypeLabel`)}
                name="cta_anchor_target_id"
                defaultLabel={t(`${I18N_NAMESPACE}.inputCTATypeDefaultLabel`)}
                defaultValue="-1"
                value={data.content && data.content.cta_anchor_target_id}
                onChange={(achorType) => updateData({
                    ...data,
                    content: {
                        ...data.content,
                        cta_anchor_target_id: achorType !== "-1" ? achorType : data.content.cta_anchor_target_id,
                    }
                })}
            />
            <Divider space={Space.Small} />
            <Row className="items-aligned">
                <Column xl={4}>
                    <Select
                        options={ImageAlignmentOptions}
                        name="alignment"
                        label={t(`${I18N_NAMESPACE}.inputImageAlignLabel`)}
                        defaultLabel={t(`${I18N_NAMESPACE}.inputImageAlignDefaultLabel`)}
                        defaultValue="-1"
                        value={data.properties && data.properties.alignment}
                        onChange={(alignmentType) => updateData({
                            ...data,
                            properties: {
                                ...data.properties,
                                alignment: alignmentType !== "-1" ? alignmentType : data.properties && data.properties.alignment,
                            }
                        })}
                    />
                </Column>
                <Column xl={4}>
                    <Select
                        options={TextColorOptions}
                        name="text_color"
                        label={t(`${I18N_NAMESPACE}.inputTextColorLabel`)}
                        defaultLabel={t(`${I18N_NAMESPACE}.inputTextColorDefaultLabel`)}
                        defaultValue="-1"
                        value={data.properties && data.properties.theme}
                        onChange={(textColor) => updateData({
                            ...data,
                            properties: {
                                ...data.properties,
                                theme: textColor !== "-1" ? textColor : data.properties && data.properties.theme,
                            }
                        })}
                    />
                </Column>
                <Column xl={4}>
                    <InputComponent
                        name="background_color"
                        type="color"
                        label={t(`${I18N_NAMESPACE}.inputBackgroundColorLabel`)}
                        value={data.properties && data.properties.background}
                        onChange={
                            (value: string) => updateData({
                                ...data,
                                properties: {
                                    ...data.properties,
                                    background: value
                                }
                            })
                        }
                    />
                </Column>
            </Row>


        </Wrapper>
    );
}

const Component = withI18n(APP_TRANSLATIONS)(ImageAndTextComponent);

const Button = {
    icon: "image",
    name: {
        key: `${I18N_NAMESPACE}.name`,
        en: "Image and Text",
        es: "Imagen y texto",
        pt: "Imagem e texto",
    },
};

export const ImageAndText = {
    key: imageAndText.type,
    Structure,
    Component,
    Button,
};
