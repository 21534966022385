export * from "./utils/QLQuery"

/**
 * Removes all null or undefined key-value pairs from object
 * @param data Data object to be processed
 */
export function removeEmptyValues<T extends object>(data: T): T {
    for (const key in data) {
        if (data.hasOwnProperty(key)) {
            const element = data[key];
            if (element === null || element === undefined) {
                delete data[key]
            }
        }
    }

    return data;
}

/**
 * Flatten complex values for given keys.
 * Extracts "id" property into its parent node.
 * @example
 * // {a: {id: 1}} -> {a: 1}
 * @param data Data object to be processed
 * @param keys Object keys to analyze
 * @param property Property to flatten
 */
export function flattenComplexValues<T extends object>(data: T, keys: string | string[], property: string = "id"): T {
    keys = typeof(keys) === "string" ? [keys] : keys

    for (const key of keys) {
        const node = (data as any)[key]
        if (node && typeof node === "object" && property in node) {
            (data as any)[key] = node[property]
        }
    }

    return data
}

/**
 * Resets all matching values for given keys by target value.
 * @param data Data object to be processed
 * @param keys Object keys to analyze
 * @param sourceValue Source value to trigger execution
 * @param targetValue Target value
 */
export function resetValues<T extends object>(data: T, keys: string | string[], sourceValue: any, targetValue: any = null): T {
    keys = typeof(keys) === "string" ? [keys] : keys

    for (const key of keys) {
        if ((data as any)[key] === sourceValue) {
            (data as any)[key] = targetValue
        }
    }

    return data
}
