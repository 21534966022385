import React from "react";
import { Store, Component } from "@7egend/web.core";
import { State } from "../../services/state";
import { CategoriesState, actions } from "../../services/categories";

/**
 * Own component props
 */
export interface OwnProps {
    // nothing
}

/**
 * Store props
 */
export interface StoreProps {
    categories: CategoriesState
}

/**
 * Store dispatch props
 */
export interface StoreDispatchProps {
    getCategories: () => void
}

/**
 * Props that are going to be injected in the target component
 */
export interface WithCategoriesProps extends StoreProps, StoreDispatchProps {

}

/**
 * HOC that provides access to the Categories
 * @param WrappedComponent React component
 */
export const WithCategories: <P extends object = {}>(WrappedComponent: React.ComponentType<P & WithCategoriesProps>) => React.ComponentType<P> = (WrappedComponent) => {

    //#region Store mapping

    /**
     * Map store state to component props
     */
    function mapStateToProps(state: State, ownProps: OwnProps): StoreProps {
        return {
            categories: state.cms.categories,
        }
    }

    /**
     * Map store dispatch actions to props
     */
    function mapDispatchToProps(dispatch: Store.ThunkDispatch<any, void, Store.Action>, ownProps: OwnProps): StoreDispatchProps {
        return {
            getCategories: () => dispatch(actions.getCategories()),
        }
    }

    //#endregion

    class WithCategoriesComponent extends Component<WithCategoriesProps> {

        public render() {
            return (
                <WrappedComponent
                    {...this.props as any}
                />
            )
        }

    }

    return Store.connect(mapStateToProps, mapDispatchToProps)(WithCategoriesComponent) as any;
}
