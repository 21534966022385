import React from "react";
import { PureComponent } from "@7egend/web.core/lib/base/component"

export interface TextareaProps {
    /** placeholder */
    placeholder?: string
    /** form input name */
    name: string;
    /** onChange */
    onChange: (value: string, name: string, valid: boolean) => void
    /** className */
    className?: string
    /** Value */
    value?: string
    /** Required */
    required?: boolean
    /** on focus fn */
    onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
    /** on blur fn */
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
}

/**
 * # Textarea Component
 * Represents the textarea input component
 *
 * # How to use
 * ````<TextareaComponent label="label" note="note"/>````
 */
export class Textarea extends PureComponent<TextareaProps> {
    public render() {
        return (
            <textarea
                className={this.props.className}
                name={this.props.name}
                placeholder={this.props.placeholder}
                onChange={this.onChange}
                value={this.props.value}
                onBlur={this.props.onBlur}
                onFocus={this.props.onFocus}
                required={this.props.required}
            />
        );
    }

    private onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value: string = (e.target as any).value
        const valid: boolean = (e.target as any).validity.valid

        this.props.onChange(value, this.props.name, valid)
    }
}
