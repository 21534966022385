import React from "react"
import { DefaultFormatterProps } from "./constants";
import { SelectOption } from "../Select";
import { DloInput } from "@7egend/web.core";

export enum DatatableActions {
    Common = "common",
    Custom = "custom",
    Picker = "picker",
    Link = "link",
}

export type Actions = Action[];
export type Action = React.ElementType | React.ReactElement;

export type Filters = Filter[];
export type Filter = React.FC<FilterProps>;
export interface FilterProps {
    filters: { [key: string]: any };
    updateFilter: (name: string, value: any) => void;
}

export interface SelectProps {
    value?: string;
    onChange: (value: any) => void;
    options: SelectOption[];
}

export type Columns = Column[];
export interface Column {
    /**
     * Param or param list to show in column
     */
    param: string | string[];

    /**
     * Grid Column size
     * @deprecated - we dont use size column anymore
     */
    size?: number;

    /**
     * Title to show in the header column 
     */
    title?: string | React.ComponentType | JSX.Element;

    /** 
     * Order item 
     */
    order?: boolean;

    /**
     * Render method
     * It will be used to display the data.
     * If not given, the column will not appear (same as hidden).
     * 
     * Default formatters available. Example:
     * - DefaultFormatter.RegularText - simple text
     * - DefaultFormatter.TwoLinesText - two lines of text
     * - DefaultFormatter.Image - image
     */
    render?: (params: DefaultFormatterProps) => Element;

    /**
     * Hide this column from datatable.
     * This is very useful to load data that is not visible.
     */
    hidden?: boolean

    /**
     * Shrink the layout for existing space
     * @experimental
     * @default false
     */
    shrink?: boolean
}

export interface Rows {
    /** Row actions */
    actions?: RowActions;
    /** List of lengths available */
    lengths: number[];
}

export interface ActionsProps<T = any> {
    item?: T;
}

export type ActionsRenderType = (params: ActionsProps) => any;

interface Types {
    /**
     * ## Action Type
     * 
     * #### Notes:
     * If you choose "Custom", you need to send the "render" param
     */
    type: DatatableActions;
    function?: any;
    param?: string;
    render?: ActionsRenderType;
    icon?: string;
    /** 
     * tooltip label
     * @default undefined
     */
    label?: string;
}

export type DloInputConstructor = new () => DloInput
export type DloInputBuilder = (data: any) => DloInput

/**
 * Config interface
 */
export interface DatatableProps {
    /** Title */
    title: string;
    /** Columns configuration */
    columns: Columns;
    /** Rows configutation */
    rows: Rows;
    /** List of actions to show in the Datatable, above header */
    actions?: Actions;
    /** List of filters */
    filters?: Filters;
    /** Default filters data */
    defaultFilters?: {
        [key: string]: any,
    };
    /**
     * Fetch class
     * It must be a DloInput class
     */
    fetch: DloInputConstructor | DloInputBuilder;

    /**
     * actionSuccess tells if some action on caller component had success in order to re-fetch data an obtain the newer results
     */
    actionSuccess?: string | number;
    isLoading?: boolean;
    footer?: React.ReactComponentElement<any> | React.ComponentType<any> | Element;

    /** 
     * ## Picked items
     * - this is used if you are using picker with Datatable
     */
    pickedItems?: any[]

    /**
     * Order column
     */
    order?: {
        by: number,
        direction: "desc" | "asc"
    }

    /** refresh datatable */
    refresh?: boolean;

    /**
     * @function onSortChange
     * @param id is the one that has seen its position changed
     * @param afterId is the one that comes after the one that was changed
     */
    onSortChange?: (id: number | string, afterId: number | string) => void;

    /**
     * isSortable
     */
    isSortable?: boolean;
}

export interface RowActions {
    list: Types[];
    size: number;
    showAllActions?: boolean;
}

/**
 * Pages inurator interface
 */
export interface Pages {
    length: number;
    start: number;
    total: number;
}

/**
 * Order direction
 */
export interface Order {
    by: number;
    direction: string;
}

/**
 * Component's state
 */
export interface State extends Pages {
    delete?: string;
}
