import React from "react";
import { CorePureComponent } from "@7egend/web.core";
import ContentLoader from "react-content-loader";
import { colors } from "../../styles";

export class Rect extends CorePureComponent {
    public render() {
        const line = {
            x: 0,
            y: 0,
            width: 400,
            height: 16,
            radius: 2,
        };
        return (
            <ContentLoader
                primaryColor={colors.neutral.light}
                secondaryColor={colors.neutral.lighter}
                height={16}
            >
                <rect
                    x={line.x}
                    y={line.y}
                    rx={line.radius}
                    ry={line.radius}
                    width={line.width}
                    height={line.height}
                />
            </ContentLoader>
        );
    }
}
