import React from "react";
import loadable from "@loadable/component";
import { Block } from "@7egend/web.core.cms/lib/dlos/block";
import { Text as TextDLO } from "@7egend/web.core.cms/lib/dlos/text";
import { BlockComponentProps } from "../../interfaces";
import { blockTypes } from "../config";
import { I18N_KEY_CMS_COMPONENTS_BLOCK } from "../../../../base/i18n";
import { useI18n } from "@7egend/web.core/lib/components/useI18n";

const { lead } = blockTypes;

const Structure = {
    type: lead.type,
    content: {
        style: lead.style,
    },
} as Block<TextDLO>;

const TextLead = loadable(async () => (await import("../../../TextArea")).TextArea);

const I18N_NAMESPACE = `${I18N_KEY_CMS_COMPONENTS_BLOCK}.types.lead`

const Component: React.FC<BlockComponentProps<TextDLO>> = ({ className, data, updateData }) => {
    const [t] = useI18n();
    return (
        <TextLead
            className={className}
            name=""
            placeholder={t(`${I18N_NAMESPACE}.inputTextPlaceholder`)}
            value={data.content && data.content.value}
            onChange={(value: string) => updateData({
                ...data,
                content: {
                    ...data.content,
                    value,
                }
            })}
        />
    )
}

const Button = {
    icon: "sort",
    name: {
        key: `${I18N_NAMESPACE}.name`,
        en: "Lead",
        es: "Lead",
        pt: "Lead",
    },
};

export const Lead = {
    key: `${lead.type}_${lead.style}`,
    Structure,
    Component,
    Button,
};
