import React, { PureComponent } from "react"
import { Image as BaseImage } from "../../../../Image";
import { PLACEHOLDER_DOCUMENT } from "../index"
import { StyledMedialaceholder } from "../styles";

/**
 * 
 * # Document Placeholder
 * 
 */
export class Document extends PureComponent {
    public render() {
        return (
            <StyledMedialaceholder>
                <BaseImage src={PLACEHOLDER_DOCUMENT} />
            </StyledMedialaceholder>
        )
    }
}
