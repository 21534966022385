import React, { PureComponent } from "react"
import { extractPathFromList } from "@7egend/web.core/lib/utils";
import { Styled } from "./styles";
import { Typography } from "../Typography";
import { WrapperWithTitleActions } from "../Wrapper/WithTitleActions";
import { Button } from "../Button";
import { Checkbox } from "../Checkbox";
import { Column } from "../Structure";
import { Label, LabelLayout } from "../Label";
import { Divider, Space } from "../Divider";
import { withI18n, WithI18nProps } from "@7egend/web.core/lib/components/withI18n";
import { APP_TRANSLATIONS } from "../../locale";
import { I18N_KEY_CORE_COMPONENTS_MULTISELECT } from "../../base/i18n";

export interface MultiSelectItemsData {
    name: string;
    id: string;
}

interface MultiSelectProps {
    /** label text {optional} */
    label?: string;

    /** items to show, to select */
    items: MultiSelectItemsData[];

    /** checked items */
    checkedItems: MultiSelectItemsData[];

    /** handle item */
    handleItem: (selectedCategories: MultiSelectItemsData[]) => void;

    /** placeholder text {optional} */
    placeholder?: string;

    /** limit selectable */
    limitSelectable?: number;

    /**
     * To override the "children" key name 
     * this key is used when it needed to iterate between multiple levels
     */
    childrenKeyName?: string;
}

interface MultiSelectState {
    modalVisible: boolean;
    selectedItems: MultiSelectItemsData[];
    confirmedItems: MultiSelectItemsData[];
    expandedItems: string[];
}

const CheckedOption: React.FC<{
    id: string;
    name: string;
    onClick: ({ id, name }: MultiSelectItemsData) => void;
}> = ({ id, name, onClick }) => {
    const handleOption = () => {
        onClick({ id, name });
    };

    const stopPropagation = (e: React.MouseEvent) => {
        e.stopPropagation();
    };

    return (
        <Label
            key={id}
            icon="delete"
            layout={LabelLayout.Default}
            iconClick={handleOption}
            onClick={stopPropagation as any}
        >
            {name}
        </Label>
    );
};

const I18N_NAMESPACE = I18N_KEY_CORE_COMPONENTS_MULTISELECT

/**
 * # MultiSelect Component
 *
 * ## How to use:
 *
 * ```jsx
 * <MultiSelect
 *  items={}
 *  label="label text"
 *  checkedItems={}
 *  handleItem={(id: string, name: string) => void}
 *  placeholder="placeholder"
 * />
 * ```
 *
 */
class MultiSelectComponent extends PureComponent<
    WithI18nProps &
    MultiSelectProps,
    MultiSelectState
    > {
    public state: MultiSelectState = {
        modalVisible: false,
        selectedItems: [],
        confirmedItems: [],
        expandedItems: [],
    };

    public componentDidMount() {
        if (this.props.checkedItems && this.props.checkedItems.length > 0) {
            this.setState({
                confirmedItems: this.props.checkedItems,
                selectedItems: this.props.checkedItems,
            });
        }
    }

    public componentDidUpdate(prevProps: MultiSelectProps) {
        const { checkedItems } = this.props;

        if (
            checkedItems !== prevProps.checkedItems
        ) {
            this.setState({
                selectedItems: checkedItems,
                confirmedItems: checkedItems,
            });

            if (this.props.childrenKeyName && (this.props.checkedItems && this.props.checkedItems.length > 0)) {
                const filtered: any[] = extractPathFromList(this.props.checkedItems[0].id, this.props.items as any, "id", this.props.childrenKeyName);

                if (filtered && filtered.length > 0) {
                    const list = filtered.map(f => f.id);

                    this.setState({
                        expandedItems: [...this.state.expandedItems, ...list],
                    })
                }
            }
        }
    }

    public render() {
        const { modalVisible, selectedItems, confirmedItems } = this.state;
        const { label, items, t } = this.props;

        return (
            <React.Fragment>
                {label && (
                    <Typography.SmallCapsTitle>
                        {label}
                    </Typography.SmallCapsTitle>
                )}
                <Styled.Wrapper
                    onClick={() => this.setState({ modalVisible: true })}
                >
                    {(this.props.placeholder && !confirmedItems.length) &&
                        <Typography.RegularText variant="neutral">{this.props.placeholder}</Typography.RegularText>
                    }
                    <Styled.Labels>
                        {confirmedItems.length > 0 &&
                            confirmedItems.map(
                                (item: { id: string; name: string }) => (
                                    <CheckedOption
                                        key={item.id}
                                        id={item.id}
                                        name={item.name}
                                        onClick={() =>
                                            this.removeItem(item)
                                        }
                                    />
                                )
                            )}
                    </Styled.Labels>
                    <Styled.Button icon="add" />
                </Styled.Wrapper>
                <Styled.Modal
                    isVisible={modalVisible}
                    overlay
                    ButtonLayout="main"
                    handleModal={this.handleModal}
                >
                    <WrapperWithTitleActions
                        title={label || ""}
                        actions={<Button icon="search" />}
                        footer={
                            <Column contentAlign="right">
                                <Button
                                    layout="main"
                                    layoutOutline
                                    onClick={this.handleModal}
                                >
                                    {t(`${I18N_NAMESPACE}.cancel`)}
                                </Button>
                                <Button
                                    layout="main"
                                    layoutFill
                                    icon="add"
                                    layoutIconPosition="left"
                                    disabled={
                                        !selectedItems ||
                                        !selectedItems.length
                                    }
                                    onClick={this.addSelectItems}
                                >
                                    {`Add ${this.state.selectedItems.length > 0 && selectedItems.length || ""} items`}
                                </Button>
                            </Column>
                        }
                        heightLimited
                    >
                        {items && this.renderSelectableItems(items)}
                    </WrapperWithTitleActions>
                </Styled.Modal>
            </React.Fragment>
        );
    }

    private renderSelectableItems = (items: any[]) => {
        if (this.props.childrenKeyName) {
            return (
                <Styled.List>
                    {
                        items.map((item) => {
                            return (
                                <Styled.ListItem key={`checkbox-${item.id}`}>
                                    <Styled.ListItemContent>
                                        <div onClick={() => this.toggleActive(item.id)}>
                                            {item[this.props.childrenKeyName || "children"].length > 0 && <Styled.ListItemIcon icon={this.verifyIfIsActive(item.id) ? "remove" : "add"} />}
                                        </div>
                                        <Styled.ListItemCheckbox
                                            label={item.name}
                                            onChange={() => this.handleItem(item)}
                                            value={this.veryfyCheckedItem(item.id)}
                                            name={item.name}
                                        />
                                    </Styled.ListItemContent>
                                    {this.verifyIfIsActive(item.id) && item[this.props.childrenKeyName || "children"].length > 0 && this.renderSelectableItems(item[this.props.childrenKeyName || "children"])}
                                </Styled.ListItem>
                            )
                        })
                    }
                </Styled.List>
            )
        }

        return items.map((item) => (
            <Column key={`checkbox-${item.id}`}>
                <Checkbox
                    label={item.name}
                    onChange={() => this.handleItem(item)}
                    value={this.veryfyCheckedItem(item.id)}
                    name={item.name}
                />
                <Divider space={Space.Tiny} />
            </Column>
        ));
    };

    private toggleActive = (id: string) => {
        this.setState({
            expandedItems: this.state.expandedItems.indexOf(id) >= 0
                ? this.state.expandedItems.filter(item => item !== id)
                : [...this.state.expandedItems, id]
        })
    }

    private verifyIfIsActive = (id: string) => {
        return this.state.expandedItems.indexOf(id) >= 0 && this.state.expandedItems.filter(item => item === id) ? true : false;
    }

    private handleItem = (item: MultiSelectItemsData) => {
        const shouldRemoveItem = this.veryfyCheckedItem(item.id);

        if (shouldRemoveItem) {
            this.setState({
                selectedItems: this.state.selectedItems.filter(
                    (selected: { id: string }) => selected.id !== item.id
                )
            });
        } else {
            if (this.props.limitSelectable && this.props.limitSelectable !== 1) {
                if (this.state.selectedItems.length < this.props.limitSelectable) {
                    this.setState({
                        selectedItems: [...this.state.selectedItems, item],
                    });
                }
            } else if (this.props.limitSelectable === 1) {
                this.setState({
                    selectedItems: [item],
                });
            } else {
                this.setState({
                    selectedItems: [...this.state.selectedItems, item],
                });
            }
        }
    };

    /** 
     * When click on trash can icon on Label Component
     * 
     * It will remove the item from the array
     */
    private removeItem = (item: any) => {
        this.setState({
            selectedItems: this.state.selectedItems.filter(
                (selected: { id: string }) => selected.id !== item.id
            ),
            confirmedItems: this.state.confirmedItems.filter(
                (confirmed: { id: string }) => confirmed.id !== item.id
            ),
        }, () => this.props.handleItem(this.state.selectedItems));
    }

    /** it returns true/false if the id is checked or not */
    private veryfyCheckedItem = (id: string) => {
        return this.state.selectedItems.findIndex(i => i.id === id) > -1 ? true : false;
    };

    /** Handle the visibility of the modal */
    private handleModal = () => {
        this.setState({
            modalVisible: !this.state.modalVisible,
        });
    };

    /** Add selected items */
    private addSelectItems = () => {
        this.setState({
            confirmedItems: this.state.selectedItems,
            modalVisible: !this.state.modalVisible,
            expandedItems: [],
        });
        this.props.handleItem(this.state.selectedItems);
    };
}

export const MultiSelect = withI18n(APP_TRANSLATIONS)(MultiSelectComponent);