import React from "react";
import loadable from "@loadable/component";
import { Block } from "@7egend/web.core.cms/lib/dlos/block";
import { Text as TextDLO } from "@7egend/web.core.cms/lib/dlos/text";
import { BlockComponentProps } from "../../interfaces";
import styled from "styled-components";
import { font, colors } from "../../../../styles";
import { withI18n, WithI18nProps } from "@7egend/web.core/lib/components/withI18n";
import { APP_TRANSLATIONS } from "../../../../locale";
import { blockTypes } from "../config";
import { I18N_KEY_CMS_COMPONENTS_BLOCK } from "../../../../base/i18n";

const { highlight } = blockTypes;

const Structure = {
    type: highlight.type,
    content: {
        style: highlight.style,
    },
} as Block<TextDLO>;

const TextArea = loadable(async () => (await import("../../../TextArea")).TextArea);

const StyledTextArea = styled(TextArea)`
    & {
        font-size: ${font.size.xxl};
        font-weight: ${font.weight.light};
        color: ${colors.neutral.darker};
    }
`

const I18N_NAMESPACE = `${I18N_KEY_CMS_COMPONENTS_BLOCK}.types.highlight`

const HighlightComponent: React.FC<WithI18nProps & BlockComponentProps<TextDLO>> = ({ className, data, updateData, t }) => {
    return (
        <StyledTextArea
            className={className}
            name=""
            placeholder={t(`${I18N_NAMESPACE}.placeholder`)}
            value={data.content && data.content.value}
            onChange={(value: any) => updateData({
                ...data,
                content: {
                    ...data.content,
                    value,
                }
            })}
        />
    )
}

const Component = withI18n(APP_TRANSLATIONS)(HighlightComponent);

const Button = {
    icon: "short_text",
    name: {
        key: `${I18N_NAMESPACE}.name`,
        en: "Highlight",
        es: "Texto Resaltado",
        pt: "Texto Realçado",
    },
};

export const Highlight = {
    key: `${highlight.type}_${highlight.style}`,
    Structure,
    Component,
    Button,
};
