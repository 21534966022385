import React from "react";
import { Block } from "@7egend/web.core.cms/lib/dlos/block";
import { Booster as BoosterDLO } from "@7egend/web.core.cms/lib/dlos/booster";
import { BlockComponentProps } from "../../interfaces";
import { Button as ButtonComponent } from "../../../Button";
import { colors } from "../../../../styles";
import styled from "styled-components";
import { Divider, Space } from "../../../Divider";
import { Input } from "../../../Input";
import { withI18n, WithI18nProps } from "@7egend/web.core/lib/components/withI18n";
import { APP_TRANSLATIONS } from "../../../../locale";
import { blockTypes } from "../config";
import { I18N_KEY_CMS_COMPONENTS_BLOCK } from "../../../../base/i18n";
import loadable from "@loadable/component";
import { Typography } from "../../../Typography";

const { container, columnHighlight, booster } = blockTypes;

const Structure = {
    type: container.type,
    content: {
        style: columnHighlight.style,
        blocks: [{
            type: booster.type,
            content: {}
        }] as Array<Block<BoosterDLO>>
    },
} as any;

const StyleListWrapper = styled.div`
    border: 1px solid ${colors.neutral.light};
    background-color: ${colors.neutral.lighter};
    border-radius: 6px;
    padding: 16px;

    &::after {
        content: "";
        width: 100%;
        display: block;
        white-space: nowrap;
        clear: both;
    }
`;

const StyledBoosterList = styled.div`
    display: block;
    width: 100%;
    padding-top: 5px;

    &::after {
        content: "";
        width: 100%;
        display: block;
        white-space: nowrap;
        clear: both;
    }
`;

const StyledBoosterListWrapper = styled.div`
    display: block;
    width: 100%;
    background-color: ${colors.light};
    padding: 32px 12px;
    border-radius: 6px;
    clear: both;
`;

const StyledAddButton = styled(ButtonComponent)`
    display: block;
    width: 100%;
`;

const StyledRemoveButton = styled(ButtonComponent)`
    float: right;
`;

const updateColumnHighlight = (valuesList: any, content: any, i: number) => {
    const newValuesList = [...valuesList];
    newValuesList[i].content = content;
    return newValuesList;
};

const removeItem = (valuesList: any, i: number) => {
    const updatedList = [
        ...valuesList.slice(0, i),
        ...valuesList.slice(i + 1),
    ];

    return updatedList;
};

const addItem = (valuesList: any) => {
    const newvaluesList = [...valuesList];
    newvaluesList.push(
        {
            type: "6",
            content: {}
        }
    );

    return newvaluesList;
};

const TextEditor = loadable(async () => (await import("../../../TextEditor")).TextEditor);

const I18N_NAMESPACE = `${I18N_KEY_CMS_COMPONENTS_BLOCK}.types.columnHighlight`

const TOOLBAR = {
    options: ["inline", "link"]
};

const ColumnHighlightComponent: React.FC<WithI18nProps & BlockComponentProps<any>> = ({ className, data, updateData, t }) => {

    if (!data.content.blocks || data.content.blocks.length === 0) {
        return null
    }

    return (
        <StyleListWrapper className={className}>
            {data.content.blocks.map((eachBlock: any, index: number) => (
                <React.Fragment key={index}>
                    <StyledBoosterList>
                        {data.content.blocks.length > 1 &&
                            <StyledRemoveButton
                                icon={"delete"}
                                onClick={() => updateData({
                                    ...data,
                                    content: {
                                        ...data.content,
                                        blocks: removeItem(data.content.blocks, index)
                                    }
                                })}
                            />
                        }
                        <StyledBoosterListWrapper>
                            <Input
                                label={t(`${I18N_NAMESPACE}.inputSubtitleLabel`)}
                                name="line1"
                                placeholder={t(`${I18N_NAMESPACE}.inputSubtitlePlaceholder`)}
                                type="text"
                                value={eachBlock.content.line1}
                                onChange={(line1) =>
                                    updateData({
                                        ...data,
                                        content: {
                                            ...data.content,
                                            blocks: updateColumnHighlight(data.content.blocks, {
                                                ...eachBlock.content,
                                                line1,
                                            }, index)
                                        }
                                    })
                                }
                            />
                            <Typography.SmallCapsTitle>{t(`${I18N_NAMESPACE}.inputTextLabel`)}</Typography.SmallCapsTitle>
                            <Divider space={Space.Tiny} />
                            <TextEditor
                                toolbar={TOOLBAR}
                                value={eachBlock.content.line3}
                                onChange={
                                    (line2) =>
                                        updateData({
                                            ...data,
                                            content: {
                                                ...data.content,
                                                blocks: updateColumnHighlight(data.content.blocks, {
                                                    ...eachBlock.content,
                                                    line2,
                                                }, index)
                                            }
                                        })
                                }
                            />
                            <Typography.SmallCapsTitle>{t(`${I18N_NAMESPACE}.inputSmallTextLabel`)}</Typography.SmallCapsTitle>
                            <Divider space={Space.Tiny} />
                            <TextEditor
                                toolbar={TOOLBAR}
                                value={eachBlock.content.line3}
                                onChange={
                                    (line3) =>
                                        updateData({
                                            ...data,
                                            content: {
                                                ...data.content,
                                                blocks: updateColumnHighlight(data.content.blocks, {
                                                    ...eachBlock.content,
                                                    line3,
                                                }, index)
                                            }
                                        })
                                }
                            />
                        </StyledBoosterListWrapper>
                    </StyledBoosterList>
                    {data.content.blocks.length > 1 && <Divider space={Space.Small} layout={"lighter"} />}
                </React.Fragment>
            ))}
            <StyledAddButton
                text={t(`${I18N_NAMESPACE}.addNewItem`)}
                icon={"add"}
                layoutIconPosition={"left"}
                onClick={() => updateData({
                    ...data,
                    content: {
                        ...data.content,
                        blocks: addItem(data.content.blocks)
                    }
                })}
            />
        </StyleListWrapper>
    )
}

const Component = withI18n(APP_TRANSLATIONS)(ColumnHighlightComponent);

const Button = {
    icon: "view_column",
    name: {
        key: `${I18N_NAMESPACE}.name`,
        en: "Column Highlight",
        es: "Resaltado de columna",
        pt: "Coluna de destaque",
    },
};

export const ColumnHighlight = {
    key: `${columnHighlight.type}_${columnHighlight.style}`,
    Structure,
    Component,
    Button,
};
