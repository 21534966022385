import React from "react";
import styled from "styled-components";
import { Typography } from "../../../Typography";
import { colors } from "../../../../styles";
import { withI18n, WithI18nProps } from "@7egend/web.core/lib/components/withI18n";
import { I18N_KEY_CMS_COMPONENTS_BLOCK } from "../../../../base/i18n";
import { BlockComponentProps } from "../../interfaces";

const Wrapper = styled.div`
    position: relative;
    padding: 16px;
    min-height: 100px;
    width: 100%;
    border-radius: 10px;
    border: 1px solid ${colors.neutral.light};
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    background-color: ${colors.neutral.lighter};
    cursor: not-allowed;
`

const I18N_NAMESPACE = `${I18N_KEY_CMS_COMPONENTS_BLOCK}.types.unsupported`

const UnsupportedComponent: React.FC<BlockComponentProps<any> & WithI18nProps> = ({ className, t }) => {
    return (
        <Wrapper className={className}>
            <Typography.RegularText>{t(`${I18N_NAMESPACE}.text`)}</Typography.RegularText>
        </Wrapper>
    )
}

export const Unsupported = withI18n()(UnsupportedComponent);
