import React, { PureComponent } from "react"
import styled from "styled-components";
import { Wrapper } from "../Wrapper";
import { StyledWrapperContent } from "../Wrapper/WrapperContent";
import { font, colors } from "../../styles";
import { Image } from "../Image";
import ic_dialog_error from "../../assets/ic_dialog_error.svg";
import ic_dialog_warning from "../../assets/ic_dialog_warning.svg";
import ic_dialog_success from "../../assets/ic_dialog_success.svg";
import { Modal } from "../Modal";

export type DialogTypes = "success" | "error" | "warning";

export interface DialogProps {
    /** show icon */
    showIcon?: boolean;

    /** type */
    type?: DialogTypes;

    /** title */
    title?:
    | React.ReactComponentElement<any>
    | React.ComponentType<any>
    | Element
    | string;

    /** description */
    description?:
    | React.ReactComponentElement<any>
    | React.ComponentType<any>
    | Element
    | string;

    /** Dialog Overlay */
    overlay?: boolean;

    /** Dialog Visibility */
    isVisible: boolean;

    /** Handle Visibility Dialog */
    handleDialog?: () => void;
}

const DefaultProps: DialogProps = {
    type: "success",
    showIcon: false,
    overlay: false,
    isVisible: false,
};

const StyledDialog = styled(Wrapper)`
    & {
        ${StyledWrapperContent} {
            padding: 32px 24px;
        }
    }
`;

const StyledTitle = styled.h3`
    font-size: ${font.size.lg};
    font-weight: ${font.weight.light};
    color: ${colors.neutral.darker};
    text-align: center;
    margin: 16px 0;
`;

const StyledDescription = styled.p`
    font-size: ${font.size.sm};
    font-weight: ${font.weight.light};
    color: ${colors.neutral.dark};
    text-align: center;
    margin-bottom: 24px;
`;

const StyledContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    button {
        margin: 0 8px;
    }
`;

const StyledIcon = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

/**
 *
 * # Dialog Component
 *
 * ## How to use:
 *
 * ```js
 * <Dialog
 *      handleDialog={this.toggleDialog} isVisible={this.state.dialogVisible} overlay
 *      showIcon
 *      type="success"
 *      title="Success"
 *      description="Lorem ipsum dolor sit amet consectetur adipiscing elit"
 * >
 *      <Button layoutFill layout="main" icon="check" layoutIconPosition="right">Save</Button>
 * </Dialog>
 * ```
 *
 */
export class Dialog extends PureComponent<DialogProps> {
    public static defaultProps = DefaultProps;

    public render() {
        /** This condition is to override the success type to main */
        const buttonCloseType =
            this.props.type === "success" ? "main" : this.props.type;

        return (
            <Modal
                handleModal={this.props.handleDialog}
                isVisible={this.props.isVisible}
                overlay={this.props.overlay}
                ButtonLayout={buttonCloseType}
            >
                <StyledDialog>
                    {this.props.showIcon && (
                        <StyledIcon>{this.renderIcon()}</StyledIcon>
                    )}
                    {this.props.title && (
                        <StyledTitle>{this.props.title}</StyledTitle>
                    )}
                    {this.props.description && (
                        <StyledDescription>
                            {this.props.description}
                        </StyledDescription>
                    )}
                    {this.props.children && (
                        <StyledContent>{this.props.children}</StyledContent>
                    )}
                </StyledDialog>
            </Modal>
        );
    }

    private renderIcon = () => {
        const { type } = this.props;

        switch (type) {
            case "success":
                return (
                    <Image src={ic_dialog_success} alt="Success Dialog Image" />
                );
            case "warning":
                return (
                    <Image src={ic_dialog_warning} alt="Warning Dialog Image" />
                );
            case "error":
                return <Image src={ic_dialog_error} alt="Error Dialog Image" />;
            default:
                return (
                    <Image src={ic_dialog_success} alt="Success Dialog Image" />
                );
        }
    };
}
