import { merge } from "@7egend/web.core/lib/utils";

/**
 * Converts a given datatable row into a complex value.
 * Splits everything by : or . characters
 * @param data Datatable row result object
 */
export function convertDatatableRowToObject(data: {[key:string]: any}) {
    const objects = Object.keys(data).map(k => keyToObj(k, data[k]))
    return merge(
        ...objects
    )
}

function keyToObj(str: string, val: any) {
    let i
    const obj = {}
    let x: any = obj;
    const strarr = str.indexOf(":") > -1 ? str.split(":") : str.split(".");
    for (i = 0; i < strarr.length - 1; i++) {
        x = x[strarr[i]] = {};
    }
    x[strarr[i]] = val;
    return obj;
}