import React from "react";
import {
    CorePureComponent,
} from "@7egend/web.core";
import { Block } from "@7egend/web.core.cms/lib/dlos/block";
import { Headers } from "./Headers";
import { RESOLVER, BlockComponentProps, BlockMapping, BlockComponent } from "../BlockDrawer";
import { BlocksDrawer } from "../BlocksDrawer";
import { Button } from "../Button";
import { Column, Row } from "../Structure";
import { Typography } from "../Typography";
import styled from "styled-components";
import { font, colors } from "../../styles";
import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd";
import { uniqueId, cloneDeep, memoizeOne } from "@7egend/web.core/lib/utils";
import { Icon } from "../Icon";
import { Unsupported } from "../BlockDrawer/BlockType/Unsupported";
import { Divider, Space } from "../Divider";
import { withI18n, WithI18nProps } from "@7egend/web.core/lib/components/withI18n";
import { APP_TRANSLATIONS } from "../../locale";
import { translateStringOrObject } from "../../utils";
import { I18N_KEY_CMS_COMPONENTS_BLOCK } from "../../base/i18n";

const StyledColumnLeft = styled(Column) <{ alignLeft?: boolean }>`
    padding: ${({ alignLeft }) => alignLeft ? "0" : undefined};
    display: flex;
    align-items: center;
    min-height: 40px;

    i {
        font-size: ${font.size.md};
        margin-right: 6px;
    }
`

const StyledColumnRight = styled(Column)`
    padding: 0;
`

const StyledButton = styled(Button)`
    i {
        font-size: ${font.size.md}
    }
`

const StyledBlockWrapper = styled.div`
    margin-bottom: 20px;
`

const StyledBlockActions = styled(Row)`
    // margin-top: 20px;
`

export interface BlockInfo {
    key: string
    Structure: Block;
    Component: React.FC<BlockComponentProps<any>> | React.ComponentType<BlockComponentProps<any>>
    Button: {
        icon: string;
        name: string | { [locale: string]: string };
    };
}

interface OrderResult extends DropResult {

}

export interface BlocksEditorProps {
    /**
     * Aditional block label info
     */
    label?: string

    /**
     * List of Block to render
     */
    blocks: Block[];

    /**
     * Function to update blocks data
     */
    onChange: (blocks: Block[]) => void;

    /**
     * List of available to add types
     */
    types: BlockInfo[];

    /**
     * Component block resolver
     * Returns a component for a given Block
     */
    resolveComponent?: (block: Block) => BlockComponent;

    /**
     * Enables sortable feature
     */
    sortable?: boolean

    /**
     * Allows to add a new block
     * This can be used to hide the add bar
     * @default true
     */
    allowAdd?: boolean

    /**
     * Allows to remove an existing block
     * This can be used to hide the remove button
     * @default true
     */
    allowRemove?: boolean

    /**
     * Shows add label above block types
     * @default true
     */
    showAddLabel?: boolean
}

const Wrapper: (WrappedComponent: any, name: string, removeBlock: any, parentProps: BlocksEditorProps) => React.FC<BlockComponentProps<any>> = (WrappedComponent, name, removeBlock, parentProps) => (props) => {
    const { data, position } = props;

    return (
        <Draggable
            key={`${position}`}
            isDragDisabled={!parentProps.sortable}
            draggableId={`${name}-${data.id || data.content.id || position}`}
            index={position}
        >
            {(provided: any) => (
                <StyledBlockWrapper
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                >
                    <StyledBlockActions>
                        <StyledColumnLeft xs={6} alignLeft={parentProps.sortable}>
                            {parentProps.sortable && (<Icon color={colors.neutral.dark} icon="drag_indicator" />)}
                            <Typography.SmallCapsTitle>{name || "error"} {parentProps.label && `(${parentProps.label})`}</Typography.SmallCapsTitle>
                        </StyledColumnLeft>
                        {parentProps.allowRemove && (
                            <StyledColumnRight xs={6} contentAlign={"right"}>
                                <StyledButton icon="delete" onClick={(() => removeBlock(props.data))} />
                            </StyledColumnRight>
                        )}
                    </StyledBlockActions>
                    <WrappedComponent {...props} />
                </StyledBlockWrapper>
            )}
        </Draggable>
    )
}

const I18N_NAMESPACE = `${I18N_KEY_CMS_COMPONENTS_BLOCK}.editor`

class BlocksEditorComponent extends CorePureComponent<BlocksEditorProps & WithI18nProps> {
    public static defaultProps = {
        sortable: true,
        allowAdd: true,
        showAddLabel: true,
    }

    /**
     * Remove block array position
     */
    private removeNewsBlock = (block: Block) => {
        const id = this.props.blocks.indexOf(block);
        const updatedBlocks = [
            ...this.props.blocks.slice(0, id),
            ...this.props.blocks.slice(id + 1),
        ];

        this.props.onChange(updatedBlocks);
    }

    private UnsupportedComponent = Wrapper(Unsupported, this.props.t(`${I18N_NAMESPACE}.unsupportedBlock`), this.removeNewsBlock, this.props);

    public render() {
        const { types, blocks, resolveComponent, sortable, allowAdd, showAddLabel, t } = this.props;

        return (
            <>
                <DragDropContext onDragEnd={this.onDragEnd as any}>
                    <Droppable droppableId="droppable" isDropDisabled={!sortable}>
                        {(provided: any) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                <BlocksDrawer
                                    blocks={blocks}
                                    resolveComponent={[resolveComponent, RESOLVER.CONTENT_BLOCKS] as unknown as Array<(block: Block) => BlockComponent>}
                                    fallbackComponent={this.UnsupportedComponent}
                                    onChange={this.updateBlock}
                                    mapping={this.getMapping(types) || undefined}
                                />
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
                {allowAdd && (
                    <React.Fragment>
                        <Divider space={Space.Tiny} />
                        {showAddLabel && (
                            <React.Fragment>
                                <Typography.SmallCapsTitle>{t(`${I18N_NAMESPACE}.addItems`)}</Typography.SmallCapsTitle>
                                <Divider space={Space.Tiny} />
                            </React.Fragment>
                        )}
                        <Headers.Buttons
                            blocksList={types}
                            addBlock={this.addBlock}
                        />
                        <Divider space={Space.Tiny} />
                    </React.Fragment>
                )}
            </>
        );
    }

    /**
     * function to help us with reordering the result
     */
    private reorder = (list: Block[], startIndex: number, endIndex: number) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    /**
     * start dragging reordable element
     */
    private onDragEnd = (result: OrderResult) => {
        if (!result.destination) {
            return;
        }
        const items = this.reorder(
            this.props.blocks,
            result.source.index,
            result.destination.index
        );

        // send the updated list
        this.updateBlock(items as Block[]);
    }

    private getMapping = memoizeOne((blocksList: BlockInfo[]) => {
        const { t, language } = this.props;
        return blocksList && blocksList.length > 0 && blocksList.reduce((result, blocksListItem) => {
            const blockName: string = translateStringOrObject(blocksListItem.Button.name, language, t);
            result[blocksListItem.key] = Wrapper(blocksListItem.Component, blockName, this.removeNewsBlock, this.props);
            return result
        }, {} as BlockMapping)
    })

    /**
     * Add block array position
     */
    private addBlock = (type: Block) => {
        const newBlock = cloneDeep(type);
        newBlock.id = uniqueId('block');
        this.props.onChange([
            ...this.props.blocks,
            newBlock
        ]);
    }

    /**
     * Updata block data
     */
    private updateBlock = (updatedBlocks: Block[]) => {
        this.props.onChange(updatedBlocks);
    }
}

export const BlocksEditor = withI18n(APP_TRANSLATIONS)(BlocksEditorComponent);
