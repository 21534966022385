import React from "react";
import { CTA } from "@7egend/web.core.cms/lib/dlos/cta";
import { Block } from "@7egend/web.core.cms/lib/dlos/block";
import { BlockComponentProps } from "../../interfaces";
import { Uploader } from "../../../Uploader";
import { Typography } from "../../../Typography";
import { MediaListData, MediaList } from "../../../MediaList";
import styled from "styled-components";
import { Input } from "../../../Input";
import { Button as ButtonComponent } from "../../../Button";
import { colors } from "../../../../styles";
import { withI18n, WithI18nProps } from "@7egend/web.core/lib/components/withI18n";
import { APP_TRANSLATIONS } from "../../../../locale";
import { blockTypes } from "../config";
import { Config } from "../../../../base/config";
import { useFramework } from "@7egend/web.core/lib/components/useFramework";
import { I18N_KEY_CMS_COMPONENTS_BLOCK, I18N_KEY_GLOBAL } from "../../../../base/i18n";
import { Medium } from "@7egend/web.core.media/lib/dlos";

const { attachment } = blockTypes;

const Wrapper = styled.div`
    display: grid;
    border: 1px solid ${colors.neutral.light};
    border-radius: 6px;
    padding: 16px 16px 16px 0;
`;

const AttachmentPicker = styled.div`
    grid-column: 1;
    max-width: 200px;
`

const AttachmentProperties = styled.div`
    grid-column: 2;
`

const Structure = {
    type: attachment.type,
    content: {
        style: attachment.style,
        anchor_target: {
            id: "2"
        },
    }
} as unknown as Block<CTA>;

const I18N_NAMESPACE = `${I18N_KEY_CMS_COMPONENTS_BLOCK}.types.attachment`

/**
 * 
 * HACK: This block type should be receiving the media object of the attachement.
 * 
 * When you try to preview the Attachment uploaded, is not goinf to be possible to see, only after the API update.
 * Associated Issue: #5976 - https://7egend.visualstudio.com/SportsHub-DXP/_sprints/backlog/Web/SportsHub-DXP/Milestone%202/Iteration%209?workitem=5976
 * 
 */

const AttachmentComponent: React.FC<WithI18nProps & BlockComponentProps<CTA & {
    medium: Medium
}>> = ({ className, data, updateData, t }) => {
    const { config }: { config: Config } = useFramework();
    const hasContentValue = !!data.content.value;
    const hasProperties = !!data.properties && !!data.properties.uuid;
    const hasContentMedium = !!data.content.medium && !!data.content.medium.uuid;
    const hasMedia = hasContentValue || hasProperties || hasContentMedium;

    Structure.type = config?.blockTypes?.attachment?.type ? config.blockTypes.attachment.type : Structure.type;

    return (
        <Wrapper className={className}>
            <AttachmentPicker>
                {!hasMedia &&
                    <Uploader.MediaList
                        limitSelectable={1}
                        heightLimited={hasMedia ? true : false}
                        info={
                            hasMedia ? (
                                <React.Fragment>
                                    <Typography.RegularText variant="neutral">
                                        <strong>{t(`${I18N_KEY_GLOBAL}.selectFromLibrary`)}</strong> {t(`${I18N_KEY_GLOBAL}.or`)} <strong>{t(`${I18N_KEY_GLOBAL}.uploadFiles`)}</strong>
                                    </Typography.RegularText>
                                </React.Fragment>
                            ) : (
                                ""
                            )
                        }
                        renderColumns={{
                            xs: 12,
                        }}
                        checkedMedia={(checkedMedia: MediaListData[]) =>
                            updateData({
                                ...data,
                                properties: checkedMedia[0]
                            })
                        }
                    />
                }
                {hasMedia && (
                    <MediaList
                        renderColumns={{
                            xs: 12,
                        }}
                        list={hasContentMedium ? [data.content.medium as any] : hasProperties ? [data.properties as any] : []}
                        isCheckable={false}
                        deleteMedia={(item: MediaListData) => {
                            updateData({
                                content: { ...Structure.content },
                                type: Structure.type,
                                id: item.uuid,
                            } as any)
                        }
                        }
                    />
                )}
            </AttachmentPicker>
            <AttachmentProperties>
                <Input
                    name="title"
                    type="text"
                    label={t(`${I18N_NAMESPACE}.inputTitleLabel`)}
                    placeholder={t(`${I18N_NAMESPACE}.inputTitlePlaceholder`)}
                    value={data.content && data.content.label}
                    onChange={(label: string) => updateData({
                        ...data,
                        content: {
                            ...data.content,
                            label,
                        }
                    })}
                />
                <Input
                    name="date"
                    type="date"
                    label={t(`${I18N_NAMESPACE}.inputDateLabel`)}
                    placeholder={t(`${I18N_NAMESPACE}.inputDatePlaceholder`)}
                    value={data.content && data.content.description}
                    onChange={(description: string) => updateData({
                        ...data,
                        content: {
                            ...data.content,
                            description,
                        }
                    })}
                    action={
                        <React.Fragment>
                            <ButtonComponent
                                icon={"calendar_today"}
                            />
                        </React.Fragment>
                    }
                />
            </AttachmentProperties>
        </Wrapper>
    );
}

const Component = withI18n(APP_TRANSLATIONS)(AttachmentComponent);

const Button = {
    icon: "attach_file",
    name: {
        key: `${I18N_NAMESPACE}.name`,
        en: "Attachment",
        es: "Adjunto archivo",
        pt: "Anexo",
    },
};

export const Attachment = {
    key: `${attachment.type}_${attachment.style}`,
    Structure,
    Component,
    Button,
};
