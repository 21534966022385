import styled from "styled-components";

export const StyledOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
`;

export const StyledDropdown = styled.div`
    position: relative;
    box-sizing: border-box;

    > div:first-child {
        display: inline-block;
    }
`;
