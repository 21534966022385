import styled, { css } from "styled-components";
import { colors } from "../../styles";
import { StyledButton } from "../Button/styles";

const Summary = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 18px 24px;
    cursor: pointer;

    ${StyledButton} {
        padding: 0;
        min-height: 0;
    }
`;

const Details = styled.div<{ isExpanded?: boolean}>`
    transition: all .1s ease-in-out;
    display: none;
    padding: 32px 12px;
    border-top: 1px solid ${colors.neutral.light};

    ${({ isExpanded }) => isExpanded && css`
        display: block;
    `}

    &::after {
        content: "";
        display: block;
        width: 100%;
        white-space: nowrap;
        clear: both;
    }
`;

const Wrapper = styled.div`
    box-shadow: 6px 12px 20px 0 rgba(0,0,0,0.04);
    background-color: ${colors.light};
    border-radius: 10px;
    clear: both;
`;

/** ## Styled Expansion Panel */
export const Styled = {
    /** ### Panel Summary */
    Summary,
    /** ### Panel Details */
    Details,
    /** ### Panel Wrapper */
    Wrapper,
}
