import styled from "styled-components";
import { colors, font } from "../../styles";
import { Label } from "../Label";
import { Input as InputComponent } from "@7egend/web.core.controls/lib/components/input/input";
import { StyledDropdown } from "../Dropdown/styles";

const Labels = styled.div`
    > * {
        margin: 0 8px 7px 0;
        &:last-child {
            margin-right: 0;
        }
    }
`;

const Input = styled(InputComponent)`
    border: 0;
    background: none;
    padding: 0;
    margin: 5px 0;
    outline: none !important;
    font-size: ${font.size.sm};
    color: ${colors.neutral.darker};
    font-weight: ${font.weight.light};
    line-height: 24px;
    width: 100%;
    display: block;

    &::-webkit-input-placeholder {
        color: ${colors.neutral.dark};
    }

    &:-moz-placeholder {
        color: ${colors.neutral.dark};
    }

    &::-moz-placeholder {
        color: ${colors.neutral.dark};
    }

    &:-ms-input-placeholder {
        color: ${colors.neutral.dark};
    }
`;

const Options = styled.div`
    overflow-y: auto;
    max-height: 300px;
    display: inline-flex;
    flex-direction: column;
`;

const Option = styled(Label)`
    margin: 5px 0;
    cursor: pointer;
`;

const Wrapper = styled.div`
    border-bottom: 1px solid ${colors.neutral.light};
    width: 100%;
`;

const Chips = styled.div`
    ${StyledDropdown} > div:first-child {
        display: block;
    }
`

export const Styled = {
    Wrapper,
    Labels,
    Input,
    Options,
    Option,
    Chips
};
