import React from "react";
import { Styled } from "./styles";
import { Button } from "../Button";

interface ExpansionPanelProps {
    className?: string
    /** Panel Summary */
    summary:
    | React.ReactComponentElement<any>
    | React.ComponentType<any>
    | Element;
    /** Panel children */
    children: any;
    /** Panel initialy expanded */
    isExpanded?: boolean;
}

/**
 *
 * # Expansion Panel Component
 *
 * ## How to use:
 *
 * ```jsx
 * <ExpansionPanel
 *  summary={"your title here"}
 * >
 * ... your content here
 * </ExpansionPanel>
 * ```
 */
export const ExpansionPanel = ({
    className,
    summary,
    isExpanded: isExpandedByProps,
    children,
}: ExpansionPanelProps) => {
    const [isExpanded, setExpanded] = React.useState(
        isExpandedByProps || false
    );

    const toggleCollapse = React.useCallback(() => {
        setExpanded(!isExpanded);
    }, [isExpanded]);

    return (
        <Styled.Wrapper className={className}>
            <Styled.Summary onClick={toggleCollapse}>
                <div style={{ flex: 1 }} onClick={e => e.stopPropagation()}>{summary}</div>
                <Button
                    onClick={toggleCollapse}
                    icon={
                        isExpanded ? "keyboard_arrow_up" : "keyboard_arrow_down"
                    }
                />
            </Styled.Summary>
            <Styled.Details isExpanded={isExpanded}>{children}</Styled.Details>
        </Styled.Wrapper>
    );
};
