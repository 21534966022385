import styled from "styled-components";
import { Button } from "../Button"
import { Link as LinkComponent } from "../Link"
import { font, colors } from "../../styles"

const Action = styled(Button)`
    & {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        min-height: 0;
        padding: 0;
        min-width: 160px;
        padding: 0 0 16px;
        min-height: auto;

        h6 {
            transition: font-weight .1s ease;
        }

        span {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
        }

        &:hover {
            h6 {
                font-weight: ${font.weight.semiBold};
            }

            i {
                color: ${colors.primary.lighter} !important;
            }
        }

        &:last-child{
            padding: 0;
        }

        i {
            font-size: 16px !important;
            color: ${colors.primary.lighter};
        }
    }
`

const Link = styled(LinkComponent)`
& {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        min-height: 0;
        padding: 0;
        min-width: 160px;
        padding: 0 0 16px;
        min-height: auto;

        h6 {
            transition: font-weight .1s ease;
        }

        span {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
        }

        &:hover {
            h6 {
                font-weight: ${font.weight.semiBold};
            }

            i {
                color: ${colors.primary.lighter} !important;
            }
        }

        &:last-child{
            padding: 0;
        }

        i {
            font-size: 16px !important;
            color: ${colors.primary.lighter};
        }
    }
`;

export const StyledMediaList = {
    Action,
    Link
}
