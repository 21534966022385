import React, { PureComponent } from "react"
import styled from "styled-components";
import { queries } from "../../../styles";

export interface WrapperHeaderProps {
    className?: string;
}

export const StyledWrapperHeader = styled.div`
    min-height: 60px;
    padding: 20px 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    ${queries.maxMD} {
        padding: 16px;  
    }
`;

export class WrapperHeader extends PureComponent<WrapperHeaderProps> {
    public render() {
        if (this.props.children) {
            return (
                <StyledWrapperHeader className={this.props.className}>
                    {this.props.children}
                </StyledWrapperHeader>
            );
        }

        return null;
    }
}
