import React, { PureComponent } from "react"
import classNames from "classnames";
import styled, { css } from "styled-components";
import { Icon as CoreIcon } from "@7egend/web.core.controls/lib/components/icon/icon";

export interface IconProps {
    /** icon name (from material icons) */
    icon: string
    /** Icon color */
    color?: string
    /** className */
    className?: string
}

interface StyledIconProps {
    color?: string
}

const StyledIcon = styled.i<StyledIconProps>`
    font-size: 16px;

    ${({ color }) => color && css`
        color: ${color};
    `}
`

/**
 * # Icon Component
 *
 * ## How to use:
 *
 * ```tsx
 * <Icon icon="check" />
 * ```
 */
export class Icon extends PureComponent<IconProps> {

    public render() {
        const { icon, color } = this.props;

        if (icon) {
            return <CoreIcon><StyledIcon color={color} className={classNames("material-icons", [this.props.className])}>{icon}</StyledIcon></CoreIcon>
        }

        return null;
    }
}
