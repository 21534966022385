import React from "react";
import { CoreComponent } from "@7egend/web.core/lib/base";
import GoogleMapReact, { ClickEventValue, Coords as CoordsCore } from 'google-map-react';
import styled, { css } from "styled-components";
import { Icon } from "../Icon";
import { colors } from "../../styles";

export type Coords = CoordsCore;

interface Options {
    styles?: any[];
    height?: string;
    width?: string;
    markerIcon?: string;
}

interface GoogleMapProps {
    /** maps api key */
    apiKey: string;

    /** map position of location */
    location: Coords[];

    /** map zoom */
    zoom: number

    /** override map wrapper styling */
    className?: string;

    /** on map click send coordinates */
    onClick?: (values: ClickEventValue) => void;

    /** map options */
    options?: Options;
}

const StyledMarker = styled(Icon)`
    & {
        font-size: 35px;
    }
`;

const StyledWrapper = styled.div<Options>`
    min-height: 100px;
    width: ${({ width }) => width ? css`${width}` : "100%"};
    height: ${({ height }) => height ? css`${height}` : "100px"};
    display: block;
`;

const Marker = ({ icon }: any) => {
    return (
        <StyledMarker
            icon={icon || "location_on"}
            color={colors.primary.lighter}
        />
    )
};


/**
 * # GoogleMap Component
 * 
 * ## How to use:
 * 
 * ```jsx
 *  <GoogleMap
 *      apiKey={CONFIG.MAPS.API_KEY}
 *      location={{
 *          lat: 0.00,
 *          lng: 0.00
 *      }}
 *      zoom={15}
 *      options={{
 *          styles: CONFIG.MAPS.STYLES
 *      }}
 *  />
 * ```
 */
export class GoogleMap extends CoreComponent<GoogleMapProps> {

    public render() {
        const { location, zoom, className, options, apiKey } = this.props;

        if (!location.length) {
            return null;
        }

        return (
            <StyledWrapper
                className={className}
                width={options && options.width}
                height={options && options.height}
            >
                <GoogleMapReact
                    options={options}
                    bootstrapURLKeys={{ key: apiKey }}
                    center={location[0]}
                    zoom={zoom}
                    onClick={this.onClick}
                >
                    {location.map((loc) => {
                        return (
                            <Marker
                                key={`location-${loc.lat}-${loc.lng}`}
                                lat={loc.lat}
                                lng={loc.lng}
                            />
                        )
                    })}
                </GoogleMapReact>
            </StyledWrapper>
        )
    }

    private onClick = (values: ClickEventValue) => {
        if (this.props.onClick) {
            this.props.onClick(values);
        }
    }
}
