import { Store } from "@7egend/web.core";
import { SessionState, SessionActions } from "./types";

const initialState: SessionState = {
    user: undefined,
    session: undefined,
    loading: false,
    error: {
        http: "",
        code: "",
        message: "",
    },
}

const root: Store.Reducer<SessionState> = (state: SessionState = initialState, action: any) => {
    switch ((action as SessionActions).type) {
        case "@@core.security/CREATE_SESSION_KEYSECRET_START":
        case "@@core.security/GET_SESSION_KEYSECRET_START":
        case "@@core.security/UPDATE_USER_START":
        case "@@core.security/UPDATE_USER_IMAGE_START":
            return {...state, loading: true, error: undefined};
        case "@@core.security/CREATE_SESSION_KEYSECRET_SUCCESS":
        case "@@core.security/GET_SESSION_KEYSECRET_SUCCESS":
            return {...state, loading: false, user: action.payload.user, session: action.payload, error: undefined};
        case "@@core.security/CREATE_SESSION_KEYSECRET_ERROR":
        case "@@core.security/GET_SESSION_KEYSECRET_ERROR":
            return {...state, loading: false, user: undefined, session: undefined, error: action.payload.body};
        case "@@core.security/UPDATE_USER_SUCCESS":
            return {...state, loading: false, user: action.payload, error: undefined};
        case "@@core.security/UPDATE_USER_ERROR":
            return {...state, loading: false, error: action.payload};
        case "@@core.security/LOGOUT_USER_SUCCESS":
            return {...state, loading: false, user: undefined, session: undefined, error: undefined};
        case "@@core.security/FORGOT_PASSWORD_SUCCESS":
            return {...state, loading: false, forgot: true};
        case "@@core.security/FORGOT_PASSWORD_ERROR":
            return {...state, loading: false, forgot: false, error: action.payload.body};
        case "@@core.security/UPDATE_USER_IMAGE_SUCCESS":
            return {...state, loading: false, user: { ...state.user!, profile: action.payload.profile }};
        default:
            return state;
    }
}

export { root }
