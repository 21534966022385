import {
    DloInput, QueryQLParams, buildQLQuery, flattenComplexValues, DloOutput,
} from "@7egend/web.core/lib/extensions/dlo";
import { Block } from "@7egend/web.core.cms/lib/dlos/block";
import { generateBlocks } from "../../utils"
import { Page } from "@7egend/web.core.cms/lib/dlos/page";

/** Dynamic Page DLO */
export interface DynamicPage extends Page {
    /** page introduction */
    introduction: string;

    /** page name [DataTable] */
    name?: string;

    /** page active */
    active: boolean;

    /** page slug */
    slug: string;

    /** page blocks */
    blocks: Block[];
}

interface DynamicPageInputParams {
    /** page title */
    title: string;
    /** page introduction */
    introduction: string;
    /** page origin */
    origin: number;
    /** current language */
    locale: string;
}

const MEDIUM_FIELDS: QueryQLParams = [
    "uuid",
    "medium_type",
    "title",
    "panoramic",
    "image",
    "normal",
    "thumb",
    "url",
]

const BLOCK_CONTENT_FIELDS: QueryQLParams = [
    "id",
    "uuid",
    "secret",
    "style",
    "date",

    "name",
    "title",
    "subtitle",
    "label",
    "description",
    "value",

    "text",
    "text_highlight",

    "cta_label",
    "cta_description",
    "cta_value",
    "cta_anchor_target_id",
    "link",
    ["anchor_target",
        ["id", "name", "value"],
    ],

    "line1",
    "line2",
    "line3",
    ["booster_media",
        ["format",
            ["medium", MEDIUM_FIELDS],
        ]
    ],

    ...MEDIUM_FIELDS,
    ["media", MEDIUM_FIELDS],
    ["medium", MEDIUM_FIELDS],
    ["header", MEDIUM_FIELDS],
    ["cover", MEDIUM_FIELDS],
    ["picture", MEDIUM_FIELDS],
    ["picture_secondary", MEDIUM_FIELDS],
]

const DYNAMIC_PAGE_FIELDS: QueryQLParams = [
    "id",
    "active",
    "internal_name",
    "title",
    "slug",
    "introduction",
    "style",
    ["cover", MEDIUM_FIELDS],
    ["blocks", [
        "id",
        "type",
        ["content", [
            ...BLOCK_CONTENT_FIELDS,
            ["blocks", [
                "id",
                "type",
                "style",
                ["content", BLOCK_CONTENT_FIELDS]
            ]]
        ]]
    ]]
];

/**
 * # Dynamic Pages Get all
 */
export class DynamicPageGetAllInput extends DloInput<{}> {
    public timeStamp = Date.now();
    public authentication = true;
    public uri = "cms-pages/datatables?draw=${timeStamp}";
}

/**
 * # Dynamic Pages Delete
 */
export class DynamicPageDeleteInput extends DloInput<{
    id: string | number;
}> {
    public method = "DELETE";
    public uri = "cms-pages/${id}";
}

/**
 * # Dynamic Pages Create <Input>
 */
export class DynamicPageCreateInput extends DloInput<DynamicPageInputParams> {
    public method = "POST";
    public uri = "cms-pages";
    public fields = buildQLQuery(DYNAMIC_PAGE_FIELDS);
    public transform = (input: any) => {
        flattenComplexValues(input.body, "cover", "uuid");
        input.body.fields = input.fields;
        input.body.blocks = generateBlocks(input.body.blocks, input.body.locale);
        return input;
    };
}

/**
 * # Dynamic Pages Create <Input>
 */
export class DynamicPageGetByIdInput extends DloInput<{
    id: number | string;
    locale: string;
}> {
    public uri = "cms-pages/${id}?fields=${fields}";
    public fields = buildQLQuery(DYNAMIC_PAGE_FIELDS);
}

/**
 * # Dynamic Pages Update <Input>
 */
export class DynamicPageUpdateInput extends DloInput<
    DynamicPageInputParams & {
        uuid: string;
    }
> {
    public method = "PUT";
    public uri = "cms-pages/${id}";
    public fields = buildQLQuery(DYNAMIC_PAGE_FIELDS);
    public transform = (input: any) => {
        flattenComplexValues(input.body, "cover", "uuid");
        input.body.fields = input.fields;
        input.body.blocks = generateBlocks(input.body.blocks, input.body.locale);
        return input;
    };
}

/** Album Create <Output> */
export class DynamicPageCreateOutput extends DloOutput<DynamicPage> { }
